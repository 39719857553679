import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';

import Home from '@screens/Main/Home/Home';
import { CustomTabbar } from '@organisms/CustomTabbar';
import Explore from '@screens/Main/Explore';
import { MyProfile } from '@screens/Main/MyProfile';
import UploadGame from '@screens/Main/UploadGame';
import { authTokenSelector, useStore } from '@store/index';

import { MainNavigatorParamList } from '../types';
import { isWebResponsive } from '@constants/platform';

const Tab = createBottomTabNavigator<MainNavigatorParamList>();
const MainNavigator = () => {
  const isLogedIn = useStore(authTokenSelector);

  return (
    <Tab.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName="Home"
      // eslint-disable-next-line react/no-unstable-nested-components
      tabBar={(props) => <CustomTabbar {...props} />}>
      <Tab.Screen name="Home" component={Home} />
      <Tab.Screen name="Explore" component={Explore} />
      {isWebResponsive ? null : (
        <>
          <Tab.Screen
            name="UploadGame"
            component={UploadGame}
            initialParams={{ fromCustomTabbarMenu: true }}
          />
        </>
      )}
      {isLogedIn && <Tab.Screen name="MyProfile" component={MyProfile} />}
    </Tab.Navigator>
  );
};

export default MainNavigator;
