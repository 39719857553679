import { useMutation } from 'react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { axiosGamesInstance } from '@config/api';
import { MetricsSessionParams } from '@interfaces/metricsSession';
import { isWebResponsive, PLATFORM } from '@constants/platform';

const getPlatform = () => {
  if (isWebResponsive) return 'WEB_MOBILE';
  if (PLATFORM === 'IOS') return 'IOS';
  if (PLATFORM === 'ANDROID') return 'ANDROID';
  return 'WEB_DESKTOP';
};

async function metricsSession(data: MetricsSessionParams) {
  const deviceToken = await AsyncStorage.getItem('deviceToken');

  const response = await axiosGamesInstance.post<MetricsSessionParams>(
    `/metrics/sesions?user_id=${data.userId}`,
    {
      game_id: data.gameId,
      total_time: data.totalTime,
      device: getPlatform(),
      sesion_id: deviceToken || '',
    }
  );
  return response.data;
}

export function useMetricsSession() {
  return useMutation((data: MetricsSessionParams) => metricsSession(data));
}
