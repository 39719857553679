import { useMutation } from 'react-query';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';

import { axiosAuthInstance } from '@config/api';
import { RegisterParams, RegisterResponse } from '@interfaces/auth';

async function registerService(data: RegisterParams) {
  const deviceToken = await AsyncStorage.getItem('deviceToken');

  const response = await axiosAuthInstance.post<RegisterResponse>('/auth/register', {
    ...data,
    deviceToken,
    operatingSystem: Platform.OS.toUpperCase(),
  });

  return response.data;
}

export function useRegister() {
  return useMutation((data: RegisterParams) => registerService(data));
}
