/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';
import { isIos, isMobile } from '@constants/platform';
import { Carousels } from '@interfaces/home';

import pkg from '../../package.json';

async function getHomeCarousels(param: boolean, location: string) {
  const SO = isIos ? 'IOS' : 'ANDROID';
  const path = isMobile ? `/carrousel/games?location=${location}` : `/carrousel/games/desktop?isMobile=${param}&location=${location}`;
  const config = isMobile ? { headers: { so_name: SO, so_version: pkg.version } } : undefined;
  const response = await axiosGamesInstance.get<Carousels>(path, config);
  return response.data;
}

export function useHomeCarousels(param: boolean, location: string) {
  return useQuery([QueryKeys.GET_HOME_CAROUSELS, param], () => getHomeCarousels(param, location), {
    enabled: !!location
  });
}
