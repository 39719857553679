import { FilterCategoryData } from '@interfaces/explore';

export const handleFilter = (filter: FilterCategoryData, filters: FilterCategoryData[]) => {
  const oldArr = [...filters];
  const auxArr = [...filters];
  let newArr = [];
  const isIncluded = oldArr.filter((item) => item.id + item.category === filter.id + filter.category).length;
  if (isIncluded) {
    newArr = auxArr.filter((item) => item.id + item.category !== filter.id + filter.category);
  } else {
    newArr = [...auxArr, filter];
  }
  return newArr;
};
