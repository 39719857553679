import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    marginTop: moderateScale(24),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: 16,
  },
  loading: {
    justifyContent: 'center',
    marginTop: moderateScale(24),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: 16,
    minHeight: moderateScale(90),
  },
  user: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatar: {
    width: moderateScale(40),
    height: moderateScale(40),
    borderRadius: moderateScale(40 / 2),
    marginTop: 0,
    marginRight: moderateScale(16),
  },
  info: {
    flex: 1,
    flexDirection: 'column',
  },
  header: {
    minHeight: moderateScale(25),
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: moderateScale(4),
    justifyContent: 'space-between',
  },
  name: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  creator: {
    marginLeft: moderateScale(2),
    transform: [{ scale: 0.66 }],
  },
  menu: {
    flexGrow: 1,
    alignItems: 'flex-end',
  },
  comment: {
    marginTop: moderateScale(16),
    flexGrow: 1,
  },
  actions: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: moderateScale(16),
  },
  likes: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: moderateScale(16),
  },
  replyNumber: {
    marginLeft: moderateScale(5),
  },
});
