import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';

async function getGamesByCreator(creatorId: number) {
  const response = await axiosGamesInstance.get(`/studio/games/${creatorId}`);
  return response.data;
}

export function useGetGamesByCreator(userId: number, userType: string) {
  return useQuery([QueryKeys.GET_GAMES_CREATOR, userId, userType], () => getGamesByCreator(userId), {
    enabled: !!userId && userType === 'CREATOR',
  });
}
