import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { useStore, notificationsModalOpenSelector, toggleNotificationsModalHandler } from '@store/index';
import NotificationBell from '@assets/svg/NotificationBell';
import { useNotificationsUnreadCount } from '@services/useNotifications';
import { View, Pressable } from 'react-native';
import CustomText from '@atoms/CustomText';
import { styles } from './styles';
import { isWebResponsive } from '@constants/platform';
import CloseIcon from '@assets/svg/CloseIcon';
import { Colors } from '@theme';

export const NotificationsButton = () => {
  const notificationsModalOpen = useStore(notificationsModalOpenSelector);
  const toggleNotificationsModal = useStore(toggleNotificationsModalHandler);

  const {
    data: unreadCount,
    isLoading: isLoadingCount,
    refetch: refetechNotificationsUnreadCount,
  } = useNotificationsUnreadCount();

  useFocusEffect(
    useCallback(() => {
      refetechNotificationsUnreadCount();
    }, [refetechNotificationsUnreadCount])
  );

  return (
    <Pressable
      hitSlop={20}
      style={[styles.notificationButton, notificationsModalOpen && styles.notificationActiveButton]}
      onPress={toggleNotificationsModal}>
      {notificationsModalOpen ? (
        <CloseIcon fill={Colors.limeGreen} />
      ) : (
        <NotificationBell style={[isWebResponsive && styles.iconSize]} />
      )}
      {!isLoadingCount && unreadCount?.totalCount > 0 && (
        <View style={styles.notificationUnreads}>
          <CustomText size="medium">{unreadCount.totalCount}</CustomText>
        </View>
      )}
    </Pressable>
  );
};
