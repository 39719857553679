import { StyleSheet } from 'react-native';

import { SCREEN_WIDTH, isDesktop } from '@constants/platform';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    width: (SCREEN_WIDTH - moderateScale(20) * 2) / 2,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginBottom: moderateScale(16),
    ...(isDesktop && {
      width: '50%',
    }),
  },
  check: {
    width: moderateScale(24),
    height: moderateScale(24),
    borderRadius: moderateScale(6),
    borderWidth: moderateScale(2),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: moderateScale(12),
  },
});
