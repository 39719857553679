import React, { FC, memo } from 'react';
import { View } from 'react-native';
import Lottie from 'lottie-react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import ButtonLine from '@atoms/ButtonLine';
import { RootStackNavigator } from 'app/navigation/types';
import { setHasSeenAvatarAlert } from '@utils/avatar';
import { useMyProfile } from '@services/useMyProfile';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const avatarFactory = require('../../../assets/lotties/avatar_factory.json');

type Props = NativeStackScreenProps<RootStackNavigator, 'AvatarAlert'>;

export const AvatarAlert: FC<Props> = ({ navigation }) => {
  const i18n = useTranslations();
  const { data: profileData } = useMyProfile();

  const onDismiss = async () => {
    await setHasSeenAvatarAlert(profileData.userName);
    navigation.goBack();
  };

  const onAccept = async () => {
    await setHasSeenAvatarAlert(profileData.userName);
    navigation.replace('ChangeAvatarFirstStep');
  };

  return (
    <View style={[styles.container]}>
      <View style={styles.content}>
        <Lottie source={avatarFactory} autoPlay loop style={styles.lottie} />
        <CustomText color="white" size="mlarge" weight="oswaldSemi" customStyle={styles.text}>
          {i18n.t('avatar.alert_title_1')} {'\n'}
          <CustomText color="limeGreen" size="mlarge" weight="oswaldSemi">
            {i18n.t('avatar.alert_title_2')}
          </CustomText>
        </CustomText>
      </View>
      <View style={styles.footer}>
        <Button onPress={onAccept} customStyle={styles.button}>
          {i18n.t('avatar.alert_cta_primary')}
        </Button>
        <ButtonLine onPress={onDismiss}>{i18n.t('avatar.alert_cta_secondary')}</ButtonLine>
      </View>
    </View>
  );
};

export default memo(AvatarAlert);
