import React from 'react';
import { Platform, Pressable, View } from 'react-native';
import RNRestart from 'react-native-restart';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import ExploreEmptyIcon from '@assets/svg/ExploreEmptyIcon';

import { styles } from './styles';

export const ErrorFallback = () => {
  const i18n = useTranslations();
  const handlePress = () => {
    if (Platform.OS !== 'web') {
      return RNRestart.Restart();
    }
    return window?.location?.assign(window?.location.origin);
  };
  return (
    <View style={[styles.container]}>
      <View style={styles.content}>
        <CustomText color="limeGreen" customStyle={[styles.textError]} size="xlarge" weight="interBold">
          {i18n.t('error.error_title')}
        </CustomText>
        <CustomText customStyle={[styles.textError]} size="big" weight="interMedium">
          {i18n.t('error.error_description')}
        </CustomText>
        <ExploreEmptyIcon style={styles.icon} />
      </View>
      <View style={styles.footer}>
        <Button customStyle={styles.restart} onPress={handlePress}>
          {i18n.t('error.try_again')}
        </Button>
        <Pressable onPress={handlePress}>
          <CustomText weight="interBold" color="greySix" customStyle={styles.backToHome} size="small">
            {i18n.t('error.back_to_home')}
          </CustomText>
        </Pressable>
      </View>
    </View>
  );
};
