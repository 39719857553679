import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';

async function validateUsernameService(data: string) {
  const response = await axiosAuthInstance.get(`/users/verify/username/${data}`);
  return response.data;
}

export function useValidateUsername() {
  return useMutation((data: string) => validateUsernameService(data));
}
