import React from 'react';
import CustomText from '@atoms/CustomText';
import { Layout } from '@organisms/Layout';
import { Spacing } from '@theme';
import { View } from 'react-native';
import { bodyTyC } from './constants';
import GoBackButton from '@molecules/GoBackButton';
import { styles } from './styles';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { RootStackNavigator } from 'app/navigation/types';
import { isWebResponsive } from '@constants/platform';

interface BodyTextProps{
  body: string[]
  id: number
}

type TermsAndConditionsProps = NativeStackScreenProps<RootStackNavigator, 'TermsAndConditions'>;

const TermsAndConditions = ({ navigation }: TermsAndConditionsProps) => {

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigation.navigate('Register');
    }
  };

  const BodyText = ({ body, id }: BodyTextProps) => (
    body.map((paragraph: string) => {
      return  (
        <CustomText 
          key={`$body-${id}`}                
          weight="interSemi"
          align='left'
          // customStyle={{marginVertical: Spacing.SMALL}}
          >
          {paragraph}
        </CustomText>)
    })
  )

  return(
    <>
      <Layout style={styles.container}>
        <View style={styles.goBack}>
          <GoBackButton goBackAction={handleGoBack} gatoLogo={false}/>
        </View>
        <CustomText 
          customStyle={{marginVertical: Spacing.MEDIUM}} 
          size="large" 
          weight="interSemi" 
          color="greySix"
          align={isWebResponsive? 'left' : 'center'}
        >
          TERMS AND CONDITIONS
        </CustomText>
        <CustomText weight='interMedium' align='left'>
          Welcome to GATO, which is provided by GATO Global Games, LLC located in New York, NY, United States or one of its affiliates (the “Platform” or “we” or “us”). 
        </CustomText>
          { bodyTyC.map(({ id, title, subtitule, body }) => (
              <View key={id}>
                {title &&
                  <CustomText
                    size='xmedium'
                    align='left'
                    customStyle={{
                      marginVertical: Spacing.SMALL
                    }} 
                  >
                    {title}
                  </CustomText>
                }
                {subtitule && 
                  <CustomText
                    weight='interMedium' 
                    align='left'
                    customStyle={{
                      textDecorationLine: 'underline', 
                      marginVertical: Spacing.SMALL
                    }} 
                  >
                    {subtitule}
                  </CustomText>
                }
                <BodyText body={body} id={id} />
              </View>
            ))
          }
          <CustomText
            weight="interSemi"
            customStyle={{marginVertical: Spacing.SMALL}}
            align='left'
          >
            These terms and conditions are effective as of December 20, 2022.
          </CustomText>
      </Layout>
    </>
  )
};

export default TermsAndConditions;
