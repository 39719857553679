import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';

async function validateEmailService(data: string) {
  const response = await axiosAuthInstance.get(`/users/verify/mail/${data}`);
  return response.data;
}

export function useValidateEmail() {
  return useMutation((data: string) => validateEmailService(data));
}
