import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CatIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={23} height={20} fill="none" {...props}>
    <Path
      d="M3.953 4.174a.76.76 0 011.14-.658l3.702 2.138a.76.76 0 010 1.316L5.093 9.107a.76.76 0 01-1.14-.657V4.174zM18.575 4.174a.76.76 0 00-1.14-.658l-3.702 2.138a.76.76 0 000 1.316l3.702 2.137a.76.76 0 001.14-.657V4.174z"
      fill={fill || '#E5F68C'}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.615 5.422a6.836 6.836 0 00-6.836 6.836v.38a6.836 6.836 0 006.836 6.836h3.418a6.836 6.836 0 006.836-6.836v-.38a6.836 6.836 0 00-6.836-6.836H9.615zm3.194 11.203c-.558 0-1.083-.236-1.486-.662-.403.426-.928.662-1.486.662-.855 0-1.63-.555-2.013-1.494a.57.57 0 111.055-.43c.236.58.637.785.958.785.318 0 .716-.201.953-.77a.571.571 0 01.139-.212l-.65-1.008a.437.437 0 01.366-.673h1.357c.346 0 .554.383.367.673l-.651 1.009c.06.059.107.13.137.207.237.572.635.774.954.774.32 0 .722-.205.959-.785a.57.57 0 111.054.43c-.382.939-1.157 1.494-2.013 1.494z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default CatIcon;
