import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';

export const styles = StyleSheet.create({
  menuButton: {
    marginLeft: Spacing.SMALL,
    height: 50,
    width: 50,
    borderRadius: 5,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.blackTwo,
  },
  activeMenu: {
    borderWidth: 2,
    borderColor: Colors.limeGreen,
  },
});
