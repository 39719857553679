import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    width: moderateScale(48),
    height: moderateScale(24),
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: moderateScale(32),
    backgroundColor: Colors.darkGrey,
  },
  switch: {
    width: moderateScale(20),
    height: moderateScale(20),
    borderRadius: moderateScale(20) / 2,
    backgroundColor: Colors.greyTwo,
  },
});
