import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale, verticalScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  splashContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.black,
  },
  splashLogoImage: {
    // marginTop: verticalScale(-30),
    height: moderateScale(200),
    width: moderateScale(200),
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
