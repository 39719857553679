import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Verified = (props: SvgProps) => (
  <Svg width={25} height={25} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.096 22.803c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z"
      fill="#387CFF"
    />
    <Path
      d="M8.096 13.205l1.889 1.6a1.03 1.03 0 001.466-.128l4.145-4.874"
      stroke="#FCFCFC"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Verified;
