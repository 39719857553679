import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  text: {
    marginLeft: moderateScale(12),
    flex: 1,
    maxHeight: moderateScale(45),
  },
});
