import { StyleSheet } from 'react-native';

import { SCREEN_HEIGHT, isDesktop, isMobile } from '@constants/platform';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: moderateScale(30),
    ...(isDesktop && { minHeight: '45vh' }),
  },
  title: {
    marginTop: moderateScale(16),
  },
  emptyContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: isMobile ? SCREEN_HEIGHT - moderateScale(120) : '100%',
  },
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    zIndex: 3,
  },
  skeletonItem: {
    marginTop: moderateScale(20),
    ...(isDesktop && { width: '100%' }),
  },
  scrollViewUnreadNotifications: {
    marginBottom: moderateScale(80),
  },
  scrollView: {
    marginBottom: moderateScale(600),
  },
});
