import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { whiteOpacity } from 'app/theme/colors';

export const styles = StyleSheet.create({
  container: {
    marginBottom: Spacing.BANNERBOTTOM,
    borderRadius: moderateScale(16),
    overflow: 'hidden',
    backgroundColor: Colors.greyFour,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: '100%',
  },
  absolute: {
    position: 'absolute',
    top: moderateScale(14),
    left: moderateScale(14),
    paddingHorizontal: moderateScale(10),
    paddingVertical: moderateScale(5),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: moderateScale(26),
    backgroundColor: whiteOpacity(0.5),
  },
});
