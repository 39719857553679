import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { isMobile } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: moderateVerticalScale(16),
  },
  dot: {
    width: isMobile ? moderateScale(6) : 8,
    height: isMobile ? moderateScale(6) : 8,
    borderRadius: isMobile ? moderateScale(46) : 4,
    marginHorizontal: isMobile ? moderateScale(2.3) : 4,
  },
});
