import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  skeletonItem: {
    marginHorizontal: moderateScale(20),
    marginTop: moderateScale(20),
  },
});
