import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, Pressable, View } from 'react-native';
import { AxiosError } from 'axios';

import EmailInputIcon from '@assets/svg/EmailInputIcon';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { LoginParams } from '@interfaces/auth';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import { useLogin } from '@services/useLogin';
import { setAuthToken, setRefreshToken } from '@utils/session';
import toast from '@utils/toast';
import { RootStackNavigator } from 'app/navigation/types';
import { setAuthTokenHandler, useStore } from '@store/index';
import { useResendValidateCode } from '@services/useResendValidateCode';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';
import { Colors } from '@theme';
import GATOLogo from '@assets/svg/GATOLogo';

import Onboarding from './Onboarding/index';
import { styles } from './styles';

type LoginProps = NativeStackScreenProps<RootStackNavigator, 'Login'>;

export const Login = ({ navigation }: LoginProps) => {
  useStatusBar('light');
  const i18n = useTranslations();
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginParams>();

  const login = useLogin();
  const resendCodeVerifyEmail = useResendValidateCode();
  const isEmpty = !watch('emailOrUsername') || !watch('password');
  const setStoreToken = useStore(setAuthTokenHandler);

  const handleRegister = () => {
    navigation.navigate('Register');
  };

  const handleForgotPassword = () => {
    navigation.navigate('ForgotPassword');
  };

  function onSubmit(values: LoginParams) {
    Keyboard.dismiss();

    login.mutate(
      { ...values, remember: true },
      {
        onSuccess: (response) => {
          setAuthToken(response.access_token);
          setStoreToken(response.access_token);
          setRefreshToken(response.refreshToken);
          navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
          });
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const errorCode = error?.response?.data.errorCode;
            const message = i18n.t(error?.response?.data.translate);
            if (errorCode === 'ERROR_NOT_VERIFIED') {
              resendCodeVerifyEmail.mutate(
                { emailOrUsername: values.emailOrUsername },
                {
                  onSuccess: (response) => {
                    navigation.navigate('ValidateCode', {
                      userId: response.id,
                      userEmail: response.mail,
                      type: 'VERIFY',
                      password: values.password,
                    });
                  },
                }
              );
            }
            toast.danger({
              title: message,
              icon: 'error',
            });
          }
        },
      }
    );
  }

  const textAlign = isDesktop && !isWebResponsive ? 'center' : 'left';

  const refInputEmail = React.useRef(null);
  const refInputPassword = React.useRef(null);

  return (
    <Layout
      withScroll
      verticallyCentered={isDesktop}
      padding={isMobile}
      backgroundColor={isDesktop ? Colors.blackThree : undefined}>
      <View style={[styles.container, isDesktop && styles.desktopContainer]}>
        {isDesktop && !isWebResponsive ? (
          <View style={styles.onboarding}>
            <Onboarding />
          </View>
        ) : null}
        <DesktopCenteredContainer style={styles.desktopForm}>
          <View>
            {isDesktop && !isWebResponsive ? <GATOLogo style={styles.logoImage} /> : null}
            <CustomText weight="interSemi" align={textAlign} size="xlarge">
              {`${i18n.t('login.title_1')} `}
              <CustomText weight="interSemi" size="xlarge" color="limeGreen">
                {i18n.t('login.title_2')}
              </CustomText>
            </CustomText>
            <CustomText customStyle={styles.description} weight="interSemi" align={textAlign} size="xbig">
              {i18n.t('login.welcome_back')}
            </CustomText>
            <Field
              icon={EmailInputIcon}
              autoFocus
              name="emailOrUsername"
              type="email-address"
              control={control}
              placeholder={i18n.t('login.email_placeholder').toString()}
              getValues={getValues}
              rules={{
                required: i18n.t('login.required').toString(),
              }}
              error={errors}
              customRef={refInputEmail}
            />
            <Field
              icon={PasswordInputIcon}
              name="password"
              control={control}
              placeholder={i18n.t('login.password_placeholder').toString()}
              rules={{
                required: i18n.t('login.required').toString(),
                minLength: {
                  value: 8,
                  message: i18n.t('login.password_min_error'),
                },
              }}
              secure
              getValues={getValues}
              error={errors}
              customRef={refInputPassword}
            />
            <Pressable hitSlop={10} onPress={handleForgotPassword}>
              <CustomText weight="interSemi" customStyle={styles.underline} color="greySix" size="medium">
                {i18n.t('login.forgot_password')}
              </CustomText>
            </Pressable>
          </View>
          <View style={styles.footer}>
            <View style={styles.register}>
              <CustomText color="greySix" size="medium" weight="interBold">
                {`${i18n.t('login.dont_have_account')}  `}
              </CustomText>
              <Pressable hitSlop={10} onPress={handleRegister}>
                <CustomText customStyle={styles.underline} size="medium" color="limeGreen" weight="interBold">
                  {i18n.t('login.register')}
                </CustomText>
              </Pressable>
            </View>
            <Button
              disabled={isEmpty}
              loading={login.isLoading}
              customStyle={styles.button}
              onPress={handleSubmit(onSubmit)}>
              {i18n.t('login.button_action')}
            </Button>
          </View>
        </DesktopCenteredContainer>
      </View>
    </Layout>
  );
};
