import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: isWebResponsive ? 0 : moderateScale(16),
  },
  title: {
    marginBottom: moderateScale(30),
  },
  itemContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'baseline',
  },
  item: {
    width: moderateScale(254),
    height: moderateScale(235),
    marginRight: moderateScale(24),
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 16,
    overflow: 'hidden',
  },
  tag: {
    position: 'absolute',
    top: 0,
  },
  description: {
    position: 'absolute',
    bottom: moderateScale(15),
    left: moderateScale(15),
  },
  creator: {
    marginTop: moderateScale(6),
  },
  emptyContainer: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: moderateScale(207),
  },
});
