import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    borderRadius: moderateScale(20),
    width: moderateScale(260),
    backgroundColor: Colors.blackTwo,
    overflow: 'hidden',
    zIndex: 1,
  },
  modalVisible: { zIndex: 1 },
  item: {
    display: 'flex',
    paddingHorizontal: Spacing.TINY * 3,
    paddingVertical: Spacing.TINY * 2,
    flexDirection: 'row',
    alignItems: 'center',
  },
  firstItem: {
    paddingTop: Spacing.TINY * 3,
  },
  lastItem: {
    paddingBottom: Spacing.TINY * 3,
  },
  icon: {
    marginRight: Spacing.SMALL,
  },
  title: {
    flex: 1,
  },
  chevron: {
    transform: [{ scale: 0.5 }, { rotate: '180deg' }],
  },
  active: {
    color: Colors.greenLight,
  },
});
