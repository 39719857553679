import { Platform, Share, ShareAction as IShareAction } from 'react-native';
import * as Clipboard from 'expo-clipboard';

import { isWebResponsive } from '@constants/platform';

import toast from './toast';
import { mediumVibration } from './vibration';
import { getLanguage } from './getLanguage';

export const URLs = {
  ios: 'https://apps.apple.com/ar/app/id6444835134',
  android: 'https://play.google.com/store/apps/details?id=com.gatogames.app',
  web: 'https://gato.us',
};

export const appURL = Platform.select(URLs);

const ShareAction = async (
  messageText?: string,
  shared?: (activityType?: string) => void,
  dismissed?: () => void
) => {
  try {
    const message =
      messageText ||
      `${getLanguage() === 'en' ? "Let's play on GATO! ^-^ " : '¡Juguemos en GATO! ^-^ ¡GATO '}${appURL}`;

    const isShare = navigator.share; // Returns undefined in desktop browsers

    // We prioritize the sharing API
    if (isShare && isWebResponsive) {
      const result: IShareAction | undefined = await Share.share({ message }); // Returns undefined too

      if (result?.action === Share.sharedAction) {
        if (shared) shared(result.activityType);
      } else if (result?.action === Share.dismissedAction) {
        if (dismissed) dismissed();
      }

      return;
    }

    // If the sharing API is not available, we fallback to the Clipboard API
    await Clipboard.setStringAsync(message);
    toast.success({
      title: getLanguage() === 'en' ? 'Link copied to clipboard!' : 'Enlace copiado al portapapeles',
      icon: 'success',
    });
    mediumVibration();
  } catch (error) {
    console.log(error);

    // TODO: Always the user cancel the action, the DOMException: Share canceled is thrown, figure out how to handle it
    // toast.danger({
    //   title: getLanguage() === 'en' ? 'Error sharing' : 'Error al compartir',
    //   icon: 'error',
    // });
  }
};

export default ShareAction;
