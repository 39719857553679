import React, { useEffect, useRef, useState } from 'react';
import { Animated, DeviceEventEmitter, Pressable, View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { IconType, toastColors, ToastOptions, ToastType } from '@utils/toast';
import ToastErrorIcon from '@assets/svg/ToastErrorIcon';
import ToastSuccessIcon from '@assets/svg/ToastSuccessIcon';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { verticalScale } from '@utils/scaling';
import { SHOW_TOAST_MESSAGE } from '@constants/toast';
import CloseIcon from '@assets/svg/CloseIcon';

import { styles } from './styles';

const AnimatedButton = Animated.createAnimatedComponent(Pressable);

const Icon = ({ type }: { type: string }) => {
  switch (type) {
    case 'success':
      return <ToastSuccessIcon />;
    case 'error':
    default:
      return <ToastErrorIcon />;
  }
};

export const Toast = () => {
  // const { top } = useSafeAreaInsets();
  const y = useRef(new Animated.Value(0)).current;
  const timeOutRef = useRef<NodeJS.Timer>();
  const [messageType, setMessageType] = useState<ToastType | null>(null);
  const [title, setTitle] = useState<string | null>(null);
  const [underlineTitle, setUnderlineTitle] = useState<JSX.Element>();
  const [icon, setIcon] = useState<IconType | null>(null);
  const [timeOutDuration, setTimeOutDuration] = useState<number>(5000);
  const onNewToast = (data: ToastOptions) => {
    if (data?.duration) {
      setTimeOutDuration(data.duration);
    }
    setTitle(data.title);
    setIcon(data.icon);
    setMessageType(data.type);
    setUnderlineTitle(data.underlineTitle);
  };
  const closeToast = () => {
    setTitle(null);
    setMessageType(null);
    setIcon(null);
    setTimeOutDuration(5000);
  };
  const changeOpacity = (value: number) => {
    Animated.timing(y, {
      toValue: value,
      useNativeDriver: isMobile,
      duration: 500,
    }).start();
  };
  const opacity = y.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
    extrapolate: 'clamp',
  });
  useEffect(() => {
    if (title) {
      timeOutRef.current = setInterval(() => {
        if (timeOutDuration === 0) {
          closeToast();
        } else {
          setTimeOutDuration((prev) => prev - 1000);
        }
      }, 1000);
    }
    return () => {
      clearInterval(timeOutRef.current!);
    };
  }, [title, timeOutDuration]);

  useEffect(() => {
    if (title) {
      changeOpacity(1);
      setTimeout(() => {
        changeOpacity(0);
      }, timeOutDuration);
    } else {
      changeOpacity(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, y, changeOpacity]);

  useEffect(() => {
    DeviceEventEmitter.addListener(SHOW_TOAST_MESSAGE, onNewToast);
    return () => {
      DeviceEventEmitter.removeAllListeners();
    };
  }, []);
  if (!title) {
    return null;
  }
  return (
    <AnimatedButton
      onPress={closeToast}
      style={[
        styles.container,
        isDesktop && !isWebResponsive && styles.containerDesktop,
        { backgroundColor: toastColors[messageType!] },
        { opacity },
        isMobile || isWebResponsive ? { bottom: verticalScale(20) } : { top: Spacing.LARGE },
      ]}>
      <View style={styles.row}>
        <Icon type={icon as IconType} />
        <View style={styles.toast}>
          <CustomText
            weight="interSemi"
            size="medium"
            color={messageType === 'info' ? 'black' : 'white'}
            customStyle={styles.text}
            align="left">
            {title}
            {underlineTitle}
          </CustomText>
        </View>
        <CloseIcon fill={Colors.white} />
      </View>
    </AnimatedButton>
  );
};
