import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import Lottie from 'lottie-react-native';
import { MotiView } from 'moti';
import { useFocusEffect } from '@react-navigation/core';
import { useMutation } from 'react-query';
import * as Haptics from 'expo-haptics';

import ChangeAvatarLayout from '@templates/ChangeAvatarLayout';
import Button from '@atoms/Button';
import { RootStackNavigator } from 'app/navigation/types';
import { useMyProfile } from '@services/useMyProfile';
import CustomText from '@atoms/CustomText';
import AvatarImage from '@molecules/AvatarImage';
import * as UserService from '@services/UserService';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';
import { useTranslations } from '@hooks/useTranslation';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../../assets/lotties/confetti_web.json');

const ChangeAvatarThirdStep = ({ selectedAvatar, selectedBackground, userName }) => {
  const i18n = useTranslations();
  const animation = useRef<Lottie>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      animation.current?.play();
    }, 1500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <View pointerEvents="none" style={styles.lottie}>
        <Lottie ref={animation} source={lottie} />
      </View>
      <View style={styles.content}>
        <MotiView
          style={styles.header}
          from={{ top: -50, opacity: 0 }}
          animate={{ top: 0, opacity: 1 }}
          transition={{
            type: 'spring',
            delay: 1000,
          }}>
          <CustomText weight="oswaldBold" customStyle={styles.title} color="white">
            {i18n.t('avatar.choose_congrats')}
          </CustomText>
          <CustomText weight="oswaldBold" customStyle={styles.title} color="limeGreen">
            {userName}
          </CustomText>
        </MotiView>
        <AvatarImage
          avatar={selectedAvatar}
          background={selectedBackground}
          delay={1500}
          transparent
          customStyle={styles.avatar}
        />
      </View>
    </>
  );
};

export default memo(ChangeAvatarThirdStep);
