import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, ViewStyle } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import CheckIcon from '@assets/svg/CheckIcon';
import CustomText from '@atoms/CustomText';
import { Colors } from '@theme';

import { styles } from './styles';

interface FilterItemI {
  name: string;
  id: number | string;
}

type CheckboxProps = {
  onSelect: (item: FilterItemI) => void;
  item: FilterItemI;
  customTextComponent?: JSX.Element;
  active: boolean;
  customStyle?: ViewStyle;
  containerCustomStyle?: ViewStyle;
};

const Checkbox = ({
  onSelect,
  item,
  active,
  customStyle,
  containerCustomStyle,
  customTextComponent,
}: CheckboxProps) => {
  const [selected, setSelected] = useState(false);
  const selectedValue = useDerivedValue(() => withTiming(selected ? 1 : 0));

  const handlePress = () => {
    setSelected((prevSelected) => !prevSelected);
    onSelect(item);
  };

  const bgAnimated = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      selectedValue.value,
      [0, 1],
      [Colors.blackTwo, Colors.limeGreen]
    );
    const borderColor = interpolateColor(
      selectedValue.value,
      [0, 1],
      [Colors.filterItemGrey, Colors.transparentWhite]
    );
    return {
      backgroundColor,
      borderColor,
    };
  });

  useEffect(() => {
    setSelected(active);
  }, [active]);

  const Text = useCallback(() => {
    if (customTextComponent) {
      return customTextComponent;
    }
    return (
      <CustomText weight="interBold" color={selected ? 'white' : 'greySix'}>
        {item.name}
      </CustomText>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customTextComponent, selected]);

  return (
    <Pressable hitSlop={10} style={[styles.container, containerCustomStyle]} onPress={handlePress}>
      <Animated.View style={[styles.check, customStyle, bgAnimated]}>
        {selected ? <CheckIcon /> : null}
      </Animated.View>
      <Text />
    </Pressable>
  );
};

export default Checkbox;
