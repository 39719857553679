import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale, verticalScale } from '@utils/scaling';
import { Colors } from '@theme';
import { WINDOW_WIDTH } from '@constants/platform';

const AVATAR_SIZE = WINDOW_WIDTH * 0.66;

export default StyleSheet.create({
  imageContainer: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    marginTop: verticalScale(100),
    borderRadius: AVATAR_SIZE,
    overflow: 'hidden',
    backgroundColor: Colors.greySix,
    alignSelf: 'center',
  },
  bottom: {
    flex: 1,
    justifyContent: 'flex-end',
  },
  bottomContent: {
    justifyContent: 'flex-end',
    maxHeight: '80%',
    // padding: moderateScale(20),
    borderWidth: 2,
    borderColor: Colors.socialBackground,
    backgroundColor: Colors.blackFour,
    borderBottomWidth: 0,
    borderTopEndRadius: 20,
    borderTopStartRadius: 20,
  },
  ctas: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  btns: {
    paddingVertical: moderateScale(14),
  },
  btnMaybe: {
    backgroundColor: Colors.socialBackground,
  },
  btnTextMaybe: {
    color: Colors.greySix,
  },
  btnText: {
    fontSize: moderateScale(18),
  },
  itemsContainer: {
    width: '100%',
    flexDirection: 'row',
    maxWidth: WINDOW_WIDTH * 0.84,
    justifyContent: 'space-between',
    marginBottom: moderateScale(10),
  },
  mt40: { marginTop: moderateVerticalScale(3), zIndex: 3, marginLeft: -1 },
  imageItem: { width: '100%', height: '100%' },
  matrixContainer: {
    alignItems: 'center',
    paddingVertical: moderateVerticalScale(47),
  },
});
