import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const InstagramIcon = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 0.5C2.73858 0.5 0.5 2.73858 0.5 5.5V10.5C0.5 13.2614 2.73858 15.5 5.5 15.5H10.5C13.2614 15.5 15.5 13.2614 15.5 10.5V5.5C15.5 2.73858 13.2614 0.5 10.5 0.5H5.5ZM12.5 4.25C12.9142 4.25 13.25 3.91421 13.25 3.5C13.25 3.08579 12.9142 2.75 12.5 2.75C12.0858 2.75 11.75 3.08579 11.75 3.5C11.75 3.91421 12.0858 4.25 12.5 4.25ZM11.75 8C11.75 10.0711 10.0711 11.75 8 11.75C5.92893 11.75 4.25 10.0711 4.25 8C4.25 5.92893 5.92893 4.25 8 4.25C10.0711 4.25 11.75 5.92893 11.75 8ZM8 10.25C9.24264 10.25 10.25 9.24264 10.25 8C10.25 6.75736 9.24264 5.75 8 5.75C6.75736 5.75 5.75 6.75736 5.75 8C5.75 9.24264 6.75736 10.25 8 10.25Z"
      fill="#777E91"
    />
  </Svg>
);
export default InstagramIcon;
