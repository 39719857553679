import { useState, useEffect, useRef } from 'react';
import { AppState } from 'react-native';

const useAppCurrentVisibility = () => {
  const appState = useRef(AppState.currentState);
  const [appVisibility, setAppVisibility] = useState(appState.current);

  useEffect(() => {
    const subscription = AppState.addEventListener('change', _handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, []);

  const _handleAppStateChange = (nextAppState) => {
    appState.current = nextAppState;
    setAppVisibility(appState.current);
  };

  return appVisibility;
};

export default useAppCurrentVisibility;
