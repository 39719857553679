import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import AsyncStorage from '@react-native-async-storage/async-storage';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { Layout } from '@organisms/Layout';
import { RootStackNavigator } from 'app/navigation/types';
import GoBackButton from '@molecules/GoBackButton';
import toast from '@utils/toast';

import { styles } from './styles';

type TokenProps = NativeStackScreenProps<RootStackNavigator, 'Token'>;

export const Token = ({ navigation, route }: TokenProps) => {
  const [token, setToken] = useState('');
  useStatusBar('light');

  const getToken = async () => {
    const deviceToken = await AsyncStorage.getItem('deviceToken');
    setToken(deviceToken || '');
  };

  const handleCopyToken = () => {
    Clipboard.setString(token);
    toast.success({ icon: 'success', title: 'Copied!' });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <Layout withScroll>
      <View style={styles.header}>
        <GoBackButton />
      </View>
      <View style={styles.container}>
        <View style={styles.body}>
          <CustomText weight="interSemi" align="left" size="xlarge">
            Token
          </CustomText>
          <CustomText weight="interSemi" align="left" size="xlarge">
            {token}
          </CustomText>
        </View>
        <View style={styles.footer}>
          <Button customStyle={styles.button} onPress={handleCopyToken}>
            Copy token
          </Button>
        </View>
      </View>
    </Layout>
  );
};
