import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';

async function editFollow(userId: number, token: string) {
  const response = await axiosAuthInstance.put(`/users/follow/${userId}`, undefined, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });

  return response.data;
}

export function useUpdateFollow(token: string) {
  return useMutation((userId: number) => editFollow(userId, token), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
      await client.invalidateQueries(QueryKeys.GET_FOLLOWS);
      await client.invalidateQueries(QueryKeys.GET_PROFILE_BY_ID);
    },
  });
}
