import * as React from 'react';
import Svg, { SvgProps, Ellipse, Path, Circle } from 'react-native-svg';

const DeleteAccountCatIcon = ({ ...props }: SvgProps) => (
  <Svg width={204} height={206} fill="none" {...props}>
    <Ellipse cx={102} cy={103} rx={102} ry={102.791} fill="#353945" />
    <Path
      d="M145.042 161.238c17.836-22.377-5.326-127.111-11.255-134.967-1.213-1.608-11.239 8.215-14.086 15.053-4.48-1.632-24.693-1.528-29.137-.267-6.474-7.775-9.444-15.874-12.328-14.474-1.003.486-5.187 9.974-7.44 16.21-4.728 13.086-8.156 21.486-10.607 30.858-9.605 36.642-7.954 79.719 6.194 90.746 16.896 13.167 67.182 11.165 78.124-2.557l.535-.602z"
      fill="#000"
    />
    <Path
      d="M146.861 161.831c7.068-9.69 6.138-24.539 5.638-35.778-.811-18.308-1.895-36.225-5.638-54.149-2.86-13.719-6.031-27.613-10.763-40.843-.774-2.166-1.43-5.986-4.409-5.509-6.651 1.06-12.527 10.89-14.911 16.282l3.147-.979c-8.057-2.585-18.748-1.665-27.032-.687-.68.082-1.37.198-2.026.361.775-.198-2.908-5.683-3.266-6.242-1.394-2.166-2.705-4.38-4.23-6.464-1.479-2.015-3.97-2.702-6.15-1.211-2.85 1.945-4.16 7.873-5.34 10.82-3.004 7.488-5.507 15.21-8.13 22.826-5.053 14.663-8.211 29.92-10.511 45.211-2.05 13.649-1.789 24.669 0 38.411 1.155 8.863 4.962 14.533 12.585 21.336 8.355 4.216 13.363 7.545 26.687 9.665 13.657 1.156 20.632.985 31.311-.866 8.165-1.41 17.555-5.476 23.038-12.184.787-.967-2.622-.524-3.182.163-4.708 5.765-11.526 7.844-18.534 9.148-10.119 1.887-20.595 2.201-30.87 1.619-8.807-.501-16.376-4.924-24.493-8.441-6.852-5.04-11.012-10.645-12.598-18.623-2.228-11.204-2.86-19.999-1.692-31.26 1.407-13.685 4.124-27.253 7.438-40.611 2.98-12.007 7.437-23.863 11.823-35.428.93-2.457 1.955-4.892 3.015-7.302.346-.769.704-1.538 1.085-2.306 1.049-2.178.763-.676-.072-1.153C80.784 28.8 82 31.807 83.18 33.68c1.764 2.819 3.624 5.556 5.65 8.2.298.395 1.454.104 1.812.01 3.933-.99 8.366-.791 12.395-.814 4.505-.024 9.738-.222 14.064 1.164.811.256 2.777-.116 3.147-.978 1.918-4.367 4.886-8.117 8.188-11.576a31.342 31.342 0 011.811-1.713c1.729-.931 1.8-1.246.215-.954.286.419.501.896.715 1.35 1.657 3.494 2.729 7.314 3.85 10.995 4.076 13.428 7.175 27.17 9.857 40.925 3.027 15.5 3.325 30.879 4.123 46.648.549 10.855 1.514 25.634-5.327 35.021-.751 1.025 2.67.547 3.182-.163v.035z"
      fill="#000"
    />
    <Path
      d="M68.646 129.438c-4.764 32.162-.946 45.236 6.228 47.683 16.875 6.289 15.596-33.657 15.944-42.918 0 0-17.407-36.926-22.172-4.765z"
      fill="#000"
    />
    <Path
      d="M62.167 123.384c.666 11.505 3.95 29.236 5.905 40.728.56 3.325 4.274 16.939 9.79 11.555 4.475-4.367 4.166-14.628 4.348-20.655.116-3.91.045-7.836-.074-11.767-.075-2.585-.59-5.795.38-8.178.215-.529 1.894-.438 2.122.114 1.338 3.179.892 7.281.932 10.598.062 5.245-.05 10.484-.515 15.685-.497 5.612-1.428 16.126-8.348 16.582-5.407.354-8.593-4.192-10.161-9.128-3.793-11.963-9.168-35.313-5.34-45.663.052-.256.936-.162.942.095l.019.034z"
      fill="#393939"
    />
    <Path
      d="M136.275 129.438c4.764 32.162.946 45.236-6.227 47.683-16.876 6.289-15.597-33.657-15.945-42.918 0 0 17.408-36.926 22.172-4.765z"
      fill="#000"
    />
    <Path
      d="M142.754 123.384c-.666 11.505-3.95 29.236-5.905 40.728-.561 3.325-4.274 16.939-9.789 11.555-4.476-4.367-4.167-14.628-4.349-20.655-.116-3.91-.045-7.836.074-11.767.075-2.585.589-5.795-.38-8.178-.215-.529-1.895-.438-2.122.114-1.338 3.179-.892 7.281-.932 10.598-.063 5.245.049 10.484.514 15.685.497 5.612 1.428 16.126 8.349 16.582 5.407.354 8.592-4.192 10.161-9.128 3.793-11.963 9.168-35.313 5.34-45.663-.052-.256-.936-.162-.943.095l-.018.034z"
      fill="#393939"
    />
    <Path
      d="M56.471 135.311v14.017c-19.136-13.528-21.457-24.114-19.273-44.095 1.747-15.984-3.538-24.415-6.415-26.15-7.018-8.892-1.56-20.396 10.4-14.257 11.96 6.14 10.504 24.976 6.656 45.269-3.078 16.235 5.096 22.441 8.632 25.216z"
      fill="#0B0B0D"
      stroke="#000"
      strokeWidth={1.201}
    />
    <Path d="M103.065 79.247v-3.943c0-.313-1.46-.17-1.46.075v3.944c0 .312 1.46.17 1.46-.076z" fill="#fff" />
    <Path
      d="M107.526 83.135a7.58 7.58 0 00-2.769-2.844c-3.235-1.94-6.392.922-8.681 2.758-.17.143-.84-.272-.7-.465 3.62-4.546 10.03-4.663 12.884.73.158.297-.61.002-.729-.199l-.005.02zM105.907 72.95c-.568 1.247-1.967 2.535-3.463 2.549-1.371 0-2.506-.928-3.517-2.507-.776-1.191.319-1.731 3.462-1.759 3.019-.014 4.072.471 3.504 1.717h.014zM83.347 53.236c2.36.179 4.754.914 6.849 2.416 2.03 1.458 2.938 3.636 3.108 6.114.172 2.492-.323 4.866-1.602 7.017-1.945 3.272-5.458 4.285-8.902 3.79-1.333-.19-2.633-.626-3.877-1.175-3.978-1.754-5.928-6.039-4.656-10.292.887-2.963 2.775-5.165 5.177-6.952 1.113-.83 2.439-.737 3.903-.916v-.002z"
      fill="#fff"
    />
    <Path
      d="M83.625 69.773c-1.569-.026-3.106-.13-4.418-1.22-2.896-2.404-3.232-6.512-.722-9.467 1.354-1.594 3.07-2.652 5.01-3.257 2.283-.713 5.487 1.029 6.67 3.539a7.717 7.717 0 01-1.663 8.89c-1.414 1.352-3.102 1.528-4.877 1.515z"
      fill="#000"
    />
    <Path
      d="M122.921 53.236c3.452.14 6.334 1.451 8.084 4.857 1.73 3.368 1.682 6.66-.317 9.829-3.346 5.305-9.878 6.336-14.547 2.332-2.183-1.873-3.134-4.256-2.795-7.207.182-1.597.279-3.141.909-4.683 1.281-3.153 3.546-4.728 6.831-4.99.559-.044 1.118-.084 1.838-.138h-.003z"
      fill="#fff"
    />
    <Path
      d="M128.843 62.422c.019 3.692-2.531 6.964-5.535 6.863-4.243-.14-7.014-1.794-7.109-5.907-.073-3.167 1.943-7.264 6.631-7.223 3.156.028 5.997 2.997 6.013 6.264v.003z"
      fill="#000"
    />
    <Circle cx={124.952} cy={60.045} r={1.946} fill="#fff" />
    <Circle cx={121.304} cy={62.234} r={1.216} fill="#fff" />
    <Circle cx={87.014} cy={60.045} r={1.946} fill="#fff" />
    <Circle cx={83.366} cy={62.234} r={1.216} fill="#fff" />
    <Path
      d="M123.461 77.22c-.026.014-.066.092-.093.173-.027.081-.281.753-.57 1.495-1.496 3.882-1.875 5.266-1.786 6.56.103 1.525.935 2.558 2.205 2.739 1.019.148 2.052-.461 2.498-1.472.231-.524.289-.838.285-1.572-.005-1.31-.316-2.443-1.594-5.776-.308-.8-.611-1.598-.677-1.771-.129-.332-.187-.42-.268-.376z"
      fill="#5194D1"
    />
    <Path d="M125 86.209a1 1 0 01-1 1c-.552 0-1 .052-1-.5s.948-1.5 1.5-1.5.5.448.5 1z" fill="#fff" />
  </Svg>
);

export default DeleteAccountCatIcon;
