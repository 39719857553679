import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    overflow: 'hidden',
  },
  responsesContainer: {
    marginTop: moderateVerticalScale(20),
    marginLeft: moderateScale(24),
  },
  line: {
    position: 'absolute',
    zIndex: 2,
  },
});
