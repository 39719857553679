// Firebase Analytics Mobile
import analytics from '@react-native-firebase/analytics';
// Firebase Analytics Web
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { Platform } from 'react-native';

const handleAnalyticsEvent = (event: string, data?: object) => {
  if (__DEV__) {
    return null;
  }

  if (Platform.OS === 'web') {
    const firebaseConfig = {
      apiKey: 'AIzaSyCpu5d-1BT26yy_0VK0oCDO8qyKGv8tsoY',
      authDomain: 'gato-games-b5544.firebaseapp.com',
      projectId: 'gato-games-b5544',
      storageBucket: 'gato-games-b5544.appspot.com',
      messagingSenderId: '1012188677531',
      appId: '1:1012188677531:web:276619ad7292da27748d3e',
      measurementId: 'G-5RSFBKT97G',
    };

    const app = initializeApp(firebaseConfig);

    const analyticsWeb = getAnalytics(app);

    if (data) return logEvent(analyticsWeb, event, data);

    return logEvent(analyticsWeb, event);
  }

  if (data) return analytics().logEvent(event, data);

  return analytics().logEvent(event);
};

export default handleAnalyticsEvent;
