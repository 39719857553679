import React, { useEffect, useRef, useCallback } from 'react';
import { View, Animated, Easing } from 'react-native';
import { TouchableWithoutFeedback } from 'react-native-gesture-handler';

import CustomText from '@atoms/CustomText';
import { isMobile } from '@constants/platform';

import styles from './styles';

interface Props {
  index: number;
  filled: string;
  onPressInput: () => any;
  isSubmiting?: boolean;
  hashedValue?: boolean;
  hasError?: boolean;
  isCompleted?: boolean;
  isNext: boolean;
}

const OTPInputDot = ({
  index,
  filled,
  isSubmiting,
  hashedValue,
  hasError,
  isCompleted,
  onPressInput,
  isNext,
}: Props) => {
  const filledValue = useRef(new Animated.Value(1)).current;
  const textOpacity = useRef(new Animated.Value(1)).current;
  const scaleBullet = useRef(new Animated.Value(1)).current;

  const isLastDot = index === 5;

  const colorHandler = () => {
    if (isSubmiting) {
      return styles.empty;
    }
    if (hasError && isCompleted) {
      return styles.error;
    }
    if (isNext) {
      return styles.filled;
    }
    return styles.empty;
  };

  const filledSecuence = useCallback(() => {
    Animated.sequence([
      Animated.timing(filledValue, {
        toValue: 0,
        duration: 0,
        easing: Easing.linear,
        useNativeDriver: isMobile,
      }),
      Animated.timing(scaleBullet, {
        toValue: 1.1,
        duration: 10,
        easing: Easing.linear,
        useNativeDriver: isMobile,
      }),
      Animated.timing(filledValue, {
        toValue: 1,
        duration: 10,
        easing: Easing.linear,
        useNativeDriver: isMobile,
      }),
      Animated.spring(scaleBullet, {
        toValue: 1,
        useNativeDriver: isMobile,
      }),
    ]).start();
  }, [filledValue, scaleBullet, textOpacity]);

  const handlePress = () => {
    filledSecuence();
    onPressInput();
  };

  useEffect(() => {
    filledSecuence();
  }, [filled, filledSecuence]);

  const textColor = () => {
    if (hasError) {
      return 'error';
    }
    if (filled) {
      return 'white';
    }
    return 'darkGrey';
  };

  const textValue = () => {
    if (filled) {
      if (hashedValue) {
        return '*';
      }
      return filled;
    }
    return '-';
  };

  return (
    <View style={[styles.content, !isLastDot && styles.spacing]}>
      <TouchableWithoutFeedback style={styles.contentTouch} onPress={handlePress}>
        <Animated.View
          style={[
            styles.bullet,
            colorHandler(),
            isNext ? styles.isNext : null,
            filled ? styles.filled : null,
            {
              opacity: filledValue,
              transform: [{ scale: scaleBullet }],
            },
          ]}
        />
        <CustomText
          customStyle={[styles.text, { opacity: textOpacity }]}
          animated
          weight="interSemi"
          color={textColor()}
          size="mbig">
          {textValue()}
        </CustomText>
      </TouchableWithoutFeedback>
    </View>
  );
};

export default OTPInputDot;
