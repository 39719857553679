const MATRIX_LIMIT = 3;

export function createMatrix(array: any[] | undefined) {
  if (!array) return [];

  return array.reduce((result, currentValue, currentIndex) => {
    if (currentIndex % MATRIX_LIMIT === 0) {
      result.push([currentValue]);
    } else {
      result[result.length - 1].push(currentValue);
    }
    return result;
  }, []);
}
