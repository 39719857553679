import { StyleSheet } from 'react-native';

import { isDesktop, isMobile, SCREEN_WIDTH } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

const width = isMobile ? SCREEN_WIDTH - Spacing.LATERALBIG * 2 : moderateScale(360);

export const styles = StyleSheet.create({
  blur: {
    ...StyleSheet.absoluteFillObject,
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'none',
  },
  box: {
    width,
    paddingVertical: isDesktop ? moderateScale(20) : moderateScale(30),
    paddingHorizontal: moderateScale(20),
    borderRadius: moderateScale(16),
    pointerEvents: 'auto',
  },
  bottomFixed: {
    width: SCREEN_WIDTH,
    position: 'absolute',
    bottom: 0,
  },
});
