import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const HelpIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.895 3.886h1.21a5.44 5.44 0 110 10.882H5.872a2.418 2.418 0 01-2.418-2.418V9.327a5.44 5.44 0 015.44-5.44zM7.082 10.99a.453.453 0 010-.907H9.5a.453.453 0 010 .907H7.082zm0-2.418a.453.453 0 110-.907h4.836a.453.453 0 010 .907H7.082z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default HelpIcon;
