import { StyleSheet } from 'react-native';

import { Colors, Fonts, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale, verticalScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    marginTop: verticalScale(8),
    paddingBottom: moderateScale(20),
    flex: 1,
  },
  loading: {
    justifyContent: 'center',
    marginBottom: moderateScale(24),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: 16,
    minHeight: moderateScale(60),
  },
  header: {
    paddingTop: Spacing.LATERALBIG,
  },
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    zIndex: 3,
  },
  comments: {
    flex: 1,
  },
  emptyState: {
    maxWidth: 170,
    lineHeight: 20,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  messageContainer: {
    flexDirection: 'row',
    paddingHorizontal: Spacing.LATERALPLUS,
    marginTop: Spacing.LATERALBIG,
  },
  inputMainContainer: {
    marginTop: moderateScale(16),
    flexDirection: 'column',
    paddingHorizontal: Spacing.LATERALPLUS,
  },
  inputModalContainer: {
    flexDirection: 'column',
    paddingHorizontal: Spacing.LATERALPLUS,
    paddingBottom: Spacing.TINY,
  },
  inputLineContainer: {
    flexDirection: 'row',
  },
  inputContainer: {
    flex: 0.9,
    marginRight: moderateScale(8),
    maxHeight: moderateScale(200),
  },
  iconContainer: {
    flex: 0.1,
    marginTop: moderateScale(6),
  },
  input: {
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
  },
  inputError: {
    borderColor: Colors.inputError,
    borderWidth: 1,
  },
  inputModal: {
    fontSize: Fonts.SIZES.lMedium,
    color: Colors.white,
    fontFamily: Fonts.INTER_MEDIUM,
    marginLeft: moderateScale(12),
    flex: 1,
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
  },
  charsLeft: {
    marginTop: moderateScale(-8),
  },
  charsLeftModal: {
    marginTop: moderateScale(-8),
    marginBottom: moderateVerticalScale(8),
  },
  charsLeftThreadModal: {
    borderColor: Colors.error,
    borderWidth: 1,
    paddingHorizontal: moderateScale(20),
    marginTop: moderateScale(-16),
    marginBottom: moderateVerticalScale(36),
  },
  bottomSheetHeader: {
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateVerticalScale(16),
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: Colors.greyTwo,
    borderBottomWidth: 1,
  },
  bottomSheetTitle: {
    marginLeft: moderateScale(10),
  },
  modalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
  },
  threadMessageContainer: {
    marginTop: moderateVerticalScale(24),
    paddingHorizontal: moderateScale(20),
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    maxHeight: moderateVerticalScale(200),
  },
  threadInputContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
    paddingVertical: moderateScale(16),
  },
  threadInputModal: {
    paddingHorizontal: moderateScale(8),
    fontSize: Fonts.SIZES.lMedium,
    color: Colors.white,
    fontFamily: Fonts.INTER_MEDIUM,
    marginLeft: moderateScale(16),
    flex: 1,
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
  },
  threadIconContainer: {
    marginTop: moderateScale(6),
  },
  threadCharsLeft: {
    paddingHorizontal: moderateScale(20),
    marginTop: moderateScale(8),
    marginBottom: moderateVerticalScale(36),
  },
});
