import React from 'react';
import { View } from 'react-native';
import AnimatedLottieView from 'lottie-react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';
import { ControlTypeDesktopEnum, ControlTypeMobileEnum } from 'app/enums/controlTypes.enum';

import { styles } from './styles';
import { CONTROL_TYPES } from './constants';

export type ControlsProps = {
  type: ControlTypeDesktopEnum | ControlTypeMobileEnum;
};

const Controls = ({ type }: ControlsProps) => {
  const i18n = useTranslations();
  const animation = React.useRef<AnimatedLottieView>(null);

  return (
    <View style={[styles.controls]}>
      <View style={[styles.container, { width: '100%', overflow: 'hidden', justifyContent: 'flex-start' }]}>
        <AnimatedLottieView
          autoPlay
          autoSize
          ref={animation}
          style={styles.control}
          source={CONTROL_TYPES[type].icon}
          loop
        />
        <CustomText size="lmedium" weight="interSemi" customStyle={styles.controlsTitle} align="left">
          {i18n.t(`game_page.${CONTROL_TYPES[type].id}_title`)}
        </CustomText>
      </View>
      <View style={styles.separator} />
      <CustomText
        size={isWebResponsive ? 'lmedium' : 'xmedium'}
        weight="interMedium"
        align="left"
        customStyle={styles.description}
        color="greyThree">
        {i18n.t(`game_page.${CONTROL_TYPES[type].id}_description`)}
      </CustomText>
    </View>
  );
};

export default Controls;
