import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { SCREEN_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  button: {
    // paddingHorizontal: moderateScale(52.5),
    // paddingVertical: moderateScale(14),
    width: (SCREEN_WIDTH - Spacing.LATERALBIG * 4 - 7) / 2,
    height: moderateScale(50),
    borderRadius: moderateScale(12),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonDesktop: {
    width: undefined,
    flex: 1,
    paddingEnd: Spacing.LATERALBIG / 2,
  },
  confirm: {
    backgroundColor: Colors.limeGreen,
    marginRight: moderateScale(7),
  },
  cancel: {
    backgroundColor: Colors.darkGrey,
  },
  message: {
    paddingHorizontal: moderateScale(50),
    paddingBottom: moderateScale(20),
  },
  row: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: moderateScale(30),
    marginBottom: moderateScale(16),
  },
  rowDesktop: {
    marginBottom: 0,
  },
});
