/* eslint-disable @typescript-eslint/naming-convention */
import React, { FC, memo, useEffect, useState } from 'react';
import { FlatList, Pressable, } from 'react-native';
import { Link } from '@react-navigation/native';
import { AnimatePresence, View } from 'moti';

import CustomText from '@atoms/CustomText';
import ChevronLeft from '@assets/svg/ChevronLeft';
import { Colors } from '@theme';
import Report from '@assets/svg/report.svg';

import { styles } from './styles';
import { MenuItemProps, PopUpMenuProps } from './popUpProps';

const MenuItem = ({ item, isFirst, isLast, fromResponse }: MenuItemProps) => {
  const { title, Icon, report } = item;
  const [hover, setHover] = useState(false);
  const [pressing, setPressing] = useState(false);

  const pressableEvents = {
    onHoverIn: () => setHover(true),
    onHoverOut: () => setHover(false),
    onPressIn: () => setPressing(true),
    onPressOut: () => setPressing(false),
  };

  const isActive = hover || pressing;

  if ('action' in item) {
    const activeTextColor = !item.danger ? 'limeGreen' : 'error';
    const activeIconColor = !item.danger ? Colors.limeGreen : Colors.inputError;
    return (
      <Pressable
        onPress={item.action}
        style={[styles.item, isFirst && styles.firstItem, isLast && styles.lastItem]}
        {...pressableEvents}>
        {Icon && <Icon style={styles.icon} fill={!fromResponse && isActive ? activeIconColor : undefined} />}
        {report && <img style={styles.icon} src={Report} alt="Report" />}
        <CustomText
          size="lmedium"
          weight="interSemi"
          customStyle={styles.title}
          align="left"
          color={fromResponse ? 'error' : isActive ? activeTextColor : undefined}>
          {title}
        </CustomText>
        {!item.danger && (
          <ChevronLeft style={styles.chevron} fill={isActive ? Colors.limeGreen : undefined} />
        )}
      </Pressable>
    );
  }

  return (
    <Pressable {...pressableEvents}>
      <Link to={item.route} style={[styles.item, isFirst && styles.firstItem, isLast && styles.lastItem]}>
        {Icon && <Icon style={styles.icon} fill={isActive ? Colors.limeGreen : undefined} />}
        <CustomText
          size="lmedium"
          weight="interSemi"
          customStyle={styles.title}
          align="left"
          color={isActive ? 'limeGreen' : undefined}>
          {title}
        </CustomText>
        <ChevronLeft style={styles.chevron} fill={isActive ? Colors.limeGreen : undefined} />
      </Link>
    </Pressable>
  );
};

const PopUpMenu: FC<PopUpMenuProps> = ({ items, visible, onDismiss, style, fromResponse }) => {
  useEffect(() => {
    let timer;

    if (visible && onDismiss) {
      timer = setTimeout(() => {
        onDismiss();
      }, 4000);
    }

    return () => {
      clearInterval(timer);
    };
  }, [visible]);

  return (
    <AnimatePresence>
      {visible && (
        <View
          key="menu"
          style={styles.modalVisible}
          from={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}>
          <Pressable
            onHoverOut={(e) => e.currentTarget && onDismiss && onDismiss()} 
            style={[styles.container, style]}>
            <FlatList
              data={items}
              showsVerticalScrollIndicator={false}
              keyExtractor={(item) => item.title}
              renderItem={(item) => (
                <MenuItem
                  item={item.item}
                  isFirst={item.index === 0}
                  isLast={item.index === items.length - 1}
                  fromResponse={fromResponse}
                />
              )}
            />
          </Pressable>
        </View>
      )}
    </AnimatePresence>
  );
};

export default memo(PopUpMenu);
