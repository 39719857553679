/* eslint-disable @typescript-eslint/naming-convention */
import { useMutation } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { isDesktop, isIos, isMobile, isWebResponsive } from '@constants/platform';
import { ExploreGames } from '@interfaces/explore';

import pkg from '../../package.json';

async function getExploreGames(data: string, isResponsive: boolean) {
  const path = `/game${isDesktop ? '/desktop' : ''}/find/filter?${data || ''}isMobile=${isResponsive}`;
  const SO = isIos ? 'IOS' : 'ANDROID';
  const config =
    isMobile || isWebResponsive ? { headers: { so_name: SO, so_version: pkg.version } } : undefined;
  const response = await axiosGamesInstance.get<ExploreGames[]>(path, config);
  return response.data;
}

export function useExploreGames(isResponsive: boolean) {
  return useMutation((data: string) => getExploreGames(data, isResponsive));
}
