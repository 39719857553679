import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { isDesktop, isMobile, isWebResponsive, IS_SMALL_DEVICE, SCREEN_HEIGHT } from '@constants/platform';
import { Colors } from '@theme';
import { BIG, LARGE, SMALL, TINY } from 'app/theme/spacing';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: IS_SMALL_DEVICE ? SCREEN_HEIGHT : SCREEN_HEIGHT - moderateScale(120),
  },
  desktopForm: {
    width: moderateScale(616),
    marginTop: SMALL,
  },
  responsiveForm: {
    flex: 1
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flex: 1,
  },
  description: {
    marginTop: SMALL,
    marginBottom: moderateScale(30),
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
  },
  button: {
    width: isMobile ? '100%' : moderateScale(300),
    paddingVertical: moderateScale(14),
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: moderateScale(18),
    marginBottom: isWebResponsive? moderateScale(70) : moderateScale(190),
    marginTop: isWebResponsive ? moderateScale(20) : undefined
  },
  backBtn: {
    marginVertical: moderateScale(60)
  },
  containerResponsive: {
    paddingTop: moderateScale(24)
  },
  title: {
    marginTop: moderateScale(16)
  },
  underline: {
    textDecorationLine: 'underline',
  },
  checkContainer: {
    width: '90%',
    marginTop: isDesktop && !isWebResponsive ? TINY : undefined,
  },
  checkPress: {
    marginTop: -2,
  },
  contentContainer: {
    padding: moderateScale(20),
    backgroundColor: Colors.blackTwo,
    paddingTop: moderateScale(10),
  },
  header: {
    backgroundColor: Colors.blackTwo,
    paddingTop: moderateScale(20),
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateScale(10),
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    paddingBottom: moderateScale(16),
  },
  modalFooter: {
    paddingTop: moderateScale(16),
    paddingHorizontal: moderateScale(24),
    paddingBottom: moderateScale(20),
    alignItems: 'center',
    backgroundColor: Colors.blackTwo,
  },
  modalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
  },
  desktopModalHeader: {
    paddingBottom: BIG,
  },
  desktopModalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginTop: moderateScale(20),
  },
  desktopModalFooter: {
    paddingTop: LARGE,
    paddingBottom: TINY,
    alignItems: 'center',
  },
  termsText: {
    lineHeight: moderateScale(24),
  },
});
