import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const LogoutIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} viewBox="0 0 19 22" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.7437 0.828208C5.50197 -0.343974 7.81152 0.730659 8.15406 2.70436H11.5827C13.1647 2.70436 14.4473 3.98688 14.4473 5.56894C14.4473 6.00042 14.0975 6.35019 13.666 6.35019C13.2345 6.35019 12.8848 6.00042 12.8848 5.56894C12.8848 4.84982 12.3018 4.26686 11.5827 4.26686H8.19727V17.2877H11.5827C12.3018 17.2877 12.8848 16.7047 12.8848 15.9856C12.8848 15.5541 13.2345 15.2044 13.666 15.2044C14.0975 15.2044 14.4473 15.5541 14.4473 15.9856C14.4473 17.5677 13.1647 18.8502 11.5827 18.8502H8.15406C7.81152 20.8239 5.50197 21.8985 3.7437 20.7263L1.66036 19.3375C0.863441 18.8062 0.384766 17.9118 0.384766 16.954V4.60057C0.384766 3.64279 0.863441 2.74838 1.66036 2.2171L3.7437 0.828208ZM13.1766 8.1415C13.4817 8.4466 13.4817 8.94126 13.1766 9.24636L12.427 9.99601H17.8325C18.264 9.99601 18.6138 10.3458 18.6138 10.7773C18.6138 11.2087 18.264 11.5585 17.8325 11.5585L12.427 11.5585L13.1766 12.3082C13.4817 12.6133 13.4817 13.1079 13.1766 13.413C12.8715 13.7181 12.3769 13.7181 12.0718 13.413L10.725 12.0663C10.0131 11.3544 10.0131 10.2002 10.725 9.48827L12.0718 8.1415C12.3769 7.8364 12.8715 7.8364 13.1766 8.1415Z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default LogoutIcon;
