import React from 'react';
import { FlatList, View } from 'react-native';

import Comment from '@organisms/Comments/Comment';
import Response from '@organisms/Comments/Response';
import VerticalDashedLine from '@assets/svg/VerticalDashedLine';
import { useThreadComments } from '@services/useComments';
import CommentSkeleton from '@molecules/CommentSkeleton';

import { styles } from './styles';

interface Props {
  comment: any;
}

const Thread = ({ comment }: Props) => {
  const { data = [], isLoading } = useThreadComments(comment?.id);

  return (
    <View style={styles.container}>
      {!isLoading && data?.comment && Object.keys(data?.comment).length > 0 ? (
        <Comment comment={data?.comment} />
      ) : (
        <CommentSkeleton />
      )}

      {data?.comment?.response && Object.keys(data?.comment?.response).length > 0 ? (
        <View style={styles.responsesContainer}>
          <View style={styles.line}>
            <VerticalDashedLine />
          </View>
          {!isLoading ? (
            <FlatList
              showsHorizontalScrollIndicator={false}
              data={data?.comment?.response}
              renderItem={({ item }) => <Response commentResponse={item} />}
              scrollEnabled={false}
            />
          ) : (
            <>
              <CommentSkeleton />
              <CommentSkeleton />
            </>
          )}
        </View>
      ) : null}
    </View>
  );
};

export default Thread;
