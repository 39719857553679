import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getHasSeenAvatarAlert(userName: string) {
  try {
    const hasSeen = await AsyncStorage.getItem(`hasSeenAvatarAlert${userName}`);
    return hasSeen === 'yes';
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}

export async function setHasSeenAvatarAlert(userName: string) {
  try {
    await AsyncStorage.setItem(`hasSeenAvatarAlert${userName}`, 'yes');
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}

export async function setShouldWatchOnboarding(shouldWatch: 'yes' | 'no') {
  try {
    await AsyncStorage.setItem(`shouldWatchOnboarding`, shouldWatch);
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}

export async function getShouldWatchOnboarding() {
  try {
    const hasSeen = await AsyncStorage.getItem('shouldWatchOnboarding');
    return hasSeen === 'yes';
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}
