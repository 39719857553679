import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  headerLeft: {
    position: 'absolute',
    top: moderateScale(20),
    left: moderateScale(20),
    zIndex: 3,
  },
  headerRight: {
    position: 'absolute',
    top: moderateScale(20),
    right: moderateScale(20),
    zIndex: 3,
  },
  lottie: {
    marginLeft: 8,
    width: moderateScale(40),
    height: moderateScale(40),
  },
  btn: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.socialBackground,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 30,
    borderRadius: 12,
    alignSelf: 'center',
  },
  headerContainer: {
    width: moderateScale(468),
    alignSelf: 'center',
  },
  profileContainer: {
    minWidth: moderateScale(290),
    display: 'flex',
  },
  profileButtons: {
    minWidth: moderateScale(333),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  footer: {
    marginBottom: 150,
    height: 20,
  },
});
