import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale, verticalScale } from '@utils/scaling';
import { Colors } from '@theme';
import { WINDOW_HEIGHT, WINDOW_WIDTH } from '@constants/platform';

const NEGATIVE_LEFT = WINDOW_WIDTH * 0.1;

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'flex-end',
    padding: moderateScale(20),
  },
  content: {
    flex: 2,
    justifyContent: 'flex-end',
  },
  lottie: {
    width: WINDOW_WIDTH * 1.1,
    height: WINDOW_HEIGHT * 1.1,
    position: 'absolute',
    top: 0,
    left: -NEGATIVE_LEFT,
    zIndex: 100,
  },
  header: {},
  imageContainer: {
    width: verticalScale(240),
    height: verticalScale(240),
    marginTop: verticalScale(40),
    borderRadius: verticalScale(120),
    overflow: 'hidden',
    backgroundColor: Colors.greySix,
    alignSelf: 'center',
  },
  title: {
    fontSize: moderateScale(35),
    lineHeight: moderateScale(50),
  },
  // eslint-disable-next-line react-native/no-color-literals
  transparent: {
    backgroundColor: 'transparent',
  },
  bottom: {
    flex: 0.75,
    justifyContent: 'flex-end',
  },
  bottomContent: {
    justifyContent: 'space-between',
    padding: moderateScale(20),
    borderWidth: 2,
    borderColor: Colors.socialBackground,
    borderBottomWidth: 0,
    borderTopEndRadius: 20,
    borderTopStartRadius: 20,
  },
  ctas: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  button: {
    paddingVertical: moderateScale(14),
    width: '100%',
  },
  buttonText: {
    fontSize: moderateScale(18),
  },
  mt40: { zIndex: 3, marginLeft: -1 },
  cat: { position: 'absolute', marginTop: moderateVerticalScale(3), zIndex: 10 },
  imageItem: { width: '100%', height: '100%' },
});
