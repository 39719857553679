import React from 'react';
import { ScrollView, ViewStyle, useWindowDimensions } from 'react-native';

import { CustomModal } from '@molecules/CustomModal';
import CustomText from '@atoms/CustomText';
import { useStore, notificationsModalOpenSelector, toggleNotificationsModalHandler } from '@store/index';
import NotificationsView from '@organisms/NotificationsView';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';

import { styles } from './styles';

export const NotificationsModal = () => {
  const i18n = useTranslations();
  const modalOpen = useStore(notificationsModalOpenSelector);
  const toggleNotificationsModal = useStore(toggleNotificationsModalHandler);
  const { width } = useWindowDimensions();

  const handleClose = () => {
    toggleNotificationsModal();
  };

  const position: ViewStyle = width < 1370 ? styles.positionWideScreen : styles.positionFullScreen;

  return (
    <CustomModal
      visible={modalOpen}
      onOverlayPress={handleClose}
      bottomFixed={false}
      overlayColor="transparent"
      customCardStyle={isWebResponsive ? styles.positionWebWideScreen : position}
      customStyle={isWebResponsive && styles.background}
      translateAnimation={false}>
      <CustomText size="xmedium" weight="interSemi" color="greySix" customStyle={styles.title}>
        {i18n.t('notifications.title')}
      </CustomText>
      <ScrollView
        contentContainerStyle={styles.innerContainer}
        style={styles.container}
        showsVerticalScrollIndicator>
        <NotificationsView handleClose={handleClose} />
      </ScrollView>
    </CustomModal>
  );
};
