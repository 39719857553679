import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

const DIMENTIONS = moderateScale(40);

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    // marginBottom: moderateScale(22),
    width: DIMENTIONS,
    height: DIMENTIONS,
    borderRadius: DIMENTIONS / 2,
    backgroundColor: Colors.greyTwo,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chevron: {
    transform: [{ scale: 0.66 }],
  },
  logoImage: {
    marginLeft: 29,
  },
});
