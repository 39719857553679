import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { verticalScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    width: '100%',
    paddingHorizontal: Spacing.LATERALPLUS,
    paddingTop: verticalScale(isWebResponsive ? 25 / 2 : 25),
    paddingBottom: verticalScale(15),
    backgroundColor: Colors.darkGrey,
    zIndex: 999,
  },
});
