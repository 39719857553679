/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { UserDataResponse } from '@interfaces/accountSettings';
import { QueryKeys } from 'app/react-query/query-keys';

async function getUserDataService(token: string) {
  const response = await axiosAuthInstance.get<UserDataResponse>('/users/me', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useUserData(token: string) {
  return useQuery([QueryKeys.GET_USER_DATA], () => getUserDataService(token), {
    enabled: !!token,
  });
}
