import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon7 = ({ fill, ...props }: SvgProps) => (
  <Svg width={23} height={22} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.206 3.566L6.916 5.5H6a3.667 3.667 0 00-3.667 3.667v6.416A3.667 3.667 0 006 19.25h11a3.667 3.667 0 003.667-3.667V9.167A3.667 3.667 0 0017 5.5h-.917l-1.289-1.934a1.833 1.833 0 00-1.525-.816H9.73c-.613 0-1.185.306-1.525.816zm6.044 8.809a2.75 2.75 0 11-5.5 0 2.75 2.75 0 015.5 0zM11.5 6.417a.917.917 0 100-1.834.917.917 0 000 1.834z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon7;
