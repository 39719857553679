import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    flexDirection: 'row',
    paddingLeft: moderateScale(5),
  },
  error: {
    position: 'absolute',
    bottom: 0,
  },
  input: {
    borderColor: Colors.transparentBlack,
    borderWidth: 1,
    color: Colors.transparentBlack,
    position: 'absolute',
    top: -500,
  },
});
