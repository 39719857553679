import { LanguageEnum } from 'app/enums/languages.enum';
import { GameDetail, SelectGameDescriptionKey } from '@interfaces/gameDetail';

const useGameDescription = (game?: GameDetail): SelectGameDescriptionKey => {
  const gameDescription: SelectGameDescriptionKey = {
    en: game?.description_en || '',
    es: game?.description_es || '',
    pt: game?.description_pt || '',
  };

  const gameDescriptionHandler = {
    get(target: SelectGameDescriptionKey, prop: LanguageEnum) {
      if (!(prop in target)) {
        return target[LanguageEnum.EN];
      }

      return target[prop] || target[LanguageEnum.EN];
    },
  };

  const gameDescriptionProxy = new Proxy<SelectGameDescriptionKey>(gameDescription, gameDescriptionHandler);

  return gameDescriptionProxy;
};

export default useGameDescription;
