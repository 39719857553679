type Size = {
  WIDTH: number;
  HEIGHT: number;
};

const HOME_CARD_MOBILE: Size = {
  WIDTH: 147,
  HEIGHT: 190,
};

const HOME_CARD_DESKTOP: Size = {
  WIDTH: 275,
  HEIGHT: 255,
};

const GAME_BANNER_MOBILE: Size = {
  WIDTH: 320,
  HEIGHT: 100,
};

const GAME_BANNER_SMALL_DESKTOP: Size = {
  WIDTH: 300,
  HEIGHT: 250,
};

const GAME_BANNER_LARGE_DESKTOP: Size = {
  WIDTH: 728,
  HEIGHT: 90,
};

const PRE_ROLL_MOBILE: Size = {
  WIDTH: 608,
  HEIGHT: 1080,
};

const PRE_ROLL_DESKTOP: Size = {
  WIDTH: 1116,
  HEIGHT: 613,
};

const HEADER_BANNER_MOBILE: Size = {
  WIDTH: 320,
  HEIGHT: 50,
};

const HOME_BANNER_DESKTOP: Size = {
  WIDTH: 970,
  HEIGHT: 250,
};

const GAME_LATERAL_BANNER_DESKTOP: Size = {
  WIDTH: 300,
  HEIGHT: 600,
};

export const CARD_SIZE = {
  HOME_CARD_MOBILE,
  HOME_CARD_DESKTOP,
};

export const BANNER_SIZE = {
  GAME_BANNER_MOBILE,
  GAME_BANNER_SMALL_DESKTOP,
  GAME_BANNER_LARGE_DESKTOP,
  HEADER_BANNER_MOBILE,
  HOME_BANNER_DESKTOP,
  GAME_LATERAL_BANNER_DESKTOP
};

export const PRE_ROLL_SIZE = {
  PRE_ROLL_MOBILE,
  PRE_ROLL_DESKTOP,
};
