import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon5 = ({ fill, ...props }: SvgProps) => (
  <Svg width={23} height={22} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.333 5.844v10.312c0 .204.02.402.055.595l2.599-5.911c.846-1.97 2.75-3.277 4.89-3.277h8.32c-.165-1.547-1.428-2.75-2.96-2.75h-3.075c-.53 0-1.05-.147-1.506-.426L8.668 3.175a2.893 2.893 0 00-1.507-.425h-1.85c-1.644 0-2.978 1.385-2.978 3.094zm7.543 3.093H18.68c1.543 0 2.496 1.75 1.702 3.124l-2.995 5.186c-.718 1.243-2.01 2.003-3.405 2.003h-8.67c-.828 0-1.576-.35-2.116-.917l3.053-6.946c.637-1.49 2.06-2.45 3.628-2.45z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon5;
