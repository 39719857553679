import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    paddingTop: isDesktop ? moderateScale(25) : moderateScale(30),
    backgroundColor: Colors.socialBackground,
    minHeight: 300,
    borderRadius: 16,
  },
  title: {
    paddingHorizontal: moderateScale(20),
  },
  comments: {
    flex: 1,
  },
  emptyState: {
    flexGrow: 1,
    marginVertical: moderateScale(60),
    paddingHorizontal: moderateScale(70),
    lineHeight: 20,
  },
  emptyContainer: {
    height: moderateScale(50),
  },
  hasMoreComments: {
    paddingHorizontal: moderateScale(20),
    minHeight: moderateScale(84),
    flex: 1,
    justifyContent: 'center',
    alignItems: isDesktop ? 'center' : 'flex-start',
  },
  underline: {
    textDecorationLine: 'underline',
  },
  inputModal: {
    width: moderateScale(280),
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
  },
  bottomSheetHeader: {
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateVerticalScale(16),
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: Colors.greyTwo,
    borderBottomWidth: 1,
  },
  bottomSheetTitle: {
    marginLeft: moderateScale(10),
  },
  modalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
  },
});
