import { AnimatePresence, View } from 'moti';
import React, { useEffect } from 'react';
import { Pressable, StyleProp, ViewStyle, useWindowDimensions } from 'react-native';
import { PanGestureHandler, PanGestureHandlerGestureEvent } from 'react-native-gesture-handler';
import Animated, {
  runOnJS,
  useAnimatedGestureHandler,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { Colors } from '@theme';
import { modalInfoSelector, useStore } from '@store/index';

import { styles } from './styles';

type CustomModalProps = {
  visible: boolean;
  dontClose?: boolean;
  children: React.ReactNode;
  onOverlayPress?: (() => Promise<void>) | (() => void);
  overlayColor?: string;
  cardColor?: string;
  bottomFixed?: boolean;
  customCardStyle?: ViewStyle;
  translateAnimation?: boolean;
  customStyle?: StyleProp<Animated.AnimateStyle<StyleProp<ViewStyle>>>;
};

type ContextType = {
  translateY: number;
};

export const CustomModal = ({
  visible,
  onOverlayPress,
  dontClose,
  overlayColor = Colors.black,
  cardColor = Colors.blackTwo,
  children,
  bottomFixed = true,
  customCardStyle = {},
  translateAnimation = true,
  customStyle = {},
}: CustomModalProps) => {
  const { height } = useWindowDimensions();
  const translateY = useSharedValue(height);

  const modalInfo = useStore(modalInfoSelector);

  const handleOverlay = () => {
    if (!onOverlayPress) return null;
    return onOverlayPress();
  };

  useEffect(() => {
    if (!visible) {
      translateY.value = height;
    } else {
      translateY.value = withTiming(0);
    }

    return () => {
      translateY.value = height;
    };
  }, [height, translateY, visible]);

  const oStyle = useAnimatedStyle(
    () => ({
      opacity: visible ? withTiming(0.7) : withTiming(0),
    }),
    [visible]
  );

  const tStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateY: translateY.value }],
    }),
    [visible]
  );

  const panGestureEvent = useAnimatedGestureHandler<PanGestureHandlerGestureEvent, ContextType>({
    onStart: (_event, context) => {
      context.translateY = translateY.value;
    },
    onActive: (event, context) => {
      translateY.value = event.translationY + context.translateY;
    },
    onEnd: (event) => {
      if (event.translationY > 150) {
        if (dontClose) {
          translateY.value = withTiming(0);
        } else {
          translateY.value = withTiming(height, undefined, () => {
            runOnJS(handleOverlay)();
          });
        }
      } else {
        translateY.value = withTiming(0);
      }
    },
  });

  return (
    <AnimatePresence>
      {visible && (
        <View
          key="modal"
          style={styles.overlay}
          from={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}>
          <Animated.View style={visible && [styles.overlay, { backgroundColor: overlayColor }, oStyle]} />
          <Pressable style={styles.blur} onPress={handleOverlay} />
          <PanGestureHandler onGestureEvent={panGestureEvent}>
            <Animated.View
              style={[
                styles.box,
                modalInfo?.bottomFixed && bottomFixed ? styles.bottomFixed : null,
                { backgroundColor: cardColor },
                translateAnimation && tStyle,
                customCardStyle,
                customStyle || {},
              ]}>
              {children}
            </Animated.View>
          </PanGestureHandler>
        </View>
      )}
    </AnimatePresence>
  );
};
