/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';
import { View, } from 'react-native';
import { isWebResponsive } from '@constants/platform';
import { styles } from './styles';
import { useAdvertisingByDimension } from '@services/useAdvertisingByDimension';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';
import Skeleton from '@atoms/Skeleton';
import { moderateScale } from '@utils/scaling';
import AddBanner from '@atoms/AddBanner';
import { BANNER_SIZE } from '@constants/sizes';
import { AnalyticsEventsInterface } from '@interfaces/analytics';
import GoogleAdManagerBanner from '@atoms/GoogleAdManagerBanner/GoogleAdManagerBanner.web';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';

interface AdImageComponentProps {
  width: number;
  height: number;
  analyticsEvent: AnalyticsEventsInterface[keyof AnalyticsEventsInterface];
  creatorId?: number;
  googleAdManagerUnitId: GoogleAdManagerUnitIdEnum;
}

const AdImageComponent = ({ width, height, analyticsEvent, creatorId, googleAdManagerUnitId }: AdImageComponentProps) => {
  const countryISOCode = useCountryIsoCode();

  const { data, isLoading } = useAdvertisingByDimension(
    width, height, "IMAGE", countryISOCode
  );

  const getDirectSaleBanner = () => {
    return (
      <AddBanner
        analyticsEvent={analyticsEvent}
        bannerDimentions={{
          width: width,
          height: height,
        }}
        creatorId={creatorId}
        isDirectSale={data?.advertising.direct_sale}
        items={data?.advertising ? [
          {
            id: 0,
            type: 'IMAGE',
            order: null,
            advertising: {
              id: data?.advertising.id,
              name: data?.advertising.name,
              imageUrl: data?.advertising.imageUrl,
              siteUrl: data?.advertising.siteUrl,
              platform: data?.advertising.platform,
            },
            game: null,
          },
        ] : []}
      />
    );
  };

  const getGoogleAdManagerBanner = () => {
    if (googleAdManagerUnitId) {
      return (
        <GoogleAdManagerBanner
          unitId={googleAdManagerUnitId}
        />
      );
    }
    return null
  };

  const getAdSkeleton = () => {
    return (
      <Skeleton
        width={moderateScale(width)}
        height={moderateScale(height)}
      />
    )
  }

  const getAd = () => {
    if (isLoading) {
      return (
        getAdSkeleton()
      )
    }
    return (
      <View style={{ width: width, height: height }}>
        {data?.advertising.direct_sale
          ? getDirectSaleBanner()
          : getGoogleAdManagerBanner()}
      </View>
    );
  }



  return (
    <View style={[styles.adContainer]}>
      {getAd()}
    </View>
  );
};

export default AdImageComponent;
