import * as React from 'react';
import Svg, { SvgProps, Rect, G, Path, Defs, ClipPath } from 'react-native-svg';

const CommentMenu = (props: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Rect x={0.216} width={24.001} height={24.001} rx={12} fill="#272B30" />
    <G clipPath="url(#a)">
      <Path
        d="M8.552 10.953A1.05 1.05 0 0 0 7.505 12a1.05 1.05 0 0 0 1.047 1.047A1.05 1.05 0 0 0 9.599 12a1.05 1.05 0 0 0-1.047-1.047Zm7.33 0A1.05 1.05 0 0 0 14.835 12a1.05 1.05 0 0 0 1.047 1.047A1.05 1.05 0 0 0 16.929 12a1.05 1.05 0 0 0-1.047-1.047Zm-3.665 0A1.05 1.05 0 0 0 11.17 12a1.05 1.05 0 0 0 1.047 1.047A1.05 1.05 0 0 0 13.264 12a1.05 1.05 0 0 0-1.047-1.047Z"
        fill="#FCFCFD"
      />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" transform="translate(5.934 5.718)" d="M0 0h12.565v12.565H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default CommentMenu;
