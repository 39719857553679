import * as React from 'react';
import { memo } from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const ShareBannerIcon = ({ size = 40, ...props }: { size?: number } & SvgProps) => (
  <Svg width={size} height={size} fill="none" {...props}>
    <Rect width={size} height={size} rx={size * 0.3} fill="#353945" />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 11h1.143c3.235 0 5.947 2.287 6.669 5.364.123.525.188 1.073.188 1.636 0 3.866-3.07 7-6.857 7h-6.857C11.023 25 10 23.955 10 22.667v-3.5C10 14.657 13.582 11 18 11zm1.429 16h-3.37a6.764 6.764 0 004.798 2h6.857C28.977 29 30 27.955 30 26.667v-3.5a8.233 8.233 0 00-2.003-5.406L28 18c0 4.97-3.838 9-8.571 9z"
      fill="#E5F68C"
      scale={size / 40}
    />
  </Svg>
);

export default memo(ShareBannerIcon);
