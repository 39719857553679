import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';

async function getLikedGames(userId: string) {
  const response = await axiosGamesInstance.get(`/game/likes/pagination?userId=${userId}`);
  return response.data;
}

export function useGetLikedGames(userId: string, userType: 'CREATOR') {
  return useQuery([QueryKeys.GET_LIKED_GAMES, userId], () => getLikedGames(userId), {
    enabled: !!userId && userType !== 'CREATOR',
  });
}

export async function prefetchLikedGames(userId: string) {
  await client.prefetchQuery(QueryKeys.GET_LIKED_GAMES, () => getLikedGames(userId));
}
