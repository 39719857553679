import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    marginTop: moderateScale(24),
    height: 1,
    width: '100%',
    backgroundColor: Colors.greyFour,
  },
  fullWidth: {
    width: '120%',
    left: -20,
    ...(isDesktop && {
      width: 'unset',
      left: 0,
    }),
  },
});
