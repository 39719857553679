import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FullnameIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={20} height={21} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.96 9.67a3.32 3.32 0 100-6.64 3.32 3.32 0 000 6.64zm0 8.299c3.208 0 5.809-1.486 5.809-3.32 0-1.833-2.601-3.32-5.81-3.32-3.208 0-5.809 1.487-5.809 3.32 0 1.834 2.601 3.32 5.81 3.32z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default FullnameIcon;
