import { useQuery } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { isMobile } from '@constants/platform';

async function getUserProfile(userName: string) {
  const URL = isMobile ? `/users/profile/${userName}` : `/users/desktop/profile/${userName}`;
  const response = await axiosAuthInstance.get(URL);
  return response.data;
}

export function useGetUserProfile(userName: string) {
  
  return useQuery([QueryKeys.GET_PROFILE_BY_ID, userName], () => getUserProfile(userName), {
    enabled: !!userName,
  });
}
