import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const WhatsappIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      d="M8.23 21.045l.32-.678a.75.75 0 00-.443-.062l.123.74zm-4.794-4.816l.74.12a.75.75 0 00-.06-.438l-.68.318zm.439 5.542l-.124-.74.124.74zM21.75 12a9.25 9.25 0 01-9.25 9.25v1.5c5.937 0 10.75-4.813 10.75-10.75h-1.5zm-18.5 0a9.25 9.25 0 019.25-9.25v-1.5C6.563 1.25 1.75 6.063 1.75 12h1.5zm9.25-9.25A9.25 9.25 0 0121.75 12h1.5c0-5.937-4.813-10.75-10.75-10.75v1.5zM3.998 22.51l4.355-.725-.246-1.48-4.356.726.247 1.48zm8.502-1.26a9.21 9.21 0 01-3.95-.883l-.64 1.356a10.71 10.71 0 004.59 1.027v-1.5zm-8.385-5.338A9.212 9.212 0 013.25 12h-1.5c0 1.623.36 3.165 1.006 4.547l1.36-.636zm-1.42.197l-.712 4.395 1.48.24.713-4.395-1.48-.24zm1.056 4.922a.25.25 0 01-.288-.287l-1.48-.24a1.75 1.75 0 002.015 2.007l-.247-1.48zM16.5 15.111v-.436c0-.542-.33-1.03-.833-1.23l-.466-.187a1.08 1.08 0 00-1.368.52s-1.11-.222-2-1.111c-.889-.89-1.11-2-1.11-2a1.08 1.08 0 00.519-1.368l-.187-.466A1.325 1.325 0 009.825 8H9.39a.889.889 0 00-.889.889A7.111 7.111 0 0015.611 16c.491 0 .889-.398.889-.889z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default WhatsappIcon;
