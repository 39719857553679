import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const Flag = (props: SvgProps) => (
  <Svg width={23} height={23} fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_4418_75378)" fill="#777E91">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.03 9.803l3.17-3.699c1.111-1.297.19-3.301-1.52-3.301H4.03v14h13.65c1.71 0 2.631-2.005 1.52-3.302l-3.17-3.698zm-10 5h11.65l-4.285-5 4.286-5H6.029v10z"
      />
      <Path d="M4.03 1.803a1 1 0 012 0v22a1 1 0 11-2 0v-22z" />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_4418_75378">
        <Path fill="#fff" transform="translate(.03 .803)" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Flag;
