import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const Send = (props: SvgProps) => {
  const { fill } = props;

  return (
    <Svg width={46} height={45} fill="none" {...props}>
      <Rect x={0.821} y={0.5} width={44.761} height={43.557} rx={21.779} fill={fill || '#6546FC'} />
      <Path
        d="M33.532 13.65a1.823 1.823 0 0 0-.867-.407 1.869 1.869 0 0 0-.962.083l-18.276 6.587a1.788 1.788 0 0 0-.82.596 1.652 1.652 0 0 0-.092 1.881c.18.288.444.52.759.666l2.853 1.33a.63.63 0 0 1 .153.103c.296.732 1.467 3.582 1.995 4.611.246.483.673.86 1.197 1.054a.664.664 0 0 1-.098-.015 1.869 1.869 0 0 0 1.144.037c.284-.081.542-.23.751-.43l1.064-1.015a.629.629 0 0 1 .718-.094l4.58 2.479a1.857 1.857 0 0 0 1.597.088c.255-.104.481-.263.66-.466.18-.202.308-.44.374-.698l3.823-14.704c.08-.302.07-.62-.027-.917a1.685 1.685 0 0 0-.526-.77ZM17.42 24.136l9.798-5.345-5.524 5.402a.85.85 0 0 0-.2.306v.015c-.01.034-1.105 3.11-1.75 4.501a1.043 1.043 0 0 1-.384-.404c-.48-.959-1.596-3.675-1.926-4.475h-.014Z"
        fill="#fff"
      />
    </Svg>
  );
};

export default Send;
