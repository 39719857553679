import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Linking, Pressable, View, StyleSheet } from 'react-native';
import Animated, { FadeOutLeft, FadeInLeft, FadeInRight, FadeOutRight } from 'react-native-reanimated';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { FooterButton } from '@atoms/FooterButton';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { authTokenSelector, setAuthTokenHandler, useStore } from '@store/index';
import toast from '@utils/toast';
import { passwordRegex } from '@utils/validations';
import { RootStackNavigator } from 'app/navigation/types';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import DeleteAccountCatIcon from '@assets/svg/DeleteAccountCatIcon';
import { useDeleteAccount } from '@services/useDeleteAccount';
import { clearData } from '@utils/session';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';

import { styles } from '../styles';
import { ScreenTitle } from '..';

const customStyle = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: isWebResponsive ? 0 : Spacing.XLARGE,
    minHeight: 420,
    maxHeight: isWebResponsive ? 840 : 600,
  },
});

type DeleteAccountProps = NativeStackScreenProps<RootStackNavigator, 'DeleteAccount'>;

interface DeleteAccountParams {
  password: string;
}

const DeleteAccount = ({ navigation }: DeleteAccountProps) => {
  const [step, setStep] = useState<number>(1);

  const setAuthToken = useStore(setAuthTokenHandler);
  const authToken = useStore(authTokenSelector);
  const i18n = useTranslations();
  const deleteAccount = useDeleteAccount(authToken || '');
  const { goBack, reset } = navigation;
  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<DeleteAccountParams>();

  const password = watch('password');

  const isDisabled = !password && step !== 1;

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    } else {
      goBack();
    }
  };

  const onSubmit = (values: DeleteAccountParams) => {
    const dataToSend = {
      password: values.password,
    };
    deleteAccount.mutate(dataToSend, {
      onSuccess: () => {
        clearData();
        setAuthToken(undefined);
        reset({ index: 0, routes: [{ name: 'Login' }] });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          if (message === 'Incorrect Password.') {
            setError('password', { message });
          } else {
            toast.danger({ icon: 'error', title: i18n.t('account_settings.changes_not_saved') });
          }
        }
      },
    });
  };

  const handleDeleteAccount = () => {
    if (step === 1) {
      setStep(2);
    } else {
      handleSubmit(onSubmit)();
    }
  };

  const handleContactGato = () => {
    Linking.openURL('mailto:hello@gato.us');
  };

  const textAlign = isMobile || isWebResponsive ? 'left' : 'center';

  return (
    <Layout
      padding={false}
      withScroll={isDesktop && !isWebResponsive}
      hasBottomCTAS={
        <FooterButton>
          <View style={styles.contactWrapper}>
            <CustomText size="medium" weight="interBold">
              {`${i18n.t('account_settings.need_help')} `}
              <Pressable hitSlop={10} onPress={handleContactGato}>
                <CustomText customStyle={styles.contact} size="medium" weight="interBold" color="limeGreen">
                  {i18n.t('account_settings.contact_us')}
                </CustomText>
              </Pressable>
            </CustomText>
          </View>
          <Button
            loading={deleteAccount.isLoading}
            disabled={isDisabled}
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            onPress={handleDeleteAccount}>
            {i18n.t('account_settings.delete_account_button_action')}
          </Button>
        </FooterButton>
      }
      verticallyCentered={isDesktop}
      showScrollIndicator={false}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          {isMobile || isWebResponsive ? (
            <View style={styles.goBack}>
              <GoBackButton goBackAction={handleGoBack} />
            </View>
          ) : null}
          {isMobile || isWebResponsive ? (
            <ScreenTitle>{i18n.t('account_settings.delete_account')}</ScreenTitle>
          ) : null}
          <View
            style={[
              styles.body,
              isDesktop && !isWebResponsive ? customStyle.body : { paddingHorizontal: 20 },
            ]}>
            {step === 1 ? (
              <Animated.View style={styles.wrapper} entering={FadeInLeft} exiting={FadeOutLeft}>
                <CustomText
                  align={textAlign}
                  weight="interSemi"
                  size={isWebResponsive ? 'xmedium' : 'xbig'}
                  color="limeGreen">
                  {i18n.t('account_settings.delete_account_title_1')}
                </CustomText>
                <CustomText
                  customStyle={styles.description}
                  align={textAlign}
                  weight="interMedium"
                  size={isWebResponsive ? 'medium' : 'xmedium'}
                  color="greyThree">
                  {i18n.t('account_settings.delete_account_description_1')}
                </CustomText>
                <View style={[styles.deleteCat, isWebResponsive ? { transform: [{ scale: 0.85 }] } : {}]}>
                  <DeleteAccountCatIcon />
                </View>
              </Animated.View>
            ) : null}
            {step === 2 ? (
              <Animated.View style={styles.wrapper} entering={FadeInRight} exiting={FadeOutRight}>
                <CustomText align="left" weight="interSemi" size="xbig" color="limeGreen">
                  {i18n.t('account_settings.delete_account_title_2')}
                </CustomText>
                <CustomText
                  customStyle={styles.description}
                  align="left"
                  weight="interMedium"
                  size="xmedium"
                  color="greyThree">
                  {i18n.t('account_settings.delete_account_description_2')}
                </CustomText>
                <Field
                  icon={PasswordInputIcon}
                  containerStyles={styles.passInput}
                  name="password"
                  control={control}
                  placeholder={i18n.t('register.password_placeholder').toString()}
                  rules={{
                    pattern: {
                      value: passwordRegex,
                      message: i18n.t('register.password_min_lenght_error'),
                    },
                  }}
                  secure
                  getValues={getValues}
                  error={errors}
                />
              </Animated.View>
            ) : null}
            {isDesktop && !isWebResponsive ? (
              <>
                <View style={styles.contactWrapper}>
                  <CustomText size="medium" weight="interBold">
                    {`${i18n.t('account_settings.need_help')} `}
                    <Pressable hitSlop={10} onPress={handleContactGato}>
                      <CustomText
                        customStyle={styles.contact}
                        size="medium"
                        weight="interBold"
                        color="limeGreen">
                        {i18n.t('account_settings.contact_us')}
                      </CustomText>
                    </Pressable>
                  </CustomText>
                </View>
                <Button
                  loading={deleteAccount.isLoading}
                  disabled={isDisabled}
                  customStyle={styles.deleteAccountButton}
                  customTextStyle={styles.footerButtonText}
                  onPress={handleDeleteAccount}>
                  {i18n.t('account_settings.delete_account_button_action')}
                </Button>
              </>
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default DeleteAccount;
