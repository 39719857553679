import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ShareIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.1663 4.09118C19.1663 5.86309 17.7299 7.29951 15.958 7.29951C14.9486 7.29951 14.048 6.83331 13.4599 6.10447C13.4392 6.11773 13.4177 6.13001 13.3952 6.14123L7.21905 9.22931C7.19681 9.24043 7.17426 9.25023 7.15149 9.25874C7.2156 9.51169 7.24967 9.77662 7.24967 10.0495C7.24967 10.3224 7.2156 10.5873 7.15148 10.8403C7.17426 10.8488 7.19681 10.8586 7.21905 10.8697L13.3952 13.9578C13.4177 13.969 13.4392 13.9813 13.4599 13.9946C14.048 13.2657 14.9485 12.7995 15.958 12.7995C17.7299 12.7995 19.1663 14.2359 19.1663 16.0078C19.1663 17.7798 17.7299 19.2162 15.958 19.2162C14.1861 19.2162 12.7497 17.7798 12.7497 16.0078C12.7497 15.735 12.7837 15.47 12.8479 15.2171C12.8251 15.2086 12.8025 15.1988 12.7803 15.1876L6.60413 12.0996C6.58169 12.0883 6.56013 12.0761 6.53949 12.0628C5.95137 12.7916 5.0508 13.2578 4.04134 13.2578C2.26943 13.2578 0.833008 11.8214 0.833008 10.0495C0.833008 8.2776 2.26943 6.84118 4.04134 6.84118C5.0508 6.84118 5.95137 7.30738 6.53949 8.03622C6.56014 8.02297 6.58169 8.01069 6.60413 7.99947L12.7803 4.91139C12.8025 4.90027 12.8251 4.89047 12.8479 4.88196C12.7837 4.62901 12.7497 4.36407 12.7497 4.09118C12.7497 2.31927 14.1861 0.882847 15.958 0.882847C17.7299 0.882847 19.1663 2.31927 19.1663 4.09118Z"
      fill={fill || '#FCFCFC'}
    />
  </Svg>
);

export default ShareIcon;
