import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { ResendValidateCodeParams } from '@interfaces/auth';

async function resendValidateCodeService(data: ResendValidateCodeParams) {
  const response = await axiosAuthInstance.post('/auth/user/verify/resend', data);
  return response.data;
}

export function useResendValidateCode() {
  return useMutation((data: ResendValidateCodeParams) => resendValidateCodeService(data));
}
