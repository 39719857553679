import { StyleSheet } from 'react-native';

import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 16,
  },
  progress: {
    width: '100%',
    height: 8,
    borderRadius: 16,
  },
  progressbarEmpty: {
    backgroundColor: Colors.grey,
  },
  progressPorcentage: {
    backgroundColor: Colors.limeGreen,
    position: 'absolute',
  },
});
