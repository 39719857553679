import * as React from 'react';
import Svg, { SvgProps, Rect, Mask, Path, G } from 'react-native-svg';

const DeviceIOS = (props: SvgProps) => (
  <Svg width={51} height={48} fill="none" {...props}>
    <Rect width={50.273} height={46.242} y={0.969} fill="#1A1D1F" rx={9.248} />
    <Mask
      id="a"
      width={15}
      height={18}
      x={18}
      y={15}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: 'alpha',
      }}>
      <Path
        fill="#A4ADC2"
        fillRule="evenodd"
        d="M28.25 15.447a3.826 3.826 0 0 0-1.897.98c-.78.735-1.215 1.717-1.215 2.742v.234l.297-.016c1.16-.064 2.21-.813 2.773-1.98.265-.548.37-.97.396-1.589.02-.495.06-.454-.353-.37Zm-.52 4.196c-.356.066-.706.174-1.246.382-.986.38-1.127.415-1.452.358a6.891 6.891 0 0 1-.791-.261c-1.058-.404-1.401-.485-1.947-.455a4.17 4.17 0 0 0-3.244 1.861 5.606 5.606 0 0 0-.717 1.61c-.24.933-.265 2.1-.071 3.237.21 1.23.671 2.526 1.282 3.598.507.891 1.422 2.036 1.916 2.397.436.319.726.418 1.202.414.434-.005.623-.053 1.278-.325.588-.245.822-.313 1.223-.354.586-.06 1.072.03 1.785.329.634.266.919.34 1.326.34.614 0 1.282-.353 1.817-.962.624-.71 1.408-1.978 1.773-2.869.117-.286.286-.76.275-.77a3.78 3.78 0 0 0-.168-.086c-1.284-.642-2.124-1.998-2.122-3.425a3.872 3.872 0 0 1 .67-2.19 4.73 4.73 0 0 1 .916-.965c.112-.082.215-.159.23-.171.035-.03-.18-.312-.43-.564-.516-.516-1.143-.869-1.898-1.065-.297-.076-.39-.087-.855-.097-.378-.007-.584.002-.752.033Z"
        clipRule="evenodd"
      />
    </Mask>
    <G mask="url(#a)">
      <Path
        fill="#A4ADC2"
        fillRule="evenodd"
        d="M28.25 15.447a3.826 3.826 0 0 0-1.897.98c-.78.735-1.215 1.717-1.215 2.742v.234l.297-.016c1.16-.064 2.21-.813 2.773-1.98.265-.548.37-.97.396-1.589.02-.495.06-.454-.353-.37Zm-.52 4.196c-.356.066-.706.174-1.246.382-.986.38-1.127.415-1.452.358a6.891 6.891 0 0 1-.791-.261c-1.058-.404-1.401-.485-1.947-.455a4.17 4.17 0 0 0-3.244 1.861 5.606 5.606 0 0 0-.717 1.61c-.24.933-.265 2.1-.071 3.237.21 1.23.671 2.526 1.282 3.598.507.891 1.422 2.036 1.916 2.397.436.319.726.418 1.202.414.434-.005.623-.053 1.278-.325.588-.245.822-.313 1.223-.354.586-.06 1.072.03 1.785.329.634.266.919.34 1.326.34.614 0 1.282-.353 1.817-.962.624-.71 1.408-1.978 1.773-2.869.117-.286.286-.76.275-.77a3.78 3.78 0 0 0-.168-.086c-1.284-.642-2.124-1.998-2.122-3.425a3.872 3.872 0 0 1 .67-2.19 4.73 4.73 0 0 1 .916-.965c.112-.082.215-.159.23-.171.035-.03-.18-.312-.43-.564-.516-.516-1.143-.869-1.898-1.065-.297-.076-.39-.087-.855-.097-.378-.007-.584.002-.752.033Z"
        clipRule="evenodd"
      />
    </G>
  </Svg>
);
export default DeviceIOS;
