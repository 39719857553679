import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import React, { useEffect, useState } from 'react';
import { Linking, Pressable, View } from 'react-native';
import { FlatList } from 'react-native-gesture-handler';
import AsyncStorage from '@react-native-async-storage/async-storage';

import CustomText from '@atoms/CustomText';
import { isIos, IS_LARGE_DEVICE } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import SettingsItem from '@molecules/SettingsItem';
import { Layout } from '@organisms/Layout';
import {
  setAuthTokenHandler,
  setModalInfoHandler,
  tabBarHeightSelector,
  toggleModalHandler,
  useStore,
} from '@store/index';
import { clearData } from '@utils/session';
import handleAnalyticsEvent from '@utils/analytics';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import GoBackButton from '@molecules/GoBackButton';
import { useLogout } from '@services/useLogin';
import toast from '@utils/toast';

import pkg from '../../../../package.json';
import { RootStackNavigator } from '../../../navigation/types';

import { SETTINGS_OPTIONS } from './constants';
import { styles } from './styles';

type SettingsProps = BottomTabScreenProps<RootStackNavigator, 'Settings'>;

const Settings = ({ navigation }: SettingsProps) => {
  const i18n = useTranslations();
  const tabBarHeight = useStore(tabBarHeightSelector);
  const actualYear = new Date().getFullYear();
  const [offset, setOffset] = useState(tabBarHeight);
  const logOut = useLogout();

  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const setAuthToken = useStore(setAuthTokenHandler);

  const handlePressItem = (item: string) => {
    if (item === 'Help') {
      Linking.openURL(
        'mailto:hello@gato.us?subject=Support request from GATO&body=Hi there,\n\nThank you for contacting GATO.\n\nHow we can assist you? Feel free to write it down.\n\n## Please type your request above this line ##\n\nOur support staff will respond as soon as possible.\n\nCheers,\nGATO'
      );
    } else {
      if (item === 'UploadGame') {
        handleAnalyticsEvent(AnalyticsEvents.UPLOAD_GAME_MENU);
      }
      navigation.navigate(item);
    }
  };

  const confirmLogout = async () => {
    const deviceToken = await AsyncStorage.getItem('deviceToken');
    const data = {
      deviceToken,
    };

    toggleModal();
    logOut.mutate(data, {
      onSuccess: () => {
        clearData();
        setAuthToken(undefined);
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Login',
            },
          ],
        });
      },
      onError: () => {
        toast.danger({ icon: 'error', title: i18n.t('settings.logout_error') });
      },
    });
  };

  const cancelLogout = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const handleLogout = () => {
    setModalInfo({
      message: i18n.t('settings.logout_confirm_message'),
      confirmText: i18n.t('settings.logout_confirm_text'),
      cancelText: i18n.t('settings.logout_cancel_text'),
      confirmAction: () => confirmLogout(),
      cancelAction: () => cancelLogout(),
      bottomFixed: false,
    });
    toggleModal();
  };

  useEffect(() => {
    if (isIos && IS_LARGE_DEVICE) {
      setOffset((prevOffset) => prevOffset - 30);
    }
  }, []);

  return (
    <Layout tabOffset={offset} withScroll={false}>
      <View style={styles.header}>
        <View style={styles.flex}>
          <GoBackButton />
        </View>
        <View style={styles.flex}>
          <CustomText color="greySix" weight="interSemi" size="lmedium">
            Settings
          </CustomText>
        </View>
        <View style={styles.flex} />
      </View>
      <View style={styles.flex}>
        <FlatList
          data={SETTINGS_OPTIONS}
          showsVerticalScrollIndicator={false}
          ListFooterComponent={
            <View>
              <Pressable hitSlop={10} onPress={handleLogout}>
                <CustomText customStyle={styles.logout} align="left" weight="interBold" color="greySix">
                  {i18n.t('settings.logout')}
                </CustomText>
              </Pressable>
              <View style={styles.between}>
                <View style={styles.footer}>
                  <CustomText size="msmall" color="greyTwo" weight="interSemi">{`${actualYear} ${i18n.t(
                    'settings.copyright'
                  )}`}</CustomText>
                  <CustomText size="msmall" color="greyTwo" weight="interSemi">
                    {`${i18n.t('settings.version')} ${pkg.version}`}
                  </CustomText>
                </View>
              </View>
            </View>
          }
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => <SettingsItem item={item} action={handlePressItem} />}
        />
      </View>
    </Layout>
  );
};

export default Settings;
