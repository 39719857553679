import { LinearGradient } from 'expo-linear-gradient';
import * as Linking from 'expo-linking';
import React from 'react';
import { useNavigation } from '@react-navigation/core';
import { ScrollView, View } from 'react-native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import GatoBannerOne from '@assets/svg/GatoBannerOne';
import GatoBannerTwo from '@assets/svg/GatoBannerTwo';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { SCREEN_WIDTH } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { Spacing } from '@theme';
import handleAnalyticsEvent from '@utils/analytics';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { RootStackNavigator } from 'app/navigation/types';

import { styles } from './styles';

type IconT = 'creator' | 'advertiser';

type GatoBannersProps = {
  buttonAction?: () => void;
  type: IconT;
};

const ICONS = {
  creator: <GatoBannerOne />,
  advertiser: <GatoBannerTwo />,
};

const GatoBanner = ({ buttonAction, type }: GatoBannersProps) => {
  const i18n = useTranslations();
  const handlePress = () => {
    if (!buttonAction) return;
    buttonAction();
  };

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 0.5, y: 1 }}
      locations={[0.3, 0.9]}
      colors={['#7154FF', '#3A298E']}
      style={styles.container}>
      <View style={styles.left}>
        <CustomText customStyle={styles.title} align="left" weight="interBlack" size="xmedium">
          {i18n.t(`home.${type}_title`)}
        </CustomText>
        <CustomText customStyle={styles.title} align="left" weight="interSemi" size="small">
          {i18n.t(`home.${type}_description`)}
        </CustomText>
        {buttonAction ? (
          <Button onPress={handlePress} customStyle={styles.button}>
            {i18n.t('home.view_more')}
          </Button>
        ) : null}
      </View>
      <View style={styles.right}>{ICONS[type]}</View>
    </LinearGradient>
  );
};

const GatoBanners = () => {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();
  const handleUpdateGame = () => {
    handleAnalyticsEvent(AnalyticsEvents.UPLOAD_GAME_BANNER);
    navigation.navigate('UploadGame');
  };

  const handlePublishAdds = () => {
    Linking.openURL('https://es.gato.games');
  };
  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      pagingEnabled
      contentContainerStyle={styles.scrollContent}
      decelerationRate="fast"
      snapToInterval={SCREEN_WIDTH - Spacing.LATERAL * 2}>
      <GatoBanner buttonAction={handleUpdateGame} type="creator" />
      {/* <GatoBanner buttonAction={handlePublishAdds} type="advertiser" /> */}
    </ScrollView>
  );
};

export default GatoBanners;
