import { AxiosError } from 'axios';

import { useTranslations } from '@hooks/useTranslation';
import { toggleModalHandler, setModalInfoHandler, useStore } from '@store/index';
import {
  useDeleteComment,
  useDeleteResponseComment,
  useReportComment,
  useReportReponseComment,
} from '@services/useComments';
import toast from '@utils/toast';

const useReportMenu = (id: number, fromResponse?: boolean) => {
  const deleteComment = useDeleteComment();
  const reportComment = useReportComment();
  const deleteResponseComment = useDeleteResponseComment();
  const reportResponseComment = useReportReponseComment();

  const i18n = useTranslations();

  const setModalInfo = useStore(setModalInfoHandler);
  const toggleModal = useStore(toggleModalHandler);

  const cancelModal = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const handleDeleteComment = () => {
    toggleModal();
    setModalInfo(undefined);

    const deleteMutation = fromResponse ? deleteResponseComment : deleteComment;

    deleteMutation.mutate(id, {
      onSuccess: async () => {
        toast.success({ title: i18n.t('comments.delete_comment_success'), icon: 'success' });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.delete_comment_error') });
        }
      },
    });
  };

  const handleDeleteCommentMenu = () => {
    setModalInfo({
      message: i18n.t('comments.delete_comment_question'),
      confirmText: i18n.t('comments.delete_comment_confirm'),
      cancelText: i18n.t('comments.delete_comment_cancel'),
      confirmAction: () => handleDeleteComment(),
      cancelAction: () => cancelModal(),
      bottomFixed: false,
    });
    toggleModal();
  };

  const handleReportComment = () => {
    toggleModal();
    setModalInfo(undefined);

    const reportMutation = fromResponse ? reportResponseComment : reportComment;

    reportMutation.mutate(id, {
      onSuccess: async () => {
        toast.success({ title: i18n.t('comments.report_comment_success'), icon: 'success' });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.report_comment_error') });
        }
      },
    });
  };

  const handleReportCommentMenu = () => {
    setModalInfo({
      message: i18n.t('comments.report_comment_question'),
      body: i18n.t('comments.report_comment_body'),
      confirmText: i18n.t('comments.delete_comment_confirm'),
      cancelText: i18n.t('comments.report_comment_cancel'),
      confirmAction: () => handleReportComment(),
      cancelAction: () => cancelModal(),
      bottomFixed: false,
    });
    toggleModal();
  };

  return { handleReportCommentMenu, handleDeleteCommentMenu, deleteComment, deleteResponseComment };
};

export default useReportMenu;
