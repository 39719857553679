import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MyGames = (props: SvgProps) => (
  <Svg width={19} height={20} fill="none" {...props}>
    <Path
      fill="#777E91"
      fillRule="evenodd"
      d="M3.167 2.28c-.874 0-1.583.71-1.583 1.584V7.03c0 .875.709 1.584 1.583 1.584h3.167c.874 0 1.583-.71 1.583-1.584V3.864c0-.875-.709-1.584-1.583-1.584H3.167Zm11.084 6.334a3.167 3.167 0 1 0 0-6.334 3.167 3.167 0 0 0 0 6.334Zm-6.334 6.333a3.167 3.167 0 1 1-6.333 0 3.167 3.167 0 0 1 6.333 0Zm4.75-3.167c-.874 0-1.583.71-1.583 1.584v3.166c0 .875.709 1.584 1.583 1.584h3.167c.874 0 1.583-.71 1.583-1.584v-3.166c0-.875-.709-1.584-1.583-1.584h-3.167Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default MyGames;
