import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isMobile, isWebResponsive } from '@constants/platform';

const ICON_DIMENTIONS = moderateScale(40);

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  controls: {
    marginTop: isMobile || isWebResponsive ? moderateScale(30) : 42,
    marginBottom: moderateScale(16),
    padding: moderateScale(20),
    backgroundColor: Colors.socialBackground,
    width: '100%',
    borderRadius: moderateScale(16),
  },
  controlsChevron: {
    transform: [{ scale: 0.6 }],
  },
  controlsTitle: {
    marginLeft: moderateScale(8),
  },
  left: {
    width: ICON_DIMENTIONS,
    height: ICON_DIMENTIONS,
    borderRadius: ICON_DIMENTIONS / 2,
    backgroundColor: Colors.greyTwo,
    justifyContent: 'center',
    alignItems: 'center',
  },
  between: {
    justifyContent: 'space-between',
  },
  description: {
    marginTop: isMobile ? moderateScale(20) : 12,
    letterSpacing: 0.5,
  },
  control: { height: 50, width: 50 },
  separator: {
    height: 1,
    backgroundColor: Colors.greyFive,
    opacity: 0.5,
    marginVertical: isMobile ? moderateScale(16) : 8,
  },
});
