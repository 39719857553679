import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getAuthToken() {
  const token = await AsyncStorage.getItem('authToken');
  return token;
}

export async function setAuthToken(token: string) {
  await AsyncStorage.setItem('authToken', token);
}

export async function getRefreshToken() {
  const token = await AsyncStorage.getItem('refreshToken');
  return token;
}

export async function setRefreshToken(token: string) {
  await AsyncStorage.setItem('refreshToken', token);
}

export async function clearData() {
  await AsyncStorage.removeItem('authToken');
  await AsyncStorage.removeItem('refreshToken');
}
