import React from 'react';
import { View, ViewStyle } from 'react-native';

import { SMALL } from 'app/theme/spacing';

import { styles } from './styles';

interface RowProps {
  children: JSX.Element[];
  gap?: number;
  style?: ViewStyle;
}

const Row = ({ children, gap = SMALL, style }: RowProps) => (
  <View style={[styles.container, { marginLeft: -gap }, style]}>
    {children.map((item) => (
      <View style={[styles.item, { marginLeft: gap }]} key={item.key}>
        {item}
      </View>
    ))}
  </View>
);

export default Row;
