import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isMobile } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    marginBottom: Spacing.BANNERBOTTOM,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    paddingLeft: isMobile ? Spacing.LATERALBIG : undefined,
  },
  scrollView: {
    flex: 1,
  },
  scrollContainer: {
    paddingLeft: isMobile ? Spacing.LATERALBIG : undefined,
    marginTop: isMobile ? moderateScale(20) : moderateScale(30),
    alignItems: 'center',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
  },
  rowAd: { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
  ad: {
    borderRadius: moderateScale(12),
    borderWidth: 2,
    borderColor: Colors.limeGreen,
    // minWidth: 275,
    // width: 275,
    // height: 245,
  },
  adSmall: {
    borderRadius: moderateScale(12),
    borderWidth: 2,
    borderColor: Colors.limeGreen,
    minWidth: 147,
    width: 147,
    height: 190,
  },
  adMarker: {
    position: 'absolute',
    top: moderateScale(14),
    left: moderateScale(14),
    paddingHorizontal: moderateScale(10),
    paddingVertical: moderateScale(5),
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: moderateScale(26),
    backgroundColor: Colors.whiteOpacity(0.5),
  },
  bottomArrows: {
    paddingTop: Spacing.SMALL,
  },
});
