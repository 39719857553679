/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { isIos, isMobile } from '@constants/platform';
import { GameDetailResponse } from '@interfaces/gameDetail';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';

import pkg from '../../package.json';

async function getGameDetail(name: string, location: string): Promise<GameDetailResponse> {
  const path = isMobile ? `/game/${name}?location=${location}` : `/game/desktop/${name}?location=${location}`;
  const config = isMobile
    ? {
        headers: {
          so_version: pkg.version,
          so_name: isIos ? 'IOS' : 'ANDROID',
        },
      }
    : undefined;
  const response = await axiosGamesInstance.get<GameDetailResponse>(path, config);

  return response.data;
}

export function useGameDetail(name: string, location: string) {
  return useQuery([QueryKeys.GET_GAME_DETAIL, name], () => getGameDetail(name, location), {
    enabled: !!name && !!location,
  });
}

export const prefetchGameDetail = async (name: string, location: string) => {
  await client.prefetchQuery(QueryKeys.GET_GAME_DETAIL, () => getGameDetail(name, location));
};
