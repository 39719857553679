import React, { FC, memo, useCallback, useRef, useState } from 'react';
import { View, ActivityIndicator } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import Lottie from 'lottie-react-native';
import { MotiView } from 'moti';
import { useFocusEffect } from '@react-navigation/core';
import { useMutation } from 'react-query';
import * as Haptics from 'expo-haptics';

import ChangeAvatarLayout from '@templates/ChangeAvatarLayout';
import Button from '@atoms/Button';
import { RootStackNavigator } from 'app/navigation/types';
import { useMyProfile } from '@services/useMyProfile';
import CustomText from '@atoms/CustomText';
import AvatarImage from '@molecules/AvatarImage';
import * as UserService from '@services/UserService';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../../assets/lotties/confetti.json');

type Props = NativeStackScreenProps<RootStackNavigator, 'ChangeAvatarThirdStep'>;

const ChangeAvatarThirdStep: FC<Props> = ({ navigation, route }) => {
  const [displayBtn, setDisplayBtn] = useState<boolean>(false);

  const i18n = useTranslations();
  const animation = useRef<Lottie>(null);

  const { selectedAvatar, selectedBackground } = route.params;
  const { data, isLoading } = useMyProfile();
  const { mutate, isLoading: mutateLoading } = useMutation(
    () =>
      UserService.updateAvatar({
        avatarId: selectedAvatar.id,
        backgroundId: selectedBackground.id,
      }),
    {
      async onSuccess() {
        navigation.replace('MyProfile');
        await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
      },
    }
  );

  useFocusEffect(
    useCallback(() => {
      if (!isLoading) {
        setTimeout(async () => {
          animation.current?.play();
          if (!isWebResponsive) {
            await Haptics.notificationAsync(Haptics.NotificationFeedbackType.Success);
          }
        }, 2000);
      }
    }, [isLoading])
  );

  return (
    <>
      <View pointerEvents="none" style={styles.lottie}>
        <Lottie
          ref={animation}
          source={lottie}
          loop={false}
          onAnimationFinish={() => {
            setDisplayBtn(true);
          }}
        />
      </View>
      <ChangeAvatarLayout title={i18n.t('avatar.choose_step_title_third')} step="3">
        {isLoading ? (
          <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ActivityIndicator />
          </View>
        ) : (
          <View style={styles.container}>
            <View style={styles.content}>
              <MotiView
                style={styles.header}
                from={{ top: -50, opacity: 0 }}
                animate={{ top: 0, opacity: 1 }}
                transition={{
                  type: 'spring',
                  delay: 1000,
                }}>
                <CustomText weight="oswaldBold" customStyle={styles.title} color="white">
                  {i18n.t('avatar.choose_congrats')}
                </CustomText>
                <CustomText weight="oswaldBold" customStyle={styles.title} color="limeGreen">
                  {data.userName}
                </CustomText>
              </MotiView>
              <AvatarImage avatar={selectedAvatar} background={selectedBackground} delay={1500} transparent />
            </View>
            <View style={styles.bottom}>
              {displayBtn ? (
                <MotiView
                  from={{ bottom: -150, opacity: 0 }}
                  animate={{ bottom: 0, opacity: 1 }}
                  transition={{ type: 'spring' }}>
                  <Button
                    onPress={mutate}
                    customStyle={styles.button}
                    customTextStyle={styles.buttonText}
                    loading={mutateLoading}>
                    {i18n.t('avatar.choose_save_cta')}
                  </Button>
                </MotiView>
              ) : null}
            </View>
          </View>
        )}
      </ChangeAvatarLayout>
    </>
  );
};

export default memo(ChangeAvatarThirdStep);
