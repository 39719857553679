import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { EditUserInfoParams } from '@interfaces/accountSettings';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';

async function editUserInfoService(data: EditUserInfoParams, token: string) {
  const response = await axiosAuthInstance.put('/users/me/update-username-fullname-password', data, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useEditUserInfo(token: string) {
  return useMutation((data: EditUserInfoParams) => editUserInfoService(data, token), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_USER_DATA);
      await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
    },
  });
}
