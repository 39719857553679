import * as React from 'react';
import Svg, { Circle, Path, Rect, SvgProps } from 'react-native-svg';

import useEventColor from '@hooks/useEventColor';

const GATOLogo = (props: SvgProps) => {
  const { logoColor } = useEventColor();

  return (
    <Svg width={98} height={33} viewBox="0 0 98 33" fill="none" {...props}>
      <Circle
        cx={16.3485}
        cy={16.3485}
        r={16.3485}
        transform="matrix(-1 0 0 1 32.697 0.151367)"
        fill={logoColor}
      />
      <Path
        d="M16.6375 3.11525C18.8254 3.11155 20.8919 3.63026 22.7194 4.55467C23.2937 4.84551 23.8226 5.22528 24.2486 5.70879C24.8201 6.35717 25.1165 7.04909 25.0637 7.69285C24.96 8.97016 23.3557 10.2549 20.2842 8.3227C17.2127 6.39052 10.7595 6.01075 8.1363 11.2219C8.1363 11.2219 9.81005 10.254 12.26 11.4461C12.9316 11.773 14.3135 12.5335 15.5038 15.0177C16.067 16.1932 16.5736 16.2775 17.109 16.2256C17.6944 16.1691 18.2789 15.4068 18.6401 12.867C19.0014 10.3271 19.4673 9.96867 19.9165 9.95571C20.3667 9.94181 21.2374 10.881 21.7375 12.0102C21.7375 12.0102 23.892 11.3859 26.2716 11.8425C26.2716 11.8425 26.7199 9.5991 27.3414 9.42681C27.6406 9.34345 27.9926 9.62874 28.3149 10.0326C28.5622 10.342 28.7623 10.6856 28.9244 11.0459C29.6673 12.6965 30.0878 14.524 30.1026 16.4488C30.16 23.8358 24.1579 29.938 16.7709 29.9954C9.25893 30.0547 3.15949 23.9506 3.2234 16.4377C3.28638 9.1054 9.30617 3.12915 16.6385 3.11618L16.6375 3.11525Z"
        fill="black"
      />
      <Path
        d="M26.8784 13.8481C26.4715 13.8481 26.1429 14.2597 26.1445 14.7667C26.1445 15.2738 26.4747 15.6838 26.8815 15.6838C27.2884 15.6838 27.617 15.2722 27.6155 14.7652C27.6155 14.2581 27.2853 13.8481 26.8784 13.8481Z"
        fill={logoColor}
      />
      <Path
        d="M22.0356 13.8755C21.5974 13.8755 21.2422 14.3137 21.2438 14.852C21.2438 15.3919 21.6006 15.8285 22.0387 15.8269C22.4769 15.8254 22.8321 15.3888 22.8306 14.8504C22.8306 14.3105 22.4738 13.8739 22.0356 13.8755Z"
        fill={logoColor}
      />
      <Rect width={2.73196} height={1.09278} transform="matrix(-1 0 0 1 23.4272 13.625)" fill="black" />
      <Rect width={2.73196} height={1.09278} transform="matrix(-1 0 0 1 28.21 13.625)" fill="black" />
      <Path
        d="M48.7292 28.4065C47.2062 28.4065 45.999 28.0821 45.1075 27.4334C44.2159 26.7656 43.5752 25.8306 43.1851 24.6285C42.8137 23.4073 42.6279 21.9954 42.6279 20.3926V12.6363C42.6279 10.9763 42.8229 9.5452 43.213 8.34311C43.603 7.14103 44.2809 6.21561 45.2468 5.56687C46.2311 4.91812 47.5591 4.59375 49.2307 4.59375C50.8466 4.59375 52.1189 4.87042 53.0475 5.42376C53.9948 5.9771 54.6727 6.75941 55.0813 7.77069C55.4899 8.78197 55.6942 9.98406 55.6942 11.3769V12.7221H51.2088V11.0907C51.2088 10.4802 51.1716 9.94589 51.0973 9.48796C51.023 9.01094 50.8466 8.63886 50.568 8.37173C50.308 8.1046 49.8808 7.97104 49.2864 7.97104C48.6735 7.97104 48.2278 8.13322 47.9492 8.4576C47.6706 8.78197 47.4941 9.20174 47.4198 9.71692C47.3455 10.213 47.3084 10.7473 47.3084 11.3197V21.5947C47.3084 22.2434 47.3641 22.8254 47.4755 23.3406C47.587 23.8557 47.7913 24.2564 48.0885 24.5426C48.4042 24.8289 48.85 24.972 49.4257 24.972C50.0015 24.972 50.4473 24.8193 50.763 24.514C51.0788 24.2087 51.2923 23.7985 51.4038 23.2833C51.5338 22.7491 51.5988 22.148 51.5988 21.4802V19.0188H49.3979V16.1567H55.7499V28.063H52.7411L52.4625 25.9737C52.1653 26.6797 51.7195 27.2616 51.1252 27.7196C50.5308 28.1775 49.7322 28.4065 48.7292 28.4065Z"
        fill={logoColor}
      />
      <Path
        d="M58.0483 28.0589L62.4223 4.59375H67.5207L71.8668 28.0589H67.6043L66.7963 22.9023H63.2303L62.3666 28.0589H58.0483ZM63.6482 19.9764H66.3506L64.9854 10.5035L63.6482 19.9764Z"
        fill={logoColor}
      />
      <Path
        d="M75.2358 28.0589V8.15697H71.95V4.59375H82.8327V8.15697H79.5994V28.0589H75.2358Z"
        fill={logoColor}
      />
      <Path
        d="M90.5572 28.3775C88.9041 28.3775 87.5947 28.0722 86.6289 27.4617C85.6631 26.8511 84.9666 25.9829 84.5394 24.8572C84.1308 23.7124 83.9265 22.3481 83.9265 20.7644V12.121C83.9265 10.5373 84.1308 9.1826 84.5394 8.05685C84.9666 6.93111 85.6631 6.07249 86.6289 5.48099C87.5947 4.8895 88.9041 4.59375 90.5572 4.59375C92.2287 4.59375 93.5474 4.89904 94.5133 5.50961C95.4976 6.10111 96.1941 6.95973 96.6027 8.08547C97.0299 9.19214 97.2435 10.5373 97.2435 12.121V20.7644C97.2435 22.3481 97.0299 23.7124 96.6027 24.8572C96.1941 25.9829 95.4976 26.8511 94.5133 27.4617C93.5474 28.0722 92.2287 28.3775 90.5572 28.3775ZM90.5572 24.9717C91.1515 24.9717 91.588 24.8381 91.8666 24.571C92.1452 24.2848 92.3309 23.9127 92.4238 23.4548C92.5166 22.9778 92.5631 22.4721 92.5631 21.9379V10.9762C92.5631 10.4419 92.5166 9.94582 92.4238 9.48788C92.3309 9.02995 92.1452 8.66743 91.8666 8.4003C91.588 8.11409 91.1515 7.97099 90.5572 7.97099C90 7.97099 89.5821 8.11409 89.3035 8.4003C89.0249 8.66743 88.8391 9.02995 88.7463 9.48788C88.6534 9.94582 88.607 10.4419 88.607 10.9762V21.9379C88.607 22.4721 88.6441 22.9778 88.7184 23.4548C88.8113 23.9127 88.997 24.2848 89.2756 24.571C89.5542 24.8381 89.9814 24.9717 90.5572 24.9717Z"
        fill={logoColor}
      />
    </Svg>
  );
};

export default GATOLogo;
