// TYPING
export interface IFilters {
  creatorId: string;
  allGames: boolean;
  year: string;
  gameId: string;
  month: string;
  numberOfSessions: boolean;
  sessionTime: boolean;
  sessionAverage: boolean;
}

export interface CalendarType {
  value: string;
  label: string;
}
export interface GraphicType {
  value: string;
  label: string;
}

export enum ControllerFields {
  PLATFORM = 'platform',
}

// constants
export const yearsArray: CalendarType[] = [
  {
    value: '2023',
    label: '2023',
  },
];

export const allMonthsArray: CalendarType[] = [
  {
    value: '01',
    label: 'months.january',
  },
  {
    value: '02',
    label: 'months.february',
  },
  {
    value: '03',
    label: 'months.march',
  },
  {
    value: '04',
    label: 'months.april',
  },
  {
    value: '05',
    label: 'months.may',
  },
  {
    value: '06',
    label: 'months.june',
  },
  {
    value: '07',
    label: 'months.july',
  },
  {
    value: '08',
    label: 'months.august',
  },
  {
    value: '09',
    label: 'months.september',
  },
  {
    value: '10',
    label: 'months.october',
  },
  {
    value: '11',
    label: 'months.november',
  },
  {
    value: '12',
    label: 'months.december',
  },
];

export const graphicTypesArray: GraphicType[] = [
  {
    value: 'numberOfSessions',
    label: 'dashboard.graphic.number_of_sessions',
  },
  {
    value: 'sessionTime',
    label: 'dashboard.graphic.session_time',
  },
  {
    value: 'sessionAverage',
    label: 'dashboard.graphic.session_average',
  },
];

export const filtersDefaultValues: IFilters = {
  creatorId: '',
  allGames: true,
  year: new Date().getFullYear().toString(),
  gameId: '',
  month: String(new Date().getMonth() + 1),
  numberOfSessions: true,
  sessionTime: false,
  sessionAverage: false,
};
