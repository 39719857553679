import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { WINDOW_HEIGHT } from '@constants/platform';

export const styles = StyleSheet.create({
  headerLeft: {
    borderColor: Colors.error,
    borderWidth: 1,
    position: 'absolute',
    top: moderateScale(20),
    left: moderateScale(20),
    zIndex: 3,
  },
  headerRight: {
    position: 'absolute',
    top: moderateScale(20),
    right: moderateScale(20),
    zIndex: 3,
  },
  lottie: {
    width: moderateScale(40),
    height: moderateScale(40),
  },
  btn: {
    width: '100%',
    height: 40,
    backgroundColor: Colors.socialBackground,
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 30,
    borderRadius: 12,
    alignSelf: 'center',
    marginRight: 8,
  },
  unfollow: {
    backgroundColor: Colors.greyFour,
  },
  pressBlur: { ...StyleSheet.absoluteFillObject, zIndex: 5 },
  blurContainer: {
    ...StyleSheet.absoluteFillObject,
  },
  moreContainer: {
    alignSelf: 'center',
    marginTop: moderateScale(350),
    marginLeft: moderateScale(600),
    width: moderateScale(248),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: moderateScale(20),
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  padding: {
    paddingVertical: moderateScale(21),
  },
  bottomsheet: {
    flex: 0.45,
    borderTopStartRadius: 20,
    borderTopEndRadius: 20,
    paddingVertical: 30,
    paddingHorizontal: 20,
    justifyContent: 'space-between',
  },
  loading: {
    height: WINDOW_HEIGHT - moderateScale(120),
    justifyContent: 'center',
  },
  headerContainer: {
    width: moderateScale(468),
    alignSelf: 'center',
  },
  profileContainer: {
    minWidth: moderateScale(290),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileButtons: {
    minWidth: moderateScale(333),
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  button: {
    justifyContent: 'center',
    flexDirection: 'row',
    marginTop: moderateScale(30),
  },
  modal: {
    minWidth: moderateScale(443),
  },
  reportBtn: {
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
    width: moderateScale(136),
  },
  cancelBtn: {
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
    backgroundColor: Colors.darkGrey,
    marginLeft: moderateScale(8),
    width: moderateScale(136),
  },
  footer: {
    marginBottom: 150,
    height: 20,
  },
});
