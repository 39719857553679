import BottomSheet from '@gorhom/bottom-sheet';
import React, { useEffect, useMemo, useRef } from 'react';
import { Pressable, ScrollView, View } from 'react-native';
import CloseIcon from '@assets/svg/CloseIcon';
import CustomText from '@atoms/CustomText';
import RoundedButton from '@atoms/RoundedButton';
import { useTranslations } from '@hooks/useTranslation';
import {
  openFilterSelector,
  setOpenFilterHandler,
  setSelectedFiltersHandler,
  totalFiltersSelector,
  totalGamesSelector,
  useStore,
} from '@store/index';
import { Colors } from '@theme';
import { styles } from './styles';
import GameFilters from '@organisms/GameFilters';

const FiltersModal = () => {
  const i18n = useTranslations();
  const sheetRef = useRef<BottomSheet>(null);
  const totalGames = useStore(totalGamesSelector);
  const totalFilters = useStore(totalFiltersSelector);
  const setSelectedFilters = useStore(setSelectedFiltersHandler);
  const openFilter = useStore(openFilterSelector);
  const setOpenFilter = useStore(setOpenFilterHandler);
  const snapPoints = useMemo(() => ['100%'], []);


  useEffect(() => {
    if (openFilter) {
      sheetRef.current?.expand();
    } else {
      sheetRef.current?.close();
    }
  }, [openFilter]);

  const handleClose = () => {
    setOpenFilter(false);
  };

  const handle = () => (
    <View style={styles.header}>
      <View style={styles.border}>
        <CustomText size="big" weight="interSemi">
          {i18n.t('explore.filters')}
        </CustomText>
        <RoundedButton action={handleClose}>
          <CloseIcon />
        </RoundedButton>
      </View>
    </View>
  );

  const handleClearFilters = () => {
    setSelectedFilters([]);
  };

  return (
    <BottomSheet
      ref={sheetRef}
      enableHandlePanningGesture={false}
      enableContentPanningGesture={false}
      handleComponent={handle}
      index={-1}
      backgroundStyle={{ backgroundColor: Colors.blackTwo }}
      snapPoints={snapPoints}
      keyboardBehavior="interactive"
      keyboardBlurBehavior="restore"
      android_keyboardInputMode="adjustResize"
      onClose={() => setOpenFilter(false)}>
      <ScrollView contentContainerStyle={styles.contentContainer}>
        <GameFilters />
      </ScrollView>
      <View style={styles.footer}>
        <Pressable style={styles.clearFilters} onPress={handleClearFilters}>
          <CustomText color={totalFilters ? 'white' : 'greySix'} weight="interBold">
            {i18n.t('explore.clear_all')}
          </CustomText>
        </Pressable>
        <Pressable style={styles.filter} onPress={handleClose}>
          <CustomText weight="interBold">{`${i18n.t('explore.view')} ${i18n.t(
            'explore.games'
          ).toLowerCase()}`}</CustomText>
        </Pressable>
      </View>
    </BottomSheet>
  );
};

export default FiltersModal;
