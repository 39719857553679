import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const FilterIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      d="M3 6.25a.75.75 0 000 1.5v-1.5zm0 10a.75.75 0 000 1.5v-1.5zm6 1.5a.75.75 0 000-1.5v1.5zm12 0a.75.75 0 000-1.5v1.5zm-6-11.5a.75.75 0 000 1.5v-1.5zm6 1.5a.75.75 0 000-1.5v1.5zm-18 0h3v-1.5H3v1.5zm0 10h6v-1.5H3v1.5zm15 0h3v-1.5h-3v1.5zm-3-10h6v-1.5h-6v1.5zM17.25 17A2.25 2.25 0 0115 19.25v1.5A3.75 3.75 0 0018.75 17h-1.5zM15 19.25A2.25 2.25 0 0112.75 17h-1.5A3.75 3.75 0 0015 20.75v-1.5zM12.75 17A2.25 2.25 0 0115 14.75v-1.5A3.75 3.75 0 0011.25 17h1.5zM15 14.75A2.25 2.25 0 0117.25 17h1.5A3.75 3.75 0 0015 13.25v1.5zM11.25 7A2.25 2.25 0 019 9.25v1.5A3.75 3.75 0 0012.75 7h-1.5zM9 9.25A2.25 2.25 0 016.75 7h-1.5A3.75 3.75 0 009 10.75v-1.5zM6.75 7A2.25 2.25 0 019 4.75v-1.5A3.75 3.75 0 005.25 7h1.5zM9 4.75A2.25 2.25 0 0111.25 7h1.5A3.75 3.75 0 009 3.25v1.5z"
      fill={fill || '#FCFCFC'}
    />
  </Svg>
);

export default FilterIcon;
