/* eslint-disable @typescript-eslint/naming-convention */
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { View, ViewStyle } from 'react-native';
import { SvgProps } from 'react-native-svg';

import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { Colors } from '@theme';

import { styles } from './styles';
import { isWebResponsive } from '@constants/platform';

interface GatoBannersProps {
  buttonAction?: () => void;
  background?: 'grey' | 'gradient';
  title: string;
  description?: string;
  buttonText: string;
  TitleIcon?: (props: SvgProps) => JSX.Element;
  RightIcon?: (props: SvgProps) => JSX.Element;
  containerStyle?: ViewStyle | ViewStyle[];
  CustomButton?: () => JSX.Element;
  headerWidth?: number | string;
}

const GatoBanner = ({
  buttonAction,
  background = 'grey',
  title,
  description,
  buttonText,
  RightIcon,
  TitleIcon,
  containerStyle,
  CustomButton,
  headerWidth,
}: GatoBannersProps) => {
  const handlePress = () => {
    if (!buttonAction) return;
    buttonAction();
  };

  const colors =
    background === 'grey' ? [Colors.shareAppBackground, Colors.shareAppBackground] : ['#7154FF', '#3A298E'];

  const descriptionColor = background === 'grey' ? 'greyThree' : undefined;
  const titleFontSize = isWebResponsive ? 'lmedium' : 'xlarge';
  const descriptionFontSize = isWebResponsive ? 'small' : 'big'

  return (
    <LinearGradient
      start={{ x: 0, y: 0 }}
      end={{ x: 0.5, y: 1 }}
      locations={[0.3, 0.9]}
      colors={colors}
      style={[styles.container, containerStyle]}>
      <View style={styles.left}>
        <View style={[styles.header, { width: headerWidth }]}>
          {TitleIcon && <TitleIcon style={styles.titleIcon} />}
          <View style={styles.titleContainer}>
            <CustomText customStyle={styles.title} align="left" weight="interBlack" size={titleFontSize}>
              {title}
            </CustomText>
            {description && (
              <CustomText
                customStyle={styles.title}
                align="left"
                weight="interRegular"
                size={descriptionFontSize}
                color={descriptionColor}
                numberOfLines={1}>
                {description}
              </CustomText>
            )}
          </View>
        </View>
        {buttonAction && (
          <Button onPress={handlePress} customStyle={[styles.button]}>
            {buttonText}
          </Button>
        )}
        {CustomButton && <CustomButton />}
      </View>
      {RightIcon && (
        <View style={styles.rightIcon}>
          <RightIcon />
        </View>
      )}
    </LinearGradient>
  );
};

export default GatoBanner;
