/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { SvgProps } from 'react-native-svg';

import ChevronLeft from '@assets/svg/ChevronLeft';
import CustomText from '@atoms/CustomText';
import RoundedButton from '@atoms/RoundedButton';
import { useTranslations } from '@hooks/useTranslation';
import { Colors } from '@theme';

import { styles } from './styles';

interface ItemProps {
  id: string;
  route: string;
  Icon: (props: SvgProps) => JSX.Element;
}

type Props = {
  action: (item: string) => void;
  item: ItemProps;
};

const SettingsItem = ({ action, item }: Props) => {
  const [pressing, setPressing] = useState(false);
  const i18n = useTranslations();
  const { Icon } = item;
  const handlePress = () => {
    action(item.route);
  };
  return (
    <Pressable
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      onPress={handlePress}
      style={styles.container}>
      <View style={styles.left}>
        <View style={styles.icon}>
          <Icon fill={pressing ? Colors.limeGreen : undefined} />
        </View>
        <CustomText color={pressing ? 'limeGreen' : 'white'}>{i18n.t(`settings.${item.id}`)}</CustomText>
      </View>
      <RoundedButton customStyle={{ backgroundColor: Colors.greyFour }}>
        <ChevronLeft fill={pressing ? Colors.limeGreen : Colors.greySix} style={styles.chevron} />
      </RoundedButton>
    </Pressable>
  );
};

export default SettingsItem;
