/* eslint-disable react-hooks/exhaustive-deps */
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Linking, Pressable, View } from 'react-native';

import Button from '@atoms/Button';
import { CountDownTimer } from '@atoms/CountDownTimer';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { ValidateCodeParams } from '@interfaces/auth';
import CustomInputPassword from '@molecules/CustomInputPassword';
import { Layout } from '@organisms/Layout';
import { useValidateCode } from '@services/useValidateCode';
import { Colors, Fonts } from '@theme';
import { setAuthToken, setRefreshToken } from '@utils/session';
import toast from '@utils/toast';
import { RootStackNavigator } from 'app/navigation/types';
import GoBackButton from '@molecules/GoBackButton';
import { useForgotPasswordCode } from '@services/useForgotPasswordCode';
import { ValidateEditUserEmailParams } from '@interfaces/accountSettings';
import { authTokenSelector, useStore, setAuthTokenHandler } from '@store/index';
import { useValidateEditUserEmail } from '@services/useValidateEditUserEmail';
import { useDeleteTemporalAccount } from '@services/useDeleteTemporalAccount';
import { useForgotPassword } from '@services/useForgotPassword';
import { useEditUserEmail } from '@services/useEditUserEmail';
import { useResendValidateCode } from '@services/useResendValidateCode';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';

import { styles } from './styles';

type ValidateCodeProps = NativeStackScreenProps<RootStackNavigator, 'ValidateCode'>;

export const ValidateCode = ({ navigation, route }: ValidateCodeProps) => {
  const userId = route?.params?.userId;
  const userEmail = route?.params?.userEmail;
  const type = route?.params?.type;
  const password = route?.params?.password;
  const [resendTries, setResendTries] = useState(0);
  const [timedown, setTimedown] = useState<number | null>(null);
  useStatusBar('light');
  const i18n = useTranslations();
  const authToken = useStore(authTokenSelector);
  const setStoreToken = useStore(setAuthTokenHandler);

  const { control, setValue, watch, handleSubmit } = useForm({ defaultValues: { code: '' } });

  const code = watch('code');

  const validate = useValidateCode();
  const validateForgotPassword = useForgotPasswordCode();
  const validateEditEmail = useValidateEditUserEmail(authToken || '');
  const deleteTemporalAccount = useDeleteTemporalAccount();
  const resendCodeForgotPassword = useForgotPassword();
  const resendCodeChangeEmail = useEditUserEmail(authToken || '');
  const resendCodeVerifyEmail = useResendValidateCode();

  const resendCode = () => {
    switch (type) {
      case 'PASSWORD':
        resendCodeForgotPassword.mutate(
          { emailOrUsername: userEmail },
          {
            onSuccess: () => {
              toast.success({ title: i18n.t('validate.code_resend_success'), icon: 'success' });
            },
            onError: () => {
              toast.danger({ icon: 'error', title: i18n.t('validate.code_resend_error') });
            },
          }
        );
        break;
      case 'VERIFY':
        resendCodeVerifyEmail.mutate(
          { emailOrUsername: userEmail },
          {
            onSuccess: () => {
              toast.success({ title: i18n.t('validate.code_resend_success'), icon: 'success' });
            },
            onError: () => {
              toast.danger({ icon: 'error', title: i18n.t('validate.code_resend_error') });
            },
          }
        );
        break;
      case 'EMAIL':
        resendCodeChangeEmail.mutate(
          { newEmail: userEmail, password: password || '' },
          {
            onSuccess: () => {
              toast.success({ title: i18n.t('validate.code_resend_success'), icon: 'success' });
            },
            onError: () => {
              toast.danger({ icon: 'error', title: i18n.t('validate.code_resend_error') });
            },
          }
        );
        break;
      default:
        toast.danger({ title: i18n.t('validate.no_service_setted'), icon: 'error' });
        break;
    }
  };

  const handleResend = () => {
    if (timedown) return;
    if (resendTries >= 5) {
      setTimedown(60);
      toast.danger({ title: i18n.t('validate.check_spam'), icon: 'error' });
    }
    if (resendTries > 1) {
      setTimedown(30);
    }
    resendCode();
    setResendTries((prevResendTries) => prevResendTries + 1);
  };

  const handleValidateAccount = (dataToSend: ValidateCodeParams) => {
    validate.mutate(dataToSend, {
      onSuccess: (response) => {
        toast.success({ title: i18n.t('validate.success'), icon: 'success' });
        setAuthToken(response.token.access_token);
        setRefreshToken(response.token.refresh_token);
        setStoreToken(response.token.access_token);
        const homeRoute = [{ name: 'Home' as const }];
        const mainNavRouter = [
          {
            name: 'Main' as const,
            state: {
              routes: homeRoute,
            },
          },
        ];
        navigation.reset({
          index: 0,
          routes: isMobile ? mainNavRouter : homeRoute,
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = i18n.t(error?.response?.data.translate);
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  };

  const handleValidatePassword = (dataToSend: ValidateCodeParams) => {
    validateForgotPassword.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ title: i18n.t('validate.success'), icon: 'success' });
        navigation.navigate('ResetPassword', {
          userId,
          code,
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = i18n.t(error?.response?.data.translate);
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  };

  const handleValidateEmail = (dataToSend: ValidateEditUserEmailParams) => {
    validateEditEmail.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ title: i18n.t('validate.success'), icon: 'success' });
        navigation.reset({ index: 0, routes: [{ name: 'AccountSettings' }] });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  };

  function onSubmit(values: { code: string }) {
    const dataToSend = {
      userId,
      code: values.code,
      type,
    };
    const dataToEmail = {
      newEmail: userEmail,
      code: values.code,
    };

    if (type === 'PASSWORD') {
      handleValidatePassword(dataToSend);
    }
    if (type === 'EMAIL') {
      handleValidateEmail(dataToEmail);
    }
    if (type === 'VERIFY') {
      handleValidateAccount(dataToSend);
    }
  }

  const isDisabled = !code || code.length < 5 || Boolean(timedown);

  const titleFirst = useMemo(() => {
    switch (type) {
      case 'PASSWORD':
        return i18n.t('forgot.code_title');

      case 'EMAIL':
        return i18n.t('account_settings.validate_new_email_title');

      default:
        return i18n.t('validate.title_1');
    }
  }, [type]);

  const titleSecond = useMemo(() => {
    switch (type) {
      case 'PASSWORD':
        return i18n.t('forgot.code_description');

      case 'EMAIL':
        return i18n.t('account_settings.validate_new_email_description');

      default:
        return i18n.t('validate.title_2');
    }
  }, [type]);

  const buttonText = useMemo(() => {
    switch (type) {
      case 'PASSWORD':
        return i18n.t('forgot.button_action_code');

      case 'EMAIL':
        return i18n.t('account_settings.validate_new_email_button_action');

      default:
        return i18n.t('validate.button_action');
    }
  }, [type]);

  const handleContactUs = () => {
    Linking.openURL(
      'mailto:hello@gato.us?subject=Error when editing your data in GATO&body=Hi there,\n\nThank you for contacting GATO.\n\nWe noticed that we had some issues editing your data. If you have any additional information that you think will help us to assist you, feel free to write it down.\n\n## Please type your request above this line ##\n\nOur support staff will respond as soon as possible.\n\nCheers!\nGATO\n\n'
    );
  };

  const handleGoBack = () => {
    const dataToSend = {
      mail: userEmail,
      password: password || '',
    };
    deleteTemporalAccount.mutate(dataToSend, {
      onSuccess: () => {
        navigation.goBack();
      },
      onError: () => {
        toast.danger({
          duration: 6000,
          icon: 'error',
          title: `${i18n.t('register.delete_account_error')} `,
          underlineTitle: (
            <Pressable hitSlop={10} onPress={handleContactUs}>
              <CustomText customStyle={styles.underline} weight="interSemi">
                {i18n.t('register.please_contact_us')}
              </CustomText>
            </Pressable>
          ),
        });
      },
    });
    navigation.goBack();
  };

  const textAlign = isDesktop && !isWebResponsive ? 'center' : 'left';

  return (
    <Layout style={isWebResponsive && [styles.responsiveLayout]} backgroundColor={Colors.blackThree} withScroll verticallyCentered={isDesktop && !isWebResponsive}>
      <View style={styles.container}>
        <GoBackButton goBackAction={handleGoBack} />
        <DesktopCenteredContainer style={isWebResponsive ? styles.responsiveForm : styles.desktopForm}>
          <View>
            <View style={styles.titles}>
              <CustomText customStyle={styles.title} align={textAlign} weight="interSemi" size="xlarge">
                {titleFirst}
              </CustomText>
              <CustomText align={textAlign} weight="interSemi" size="xbig">
                {`${titleSecond} `}
                <CustomText color="limeGreen" align="left" weight="interSemi" size="xbig">
                  {userEmail}
                </CustomText>
              </CustomText>
            </View>
            <CustomInputPassword
              control={control}
              name="code"
              currentValue={code}
              setValue={(value) => setValue('code', value)}
            />
          </View>
          <View style={styles.footer}>
            <View style={timedown ? {} : styles.resend}>
              <CustomText color="greySix" size="medium" weight="interBold">
                {`${i18n.t('validate.not_receive')}  `}
              </CustomText>
              {!timedown ? (
                <Pressable onPress={handleResend}>
                  <CustomText
                    customStyle={styles.underline}
                    size="medium"
                    color="limeGreen"
                    weight="interBold">
                    {i18n.t('validate.resend')}
                  </CustomText>
                </Pressable>
              ) : null}
            </View>
            {timedown ? (
              <View style={styles.timedown}>
                <CustomText weight="interSemi" size="medium" color="greySeven">
                  {`${i18n.t('validate.resend_email')} 0:`}
                  <CountDownTimer initialValue={timedown} onTimeFinish={() => setTimedown(null)} />
                </CustomText>
              </View>
            ) : null}
            <Button
              disabled={isDisabled}
              loading={validate.isLoading || validateForgotPassword.isLoading}
              customStyle={styles.button}
              customTextStyle={{ fontSize: Fonts.SIZES.xMedium }}
              onPress={handleSubmit(onSubmit)}>
              {buttonText}
            </Button>
          </View>
        </DesktopCenteredContainer>
      </View>
    </Layout>
  );
};
