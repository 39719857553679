import React from 'react';

import CustomText from '@atoms/CustomText';

import { styles } from './styles';

type ErrorMessageProps = {
  text: string;
  style?: any;
};

const ErrorMessage = ({ text, style = {} }: ErrorMessageProps) => (
  <CustomText weight="interSemi" customStyle={[styles.text, style]} align="left" size="msmall" color="error">
    {text}
  </CustomText>
);

export default ErrorMessage;
