import React, { useEffect, useState } from 'react';
import { GoogleAdManagerUnitIdEnum, unitsIdData } from './constants';
import useGPT from '@hooks/useGPT';
import { View } from 'react-native';

interface GoogleAdManagerBannerProps {
  unitId: GoogleAdManagerUnitIdEnum;
}

const GoogleAdManagerBanner = ({ unitId }: GoogleAdManagerBannerProps) => {
  useGPT({
    path: unitsIdData[unitId].path,
    size: [unitsIdData[unitId].size.width, unitsIdData[unitId].size.height],
    id: unitsIdData[unitId].id,
  });

  return (
    <View
      style={{
        backgroundColor: "transparent",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id={unitsIdData[unitId].id}
        style={{
          width: unitsIdData[unitId].size.width,
          height: unitsIdData[unitId].size.height,
        }}
      />
    </View>
  );
};

export default React.memo(GoogleAdManagerBanner);
