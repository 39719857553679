import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { WINDOW_WIDTH } from '@constants/platform';

const AVATAR_SIZE = WINDOW_WIDTH * 0.16;
export default StyleSheet.create({
  items: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: Colors.darkGrey,
    padding: moderateScale(7),
    marginTop: moderateScale(12),
    borderRadius: moderateScale(12),
  },
  imageItem: { width: '100%', height: '100%' },
});
