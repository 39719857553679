import { StyleSheet } from 'react-native';

import { isMobile, isWebResponsive, SCREEN_HEIGHT } from '@constants/platform';
import { moderateScale } from '@utils/scaling';
import { LARGE, SMALL, XLARGE } from 'app/theme/spacing';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    minHeight: isWebResponsive ? SCREEN_HEIGHT - moderateScale(120) : undefined,
    height: isWebResponsive ? '100%' : SCREEN_HEIGHT - moderateScale(120),
  },
  desktopForm: {
    width: moderateScale(470),
    paddingVertical: moderateScale(36),
  },
  responsiveForm: {
    flex: 1
  },
  responsiveLayout: {
    marginVertical: moderateScale(36), 
  },
  title: {
    marginBottom: isMobile ? SMALL : LARGE,
  },
  titles: {
    marginTop: moderateScale(16),
    marginBottom: isMobile ? XLARGE : XLARGE * 1.3,
  },
  description: {
    marginTop: moderateScale(16),
    marginBottom: moderateScale(30),
  },
  footer: { 
    justifyContent: 'flex-end', 
    alignItems: 'center', 
    flex: 1,
  },
  underline: {
    textDecorationLine: 'underline',
  },
  button: {
    width: isMobile ? '100%' : moderateScale(300),
    paddingVertical: moderateScale(14),
  },
  resend: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
  timedown: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
});
