import { StyleSheet } from 'react-native';

import { isDesktop, isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { whiteOpacity } from 'app/theme/colors';

export const styles = StyleSheet.create({
  violent: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: moderateScale(8),
    paddingVertical: isWebResponsive ? moderateScale(1) : moderateScale(4),
    borderRadius: moderateScale(8),
    backgroundColor: whiteOpacity(0.24),
  },
  violentText: {
    letterSpacing: 2,
    marginLeft: moderateScale(5),
    paddingVertical: isDesktop ? Spacing.TINY : Spacing.TINY / 2,
  },
});
