import * as React from 'react';
import { SVGProps } from 'react';

const GameUploadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={417} height={309} fill="none" {...props}>
    <g clipPath="url(#a)">
      <g clipPath="url(#b)">
        <path
          fill="#E5F68C"
          stroke="#6546FC"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={0.758}
          d="m46.632 275.168 29.34 31.31 242.371-198.249 15.466 16.224 39.422-99.087-99.086 34.646 14.859 16.224L46.632 275.168Z"
        />
        <path
          fill="#00295B"
          stroke="#6546FC"
          strokeWidth={0.758}
          d="m122.613 213.241 43.535-35.707 2.457 52.654-41.3 33.846-4.692-50.793ZM248.479 109.985l25.475-20.897 14.005 43.579-17.581 14.932-21.899-37.614Z"
        />
        <mask id="c" fill="#fff">
          <path d="M220.319 266.449c-.91.758-1.593 1.289-2.047 1.592l2.047-1.592Z" />
        </mask>
        <path fill="#00295B" d="M220.319 266.449c-.91.758-1.593 1.289-2.047 1.592l2.047-1.592Z" />
        <path
          fill="#6546FC"
          d="m220.319 266.449.485.583-.951-1.181.466.598Zm-2.047 1.592-.466-.598.886 1.229-.42-.631Zm1.561-2.174c-.907.756-1.563 1.264-1.982 1.543l.841 1.262c.491-.327 1.2-.88 2.112-1.64l-.971-1.165Zm-1.096 2.773 2.047-1.592-.931-1.197-2.047 1.592.931 1.197Z"
          mask="url(#c)"
        />
        <path
          fill="#2D1A87"
          d="M202.84 308.185c43.54 0 78.837-5.101 78.837-11.395 0-6.293-35.297-11.394-78.837-11.394-43.54 0-78.836 5.101-78.836 11.394 0 6.294 35.296 11.395 78.836 11.395Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M121.291 76.32s.844-13.251-1.097-22.198c-1.941-8.948 9.285-12.408 16.206-4.896 6.922 7.512 7.259 17.303 3.714 25.069-3.545 7.765-8.441 10.129-8.441 10.129s-8.778-4.558-10.382-8.103Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M109.306 72.437s3.207-4.642 11.901-2.11c8.694 2.532 12.661 11.226 13.337 15.025.675 3.798-1.773 7.427-7.428 6.161-5.571-1.181-15.109-10.888-15.109-10.888l-2.701-8.188Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M98.164 74.55s4.136-4.305 11.226-2.11c7.006 2.11 8.694 7.68 9.116 9.875.506 2.195 3.039 10.805-3.208 13.759-6.161 2.954-10.719-1.773-12.661-5.824-1.857-4.136-4.473-15.7-4.473-15.7Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M118.929 163.259s-20.173 3.545-26.504-16.46c-6.33-20.004 0-32.918 0-32.918s-13.843-4.39-13.927-18.57c-.169-19.667 14.518-22.537 22.03-20.173 5.571 1.688 7.681 11.648 6.078 16.46l-2.111 3.713s4.812-4.98 12.493-2.363c0 0-4.305-10.382 6.077-12.155 12.155-2.026 18.738 11.986 18.57 16.797-.169 4.811-1.942 20.764-15.953 22.706-13.928 1.941-18.401-.169-18.401-.169s-5.402 15.531 2.785 21.946c8.103 6.415 14.603 3.545 14.603 3.545s2.447 14.94-5.74 17.641Z"
        />
        <path fill="#fff" d="M91.92 84.424s-1.688 6.5 2.617 9.875c4.304 3.377 10.044 3.968 12.07-2.7" />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M91.92 84.424s-1.688 6.5 2.617 9.875c4.304 3.377 10.044 3.968 12.07-2.7"
        />
        <path fill="#fff" d="M116.987 93.034s2.701 5.74 8.188 8.188l-8.188-8.188Z" />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M116.987 93.034s2.701 5.74 8.188 8.188"
        />
        <path
          fill="#D4BFFA"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M292.049 141.819c1.435 9.2 1.435 18.232.169 26.926-4.136 28.276-22.03 52.332-47.353 62.546h-.084c-3.376 1.35-17.303 6.752-20.933 7.765-2.026.507-3.798 1.013-5.318 1.351-6.246 1.603-9.369 2.11-9.369 2.11l-1.772-6.837c-32.581-4.811-60.436-31.568-67.188-68.032-5.993-32.244 6.246-63.306 28.951-81.2l-1.097-4.22 16.713-5.233c5.064-2.195 12.576-3.546 18.147-4.559 41.866-7.68 80.356 22.2 88.712 67.273.253.675.338 1.35.422 2.11Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="m169.828 235.764-5.233 14.518s-10.72-24.9-37.308-24.9c-27.01 0-20.258 22.199-10.551 34.607 13.336 17.05 29.965 34.691 42.626 37.139 17.134 3.292 21.861-6.922 19.751-16.882-2.11-9.96-.253-26.335 2.616-31.146 2.87-4.727 5.74-11.142 5.74-11.142s-4.136-8.609-17.641-2.194Z"
        />
        <path
          fill="#00295B"
          d="M167.381 242.515c-.084.253 1.773 2.955 5.909 4.389 4.051 1.435 9.622 0 9.622 0l3.545-7.174-16.712-3.967-2.364 6.752Z"
        />
        <path
          fill="#fff"
          d="M116.735 235.088s12.661-5.318 32.919 14.602c20.258 20.004 21.946 35.704 21.946 35.704"
        />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M116.735 235.088s12.661-5.318 32.919 14.602c20.258 20.004 21.946 35.704 21.946 35.704"
        />
        <path fill="#fff" d="M160.713 243.7s6.668 8.694 8.609 20.764l-8.609-20.764Z" />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M160.713 243.7s6.668 8.694 8.609 20.764"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M266.475 280.163c0 21.355-24.816 22.706-33.341 22.706-8.525 0-29.711.169-29.711-14.856 0-15.109 9.791-17.388 9.791-17.388s-15.615-1.266-19.92-4.051c-4.389-2.786-5.402-8.103-4.22-12.07 2.785-9.201 16.037-17.895 16.037-17.895h19.583c-2.448 5.149-10.72 12.239-15.109 15.785-1.013.844-1.773 1.434-2.279 1.772l-.254.169-.253.169c.338 3.545 3.208 4.304 5.824 4.304 1.857 0 3.714-.422 4.39-.506 1.688-.169 26.757-3.798 32.918-3.376 6.162.422 16.544 3.882 16.544 25.237Z"
        />
        <path
          fill="#fff"
          d="M213.216 270.625s7.512-3.545 24.647-1.857c16.628 1.688 21.777 11.395 21.777 11.395"
        />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M213.216 270.625s7.512-3.545 24.647-1.857c16.628 1.688 21.777 11.395 21.777 11.395"
        />
        <path
          fill="#A07CDE"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M292.217 168.66c-4.136 28.277-22.03 52.333-47.352 62.546h-.084c-3.377 1.35-17.304 6.752-20.933 7.765-2.026.507-3.799 1.013-5.318 1.351 5.824-5.233 39.925-36.633 51.742-63.474l21.945-8.188Z"
        />
        <path
          fill="#D4BFFA"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M209.162 242.514c-10.213 1.857-20.342 1.519-29.88-.76-13.674-3.207-26.25-10.382-36.464-20.342-29.542-30.639-26.926-67.188-23.887-81.368 3.208-14.518 9.96-27.686 19.583-38.068 10.55-11.479 24.478-19.582 40.515-22.536a69.092 69.092 0 0 1 24.984-.17c31.906 5.403 59.17 31.907 65.753 67.78 3.124 16.965 1.267 33.678-4.558 48.196-9.622 24.056-29.964 42.457-56.046 47.268Z"
        />
        <path
          fill="#B393EB"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="m265.208 195.164-17.725-3.377c5.908-12.323 8.187-26.841 5.402-41.697-5.149-27.77-26.082-48.365-50.729-52.67l1.857-18.231c31.99 5.317 59.169 31.905 65.753 67.778 3.208 16.966 1.266 33.594-4.558 48.197Z"
        />
        <path
          fill="#A07CDE"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M210.512 223.274c-1.519.422-3.123.759-4.727 1.097-32.497 5.993-64.149-17.557-70.564-52.586-6.499-35.029 14.602-68.369 47.183-74.362a28.823 28.823 0 0 1 3.63-.507c31.231-3.713 60.689 19.414 66.935 53.092 6.161 33.341-12.661 65.078-42.457 73.266Z"
        />
        <path
          fill="#B393EB"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M247.566 191.785c-7.343 15.194-20.511 27.011-36.97 31.484-30.556 2.532-59.085-20.342-65.162-53.514-6.078-32.665 11.901-63.896 40.684-72.843 5.486-.675 10.888-.422 16.206.422 24.647 4.305 45.664 24.9 50.729 52.67 2.616 14.94.422 29.458-5.487 41.781Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="m113.866 125.442 47.268 3.714c2.195.169 3.967 1.689 4.474 3.799.591 2.279 1.772 4.895 4.136 5.655 2.532.76 4.22-2.195 5.317-4.727.844-2.026 2.87-3.376 5.065-3.207l60.013 4.727 16.291 1.266s5.655 2.954 3.629 9.116c-2.026 6.161-11.479 6.752-11.479 6.752s-4.811 33.932-15.953 34.523c-8.103.422-23.55-1.773-31.484-2.279-6.837-.422-12.914-4.643-15.446-10.973 0-.085-.085-.169-.085-.253-3.629-9.37-8.609-22.875-15.7-23.466-7.005-.591-7.596 2.786-9.2 5.656-1.604 2.869-5.655 16.375-5.655 16.375s-2.617 7.259-11.986 6.499c-9.369-.76-21.102-1.688-21.102-1.688s-10.804-3.883-10.804-16.375.169-27.939.169-27.939l-.76-3.292c-.591-2.026 1.098-4.051 3.292-3.883Z"
        />
        <path
          fill="#5940D0"
          d="M184.178 138.779c-2.785.76-3.714 4.389-2.194 8.947 1.941 5.909 6.415 15.784 9.875 24.141 1.773 4.389 5.909 7.343 10.635 7.681l27.011 2.026s5.064.844 8.609-9.707c2.448-7.259 4.643-18.739 5.74-25.238.422-2.617-1.519-5.064-4.136-5.233l-52.839-3.292c-.928-.085-1.857.169-2.701.675ZM119.013 132.703c-2.194-.169-3.967 1.603-3.967 3.713.169 7.597.507 23.719 1.182 27.602.844 5.064 4.22 6.921 8.609 8.018 4.39 1.098 18.823 1.52 18.823 1.52s5.065-1.013 6.922-4.812l1.856-3.798 5.993-25.069c.507-2.194-1.097-4.389-3.376-4.558l-36.042-2.616Z"
        />
        <path
          fill="#fff"
          d="m143.913 140.215 2.448 26.166c.084.844-.422 1.688-1.182 1.941-.675.254-1.519.423-2.617.423a7.79 7.79 0 0 1-1.603-.169c-.929-.085-1.604-.844-1.689-1.688l-2.785-26.335c-.084-1.098.76-2.111 1.941-2.111l3.63.085c.928 0 1.772.759 1.857 1.688ZM134.038 139.372c.422 4.473 2.11 22.115 2.617 27.432.084.76-.591 1.435-1.351 1.351-.76-.085-1.688-.169-2.363-.254a1.255 1.255 0 0 1-1.098-1.097l-2.701-27.348c-.084-.759.591-1.435 1.351-1.35l2.448.253c.591-.084 1.097.422 1.097 1.013ZM216.927 146.883l2.448 26.166c.084.844-.422 1.688-1.182 1.941-.675.254-1.519.422-2.617.422a7.799 7.799 0 0 1-1.603-.168c-.929-.085-1.604-.844-1.689-1.688l-2.616-26.335c-.085-1.098.759-2.111 1.941-2.111l3.63.085c.844-.085 1.603.675 1.688 1.688ZM207.136 145.953c.422 4.474 2.11 22.115 2.616 27.432.085.76-.59 1.435-1.35 1.351-.76-.085-1.688-.169-2.363-.253a1.257 1.257 0 0 1-1.098-1.098l-2.701-27.348c-.084-.759.591-1.435 1.351-1.35l2.448.253c.59-.084 1.012.422 1.097 1.013Z"
        />
        <path fill="#00295B" d="M209.582 252.393c-1.013.844-1.773 1.435-2.279 1.772l2.279-1.772Z" />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M297.282 232.64s5.993-1.604 11.395-1.182c5.318.422 5.908 4.136 5.233 6.415l-17.894.591 1.266-5.824Z"
        />
        <path
          fill="#fff"
          d="M274.409 177.099s26.842-5.908 38.068 16.629c11.226 22.536-15.616 41.697-15.616 41.697s18.823 3.207 21.355 11.986c1.52 5.148-7.005 11.141-21.355 12.239-14.349 1.097-32.412-1.267-34.016-19.836-1.857-21.693 28.277-19.836 32.75-32.75 4.474-12.914-8.862-17.725-17.978-17.135"
        />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M274.409 177.099s26.842-5.908 38.068 16.629c11.226 22.536-15.616 41.697-15.616 41.697s18.823 3.207 21.355 11.986c1.52 5.148-7.005 11.141-21.355 12.239-14.349 1.097-32.412-1.267-34.016-19.836-1.857-21.693 28.277-19.836 32.75-32.75 4.474-12.914-8.862-17.725-17.978-17.135"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M298.549 236.186s5.992-1.604 11.395-1.182c5.402.422 5.908 4.136 5.233 6.415l-17.895.591 1.267-5.824Z"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M299.897 240.66s5.993-1.604 11.395-1.182c5.318.422 5.909 4.136 5.233 6.415l-17.894.591 1.266-5.824Z"
        />
        <path
          fill="#fff"
          d="M297.282 232.556s5.909 7.596 0 16.121c0 0-7.512 1.182-11.479-2.279-3.967-3.46-4.389-10.635-4.389-10.635"
        />
        <path fill="#fff" d="M297.282 232.556s5.909 7.596 0 16.121v-16.121Z" />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M297.282 232.556s5.909 7.596 0 16.121"
        />
        <path
          fill="#fff"
          d="M318.299 247.498c-.422-1.773-1.52-3.208-7.428-3.208-5.909 0-12.239 2.195-12.239 2.195l-.844 1.603"
        />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M318.299 247.498c-.422-1.773-1.52-3.208-7.428-3.208-5.909 0-12.239 2.195-12.239 2.195l-.844 1.603"
        />
        <path
          fill="#A07CDE"
          d="M272.213 197.359s6.331-.169 10.804-10.045c4.474-9.876 1.098-15.531 1.098-15.531l-13.843 5.149"
        />
        <path
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M272.213 197.359s6.331-.169 10.804-10.045c4.474-9.876 1.098-15.531 1.098-15.531l-13.843 5.149"
        />
        <path
          fill="#fff"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M180.041 210.778c-1.519 0-3.123-.084-4.727-.169-31.568-1.772-36.885-21.27-37.139-22.114a3.442 3.442 0 0 1 2.448-4.136 3.442 3.442 0 0 1 4.136 2.448c.169.675 4.643 15.615 30.978 17.05 25.322 1.435 30.386-9.538 30.47-9.623.676-1.772 2.617-2.532 4.39-1.857 1.772.676 2.616 2.701 1.941 4.39-.338.59-5.908 14.011-32.497 14.011Z"
        />
        <path
          fill="#BBACED"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M370.107 167.987c-14.434-1.182-27.517-18.57-27.517-18.57s-1.097 21.777-12.408 30.809c0 0 15.7 2.447 27.517 18.569.844-20.004 12.408-30.808 12.408-30.808Z"
        />
        <path
          fill="#6546FC"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M241.825 40.108c-6.583-.506-12.661-8.525-12.661-8.525s-.506 9.96-5.655 14.096c0 0 7.175 1.097 12.661 8.525.338-9.116 5.655-14.096 5.655-14.096ZM76.218 206.222c6.161-2.533 9.453-11.902 9.453-11.902s3.461 9.37 9.707 11.733c0 0-6.5 3.292-9.453 11.901-3.123-8.609-9.707-11.732-9.707-11.732Z"
        />
        <path
          fill="#BBACED"
          stroke="#00295B"
          strokeMiterlimit={10}
          strokeWidth={0.844}
          d="M40.6 170.939c13.505-5.317 20.933-25.744 20.933-25.744s7.427 20.511 20.848 25.829c0 0-14.265 6.921-20.933 25.744-6.584-18.907-20.848-25.829-20.848-25.829Z"
        />
      </g>
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M40.312 0h336.374v308.801H40.312z" />
      </clipPath>
      <clipPath id="b">
        <path fill="#fff" d="M40.312 24.513h332.29v292.225H40.311z" />
      </clipPath>
    </defs>
  </svg>
);
export default GameUploadIcon;
