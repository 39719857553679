import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const TapIconBig = ({ fill, ...props }: SvgProps) => (
  <Svg width={41} height={40} fill="none" {...props}>
    <Path
      d="M40.172 25.524l-1.263-1.263a2.119 2.119 0 00-2.15-.51c-1.325-1.435-1.866-2.533-2.477-3.773-.753-1.537-1.608-3.278-4.055-5.905-1.695-1.82-2.393-2.338-3.2-2.926l-.11-.078c-1.216-.894-2.651-.87-3.569.047-.33.33-.533.73-.643 1.145-.102-.016-.196-.031-.298-.031a2.655 2.655 0 00-1.96.784 2.645 2.645 0 00-.738 1.427 2.675 2.675 0 00-2.588 1.428l-3.773-3.773c-1.098-1.09-2.855-1.106-3.921-.039a2.666 2.666 0 00-.777 1.969c.024.721.322 1.411.847 1.937l8.345 8.345c-.478-.07-1.011-.18-1.49-.275-.47-.094-.91-.188-1.263-.235-2.839-.384-4.525.667-4.933 1.875-.173.51-.141 1.02.102 1.474.51.965 1.773 1.475 3.922 2.079.8.22 1.42.47 2.07.729 1.286.51 2.753 1.09 5.898 1.663 1.404.25 3.859.698 5.232 1.584a2.11 2.11 0 00.533 2.079l1.263 1.262c.4.4.933.62 1.506.62.572 0 1.105-.22 1.505-.62l7.992-8a2.12 2.12 0 000-3.004l-.007-.015zm-11.67 6.133c-1.71-1.145-4.354-1.624-6.024-1.93-2.957-.533-4.267-1.05-5.53-1.552a21.346 21.346 0 00-2.259-.793c-2.008-.556-2.604-.91-2.737-1.105.07-.212.933-.863 2.879-.604.29.039.697.125 1.129.211.627.126 1.333.275 1.968.346a1.766 1.766 0 001.812-1.004 1.78 1.78 0 00-.345-2.047l-8.564-8.565a.956.956 0 01-.29-.643c0-.22.07-.424.219-.573.33-.33.886-.314 1.24.04l5.67 5.67 1.121 1.122a.942.942 0 00.675.274.976.976 0 00.674-.274.952.952 0 000-1.342l-1.121-1.121a.956.956 0 01-.29-.643c0-.22.07-.424.22-.573.32-.321.87-.298 1.223.047l.33.33 1.003 1.003a.951.951 0 101.349-1.34l-1.004-1.005a.984.984 0 01-.282-.643c0-.227.078-.431.227-.58a.781.781 0 01.565-.228h.016a.9.9 0 01.643.283l.384.384.831.831a.951.951 0 001.342 0 .951.951 0 000-1.34l-.832-.832c-.29-.29-.306-.73-.047-.988.377-.377 1.028.086 1.098.14l.11.08c.76.556 1.365.995 2.933 2.69 2.251 2.423 3.004 3.96 3.734 5.45.635 1.295 1.239 2.518 2.674 4.102l-6.737 6.738-.008-.016z"
      fill={fill || '#777E91'}
    />
    <Path
      d="M10.618 8.567c2.353 0 4.353 1.835 4.541 4.18a.941.941 0 001.028.87.951.951 0 00.87-1.027c-.266-3.325-3.098-5.93-6.431-5.93a6.46 6.46 0 00-6.455 6.456 6.433 6.433 0 005.804 6.423h.094a.95.95 0 00.094-1.898 4.541 4.541 0 01-4.094-4.533 4.566 4.566 0 014.557-4.557l-.008.016z"
      fill={fill || '#777E91'}
    />
    <Path
      d="M13.081 22.927c.635-.157 1.028-.8.87-1.435a1.189 1.189 0 00-1.435-.87c-2.635.643-5.349-.11-7.262-2.024a7.638 7.638 0 01-2.243-5.42c0-2.047.8-3.968 2.243-5.42 2.988-2.987 7.85-2.987 10.839 0a7.543 7.543 0 012.141 4.181 1.185 1.185 0 001.365.98 1.18 1.18 0 00.98-1.364 9.962 9.962 0 00-2.808-5.482c-3.913-3.914-10.282-3.914-14.196 0A9.967 9.967 0 00.634 13.17c0 2.682 1.043 5.2 2.941 7.098a9.972 9.972 0 009.506 2.65v.008z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default TapIconBig;
