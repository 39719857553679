import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CheckIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={17} height={12} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.707.293a1 1 0 010 1.414l-8.586 8.586a3 3 0 01-4.242 0L.293 6.707a1 1 0 011.414-1.414l3.586 3.586a1 1 0 001.414 0L15.293.293a1 1 0 011.414 0z"
      fill={fill || '#141416'}
    />
  </Svg>
);

export default CheckIcon;
