import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { isMobile, isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  lottie: { marginRight: 6, width: 36, height: 36 },
  socials: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: isMobile ? 30 : 0,
    minWidth: 243,
    width: isWebResponsive? '100%' : undefined
  },
  btn: { flex: 0.49 },
  socialButton: {
    borderWidth: 2,
    borderColor: Colors.greyFour,
    backgroundColor: Colors.socialBackground,
    height: 45,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  mr6: { marginRight: 6 },
});
