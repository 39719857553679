import { useEffect } from 'react';

import { useMyProfile } from '@services/useMyProfile';
import { getHasSeenAvatarAlert } from '@utils/avatar';
import { isMobile } from '@constants/platform';
import {
  authTokenSelector,
  setShowAvatarModalHandler,
  setShowOnboardingHandler,
  useStore,
} from '@store/index';

export default function useCheckAvatar(navigation?: any) {
  const { data: profileData, isSuccess } = useMyProfile();
  const authToken = useStore(authTokenSelector);

  const setShowAvatarModal = useStore(setShowAvatarModalHandler);
  const setShowOnboarding = useStore(setShowOnboardingHandler);

  const checkAvatarData = async () => {
    const hasSeenAvatarAlert = await getHasSeenAvatarAlert(profileData.userName);
    // ToDo: This logic should be in the backend
    if (!profileData.backgroundProfileImage && !hasSeenAvatarAlert) {
      if (isMobile) navigation.navigate('AvatarAlert');
      else {
        setShowOnboarding();
        setShowAvatarModal();
      }
    }
  };

  useEffect(() => {
    if (profileData && isSuccess && authToken) checkAvatarData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData, isSuccess, authToken]);
}
