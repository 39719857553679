import { CommonActions, useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useTranslations } from '@hooks/useTranslation';
import { setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { RootStackNavigator } from 'app/navigation/types';
import { isMobile } from '@constants/platform';

export default function useLeaveGameModal(beforeRedirect, name) {
  const i18n = useTranslations();
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();

  const confirmLeaveGame = () => {
    toggleModal();
    beforeRedirect()
    navigation.setOptions({ headerShown: true });
    navigation.dispatch(CommonActions.goBack());
  };

  const cancelLeaveGame = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const handleLeaveGame = () => {
    setModalInfo({
      message: i18n.t('game_web_view.leave_message'),
      confirmText: i18n.t('game_web_view.leave_confirm_text'),
      cancelText: i18n.t('game_web_view.leave_cancel_text'),
      confirmAction: confirmLeaveGame,
      cancelAction: cancelLeaveGame,
    });
    toggleModal();
  };

  return handleLeaveGame;
}
