import React, { memo } from 'react';
import { FlatList, View } from 'react-native';

import Response from '@organisms/Comments/Response';
import VerticalDashedLine from '@assets/svg/VerticalDashedLine';
import { useThreadComments } from '@services/useComments';
import CommentSkeleton from '@molecules/CommentSkeleton';

import { styles } from './styles';

interface Props {
  comment: any;
  userId?: number;
  skeletons?: number;
}

const Thread = ({ comment, userId, skeletons = 1 }: Props) => {
  const { data = [], isLoading } = useThreadComments(comment?.id);

  return (
    <View style={styles.container}>
      {data?.comment?.response && Object.keys(data?.comment?.response).length > 0 ? (
        <View style={styles.responsesContainer}>
          <View style={styles.line}>
            <VerticalDashedLine />
          </View>
          {!isLoading ? (
            <FlatList
              showsHorizontalScrollIndicator={false}
              data={data?.comment?.response}
              renderItem={({ item, index }) => (
                <Response
                  commentResponse={item}
                  lastItem={index + 1 === data?.comment?.response.length}
                  userId={userId}
                  key={item.id}
                />
              )}
              scrollEnabled={false}
            />
          ) : (
            <>
              <CommentSkeleton />
              <CommentSkeleton />
            </>
          )}
        </View>
      ) : (
        [...Array(skeletons).keys()].map(() => <CommentSkeleton />)
      )}
    </View>
  );
};

export default memo(Thread);
