import * as React from "react";
import Svg, { SvgProps, Path, Rect, Mask, Ellipse } from "react-native-svg";

function GatoBannerTwo(props: SvgProps) {
  return (
    <Svg
      width={133}
      height={140}
      fill="none"
      {...props}
    >
      <Path
        d="M111.014 59.805c6.286-2.084 10.201-11.216 10.201-11.216s2.879 9.533 8.887 12.317c0 0-6.722 2.8-10.2 11.217-2.536-8.805-8.888-12.318-8.888-12.318z"
        fill="#FB8AD8"
        stroke="#00295B"
        strokeWidth={0.669}
        strokeMiterlimit={10}
      />
      <Path
        d="M23.642 21.105c-5.755-2.034-9.184-10.513-9.184-10.513s-2.774 8.711-8.34 11.19c0 0 6.132 2.682 9.184 10.513 2.482-8.02 8.34-11.19 8.34-11.19z"
        fill="#FB8AD8"
        stroke="#00295B"
        strokeWidth={0.465}
        strokeMiterlimit={10}
      />
      <Path
        d="M115.389 10.413c-4.065-1.957-5.915-8.527-5.915-8.527s-2.779 6.257-7.124 7.597c0 0 4.293 2.53 5.915 8.526 2.542-5.773 7.124-7.596 7.124-7.596zM10.167 60.119c-3.231-.47-5.989-4.595-5.989-4.595S3.631 60.425.93 62.327c0 0 3.546.79 5.99 4.595.48-4.5 3.247-6.803 3.247-6.803z"
        fill="#E5F68C"
        stroke="#00295B"
        strokeWidth={0.669}
        strokeMiterlimit={10}
      />
      <Rect
        x={12.366}
        y={89.436}
        width={101.671}
        height={16.838}
        rx={8.162}
        fill="#D4BFFA"
        stroke="#000"
        strokeWidth={0.643}
      />
      <Rect
        x={17.701}
        y={94.493}
        width={91.002}
        height={5.917}
        rx={2.958}
        fill="#000"
      />
      <Path
        d="M20.528 82.687a2.57 2.57 0 012.57-2.57h79.949a3.6 3.6 0 013.599 3.599v5.398H20.528v-6.427z"
        fill="#C7DA64"
      />
      <Rect
        x={20.849}
        y={68.356}
        width={84.704}
        height={16.324}
        rx={8.162}
        fill="#E5F68C"
        stroke="#000"
        strokeWidth={0.643}
      />
      <Rect
        x={25.247}
        y={73.433}
        width={75.911}
        height={5.655}
        rx={2.828}
        fill="#000"
      />
      <Mask id="prefix__a" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.408 99.91h-.363v17.481a7.455 7.455 0 007.455 7.455h87.404a7.455 7.455 0 007.455-7.455v-17.48h-.363a8.358 8.358 0 01-7.992 5.912H20.4a8.357 8.357 0 01-7.992-5.912z"
        />
      </Mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.408 99.91h-.363v17.481a7.455 7.455 0 007.455 7.455h87.404a7.455 7.455 0 007.455-7.455v-17.48h-.363a8.358 8.358 0 01-7.992 5.912H20.4a8.357 8.357 0 01-7.992-5.912z"
        fill="#9982E7"
      />
      <Path
        d="M12.045 99.91v-.642h-.642v.643h.642zm.363 0l.615-.187-.14-.455h-.475v.643zm101.951 0h.642v-.642h-.642v.643zm-.363 0v-.642h-.476l-.139.455.615.188zm-101.95.643h.362v-1.285h-.363v1.285zm.642 16.838v-17.48h-1.285v17.48h1.285zm6.812 6.813a6.812 6.812 0 01-6.812-6.813h-1.285a8.097 8.097 0 008.097 8.098v-1.285zm87.404 0H19.5v1.285h87.404v-1.285zm6.812-6.813a6.812 6.812 0 01-6.812 6.813v1.285a8.097 8.097 0 008.097-8.098h-1.285zm0-17.48v17.48h1.285v-17.48h-1.285zm.28.642h.363v-1.285h-.363v1.285zm-.615-.83a7.715 7.715 0 01-7.377 5.458v1.285a9.001 9.001 0 008.607-6.368l-1.23-.375zm-7.377 5.458H20.4v1.285h85.604v-1.285zm-85.604 0a7.716 7.716 0 01-7.377-5.458l-1.23.375a9 9 0 008.607 6.368v-1.285z"
        fill="#000"
        mask="url(#prefix__a)"
      />
      <Mask id="prefix__b" fill="#fff">
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.34 119.192h-.295v12.853A7.455 7.455 0 0019.5 139.5h87.403a7.455 7.455 0 007.455-7.455v-12.853h-.295a6.808 6.808 0 01-6.509 4.815H18.85a6.808 6.808 0 01-6.51-4.815z"
        />
      </Mask>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.34 119.192h-.295v12.853A7.455 7.455 0 0019.5 139.5h87.403a7.455 7.455 0 007.455-7.455v-12.853h-.295a6.808 6.808 0 01-6.509 4.815H18.85a6.808 6.808 0 01-6.51-4.815z"
        fill="#6723B0"
      />
      <Path
        d="M12.045 119.192v-.643h-.643v.643h.643zm.296 0l.614-.188-.139-.455h-.475v.643zm102.017 0h.643v-.643h-.643v.643zm-.295 0v-.643h-.476l-.139.455.615.188zm-102.018.642h.296v-1.285h-.296v1.285zm.643 12.211v-12.853h-1.286v12.853h1.286zm6.812 6.812a6.812 6.812 0 01-6.812-6.812h-1.286a8.098 8.098 0 008.098 8.098v-1.286zm87.403 0H19.5v1.286h87.403v-1.286zm6.812-6.812a6.812 6.812 0 01-6.812 6.812v1.286a8.099 8.099 0 008.098-8.098h-1.286zm0-12.853v12.853h1.286v-12.853h-1.286zm.348.642h.295v-1.285h-.295v1.285zm-.615-.83a6.164 6.164 0 01-5.894 4.36v1.286a7.45 7.45 0 007.124-5.271l-1.23-.375zm-5.894 4.36H18.85v1.286h88.704v-1.286zm-88.704 0a6.164 6.164 0 01-5.895-4.36l-1.23.375a7.45 7.45 0 007.125 5.271v-1.286z"
        fill="#000"
        mask="url(#prefix__b)"
      />
      <Path
        fill="#7E94F8"
        stroke="#000"
        strokeWidth={0.643}
        d="M36.019 71.955h9.126v28.149h-9.126z"
      />
      <Path fill="#6773E5" d="M40.583 72.148h4.37v27.635h-4.37z" />
      <Path
        d="M53.95 69.191c0-1.67-1.672-8.308-2.946-8.308-3.825.775-11.427 1.898-11.693 4.326-.176 1.6 1.031 5.01 1.717 6.492 4.824 3.66 12.921-.437 12.921-2.51z"
        fill="#FB8AD8"
        stroke="#000"
        strokeWidth={0.643}
      />
      <Path
        d="M27.198 69.132c0-1.671 1.671-8.309 2.946-8.309 3.824.776 11.427 1.898 11.693 4.327.175 1.6-1.032 5.009-1.717 6.491-4.824 3.66-12.922-.437-12.922-2.51z"
        fill="#FB8AD8"
        stroke="#000"
        strokeWidth={0.643}
      />
      <Path
        d="M47.521 71.505c.206-1.645-.386-5.44-.771-6.683-3.856-.6-11.375-2.186-12.468 0-.72 1.44-.77 5.055-.642 6.683 5.013 7.97 13.624 2.057 13.881 0z"
        fill="#FEB4E7"
        stroke="#000"
        strokeWidth={0.643}
      />
      <Path
        d="M56.89 49.18c0 9.19-7.37 16.637-16.455 16.637-9.086 0-16.456-7.446-16.456-16.638 0-9.19 7.37-16.637 16.456-16.637 9.085 0 16.456 7.446 16.456 16.637z"
        fill="#B895F6"
        stroke="#000"
        strokeWidth={0.514}
      />
      <Ellipse cx={34.29} cy={44.436} rx={4.756} ry={4.884} fill="#C2B2FA" />
      <Path
        fill="#7E94F8"
        stroke="#000"
        strokeWidth={0.569}
        d="M80.661 53.884h8.079v24.92h-8.079z"
      />
      <Path fill="#6773E5" d="M84.702 54.054h3.869v24.465h-3.869z" />
      <Path
        d="M96.534 51.437c0-1.479-1.479-7.355-2.607-7.355-3.386.686-10.117 1.68-10.352 3.83-.156 1.416.913 4.435 1.52 5.747 4.27 3.24 11.44-.387 11.44-2.222z"
        fill="#FB8AD8"
        stroke="#000"
        strokeWidth={0.569}
      />
      <Path
        d="M72.85 51.384c0-1.479 1.48-7.355 2.608-7.355 3.386.686 10.116 1.68 10.352 3.83.155 1.416-.913 4.435-1.52 5.747-4.27 3.24-11.44-.387-11.44-2.222z"
        fill="#FB8AD8"
        stroke="#000"
        strokeWidth={0.569}
      />
      <Path
        d="M90.844 53.486c.183-1.456-.34-4.817-.682-5.917-3.414-.531-10.07-1.935-11.038 0-.637 1.274-.682 4.476-.569 5.917 4.438 7.055 12.062 1.82 12.29 0z"
        fill="#FEB4E7"
        stroke="#000"
        strokeWidth={0.569}
      />
      <Path
        d="M99.793 32.103c0 8.605-6.895 15.577-15.395 15.577s-15.395-6.972-15.395-15.577c0-8.606 6.895-15.577 15.395-15.577s15.395 6.971 15.395 15.577z"
        fill="#B895F6"
        stroke="#000"
        strokeWidth={0.455}
      />
      <Ellipse cx={78.442} cy={28.337} rx={4.21} ry={4.324} fill="#C2B2FA" />
    </Svg>
  );
}

export default GatoBannerTwo;