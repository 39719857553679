import { useMutation, useQuery } from 'react-query';
import { axiosGamesInstance } from '@config/api';
import { GameEventInterface } from '@interfaces/event';
import { QueryKeys } from 'app/react-query/query-keys';

async function getGameEvents(gameId: number) {
  const response = await axiosGamesInstance.get<{ gameEvents: GameEventInterface[] }>(`/event/game/${gameId}`);
  return response.data;
}

export function useGameEvents(gameId: number) {
  return useQuery([QueryKeys.GET_GAME_EVENTS, { gameId }], () => getGameEvents(gameId));
}
