import { StyleSheet } from 'react-native';

import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    borderRadius: moderateScale(16),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: moderateScale(27),
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: { flex: 1 },
  left: {
    flex: 1,
    justifyContent: 'space-between',
    height: '100%',
  },
  title: {
    letterSpacing: 1,
    marginBottom: Spacing.TINY * 1.5,
    flexWrap: 'nowrap',
    flex: 1,
  },
  button: {
    width: '100%',
    marginTop: Spacing.SMALL,
  },
  rightIcon: {
    flex: 2,
    alignItems: 'center',
  },
  titleIcon: {
    display: 'flex',
    marginRight: Spacing.BIG,
  },
});
