import * as React from 'react';
import Svg, { SvgProps, Rect, G, Path, Defs, ClipPath } from 'react-native-svg';

const MoreIcon = (props: SvgProps) => (
  <Svg width={40} height={41} fill="none" {...props}>
    <Rect y={0.806} width={40} height={40} rx={20} fill="#1D1F23" />
    <G clipPath="url(#prefix__clip0_5008_50495)">
      <Path
        d="M13.892 19.061a1.75 1.75 0 00-1.745 1.745c0 .96.785 1.745 1.745 1.745a1.75 1.75 0 001.745-1.745 1.75 1.75 0 00-1.745-1.745zm12.216 0a1.75 1.75 0 00-1.745 1.745c0 .96.785 1.745 1.745 1.745a1.75 1.75 0 001.745-1.745 1.75 1.75 0 00-1.745-1.745zm-6.108 0a1.75 1.75 0 00-1.745 1.745c0 .96.785 1.745 1.745 1.745a1.75 1.75 0 001.745-1.745A1.75 1.75 0 0020 19.061z"
        fill="#FCFCFD"
      />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_5008_50495">
        <Path fill="#fff" transform="translate(9.53 10.335)" d="M0 0h20.941v20.941H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default MoreIcon;
