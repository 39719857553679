import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    marginTop: moderateScale(20),
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    pointerEvents: isWebResponsive ? 'none' : 'auto',
    marginBottom: isWebResponsive ? moderateScale(20) : undefined,
    paddingHorizontal: isWebResponsive ? moderateScale(36) : undefined
  },
  flex: { flex: 1 },
  divider: { height: 25, width: 2, backgroundColor: Colors.socialBackground },
  description: { marginTop: moderateScale(10) },
  modal: {
    width: moderateScale(464),
    maxHeight: moderateScale(488),
  },
});
