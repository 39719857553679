import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const HelpVideoAdIcon = (props: SvgProps) => {
  const { fill } = props;
  return (
    <Svg width={20} height={20} fill="none" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.333 10a8.333 8.333 0 1 1-16.666 0 8.333 8.333 0 0 1 16.666 0ZM10 6.458c-.575 0-1.042.467-1.042 1.042a.625.625 0 1 1-1.25 0 2.292 2.292 0 1 1 4.22 1.239 7.51 7.51 0 0 1-.468.63l-.084.105c-.129.16-.247.306-.356.456-.27.37-.395.649-.395.903v.417a.625.625 0 1 1-1.25 0v-.417c0-.666.327-1.217.634-1.639.13-.178.272-.354.401-.514l.076-.094c.154-.192.286-.36.39-.524A1.042 1.042 0 0 0 10 6.458Zm.625 6.875a.625.625 0 1 0-1.25 0v.834a.625.625 0 0 0 1.25 0v-.834Z"
        fill={fill || '#fff'}
        fillOpacity={fill ? 1 : 0.2}
      />
    </Svg>
  );
};
