/* eslint-disable @typescript-eslint/naming-convention */

import tap from '@assets/lotties/control_tap.json';
import control from '@assets/lotties/control_control.json';
import swipe from '@assets/lotties/control_swipe.json';

export const CONTROL_TYPES = {
  TAP: {
    id: 'tap_control',
    icon: tap,
  },
  CONTROL: {
    id: 'control_control',
    icon: control,
  },
  GAMEPAD: {
    id: 'control_control',
    icon: control,
  },
  SWIPE: {
    id: 'swipe_control',
    icon: swipe,
  },
  KEYBOARD: {
    id: 'keyboard_control',
    icon: tap,
  },
  MOUSE: {
    id: 'mouse_control',
    icon: tap,
  },
  BOTH: {
    id: 'mouse_keyboard_control',
    icon: tap,
  },
};
