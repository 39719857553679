import { useTranslation } from 'react-i18next';

import globalEn from '../translations/en/global.json';
import globalEs from '../translations/es/global.json';
import globalPt from '../translations/pt/global.json';


export const translations = {
  en: globalEn,
  es: globalEs,
  pt: globalPt,
};

export function useTranslations() {
  const i18n = useTranslation();
  return i18n
}
