import * as React from 'react';
import Svg, { SvgProps, Path, G, Defs, ClipPath } from 'react-native-svg';

const ViolentIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_2454_31088)" fill="#FCFCFC">
      <Path d="M16.067 4.817a.625.625 0 10-.884-.884l-1.25 1.25-.366.366-.808-.808a1.25 1.25 0 00-1.768 0l-.625.625c-.049.05-.09.104-.13.158A7.45 7.45 0 007.5 5C3.365 5 0 8.365 0 12.5S3.365 20 7.5 20s7.5-3.365 7.5-7.5c0-.966-.19-1.888-.524-2.737.054-.04.108-.08.158-.13l.625-.624a1.25 1.25 0 000-1.768l-.808-.808.366-.366 1.25-1.25zM7.5 8.75a3.754 3.754 0 00-3.75 3.75.625.625 0 01-1.25 0c0-2.757 2.243-5 5-5a.625.625 0 010 1.25zM17.93 5.82a.625.625 0 10-.884.883l.625.625a.623.623 0 00.884 0 .625.625 0 000-.883l-.625-.625zM13.296 2.953a.623.623 0 00.884 0 .625.625 0 000-.883l-.625-.625a.625.625 0 10-.884.883l.625.625zM15.625 2.5c.345 0 .625-.28.625-.625V.625a.625.625 0 10-1.25 0v1.25c0 .345.28.625.625.625zM19.375 3.773h-1.25a.625.625 0 100 1.25h1.25a.625.625 0 100-1.25zM17.07 2.953a.623.623 0 00.884 0l1.25-1.25A.625.625 0 1018.32.82l-1.25 1.25a.625.625 0 000 .883z" />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_2454_31088">
        <Path fill="#fff" d="M0 0h20v20H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ViolentIcon;
