import { StyleSheet } from 'react-native';
import { Spacing } from '@theme';

export const styles = StyleSheet.create({
  buttonText: {
    paddingHorizontal: Spacing.TINY,
    paddingVertical: Spacing.TINY / 2,
  },
  leftGap: {
    marginLeft: Spacing.SMALL,
  },
});
