import { Dimensions, StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { LATERAL } from 'app/theme/spacing';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    alignSelf: isWebResponsive ? 'center' : 'flex-end',
    zIndex: 1,
    elevation: 1,
    borderRadius: moderateScale(8),
    paddingVertical: moderateScale(9.5),
    paddingHorizontal: moderateScale(16),
    width: Dimensions.get('window').width - LATERAL * 2,
  },
  containerDesktop: {
    width: moderateScale(422),
    alignSelf: 'flex-end',
    marginTop: moderateScale(60),
    right: moderateScale(100),
  },
  text: {
    lineHeight: moderateScale(24),
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  toast: {
    flex: 1,
    paddingHorizontal: moderateScale(8),
    maxWidth: '85%',
  },
});
