import { useMutation } from 'react-query';

import { axiosSearchInstance } from '@config/api';
import { isAndroid, isMobile, isWebResponsive } from '@constants/platform';

async function searchService(text: string) {
  const getDeviceInfo = () => {
    if (isMobile ) {
      if (isAndroid) {
        return 'MOBILE/ANDROID';
      }
      return 'MOBILE/IOS';
    }
    if (isWebResponsive) return 'MOBILE/ANDROID'
    return 'DESKTOP/WEB';
  };

  const DeviceInfo = getDeviceInfo();
  const response = await axiosSearchInstance.get(`/search/info/1/${text}/50/${DeviceInfo}`);

  return response.data;
}

export function useSearch() {
  return useMutation((text: string) => searchService(text), { mutationKey: ['search'] });
}
