import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Trash = ({ stroke, ...props }: SvgProps) => (
  <Svg width={25} height={25} fill="none" {...props}>
    <Path
      d="m19.486 9.5-.716 9.307a4 4 0 0 1-3.988 3.693H10.19a4 4 0 0 1-3.988-3.693L5.486 9.5m16-2c-2.598-1.266-5.687-2-9-2-3.314 0-6.402.734-9 2m7-2v-1a2 2 0 0 1 4 0v1m-4 6v6m4-6v6"
      stroke={stroke || '#DD438D'}
      strokeWidth={1.5}
      strokeLinecap="round"
    />
  </Svg>
);

export default Trash;
