import { StyleSheet } from 'react-native';

import {
  isDesktop,
  isMobile,
  isWebResponsive,
  SCREEN_HEIGHT,
  SCREEN_WIDTH as HW_SCREEN_WIDTH,
  WINDOW_WIDTH,
} from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { SIZES } from 'app/theme/fonts';
import { BANNER_SIZE } from '@constants/sizes';

const AVATAR_DIMENTIONS = moderateScale(42);
const SCREEN_WIDTH = isMobile ? HW_SCREEN_WIDTH : WINDOW_WIDTH;

export const styles = StyleSheet.create({
  loading: {
    justifyContent: 'center',
    marginBottom: moderateScale(24),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: 16,
    minHeight: moderateScale(80),
  },
  lottie: {
    width: moderateScale(40),
    height: moderateScale(40),
  },
  headerLeft: {
    position: 'absolute',
    top: moderateScale(122),
    left: moderateScale(20),
    zIndex: 3,
  },
  backBtn: {
    backgroundColor: Colors.socialBackground
  },
  headerRight: {
    position: 'absolute',
    top: moderateScale(122),
    right: moderateScale(20),
    zIndex: 3,
  },
  headImage: {
    width: isMobile || isWebResponsive ? SCREEN_WIDTH : '100vw',
    height: '100%',
  },
  headInfo: {
    position: 'absolute',
    left: moderateScale(20),
    bottom: moderateScale(20),
    zIndex: 2,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  headInfoDesktop: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: 2,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingVertical: isWebResponsive ? 0 : Spacing.XXLARGE,
  },
  headTitle: {
    marginTop: moderateScale(8),
    fontSize: isWebResponsive ? '28px' : undefined
  },
  footerButton: {
    width: SCREEN_WIDTH - Spacing.LATERAL * 2,
    paddingVertical: moderateScale(14),
    ...(isDesktop && {
      width: moderateScale(186),
      alignSelf: 'flex-start',
      marginRight: Spacing.MEDIUM,
      marginBottom: isWebResponsive ? moderateScale(24) : undefined
    }),
  },
  footerButtonText: {
    fontSize: moderateScale(18),
    ...(isDesktop && {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    }),
  },
  headerButtons: {
    flexDirection: 'row',
  },
  buttonIcon: {
    overflow: 'visible',
  },
  genre: {
    paddingVertical: moderateScale(12),
    paddingHorizontal: moderateScale(16),
    marginRight: moderateScale(12),
    borderRadius: moderateScale(30),
    backgroundColor: Colors.purple,
    marginBottom: isDesktop ? Spacing.SMALL : undefined,
  },
  body: {
    paddingHorizontal: isMobile ? moderateScale(20) : undefined,
    paddingTop: isMobile ? moderateScale(25) : Spacing.XXLARGE,
    // paddingBottom: isDesktop ? Spacing.LATERALLARGE : undefined,
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  gameRow: {
    flexDirection: isWebResponsive ? 'column' : 'row',
    alignItems: isWebResponsive ? 'flex-start' : 'center',
  },
  avatar: {
    flexDirection: 'row',
    marginBottom: isWebResponsive ? moderateScale(16) : undefined
  },
  players: {
    letterSpacing: 2,
    marginLeft: moderateScale(5),
  },
  description: {
    marginTop: moderateScale(20),
    letterSpacing: 0.5,
    marginBottom: moderateScale(33),
    width: isDesktop && !isWebResponsive ? WINDOW_WIDTH * 0.5 : undefined,
  },
  creatorImg: {
    width: AVATAR_DIMENTIONS,
    height: AVATAR_DIMENTIONS,
    borderRadius: AVATAR_DIMENTIONS / 2,
    marginRight: moderateScale(15),
    marginTop: 0,
  },
  madeBy: {
    letterSpacing: 2,
    marginBottom: moderateScale(4),
  },
  controls: {
    marginBottom: moderateScale(16),
    padding: moderateScale(20),
    backgroundColor: Colors.socialBackground,
    width: '100%',
    borderRadius: moderateScale(16),
    justifyContent: 'space-between',
  },
  controlsChevron: {
    transform: [{ scale: 0.75 }],
  },
  controlsTitle: {
    marginLeft: moderateScale(8),
  },
  category: {
    backgroundColor: Colors.socialBackground,
    borderRadius: moderateScale(16),
    width: (SCREEN_WIDTH - moderateScale(20) * 2 - moderateScale(12) * 2) / 3,
    height: (SCREEN_WIDTH - moderateScale(20) * 2 - moderateScale(12) * 2) / 3,
    justifyContent: 'center',
    alignItems: 'center',
    ...(isDesktop && {
      flex: 1,
      width: undefined,
      height: moderateScale(100),
      marginRight: Spacing.SMALL,
      flexDirection: isWebResponsive ? 'column' : 'row',
    }),
  },
  categoryText: {
    marginTop: moderateScale(2),
    paddingHorizontal: 10,
  },
  categories: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: moderateScale(25),
    marginRight: isDesktop ? -Spacing.SMALL : undefined,
  },
  previewImg: {
    width: moderateScale(308),
    height: moderateScale(280),
    borderRadius: moderateScale(16),
  },
  slider: {
    paddingLeft: 0,
    fontSize: isWebResponsive ? SIZES.mBig : undefined
  },
  skeletonImg: {
    marginBottom: moderateScale(20),
  },
  skeletonText: {
    marginRight: moderateScale(10),
  },
  linear: { position: 'absolute', width: '200%', height: '100%', zIndex: 1 },
  addBanner: {
    marginLeft: 0,
  },
  made: {
    marginLeft: moderateScale(6),
  },
  verified: { position: 'absolute', top: 0, right: 10, zIndex: 3, transform: [{ scale: 1.25 }] },
  // ad: {
  //   borderRadius: moderateScale(16),
  //   borderWidth: 2,
  //   borderColor: Colors.limeGreen,
  // },
  desktopHeader: {
    width: '100%',
    alignItems: 'center',
    // Commented styles are for the header banner
    // height: 628 - (Spacing.SMALL + (isWebResponsive ? BANNER_SIZE.HEADER_BANNER_MOBILE.HEIGHT : 0)),
    height: 628
  },
  navbarBackground: {
    backgroundColor: Colors.black,
    // Commented styles are for the header banner
    // height: 100 + (!isWebResponsive ? BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT + Spacing.SMALL : 0),
    height: 100,
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  bodyLeft: {
    // flex: 3,
    maxWidth: 771,
    width: '56vw',
  },
  bodyRight: {
    // flex: 1,
    width: '27vw',
    maxWidth: 375,
  },
  categoryIcon: {
    height: 35,
    width: 35,
    marginRight: Spacing.TINY,
  },
  separator: {
    flex: 1,
  },
  addContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  bigAddContainer: {
    maxWidth: '100%',
    marginBottom: Spacing.XLARGE,
  },
  ad: {
    width: 300,
    height: 250,
    marginBottom: Spacing.XLARGE,
  },
  adWide: {
    width: isWebResponsive ? 300 : 728,
    height: 90,
    marginBottom: Spacing.XLARGE,
  }
});
