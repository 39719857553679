import * as React from "react"
import Svg, { Path, Defs, LinearGradient, Stop, SvgProps } from "react-native-svg"

function GameQuestLogo({ fill, ...props }: SvgProps) {
  return (
    <Svg
      width={149}
      height={20}
      viewBox="0 0 149 20"
      fill="none"
      {...props}
    >
      <Path
        d="M9.897 17.234v2.026H2.95v-2.314H.636V5.369h2.315V3.054h11.575v4.63H12.21V5.368H5.268v11.575h4.34l.29-.29v-4.342H7.585V9.998h6.944v9.26h-2.315v-2.314h-2.026l-.29.29z"
        fill="url(#paint0_linear_429_23958)"
      />
      <Path
        d="M21.47 14.63v4.628h-4.629V5.368h2.315V3.055h9.26V5.37h2.315v13.89H26.1v-4.63h-4.63zm4.63-2.316V5.372h-4.63v6.944h4.63z"
        fill="url(#paint1_linear_429_23958)"
      />
      <Path
        d="M35.359 12.315v6.943h-2.315V3.054h4.63v4.63h6.943v-4.63h4.629v16.204h-4.63v-6.943h-2.314v4.629h-4.629v-4.63H35.36z"
        fill="url(#paint2_linear_429_23958)"
      />
      <Path
        d="M51.562 19.258V3.054h11.575V5.37h-6.943v4.629h6.943v2.314h-6.943v4.63h6.943v2.314H51.562v.002z"
        fill="url(#paint3_linear_429_23958)"
      />
      <Path
        d="M84.474 16.944H86.5v2.314h-2.314v-2.026l-.29-.29h-1.737l-.29.29v2.026h-6.944v-2.314h-2.314V5.369h2.314V3.054h9.26V5.37H86.5v9.26h-2.314v2.027l.29.29-.002-.002zm-2.893 0l.29-.29V5.369h-4.629v11.575h4.341-.002z"
        fill="url(#paint4_linear_429_23958)"
      />
      <Path
        d="M98.073 3.054h4.629v16.204H91.127v-2.314h-2.314V3.054h4.63v13.89h4.628V3.054h.002z"
        fill="url(#paint5_linear_429_23958)"
      />
      <Path
        d="M105.016 19.258V3.054h11.575V5.37h-6.944v4.629h6.944v2.315h-6.944v4.629h6.944v2.314h-11.575v.002z"
        fill="url(#paint6_linear_429_23958)"
      />
      <Path
        d="M128.167 5.369h-4.629v4.629h6.943v2.315h2.315v4.629h-2.315v2.314h-9.26v-2.314h-2.314v-2.315h4.629v2.315h4.629v-4.63h-6.944V9.998h-2.314v-4.63h2.314V3.055h9.26V5.37h2.315v2.314h-4.629V5.37z"
        fill="url(#paint7_linear_429_23958)"
      />
      <Path
        d="M148.998 3.054V5.37h-4.629v13.89h-4.629V5.368h-4.629V3.054h13.89-.003z"
        fill="url(#paint8_linear_429_23958)"
      />
      <Path
        d="M9.897 14.92v2.026H2.95V14.63H.636V3.054h2.315V.74h11.575v4.629H12.21V3.054H5.268V14.63h4.34l.29-.29V9.998H7.585V7.683h6.944v9.26h-2.315V14.63h-2.026l-.29.29z"
        fill="url(#paint9_linear_429_23958)"
      />
      <Path
        d="M21.47 12.314v4.63h-4.629V3.053h2.315V.74h9.26v2.314h2.315v13.89H26.1v-4.63h-4.63zM26.1 10V3.054h-4.63v6.944h4.63V10z"
        fill="url(#paint10_linear_429_23958)"
      />
      <Path
        d="M35.359 10v6.943h-2.315V.74h4.63v4.629h6.943V.739h4.629v16.204h-4.63V10h-2.314v4.629h-4.629v-4.63H35.36z"
        fill="url(#paint11_linear_429_23958)"
      />
      <Path
        d="M51.562 16.943V.74h11.575v2.314h-6.943v4.63h6.943v2.314h-6.943v4.629h6.943v2.314H51.562v.002z"
        fill="url(#paint12_linear_429_23958)"
      />
      <Path
        d="M84.474 14.629H86.5v2.314h-2.314v-2.026l-.29-.29h-1.737l-.29.29v2.026h-6.944V14.63h-2.314V3.054h2.314V.74h9.26v2.314H86.5v9.26h-2.314v2.027l.29.29-.002-.002zm-2.893 0l.29-.29V3.054h-4.629v11.575h4.341-.002z"
        fill="url(#paint13_linear_429_23958)"
      />
      <Path
        d="M98.073.74h4.629v16.203H91.127V14.63h-2.314V.739h4.63v13.89h4.628V.739h.002z"
        fill="url(#paint14_linear_429_23958)"
      />
      <Path
        d="M105.016 16.943V.74h11.575v2.314h-6.944v4.63h6.944v2.314h-6.944v4.629h6.944v2.314h-11.575v.002z"
        fill="url(#paint15_linear_429_23958)"
      />
      <Path
        d="M128.167 3.054h-4.629v4.63h6.943v2.314h2.315v4.629h-2.315v2.314h-9.26v-2.314h-2.314v-2.315h4.629v2.315h4.629v-4.63h-6.944V7.684h-2.314V3.054h2.314V.74h9.26v2.314h2.315v2.315h-4.629V3.054z"
        fill="url(#paint16_linear_429_23958)"
      />
      <Path
        d="M148.998.74v2.314h-4.629v13.89h-4.629V3.053h-4.629V.74h13.89-.003z"
        fill="url(#paint17_linear_429_23958)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_429_23958"
          x1={7.58214}
          y1={3.0542}
          x2={7.58214}
          y2={19.2603}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_429_23958"
          x1={23.7861}
          y1={3.0542}
          x2={23.7861}
          y2={19.2581}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_429_23958"
          x1={41.1451}
          y1={3.0542}
          x2={41.1451}
          y2={19.2581}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_429_23958"
          x1={57.3498}
          y1={3.0542}
          x2={57.3498}
          y2={19.2581}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_429_23958"
          x1={79.5555}
          y1={3.05432}
          x2={79.5555}
          y2={19.2583}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_429_23958"
          x1={95.7577}
          y1={3.05432}
          x2={95.7577}
          y2={19.2583}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_429_23958"
          x1={110.804}
          y1={3.05432}
          x2={110.804}
          y2={19.2583}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_429_23958"
          x1={125.851}
          y1={3.05432}
          x2={125.851}
          y2={19.2561}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_429_23958"
          x1={142.056}
          y1={3.05432}
          x2={142.056}
          y2={19.2583}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#141416" />
          <Stop offset={1} />
        </LinearGradient>
        <LinearGradient
          id="paint9_linear_429_23958"
          x1={7.57996}
          y1={16.9436}
          x2={7.57996}
          y2={0.739624}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint10_linear_429_23958"
          x1={23.784}
          y1={16.9413}
          x2={23.784}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint11_linear_429_23958"
          x1={41.1425}
          y1={16.9413}
          x2={41.1425}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint12_linear_429_23958"
          x1={57.348}
          y1={16.9413}
          x2={57.348}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint13_linear_429_23958"
          x1={79.5534}
          y1={16.9413}
          x2={79.5534}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint14_linear_429_23958"
          x1={95.7555}
          y1={16.9413}
          x2={95.7555}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint15_linear_429_23958"
          x1={110.802}
          y1={16.9413}
          x2={110.802}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint16_linear_429_23958"
          x1={125.849}
          y1={16.9391}
          x2={125.849}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint17_linear_429_23958"
          x1={142.054}
          y1={16.9413}
          x2={142.054}
          y2={0.739502}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default GameQuestLogo
