// import { authTokenSelector } from '@store/index';
import React from 'react';
import { View } from 'react-native';

import { authTokenSelector, useStore } from '@store/index';

import MenuButton from '../MenuButton/MenuButton';
import { NotificationsButton } from '../NotificationButton/NotificationButton';
import LoginButton from '../LoginButton/LoginButton';

import { styles } from './styles';

const ResponsiveTopbar = () => {
  const isLogedIn = useStore(authTokenSelector);

  return (
    <View style={styles.containerButtons}>
      {isLogedIn ? (
        <>
          <NotificationsButton />
          <MenuButton />
        </>
      ) : (
        <LoginButton />
      )}
    </View>
  );
};

export default ResponsiveTopbar;
