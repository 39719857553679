import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

const DIMENTIONS = moderateScale(40);

export const styles = StyleSheet.create({
  container: {
    width: DIMENTIONS,
    height: DIMENTIONS,
    borderRadius: DIMENTIONS / 2,
    backgroundColor: Colors.greyTwo,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
