import React, { FC, memo } from 'react';
import { Image, ImageStyle } from 'react-native';

interface SvgUriComponentI {
  uri: string;
  style?: ImageStyle;
}

const SvgUriComponent: FC<SvgUriComponentI> = ({ uri, style }: SvgUriComponentI) => (
  <Image source={{ uri }} style={style} />
);

export default memo(SvgUriComponent);
