import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    zIndex: 3,
    width: moderateScale(110),
    height: moderateScale(110),
    borderRadius: moderateScale(55),
    // backgroundColor: Colors.greySix,
    alignSelf: 'center',
    marginTop: moderateScale(20),
    overflow: 'hidden',
  },
  background: { width: '100%', height: '100%' },
  cat: { width: '100%', height: '100%', marginTop: 30 },
  greyBackground: {
    backgroundColor: Colors.greySix,
  },
});
