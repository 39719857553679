import { StyleSheet } from 'react-native';

import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginRight: -Spacing.XXLARGE,
    justifyContent: 'space-evenly',
    marginBottom: Spacing.BANNERBOTTOM - Spacing.BIG,
  },
  banner: {
    marginRight: Spacing.XXLARGE,
    marginBottom: Spacing.BIG,
  },
  banner1: {
    flex: 1,
    minWidth: 340,
  },
  banner2: {
    flex: 2.2,
    minWidth: 523,
  },
  lottie: { width: moderateScale(72), height: moderateScale(33) },
});
