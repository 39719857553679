import * as React from "react";
import Svg, { SvgProps, Path } from "react-native-svg";

function SparkIcon(props: SvgProps) {
  return (
    <Svg width={17} height={20} fill="none" {...props}>
      <Path
        fill={props.fill || "#fff"}
        d="M7.438.109h1.447v5.307H7.438zM7.438 14.584h1.447v5.307H7.438zM5.029 10.723h2.412v3.86H5.029zM8.876 10.723h2.412v3.86H8.876z"
      />
      <Path
        fill={props.fill || "#fff"}
        d="M8.876 5.415h2.412v3.86H8.876zM5.029 5.415h2.412v3.86H5.029zM.198 10.723V9.276h4.825v1.447zM11.299 10.723V9.276h4.825v1.447z"
      />
    </Svg>
  );
}

export default SparkIcon;
