import React, { memo } from 'react';
import { View, Image, Pressable } from 'react-native';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { useTranslations } from '@hooks/useTranslation';
import CustomText from '@atoms/CustomText';
import { GamesMightAlsoLike } from '@interfaces/game';
import { RootStackNavigator } from 'app/navigation/types';
import { setGameIdHandler, useStore } from '@store/index';

import styles from './styles';

interface Props {
  games: GamesMightAlsoLike[];
}

const SliderWeb = ({ games }: Props) => {
  const i18n = useTranslations();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();

  const setGameId = useStore(setGameIdHandler);

  const handleNavigateToGame = (game: GamesMightAlsoLike) => {
    setGameId(game.id);

    const { gameSlug } = game;

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: gameSlug,
      },
      key: `GamePage/${gameSlug}`,
    });
    navigation.dispatch(navigateAction);
  };

  return (
    <View>
      <CustomText size="large" align="left" weight="interBold">
        {i18n.t('game_page.also_like')}
      </CustomText>
      <View style={styles.content}>
        {games.map((game) => (
          <Pressable
            style={styles.item}
            key={`also_like_${game.id}`}
            onPress={() => handleNavigateToGame(game)}>
            <Image source={{ uri: game.principalImage }} style={styles.image} />
            <CustomText size="mbig" weight="interBold" align="left">
              {game.name}
            </CustomText>
          </Pressable>
        ))}
      </View>
    </View>
  );
};

export default memo(SliderWeb);
