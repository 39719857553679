import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { SCREEN_HEIGHT } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: SCREEN_HEIGHT - moderateScale(120),
  },
  body: {
    marginTop: moderateScale(60),
  },
  description: {
    marginTop: moderateScale(16),
    marginBottom: moderateScale(30),
  },
  footer: { justifyContent: 'flex-end', alignItems: 'center', flex: 1 },
  underline: {
    textDecorationLine: 'underline',
  },
  button: {
    width: '100%',
    paddingVertical: moderateScale(14),
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
  header: {
    position: 'absolute',
    top: moderateScale(20),
    left: moderateScale(20),
    zIndex: 3,
  },
});
