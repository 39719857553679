import { StyleSheet } from 'react-native';

import { isDesktop } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  item: {
    flex: 1,
    minWidth: isDesktop ? 'fit-content' : undefined,
  },
});
