import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { DeleteAccountParams } from '@interfaces/accountSettings';

async function deleteAccountService(data: DeleteAccountParams, token: string) {
  const response = await axiosAuthInstance.delete('/users/me', {
    data,
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useDeleteAccount(token: string) {
  return useMutation((data: DeleteAccountParams) => deleteAccountService(data, token));
}
