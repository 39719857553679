import * as React from 'react';
import Svg, { SvgProps, Mask, Circle, G, Path, Defs, LinearGradient, Stop } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const CatIconNewStoreVersion = (props: SvgProps) => (
  <Svg width={48} height={48} fill="none" {...props}>
    <Mask id="b" maskUnits="userSpaceOnUse" x={0} y={0} width={48} height={49}>
      <Circle cx={24} cy={24} r={24} fill="url(#a)" />
    </Mask>
    <G mask="url(#b)">
      <Circle cx={24} cy={24} r={24} fill="url(#c)" />
      <G filter="url(#d)">
        <Path
          d="M23.966 6.104a17.822 17.822 0 0 1 8.098 1.917c.765.387 1.47.892 2.037 1.536.76.864 1.155 1.785 1.085 2.642-.138 1.7-2.274 3.412-6.364.839s-12.683-3.079-16.175 3.86c0 0 2.228-1.289 5.49.299.895.435 2.735 1.448 4.32 4.756.75 1.565 1.424 1.677 2.137 1.608.78-.076 1.558-1.09 2.039-4.472.48-3.382 1.101-3.86 1.7-3.877.599-.018 1.758 1.232 2.424 2.736 0 0 2.869-.832 6.037-.224 0 0 .597-2.987 1.425-3.216.398-.111.867.269 1.296.807.33.412.596.87.812 1.349a17.822 17.822 0 0 1 1.569 7.194c.076 9.836-7.916 17.961-17.752 18.038-10.003.079-18.124-8.05-18.04-18.053.085-9.763 8.1-17.72 17.863-17.738v-.001Z"
          fill="#000"
        />
        <Path
          d="M37.602 20.395c-.542 0-.98.549-.977 1.224 0 .675.44 1.22.981 1.22.542 0 .98-.547.977-1.223 0-.675-.44-1.22-.981-1.22ZM31.154 20.431c-.583 0-1.056.583-1.054 1.3 0 .719.475 1.3 1.058 1.298.583-.002 1.056-.583 1.054-1.3 0-.719-.475-1.3-1.058-1.298Z"
          fill="#E5F68C"
        />
        <Path fill="#000" d="M33.008 20.098H29.37v1.455h3.64zM39.376 20.098h-3.64v1.455h3.64z" />
      </G>
    </G>
    <Defs>
      <LinearGradient id="a" x1={42.926} y1={-28.619} x2={24} y2={48} gradientUnits="userSpaceOnUse">
        <Stop stopColor="#8E96FF" />
        <Stop offset={1} stopColor="#6F52F9" />
      </LinearGradient>
      <LinearGradient id="c" x1={24} y1={0} x2={24} y2={48} gradientUnits="userSpaceOnUse">
        <Stop offset={0.275} stopColor="#E5F68C" />
        <Stop offset={1} stopColor="#BECF67" />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default CatIconNewStoreVersion;
