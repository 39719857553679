/* eslint-disable prefer-arrow-callback */
import { useEffect } from 'react';

interface IUseGPT {
  path: string;
  size: number[];
  id: string;
}

const useGPT = ({ path, size, id }: IUseGPT) => {
  useEffect(() => {
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    let slot = undefined;
    googletag.cmd.push(function () {
      let slots = googletag.pubads().getSlots();
      for (let item of slots) {
        let slotPath = item.getAdUnitPath();
        if (slotPath === path) {
          googletag.destroySlots([slot])
        }
      }
    }
    );
    googletag.cmd.push(function () {
      slot = googletag.defineSlot(path, size, id)
        .addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
    });
    googletag.cmd.push(function () {
      googletag.display(slot.getSlotElementId());
    });

    return () => {
      googletag.cmd.push(function () {
        if (slot) {
          googletag.destroySlots([slot]); // Only destroy this slot
        }
      });
    }
  }, []);

};

export default useGPT;
