import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Animated, Easing, Pressable } from 'react-native';
import Lottie from 'lottie-react-native';
import { useNavigation } from '@react-navigation/core';

import CustomText from '@atoms/CustomText';
import { mediumVibration } from '@utils/vibration';
import { useTranslations } from '@hooks/useTranslation';
import { useLikeComment } from '@services/useComments';
import { authTokenSelector, useStore } from '@store/index';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/comment_like.json');

interface LikeCommentCTAProps {
  likesCount: number;
  likeByYou?: boolean;
  commentId: number;
  isResponse?: boolean;
}

export const LikeCommentCTA = ({
  likesCount = 0,
  likeByYou,
  commentId,
  isResponse = false,
}: LikeCommentCTAProps) => {
  const i18n = useTranslations();
  const animationProgress = useRef(new Animated.Value(0));
  const [likeSelected, setLikeSelected] = useState<boolean | undefined>(likeByYou);
  const navigation = useNavigation();
  const authToken = useStore(authTokenSelector);

  const likeOrDislike = useLikeComment(isResponse);

  const animateLottie = useCallback((value: number) => {
    Animated.timing(animationProgress.current, {
      toValue: value,
      duration: value === 0 ? 0 : 2500,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }, []);

  const onSocialPress = () => {
    if (!authToken) {
      navigation.navigate('Login');
      return;
    }
    setLikeSelected((prev) => !prev);
    mediumVibration();
    likeOrDislike.mutate(commentId, {
      onSuccess: () => {
        mediumVibration();
      },
      onError: () => {
        setLikeSelected((prev) => !prev);
      },
    });
  };

  useEffect(() => {
    if (likeByYou) {
      setLikeSelected(true);
    } else {
      setLikeSelected(false);
    }
  }, [likeByYou]);

  useLayoutEffect(() => {
    if (likeSelected) {
      animateLottie(1);
    } else {
      animateLottie(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likeSelected]);

  const getLikeText = () => {
    if (!likesCount || likesCount < 1) return i18n.t('comments.like');
    if (likesCount === 1) return `1 ${i18n.t('comments.like')}`;

    return `${likesCount} ${i18n.t('comments.likes')}`;
  };

  return (
    <Pressable
      disabled={likeOrDislike.isLoading}
      hitSlop={10}
      onPress={onSocialPress}
      style={styles.container}>
      <Lottie
        autoPlay={false}
        progress={animationProgress.current}
        source={lottie}
        style={styles.lottie}
        loop={false}
      />
      <CustomText
        weight="interSemi"
        align="left"
        size="medium"
        color={likeSelected ? 'pinkBubblegum' : 'whiteTwo'}>
        {getLikeText()}
      </CustomText>
    </Pressable>
  );
};
