import React, { useRef, useState } from 'react';
import {
  Animated,
  View,
  ViewStyle,
  NativeScrollEvent,
  NativeSyntheticEvent,
  LayoutChangeEvent,
} from 'react-native';

import CustomText from '@atoms/CustomText';
import {
  isMobile,
  isWebResponsive,
  SCREEN_WIDTH as HW_SCREEN_WIDTH,
  WINDOW_WIDTH,
} from '@constants/platform';
import GameBanner from '@molecules/GameBanner';
import { moderateScale } from '@utils/scaling';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { useTranslations } from '@hooks/useTranslation';

import { LATERALPLUS, LATERALSMALL } from '../../../theme/spacing';

import Arrows from './Arrows';
import Dots from './Dots';
import { styles } from './styles';
import { CARD_SIZE } from '@constants/sizes';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';
import HalloweenBanner from '@molecules/HalloweenBanner';
import AddBanner from '@atoms/AddBanner';
import { AdPlatformEnum } from '@interfaces/advertising';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';
import GoogleAdManagerBanner from '@atoms/GoogleAdManagerBanner/GoogleAdManagerBanner.web';
import ChristmasBanner from '@molecules/ChristmasBanner';

const SCREEN_WIDTH = isMobile ? HW_SCREEN_WIDTH : WINDOW_WIDTH;

interface SliderProps {
  ppal?: boolean;
  might?: boolean;
  title?: string;
  showTags?: boolean;
  tagColor?: string;
  tagTextColor?: string;
  banners: Array<any>;
  qtyToSlide?: number;
  bannersGap?: number;
  pagination?: boolean;
  containerStyles?: ViewStyle;
  innerContainerStyles?: ViewStyle;
  // bannerAd?: ImageProps['source']; //  'bannerAd' PropType is defined but prop is never used
  titleStyles?: ViewStyle;
  bannerDimentions?: {
    width: number;
    height: number;
  };
  customSlide?: (item: any) => JSX.Element;
  // sliderWidth?: number; //'sliderWidth' PropType is defined but prop is never used
  arrows?: boolean;
  arrowsStyle?: ViewStyle;
  directSale: boolean;
  googleAdManagerUnitId?: GoogleAdManagerUnitIdEnum;
}

const Slider = ({
  ppal,
  title,
  banners,
  showTags,
  tagColor,
  tagTextColor,
  containerStyles,
  innerContainerStyles,
  titleStyles,
  bannersGap = isMobile ? LATERALSMALL : LATERALPLUS,
  qtyToSlide = 1,
  pagination = false,
  might = false,
  customSlide,
  bannerDimentions = {
    width: moderateScale(SCREEN_WIDTH - LATERALSMALL * 2),
    height: moderateScale(SCREEN_WIDTH - 100),
  },
  arrows = false,
  arrowsStyle,
  directSale,
  googleAdManagerUnitId
}: SliderProps) => {
  const i18n = useTranslations();
  const scrollX = useRef(new Animated.Value(0)).current;
  let scrollXvalue = 0;
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(false);
  const scrollViewRef = useRef<any>(null);
  const paginationInterval = (+bannerDimentions.width + bannersGap) * qtyToSlide;
  const isGGQ = useFeatureFlag(gameQuestEventSelector);

  const items =
    isGGQ && ppal
      ? [
        {
          isChristmas: true,
        },
        ...banners,
      ]
      : banners;

  const [containerWidth, setContainerWidth] = useState(moderateScale(SCREEN_WIDTH - LATERALSMALL * 2));
  const handleLayout = (event: LayoutChangeEvent) => {
    setContainerWidth(event.nativeEvent.layout.width);
  };

  const animatedEvent = Animated.event([{ nativeEvent: { contentOffset: { x: scrollX } } }], {
    useNativeDriver: false,
  });

  const updatePaginationState = (scroll: number, contentWidth: number) => {
    scrollXvalue = scroll;
    setIsFirstPage(scroll <= paginationInterval * 0.15);
    setIsLastPage((prevValue) => prevValue && contentWidth - containerWidth <= scroll + 5);
  };

  const handleScroll = (event: NativeSyntheticEvent<NativeScrollEvent>) => {
    updatePaginationState(
      Math.floor(event.nativeEvent.contentOffset.x),
      Math.floor(event.nativeEvent.contentSize.width)
    );
    animatedEvent(event);
  };

  const scrollTo = (offset: number) => {
    scrollViewRef.current?.scrollToOffset({ offset, animated: true });
  };

  const scrollToNextPage = () => {
    scrollTo(scrollXvalue + containerWidth + bannersGap);
  };

  const scrollToPreviousPage = () => {
    scrollTo(scrollXvalue - containerWidth - bannersGap);
  };

  const titleFontSize = isMobile || isWebResponsive ? 'big' : 'mlarge';
  const gameBannerTitleSize = isMobile || isWebResponsive ? 'medium' : 'lmedium';
  const gamePpalBannerTitleSize = isMobile || isWebResponsive ? 'xbig' : 'xlarge';

  const bannerHasGap = (index: number) => isMobile || index < items.length - 1;

  const getDirectSaleCarouselBanner = (item: any, index: any) => {
    return (
      <View style={[bannerHasGap(index) && { marginRight: bannersGap }, bannerDimentions]}>
        <AddBanner
          analyticsEvent={AnalyticsEvents.AD_CAROUSEL_CLICK}
          bannerDimentions={{
            width: bannerDimentions.width,
            height: bannerDimentions.height,
          }}
          creatorId={0}//T0D0
          isDirectSale={item?.advertising.direct_sale}
          items={item?.advertising ? [
            {
              id: 0,
              type: 'IMAGE',
              order: null,
              advertising: {
                id: item?.advertising.id,
                name: item?.advertising.name,
                imageUrl: item?.advertising.imageUrl,
                siteUrl: item?.advertising.siteUrl,
                platform: item?.advertising.platform,
              },
              game: null,
            },
          ] : []}
        />
      </View>
    )
  };
  const getGoogleAdManagerBanner = (index: any) => {
    if (googleAdManagerUnitId) {
      return (
        <View
          style={[
            bannerHasGap(index) && {
              // flex: 1,
              marginRight: bannersGap,
            },
            bannerDimentions,
          ]}>
          <GoogleAdManagerBanner
            unitId={googleAdManagerUnitId}
          />
        </View>
      );
    }
    return null
  };

  const getGameBanner = (item: any, index: number) => (
    <View style={bannerHasGap(index) && { marginRight: bannersGap }}>
      {customSlide ? (
        customSlide(item)
      ) : (
        <GameBanner
          tag={getTags(item, showTags)}
          tagColor={tagColor}
          tagTextColor={tagTextColor}
          item={might ? item : item?.game}
          dimentions={bannerDimentions}
          ppal={ppal}
          title={
            <CustomText
              align="left"
              numberOfLines={2}
              size={ppal ? gamePpalBannerTitleSize : gameBannerTitleSize}
              weight="interSemi">
              {might ? item.name : item?.game?.name}
            </CustomText>
          }
        />
      )}
    </View>
  );

  const getTags = (item: any, value1?: boolean, value2?: boolean) => {
    if (value1) {
      return i18n.t(item?.game?.genre?.translate);
    }
    if (value2) {
      return i18n.t(item.genre.translate);
    }
    return undefined;
  };

  return (
    <View style={[styles.container, containerStyles]} onLayout={handleLayout}>
      <View style={styles.header}>
        {title && (
          <CustomText customStyle={[styles.title, { ...titleStyles }]} size={titleFontSize} align="left">
            {i18n.t(title)}
          </CustomText>
        )}
        {title && arrows ? (
          <Arrows
            onNext={scrollToNextPage}
            onPrevious={scrollToPreviousPage}
            isFirstPage={isFirstPage}
            isLastPage={isLastPage}
          />
        ) : null}
      </View>
      <Animated.FlatList
        horizontal
        pagingEnabled
        snapToInterval={paginationInterval}
        decelerationRate="fast"
        scrollEventThrottle={16}
        contentContainerStyle={[styles.scrollContainer, { ...innerContainerStyles }]}
        showsHorizontalScrollIndicator={false}
        onScroll={handleScroll}
        ref={scrollViewRef}
        data={items}
        onEndReached={() => setIsLastPage(true)}
        keyExtractor={(item, index) => `${item?.game?.id}-${index}`}
        renderItem={({ item, index }) => {
          if (!item?.game && item?.advertising && directSale) {
            return getDirectSaleCarouselBanner(item, index);
          }
          if (!item?.game && item?.advertising && !directSale) {
            return getGoogleAdManagerBanner(index);
          }


          if (item.isChristmas && !title) {
            return (
              <View style={bannerHasGap(index) && { marginRight: !title ? bannersGap : 0 }}>
                <ChristmasBanner dimensions={bannerDimentions} isPrincipal={ppal} />
              </View>
            );
          }

          return getGameBanner(item, index);
        }}
      />
      {pagination ? (
        <Dots
          scrollX={scrollX}
          quantity={items.length}
          sliderWidth={containerWidth}
          scrollTo={(index) => scrollTo(index * containerWidth)}
        />
      ) : null}
      {!title && arrows ? (
        <Arrows
          onNext={scrollToNextPage}
          onPrevious={scrollToPreviousPage}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
          containerStyle={arrowsStyle ?? styles.bottomArrows}
          isDesktop={!!arrowsStyle}
        />
      ) : null}
    </View>
  );
};

export default Slider;
