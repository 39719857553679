import { SCREEN_WIDTH } from '@gorhom/bottom-sheet';

import { isMobile, isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale, scale } from '@utils/scaling';

export const BANNER_DIMENTIONS = (isMobile || isWebResponsive)
  ? {
      width: (SCREEN_WIDTH - Spacing.LATERALBIG * 2 - moderateScale(16)) / 2,
      height: ((SCREEN_WIDTH - Spacing.LATERALBIG * 2 - moderateScale(16)) / 2) * 1.25,
    }
  : { width: scale(250), height: scale(230) };
