import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { IS_LARGE_DEVICE, SCREEN_HEIGHT } from '@constants/platform';

export const styles = StyleSheet.create({
  header: { flexDirection: 'row', alignItems: 'center', marginBottom: moderateScale(22) },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: moderateScale(12),
  },
  logout: {
    textDecorationLine: 'underline',
  },
  flex: {
    flex: 1,
  },
  between: {
    // backgroundColor: 'red',
    justifyContent: 'space-between',
    marginTop: IS_LARGE_DEVICE ? SCREEN_HEIGHT / 5.25 : SCREEN_HEIGHT / 9,
  },
});
