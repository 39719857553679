import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isDesktop, SCREEN_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: moderateScale(8),
    paddingHorizontal: moderateScale(20),
  },
  avatar: {
    width: moderateScale(40),
    height: moderateScale(40),
    borderRadius: moderateScale(40 / 2),
    marginTop: 0,
  },
  notificationUnreads: {
    position: 'absolute',
    top: -5,
    right: -5,
    borderRadius: 1000,
    minWidth: moderateScale(16),
    minHeight: moderateScale(16),
    backgroundColor: Colors.purple,
    justifyContent: 'center',
    alignItems: 'center',
  },
  skeletonContainer: {
    padding: Spacing.LATERALBIG,
    paddingTop: moderateScale(36),
    ...(isDesktop && {
      paddingHorizontal: 0,
      paddingBottom: 100,
    }),
  },
  sliderSkeleton: {
    flexDirection: 'row',
  },
  ppalSkeleton: {
    marginBottom: 50,
    width: isDesktop ? '100%' : undefined,
  },
  slideSkeleton: {
    marginRight: 20,
  },
  ad: {
    borderRadius: moderateScale(16),
    borderWidth: 2,
    borderColor: Colors.limeGreen,
    height: 90,
    marginBottom: 36,
  },
  adHome: {
    width: SCREEN_WIDTH - Spacing.LATERALPLUS * 2,
    height: 150,
    marginBottom: 36,
  },
  sliderContainer: {
    position: 'relative',
  },
  arrowsStyle: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    paddingHorizontal: '30px',
    pointerEvents: 'none',
  },
  bigAddContainer: {
    maxWidth: '100%',
    marginBottom: Spacing.XLARGE,
  },
  addContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
});
