/* eslint-disable react-native/no-inline-styles */
import * as React from 'react';
import Svg, { SvgProps, Path, G, Mask, Ellipse } from 'react-native-svg';

const Onboarding1 = ({ ...props }: SvgProps) => (
  <Svg width={414} height={261} viewBox="0 0 414 261" fill="none" {...props}>
    <Ellipse cx={14.5843} cy={39.8224} rx={2.70148} ry={15.068} fill="#FCFCFC" />
    <Ellipse
      cx={15.1295}
      cy={39.8226}
      rx={3.22886}
      ry={15.1283}
      transform="rotate(90 15.1295 39.8226)"
      fill="#FCFCFC"
    />
    <Ellipse cx={38.5906} cy={8.61029} rx={1.5437} ry={8.61029} fill="#FCFCFC" />
    <Ellipse
      cx={38.8943}
      cy={8.6102}
      rx={1.84506}
      ry={8.64474}
      transform="rotate(90 38.8943 8.6102)"
      fill="#FCFCFC"
    />
    <Ellipse
      cx={317.435}
      cy={30.4437}
      rx={2.70148}
      ry={15.068}
      transform="rotate(180 317.435 30.4437)"
      fill="#FCFCFC"
    />
    <Ellipse
      cx={316.89}
      cy={30.4435}
      rx={3.22886}
      ry={15.1283}
      transform="rotate(-90 316.89 30.4435)"
      fill="#FCFCFC"
    />
    <Ellipse
      cx={293.429}
      cy={61.6563}
      rx={1.5437}
      ry={8.61029}
      transform="rotate(180 293.429 61.6563)"
      fill="#FCFCFC"
    />
    <Ellipse
      cx={293.125}
      cy={61.6564}
      rx={1.84506}
      ry={8.64474}
      transform="rotate(-90 293.125 61.6564)"
      fill="#FCFCFC"
    />
    <Mask
      id="mask0_6240_72299"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={21}
      y={8}
      width={296}
      height={218}>
      <Mask id="path-9-inside-1_6240_72299" fill="white">
        <Path d="M27.6257 111.796C15.7025 95.7431 23.1073 72.725 42.154 66.6345L219.477 9.93254C233.344 5.49864 248.356 11.9705 254.651 25.0965L313.747 148.304C321.884 165.269 312.063 185.386 293.681 189.403L132.775 224.569C121.573 227.017 109.976 222.673 103.139 213.468L27.6257 111.796Z" />
      </Mask>
      <Path
        d="M27.6257 111.796C15.7025 95.7431 23.1073 72.725 42.154 66.6345L219.477 9.93254C233.344 5.49864 248.356 11.9705 254.651 25.0965L313.747 148.304C321.884 165.269 312.063 185.386 293.681 189.403L132.775 224.569C121.573 227.017 109.976 222.673 103.139 213.468L27.6257 111.796Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth={4.39293}
        mask="url(#path-9-inside-1_6240_72299)"
      />
    </Mask>
    <G mask="url(#mask0_6240_72299)">
      <Path
        d="M224.506 114.86C231.988 110.544 232.379 103.773 225.38 99.7356C218.381 95.6982 206.642 95.924 199.16 100.24C191.678 104.556 191.287 111.327 198.286 115.364C205.285 119.402 217.024 119.176 224.506 114.86Z"
        fill="#BBACED"
        stroke="black"
        strokeWidth={2.19646}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M193.48 109.567C193.48 109.567 197.731 108.091 199.617 106.915C201.502 105.738 202.039 105.163 202.347 104.898C202.653 104.632 205.206 104.924 206.048 105.805C206.891 106.685 206.126 108.405 206.087 109.663C206.049 110.921 205.4 111.38 205.459 112.055C205.518 112.729 208.112 113.698 209.87 113.476C211.629 113.255 212.567 112.714 214.407 113.724C216.244 114.736 218.027 116.326 212.67 118.461"
        stroke="black"
        strokeWidth={2.19646}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M210.959 96.4589C210.959 96.4589 207.379 99.3445 209.257 101.381C211.135 103.418 214.037 101.078 216.774 101.995C219.511 102.913 220.005 104.77 220.607 106.027C221.208 107.285 221.436 108.493 223.079 108.613C224.724 108.735 226.475 107.299 230.48 108.39"
        stroke="black"
        strokeWidth={2.19646}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
    <Mask
      id="mask1_6240_72299"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x={33}
      y={-15}
      width={323}
      height={236}>
      <Mask id="path-13-inside-2_6240_72299" fill="white">
        <Path d="M40.0591 97.6824C27.039 80.222 35.099 55.1348 55.8522 48.525L249.583 -13.1767C264.632 -17.9697 280.914 -10.9544 287.766 3.27531L352.281 137.245C361.169 155.703 350.479 177.618 330.461 181.975L154.644 220.247C142.494 222.891 129.92 218.189 122.487 208.221L40.0591 97.6824Z" />
      </Mask>
      <Path
        d="M40.0591 97.6824C27.039 80.222 35.099 55.1348 55.8522 48.525L249.583 -13.1767C264.632 -17.9697 280.914 -10.9544 287.766 3.27531L352.281 137.245C361.169 155.703 350.479 177.618 330.461 181.975L154.644 220.247C142.494 222.891 129.92 218.189 122.487 208.221L40.0591 97.6824Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-13-inside-2_6240_72299)"
      />
    </Mask>
    <G mask="url(#mask1_6240_72299)">
      <Mask id="path-14-inside-3_6240_72299" fill="white">
        <Path d="M134.857 172.483L279.785 145.907V177.168C279.785 178.855 278.608 180.314 276.959 180.67L132.973 211.741L134.857 172.483Z" />
      </Mask>
      <Path
        d="M134.857 172.483L279.785 145.907V177.168C279.785 178.855 278.608 180.314 276.959 180.67L132.973 211.741L134.857 172.483Z"
        fill="#E5F68C"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-14-inside-3_6240_72299)"
      />
      <Mask id="path-15-inside-4_6240_72299" fill="white">
        <Path d="M136.559 172.108L55.4406 61.0029L53.5213 88.5095C53.3349 91.1798 54.0411 93.8364 55.5287 96.0618L133.336 212.455C133.983 213.423 135.492 212.987 135.522 211.822L136.559 172.108Z" />
      </Mask>
      <Path
        d="M136.559 172.108L55.4406 61.0029L53.5213 88.5095C53.3349 91.1798 54.0411 93.8364 55.5287 96.0618L133.336 212.455C133.983 213.423 135.492 212.987 135.522 211.822L136.559 172.108Z"
        fill="#E5F68C"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-15-inside-4_6240_72299)"
      />
      <Mask id="path-16-inside-5_6240_72299" fill="white">
        <Path d="M55.4559 62.131C53.9964 59.9833 55.2359 57.0447 57.7928 56.5911L188.139 33.468C189.428 33.2394 190.739 33.7321 191.559 34.7532L278.831 143.524C280.507 145.612 279.382 148.734 276.76 149.274L136.908 178.066C135.49 178.357 134.036 177.767 133.223 176.57L55.4559 62.131Z" />
      </Mask>
      <Path
        d="M55.4559 62.131C53.9964 59.9833 55.2359 57.0447 57.7928 56.5911L188.139 33.468C189.428 33.2394 190.739 33.7321 191.559 34.7532L278.831 143.524C280.507 145.612 279.382 148.734 276.76 149.274L136.908 178.066C135.49 178.357 134.036 177.767 133.223 176.57L55.4559 62.131Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-16-inside-5_6240_72299)"
      />
      <Mask id="path-17-inside-6_6240_72299" fill="white">
        <Path d="M151.207 148.532C150.482 146.906 151.413 145.025 153.145 144.616L227.605 127.049C229.826 126.525 232.055 127.899 232.585 130.118V130.118C233.127 132.39 231.678 134.654 229.388 135.114L154.373 150.169C153.066 150.431 151.749 149.751 151.207 148.532V148.532Z" />
      </Mask>
      <Path
        d="M151.207 148.532C150.482 146.906 151.413 145.025 153.145 144.616L227.605 127.049C229.826 126.525 232.055 127.899 232.585 130.118V130.118C233.127 132.39 231.678 134.654 229.388 135.114L154.373 150.169C153.066 150.431 151.749 149.751 151.207 148.532V148.532Z"
        fill="black"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-17-inside-6_6240_72299)"
      />
      <Mask id="path-18-inside-7_6240_72299" fill="white">
        <Path d="M151.177 146.371C150.917 144.098 152.413 141.998 154.647 141.5L189.053 133.823C191.853 133.198 194.574 135.164 194.859 138.017V138.017C195.102 140.446 193.472 142.663 191.08 143.155L156.549 150.262C153.965 150.794 151.476 148.992 151.177 146.371V146.371Z" />
      </Mask>
      <Path
        d="M151.177 146.371C150.917 144.098 152.413 141.998 154.647 141.5L189.053 133.823C191.853 133.198 194.574 135.164 194.859 138.017V138.017C195.102 140.446 193.472 142.663 191.08 143.155L156.549 150.262C153.965 150.794 151.476 148.992 151.177 146.371V146.371Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-18-inside-7_6240_72299)"
      />
      <Mask id="path-19-inside-8_6240_72299" fill="white">
        <Path d="M150.75 143.156C150.535 141.287 151.764 139.56 153.6 139.149L190.12 130.981C191.379 130.7 192.696 131.075 193.619 131.979V131.979C195.784 134.097 194.732 137.764 191.776 138.406L155.205 146.344C153.07 146.808 150.999 145.325 150.75 143.156V143.156Z" />
      </Mask>
      <Path
        d="M150.75 143.156C150.535 141.287 151.764 139.56 153.6 139.149L190.12 130.981C191.379 130.7 192.696 131.075 193.619 131.979V131.979C195.784 134.097 194.732 137.764 191.776 138.406L155.205 146.344C153.07 146.808 150.999 145.325 150.75 143.156V143.156Z"
        fill="#F8F8F8"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-19-inside-8_6240_72299)"
      />
      <Mask id="path-20-inside-9_6240_72299" fill="white">
        <Path d="M80.1803 71.573C78.5357 69.4357 79.7536 66.3163 82.4112 65.8588L176.871 49.5961C178.131 49.3792 179.412 49.8504 180.23 50.8325L226.158 105.927C227.913 108.032 226.75 111.247 224.055 111.744L127.261 129.564C125.94 129.807 124.593 129.29 123.774 128.226L80.1803 71.573Z" />
      </Mask>
      <Path
        d="M80.1803 71.573C78.5357 69.4357 79.7536 66.3163 82.4112 65.8588L176.871 49.5961C178.131 49.3792 179.412 49.8504 180.23 50.8325L226.158 105.927C227.913 108.032 226.75 111.247 224.055 111.744L127.261 129.564C125.94 129.807 124.593 129.29 123.774 128.226L80.1803 71.573Z"
        fill="#E5F68C"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-20-inside-9_6240_72299)"
      />
      <Ellipse cx={151.814} cy={86.582} rx={27.447} ry={15.3471} fill="black" />
      <Mask id="path-22-inside-10_6240_72299" fill="white">
        <Path d="M139.234 59.8667H163.25V78.5529C163.25 85.1847 157.874 90.5609 151.242 90.5609V90.5609C144.611 90.5609 139.234 85.1847 139.234 78.5529V59.8667Z" />
      </Mask>
      <Path
        d="M139.234 59.8667H163.25V78.5529C163.25 85.1847 157.874 90.5609 151.242 90.5609V90.5609C144.611 90.5609 139.234 85.1847 139.234 78.5529V59.8667Z"
        fill="#86983D"
        stroke="black"
        strokeWidth={4.77575}
        mask="url(#path-22-inside-10_6240_72299)"
      />
      <Path
        d="M171.207 46.7933C171.207 57.0832 162.564 65.4938 151.816 65.4938C141.067 65.4938 132.424 57.0832 132.424 46.7933C132.424 36.5034 141.067 28.0929 151.816 28.0929C162.564 28.0929 171.207 36.5034 171.207 46.7933Z"
        fill="#E5F68C"
        stroke="black"
        strokeWidth={2.38787}
      />
    </G>
    <Path
      d="M384.801 211.6C375.901 211.6 365.301 215.9 355.001 220.1C345.601 223.9 336.801 227.5 330.201 227.5C323.801 227.5 319.801 224.6 315.101 221.2C309.801 217.3 303.701 213 292.901 211.6C283.201 210.4 276.801 213.6 258.601 228.8C255.401 231.4 251.901 233.5 248.101 235C243.601 227.1 230.701 220 212.601 216C205.001 214.3 191.301 214.6 186.101 220.6C184.501 222.4 183.001 225.7 184.801 230.6C187.601 237.9 196.101 239.6 203.701 241.1C204.601 241.3 205.501 241.5 206.401 241.6C206.701 241.7 227.601 245.6 246.401 239.1C246.501 239.5 246.601 239.8 246.601 240.2C247.201 245.4 242.301 249 238.101 251.1C219.001 260.7 187.601 257.8 173.601 255C136.001 240.4 76.3014 237.3 63.6014 237.3C41.0014 237.3 22.3014 223.7 20.8014 211.5C19.7014 203 26.8014 196.4 40.7014 192.8L40.9014 192.7C58.9014 185.7 68.8014 186.4 76.7014 187C83.5014 187.5 88.9014 187.9 95.9014 182.6C96.6014 182.1 96.8014 181 96.2014 180.3C95.7014 179.6 94.6014 179.4 93.9014 180C87.9014 184.5 83.5014 184.2 76.9014 183.7C69.1014 183.2 58.5014 182.4 39.8014 189.6C24.4014 193.6 16.2014 201.7 17.5014 211.9C19.2014 225.5 38.6014 240.6 63.6014 240.6C76.2014 240.6 135.401 243.7 172.501 258.1L172.801 258.2C179.701 259.6 190.601 261 202.401 261C215.301 261 229.101 259.3 239.601 254C246.901 250.3 250.501 245.3 249.901 239.7C249.801 239.1 249.701 238.4 249.501 237.8C253.501 236.1 257.301 233.9 260.801 231.1C279.201 215.8 284.601 213.6 292.601 214.6C302.601 215.8 308.001 219.8 313.201 223.6C318.101 227.2 322.801 230.5 330.301 230.5C337.501 230.5 346.701 226.8 356.301 222.8C366.301 218.7 376.601 214.5 384.801 214.5C392.501 214.7 402.501 214.5 413.501 214.2V210.9C402.501 211.6 392.501 211.8 384.801 211.6ZM207.101 238.5C206.201 238.3 205.301 238.1 204.401 237.9C197.701 236.6 190.101 235 187.901 229.4C186.901 226.6 187.101 224.4 188.601 222.7C191.401 219.5 197.801 218.3 203.801 218.3C206.801 218.3 209.701 218.6 212.001 219.1C230.401 223.2 241.201 230 245.101 236.1C227.301 242.1 207.301 238.6 207.101 238.5Z"
      fill="#E5F68C"
    />
  </Svg>
);

export default Onboarding1;
