import React from 'react';

import GameBanner from '@molecules/GameBanner';
import links from '@constants/links';
import { IS_LG, IS_MD, IS_SM, IS_XL } from '@constants/platform';

interface Props {
  dimensions: { width: string | number; height: string | number };
  isPrincipal?: boolean;
}

const showDesktopBanner: boolean = IS_MD || IS_LG || IS_XL;

const ChristmasBanner = ({ dimensions, isPrincipal }: Props): JSX.Element => {
  let image: string = links.christmasMobileBanner;

  if (IS_SM) {
    image = links.christmasTabletBanner;
  }

  if (showDesktopBanner) {
    image = links.christmasDesktopBanner;
  }

  const item: IBannerItems = {
    id: 0,
    principalImage: links.christmasMobileBanner,
    principalImage_web: image,
    link: links.avatarsGatoLink,
  };

  return <GameBanner item={item} dimentions={dimensions} ppal={isPrincipal} />;
};

export default ChristmasBanner;

export interface IBannerItems {
  id: number;
  principalImage: string;
  principalImage_web: string;
  link: string;
}
