import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { EditUserEmailParams, ValidateEditUserEmailParams } from '@interfaces/accountSettings';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';

async function editUserEmailService(data: EditUserEmailParams, token: string) {
  const response = await axiosAuthInstance.put('/users/me/update-mail', data, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useEditUserEmail(token: string) {
  return useMutation((data: EditUserEmailParams) => editUserEmailService(data, token));
}

async function editUserEmailVerifyService(data: ValidateEditUserEmailParams, token: string) {
  const response = await axiosAuthInstance.put('/users/me/update-mail/verify', data, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useEditUserEmailVerify(token: string) {
  return useMutation((data: ValidateEditUserEmailParams) => editUserEmailVerifyService(data, token), {
    onSuccess: () => {
      client.invalidateQueries(QueryKeys.GET_USER_DATA);
    },
  });
}
