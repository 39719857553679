import { StyleSheet } from 'react-native';

import { SCREEN_WIDTH } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH - Spacing.LATERALBIG * 2,
    height: moderateScale(175),
    borderRadius: moderateScale(16),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: moderateScale(12),
    marginBottom: Spacing.BANNERBOTTOM,
    padding: moderateScale(16),
  },
  left: {
    flex: 0.45,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
  },
  button: {
    marginRight: 'auto',
  },
  right: {
    flex: 0.55,
  },
  scrollContent: {
    paddingLeft: Spacing.LATERALBIG,
    paddingRight: Spacing.LATERAL / 3,
  },
  title: {
    letterSpacing: 1,
    lineHeight: 22.5,
  },
});
