import create from 'zustand';

import { FilterCategoryData } from '@interfaces/explore';

interface ModalInfoProps {
  message: string;
  body?: string;
  confirmText: string;
  cancelText: string;
  confirmAction: () => void;
  cancelAction: () => void;
  bottomFixed?: boolean;
}

interface Menu {
  id: number;
  text: string;
  icon: JSX.Element;
  buttonAction: () => void;
}

interface SubMenuModalInfoProps {
  menues: Menu[];
}

interface SearchResultsProps {
  games: [];
  users: [];
}

export interface Store {
  authToken?: string;
  modalOpen: boolean;
  modalSubMenuOpen: boolean;
  notificationsModalOpen: boolean;
  openFilter: boolean;
  tabBarHeight: number;
  totalFilters: number | null;
  totalGames: number | null;
  selectedFilters: FilterCategoryData[] | null;
  modalInfo?: ModalInfoProps;
  subMenuModalInfo?: SubMenuModalInfoProps;
  menuModal?: boolean;
  showAvatarModal: boolean;
  showOnboarding: boolean;
  gameId: number;
  searchResults: SearchResultsProps;
  toggleModal: () => void;
  toggleSubMenuModal: () => void;
  toggleNotificationsModal: () => void;
  toggleMenuModal: () => void;
  setTabBarHeight: (data: number) => void;
  setAuthToken: (data: string | undefined) => void;
  setTotalGames: (data: number | null) => void;
  setOpenFilter: (data: boolean) => void;
  setSelectedFilters: (data: FilterCategoryData[]) => void;
  setModalInfo: (data?: ModalInfoProps) => void;
  setSubMenuModalInfo: (data?: SubMenuModalInfoProps) => void;
  setShowAvatarModal: () => void;
  setShowOnboarding: () => void;
  setGameId: (data: number | undefined) => void;
  setSearchResults: (data: SearchResultsProps) => void;
}

export const useStore = create<Store>((set) => ({
  modalOpen: false,
  modalSubMenuOpen: false,
  notificationsModalOpen: false,
  menuModal: false,
  openFilter: false,
  tabBarHeight: 0,
  totalFilters: null,
  totalGames: 0,
  selectedFilters: null,
  showAvatarModal: false,
  showOnboarding: false,
  gameId: 0,
  searchResults: {
    games: [],
    users: [],
  },
  toggleModal: () => set((state) => ({ ...state, modalOpen: !state.modalOpen })),
  toggleSubMenuModal: () => set((state) => ({ ...state, modalSubMenuOpen: !state.modalSubMenuOpen })),
  toggleNotificationsModal: () =>
    set((state) => ({ ...state, notificationsModalOpen: !state.notificationsModalOpen })),
  toggleMenuModal: () => set((state) => ({ ...state, menuModal: !state.menuModal })),
  setTotalGames: (data) => set((state) => ({ ...state, totalGames: data })),
  setAuthToken: (data) => set((state) => ({ ...state, authToken: data })),
  setTabBarHeight: (data) => set((state) => ({ ...state, tabBarHeight: data })),
  setSelectedFilters: (data) =>
    set((state) => ({ ...state, selectedFilters: data, totalFilters: data.length })),
  setOpenFilter: (data) => set((state) => ({ ...state, openFilter: data })),
  setModalInfo: (data) => set((state) => ({ ...state, modalInfo: data })),
  setSubMenuModalInfo: (data) => set((state) => ({ ...state, subMenuModalInfo: data })),
  setShowAvatarModal: () => set((state) => ({ ...state, showAvatarModal: !state.showAvatarModal })),
  setShowOnboarding: () => set((state) => ({ ...state, showOnboarding: !state.showOnboarding })),
  setGameId: (data) => set((state) => ({ ...state, gameId: data })),
  setSearchResults: (data) => set((state) => ({ ...state, searchResults: data })),
}));

// Selectors
export const openFilterSelector = (state: Store) => state.openFilter;
export const selectedFiltersSelector = (state: Store) => state.selectedFilters;
export const totalGamesSelector = (state: Store) => state.totalGames;
export const totalFiltersSelector = (state: Store) => state.totalFilters;
export const tabBarHeightSelector = (state: Store) => state.tabBarHeight;
export const modalInfoSelector = (state: Store) => state.modalInfo;
export const subMenuModalInfoSelector = (state: Store) => state.subMenuModalInfo;
export const menuModalSelector = (state: Store) => state.menuModal;
export const modalOpenSelector = (state: Store) => state.modalOpen;
export const modalSubMenuOpenSelector = (state: Store) => state.modalSubMenuOpen;
export const notificationsModalOpenSelector = (state: Store) => state.notificationsModalOpen;
export const authTokenSelector = (state: Store) => state.authToken;
export const showAvatarSelector = (state: Store) => state.showAvatarModal;
export const showOnboardingSelector = (state: Store) => state.showOnboarding;
export const gameIdSelector = (state: Store) => state.gameId;
export const searchResultsSelector = (state: Store) => state.searchResults;

// Handlers
export const setOpenFilterHandler = (state: Store) => state.setOpenFilter;
export const setSelectedFiltersHandler = (state: Store) => state.setSelectedFilters;
export const setTotalGamesHandler = (state: Store) => state.setTotalGames;
export const setTabBarHeightHandler = (state: Store) => state.setTabBarHeight;
export const setModalInfoHandler = (state: Store) => state.setModalInfo;
export const setSubMenuModalInfoHandler = (state: Store) => state.setSubMenuModalInfo;
export const toggleMenuModalHandler = (state: Store) => state.toggleMenuModal;
export const toggleModalHandler = (state: Store) => state.toggleModal;
export const toggleSubMenuModalHandler = (state: Store) => state.toggleSubMenuModal;
export const toggleNotificationsModalHandler = (state: Store) => state.toggleNotificationsModal;
export const setAuthTokenHandler = (state: Store) => state.setAuthToken;
export const setShowAvatarModalHandler = (state: Store) => state.setShowAvatarModal;
export const setShowOnboardingHandler = (state: Store) => state.setShowOnboarding;
export const setGameIdHandler = (state: Store) => state.setGameId;
export const setSearchResultsHandler = (state: Store) => state.setSearchResults;
