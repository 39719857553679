import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
const MenuIcon = (props: SvgProps) => (
  <Svg width={21} height={21} fill="none" {...props}>
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      d="M5.744 4.875a.625.625 0 1 0 0 1.25h10a.625.625 0 1 0 0-1.25h-10Zm0 3.333a.625.625 0 1 0 0 1.25h10a.625.625 0 1 0 0-1.25h-10Zm-.625 3.959c0-.345.28-.625.625-.625h10a.625.625 0 1 1 0 1.25h-10a.625.625 0 0 1-.625-.625Zm.625 2.708a.625.625 0 1 0 0 1.25h10a.625.625 0 1 0 0-1.25h-10Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default MenuIcon;
