import React from "react";
import { View } from "react-native";
import CustomText from "@atoms/CustomText";
import { isIos } from "@constants/platform";
import { useTranslations } from "@hooks/useTranslation";
import { BlurView } from 'expo-blur';
import SafeIcon from '@assets/svg/SafeIcon';
import ViolentIcon from '@assets/svg/ViolentIcon';
import { styles } from './styles'

export const SensitiveMaterial = ({ violent }: { violent?: boolean }) => {
    const i18n = useTranslations();
    const Back = isIos ? BlurView : View;
  
    return (
      <Back intensity={3} style={styles.violent}>
        {violent ? <ViolentIcon /> : <SafeIcon />}
        <CustomText customStyle={styles.violentText} size="msmall" weight="interBold">
          {violent ? i18n.t('game_page.violent_scenes') : i18n.t('game_page.safe')}
        </CustomText>
      </Back>
    );
  };