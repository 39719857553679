import { Platform, StyleSheet } from 'react-native';

import { IS_SMALL_DEVICE, SCREEN_HEIGHT, SCREEN_WIDTH } from '@constants/platform';
import { Colors, Fonts } from '@theme';
import { moderateScale, verticalScale } from '@utils/scaling';
import { LATERALPLUS } from 'app/theme/spacing';

const DOT_HEIGHT = 4;
const isWeb = Platform.OS === 'web';
const DOT_WIDTH = !isWeb ? SCREEN_WIDTH / 2 - LATERALPLUS : '100%';
export const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: verticalScale(25),
  },
  image1: {
    width: !isWeb ? SCREEN_WIDTH - 10 : '100%',
    height: IS_SMALL_DEVICE ? SCREEN_HEIGHT / 2.2 : SCREEN_HEIGHT / 2.5,
    marginLeft: !isWeb ? 20 : undefined,
  },
  image2: {
    width: !isWeb ? SCREEN_WIDTH : 450,
    maxWidth: '100%',
    height: IS_SMALL_DEVICE ? SCREEN_HEIGHT / 2.2 : SCREEN_HEIGHT / 2.5,
    alignSelf: 'center',
  },
  bottom: {
    paddingHorizontal: LATERALPLUS,
  },
  btnTop: {
    marginBottom: verticalScale(26),
    width: '100%',
  },
  btnBottom: {
    marginBottom: verticalScale(11),
  },
  buttonText: {
    fontSize: Fonts.SIZES.xMedium,
  },
  itemContainer: {
    width: !isWeb ? SCREEN_WIDTH + 5 : 'calc(100% + 5)',
  },
  itemText: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: moderateScale(18),
    paddingHorizontal: LATERALPLUS * 2,
    marginVertical: verticalScale(50),
  },
  title: {
    lineHeight: moderateScale(26),
    marginBottom: moderateScale(16),
    paddingHorizontal: IS_SMALL_DEVICE ? 0 : LATERALPLUS,
  },
  description: {
    paddingHorizontal: IS_SMALL_DEVICE ? 0 : moderateScale(20),
  },
  login: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
  underline: {
    textDecorationLine: 'underline',
  },
  dots: {
    marginTop: moderateScale(40),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingHorizontal: moderateScale(20),
    marginBottom: moderateScale(10),
    width: '100%',
  },
  dotContainer: { width: Platform.OS !== 'web' ? undefined : '50%' },
  dot: {
    width: DOT_WIDTH,
    height: DOT_HEIGHT,
    borderRadius: moderateScale(50),
    backgroundColor: Colors.whiteOpacity(0.15),
  },
});
