import { StyleSheet } from 'react-native';

import { isDesktop, isMobile, isWebResponsive, WINDOW_WIDTH } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale, scale } from '@utils/scaling';

import { BANNER_DIMENTIONS } from './constants';

const TOTAL_FILTER_DIMENTIONS = moderateScale(29);
const BANNERS_PER_ROW = Math.min(Math.max(Math.floor((WINDOW_WIDTH * 0.55) / BANNER_DIMENTIONS.width), 1), 3);

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: moderateScale(24),
  },
  skeletonContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: (isMobile || isWebResponsive) ? 'space-between' : 'flex-start',
    marginTop: (isMobile || isWebResponsive) ? moderateScale(24) : 0,
    ...(isDesktop && !isWebResponsive && {
      width: (BANNER_DIMENTIONS.width + Spacing.SMALL) * BANNERS_PER_ROW,
      maxWidth: '100%',
      marginRight: -Spacing.SMALL,
    }),
  },
  skeletonItem: {
    marginBottom: moderateScale(16),
    zIndex: isWebResponsive ? -1 : undefined,
    ...(isDesktop && !isWebResponsive && {
      marginRight: Spacing.SMALL,
    }),
  },
  search: {
    zIndex: 1
  },
  filterButton: {
    alignSelf: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingVertical: moderateScale(11),
    paddingHorizontal: moderateScale(16),
    backgroundColor: Colors.blackTwo,
    borderRadius: moderateScale(12),
  },
  filterText: {
    marginLeft: moderateScale(5),
  },
  filterTotal: {
    width: TOTAL_FILTER_DIMENTIONS,
    height: TOTAL_FILTER_DIMENTIONS,
    borderRadius: TOTAL_FILTER_DIMENTIONS / 2,
    backgroundColor: Colors.purple,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: moderateScale(5),
  },
  emptyState: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: moderateScale(20),
  },
  emptyStateDesktop: {
    flex: 1,
    height: moderateVerticalScale(600),
  },
  emptyTitle: {
    marginTop: moderateScale(24),
    marginBottom: moderateScale(16),
  },
  responsiveTitle: {
    marginBottom: moderateScale(16)
  },
  title: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  goBack: {
    marginRight: moderateScale(8),
    transform: [{ scale: 0.8 }],
  },
  searchGame: {
    width: '100%',
    marginVertical: moderateScale(16)
  },
  desktopLayoutContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flex: 1,
    overflow: 'visible',
    alignItems: isWebResponsive ? 'center' : 'flex-start',
    paddingBottom: Spacing.XXLARGE,
    paddingHorizontal: isWebResponsive ? Spacing.SMALL : undefined,
  },
  desktopFiltersContainer: {
    paddingRight: moderateScale(Spacing.BIG),
    maxWidth: scale(330),
    overflow: 'visible',
    flex: BANNERS_PER_ROW > 1 ? undefined : 1,
  },
  desktopVerticalSeparator: {
    flex: 1,
  },
  desktopResultsContainer: {
    justifyContent: 'flex-start',
    alignItems: BANNERS_PER_ROW === 3 ? 'flex-end' : 'center',
    flex: 1,
  },
  filtersHeader: {
    height: moderateScale(60),
    marginBottom: Spacing.SMALL,
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    paddingBottom: moderateScale(16),
  },
});
