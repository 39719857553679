import { UIManager, LayoutAnimation, LayoutAnimationConfig } from 'react-native';

import { isAndroid } from '@constants/platform';

if (isAndroid && UIManager.setLayoutAnimationEnabledExperimental) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

export const revealConfig: LayoutAnimationConfig = {
  duration: 300,
  create: {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
    // springDamping: 0.7,
  },
};

const customLayoutSpring = {
  duration: 550,
  create: {
    type: LayoutAnimation.Types.spring,
    property: LayoutAnimation.Properties.opacity,
    springDamping: 0.7,
  },
  update: {
    type: LayoutAnimation.Types.spring,
    springDamping: 0.7,
  },
};

export const animateLayout = (customLayoutConfig?: LayoutAnimationConfig) => {
  LayoutAnimation.configureNext(customLayoutConfig || customLayoutSpring);
};
