import { StyleSheet } from 'react-native';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  goBack: {
    position: 'absolute',
    top: -36,
    left: moderateScale(36),
    zIndex: 3,
  },
  container: {
    display: 'flex',
    alignSelf: 'center',
    paddingVertical: moderateScale(48)
  }
});
