import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';

export const styles = StyleSheet.create({
  drawerContainer: {
    backgroundColor: Colors.black,
    padding: 20,
  },
  drawerTitle: {
    marginBottom: Spacing.SMALL,
  },
  drawerItem: {
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 4,
    marginHorizontal: 0,
    pointerEvents: 'none',
  },
  drawerItemDisable: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
});
