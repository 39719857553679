import React from 'react';
import { Linking, Pressable, View } from 'react-native';

import UpdateAppIcon from '@assets/svg/UpdateAppIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { isIos } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';

import { styles } from './styles';

const STORE_LINK = isIos
  ? 'https://apps.apple.com/ar/app/id6444835134'
  : 'https://play.google.com/store/apps/details?id=com.gatogames.app';

interface UpdateAppProps {
  handleDismiss: () => void;
}

const UpdateApp = ({ handleDismiss }: UpdateAppProps) => {
  const i18n = useTranslations();

  const handleUpdateApp = () => Linking.openURL(STORE_LINK);

  return (
    <View style={styles.container}>
      <CustomText color="limeGreen" customStyle={[styles.textError]} size="xlarge" weight="interBold">
        {i18n.t('update_app.title')}
      </CustomText>
      <CustomText customStyle={[styles.textError]} size="big" weight="interMedium">
        {i18n.t('update_app.subtitle')}
      </CustomText>
      <UpdateAppIcon />
      <Button customStyle={styles.button} onPress={handleUpdateApp}>
        {i18n.t('update_app.button_action')}
      </Button>
      <Pressable onPress={handleDismiss}>
        <CustomText weight="interBold" color="greySix" customStyle={styles.dismiss}>
          {i18n.t('update_app.dismiss')}
        </CustomText>
      </Pressable>
    </View>
  );
};

export default UpdateApp;
