import React from 'react';
import { View } from 'react-native';

import { styles } from './styles';

interface ItemDividerProps {
  fullWidth?: boolean;
}

const ItemDivider = ({ fullWidth }: ItemDividerProps) => (
  <View style={[styles.container, fullWidth && styles.fullWidth]} />
);

export default ItemDivider;
