import { Root } from "./game";

export interface GameEventInterface {
  id: number;
  game: Root;
  event: EventInterface;
}

export interface EventInterface {
  id: number;
  name: EventNameEnum;
}

export enum EventNameEnum {
  GATO_GAME_QUEST = 'GATO_GAME_QUEST',
}