import React from 'react';
import { View } from 'react-native';

import { styles } from './styles';

type Props = {
  porcetange: number;
};

const Progressbar = ({ porcetange }: Props) => (
  <View style={styles.container}>
    <View style={[styles.progress, styles.progressbarEmpty]} />
    <View style={[styles.progress, styles.progressPorcentage, { width: `${porcetange}%` }]} />
  </View>
);

export default Progressbar;
