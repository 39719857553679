import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

function StarSecond({ fill, ...props }: SvgProps) {
    return (
        <Svg
            width={16}
            height={16}
            viewBox="0 0 16 16"
            fill="none"
            {...props}
        >
            <Path
                d="M8 0l2.161 5.84L16.001 8l-5.84 2.16L8.001 16 5.84 10.16 0 8l5.84-2.16L8 0z"
                fill="#fff"
            />
        </Svg>
    )
}

export default StarSecond