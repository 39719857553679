import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';

export const styles = StyleSheet.create({
  drawer: {
    backgroundColor: Colors.black,
    borderRightWidth: 0,
    paddingLeft: Spacing.XLARGE,
    paddingTop: Spacing.LARGE,
    paddingRight: Spacing.BIG,
    paddingBottom: 0,
    width: 385,
    maxWidth: '43%',
  },
  drawerTitle: {
    marginBottom: Spacing.BIG,
  },
  drawerItem: {
    borderRadius: 16,
    paddingHorizontal: 16,
    paddingVertical: 4,
    marginHorizontal: 0,
  },
});
