import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AccountSettingsIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.744 2.375h1.512c.834 0 1.511.638 1.511 1.425 0 .9.964 1.473 1.755 1.043l.08-.044c.723-.394 1.647-.16 2.065.521l.755 1.235c.418.681.17 1.553-.553 1.946a1.137 1.137 0 000 1.998c.723.393.97 1.265.553 1.946l-.755 1.235c-.418.681-1.342.915-2.065.521l-.08-.044a1.187 1.187 0 00-1.755 1.043c0 .787-.677 1.425-1.511 1.425H8.744c-.834 0-1.511-.638-1.511-1.425 0-.9-.964-1.473-1.755-1.043l-.08.044c-.723.394-1.647.16-2.065-.521l-.755-1.235c-.418-.681-.17-1.553.553-1.946a1.137 1.137 0 000-1.998c-.723-.393-.97-1.265-.553-1.946l.755-1.235c.418-.681 1.342-.915 2.065-.521l.08.044A1.187 1.187 0 007.233 3.8c0-.787.677-1.425 1.511-1.425zm.756 9.5a2.375 2.375 0 100-4.75 2.375 2.375 0 000 4.75z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default AccountSettingsIcon;
