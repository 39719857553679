import React from 'react';
import { Pressable } from 'react-native';

import MenuIcon from '@assets/svg/MenuIcon';
import CloseIcon from '@assets/svg/CloseIcon';
import { Colors } from '@theme';
import { useStore, toggleMenuModalHandler, menuModalSelector } from '@store/index';

import { styles } from './styles';

const MenuButton = () => {
  const menuModalOpen = useStore(menuModalSelector);
  const toggleMenu = useStore(toggleMenuModalHandler);

  return (
    <Pressable
      hitSlop={20}
      style={[styles.menuButton, menuModalOpen && styles.activeMenu]}
      onPress={() => toggleMenu()}>
      {menuModalOpen ? <CloseIcon fill={Colors.limeGreen} /> : <MenuIcon />}
    </Pressable>
  );
};

export default MenuButton;
