import * as React from 'react';
import Svg, { SvgProps, Rect, Circle, Path } from 'react-native-svg';

const PasswordInputIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={22} height={23} fill="none" {...props}>
    <Rect
      x={3.666}
      y={6.917}
      width={14.667}
      height={12.833}
      rx={3.667}
      stroke={fill || '#777E91'}
      strokeWidth={1.375}
    />
    <Circle cx={10.999} cy={13.333} r={1.833} stroke={fill || '#777E91'} strokeWidth={1.375} />
    <Path
      d="M13.979 6.917a.688.688 0 001.375 0h-1.375zM8.119 3.65a.688.688 0 10.91 1.031l-.91-1.03zm2.88.287a2.98 2.98 0 012.98 2.979h1.375A4.354 4.354 0 0011 2.563v1.374zm-1.97.744A2.965 2.965 0 0111 3.937V2.563A4.34 4.34 0 008.12 3.65l.91 1.031z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default PasswordInputIcon;
