import { CommonActions, useNavigation } from '@react-navigation/core';
import React, { useEffect, useState } from 'react';
import { Pressable, View, ImageBackground } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import { useTranslation } from 'react-i18next';

import { Layout } from '@organisms/Layout';
import CustomText from '@atoms/CustomText';
import { RootStackNavigator } from 'app/navigation/types';
import { searchResultsSelector, useStore } from '@store/index';
import { AvatarProfile } from '@atoms/AvatarProfile';
import VerifiedIcon from '@assets/svg/VerifiedIcon';
import GoBackButton from '@molecules/GoBackButton';
import { isMobile } from '@constants/platform';
import { useMyProfile } from '@services/useMyProfile';
import { getGameSlug } from '@utils/common';

import { styles } from './styles';

const Game = ({ item }) => {
  const navigation = useNavigation();
  const { id, title: name, genre, image: principalImage } = item;

  const handlePress = () => {
    if (isMobile) {
      const navigateAction = CommonActions.navigate({
        name: 'GamePage',
        params: {
          game: item,
        },
        key: `GamePage-${item?.id}`,
      });

      return navigation.dispatch(navigateAction);
    }

    const gameSlug = getGameSlug(name);

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: gameSlug,
      },
      key: `GamePage/${gameSlug}`,
    });
    return navigation.dispatch(navigateAction);
  };

  return (
    <Pressable onPress={handlePress} key={id}>
      <ImageBackground style={[styles.item, isMobile && styles.itemMobile]} source={{ uri: principalImage }}>
        <View style={styles.genreContainer}>
          <CustomText
            size="xsmall"
            color="darkGrey"
            weight="interSemi"
            customStyle={styles.genre}
            align="left">
            {genre}
          </CustomText>
        </View>
        <LinearGradient
          start={{ x: 1, y: 0 }}
          end={{ x: 1, y: 1 }}
          colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.5)']}
          style={styles.gradient}
        />
        <CustomText size="xmedium" weight="interSemi" customStyle={styles.text} align="left">
          {name}
        </CustomText>
      </ImageBackground>
    </Pressable>
  );
};

const Profile = ({ item }) => {
  const navigation = useNavigation();
  const { id, title: name, username, image, type } = item;
  const { data: profileData } = useMyProfile();
  const isOwnProfile = profileData?.id === id;

  const userIsCreator = type === 'CREATOR';

  const handlePress = () => {
    if (isOwnProfile) {
      if (isMobile) {
        return navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Main',
              state: {
                routes: [
                  {
                    name: 'MyProfile',
                  },
                ],
              },
            },
          ],
        });
      }

      return navigation.navigate('MyProfile');
    }

    if (isMobile) {
      const userId = item?.id;
      const navigateAction = CommonActions.navigate({
        name: 'UserProfile',
        params: {
          userId,
        },
        key: `UserProfile-${userId}`,
      });
      return navigation.dispatch(navigateAction);
    }

    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName: username,
      },
      key: `UserProfile-${username}`,
    });
    return navigation.dispatch(navigateAction);
  };

  const nameIsLong = name.split(' ')?.length >= 4;

  return (
    <Pressable
      onPress={handlePress}
      style={[styles.profileContainer, isMobile && styles.profileContainerMobile]}
      key={id}>
      <View>
        <AvatarProfile
          customContainerStyle={styles.avatar}
          imageUrl={image ?? ''}
          imageUrlPlaceholder={image}
          offsetKitten={5}
        />
        {userIsCreator && <VerifiedIcon style={styles.verified} />}
      </View>
      <View style={styles.textContainer}>
        <CustomText
          size={nameIsLong ? 'medium' : 'lmedium'}
          weight="interSemi"
          customStyle={styles.text}
          align="center">
          {name}
        </CustomText>
        <CustomText size="xsmall" color="greySix" weight="interSemi" customStyle={styles.text} align="center">
          @{username}
        </CustomText>
      </View>
    </Pressable>
  );
};

const FILTER_OPTIONS = {
  all: 'all',
  games: 'games',
  profiles: 'profiles',
};

type SearchProps = NativeStackScreenProps<RootStackNavigator, 'Search'>;

export const Search = ({ route, navigation }: SearchProps) => {
  const i18n = useTranslation();
  const searchResults = useStore(searchResultsSelector);
  const [optionSelected, setOptionSelect] = useState(FILTER_OPTIONS.all);

  const search = route?.params?.search;
  const { games, users } = searchResults;
  const totalItems = games && users ? (games?.length || 0) + (users?.length || 0) : 0;

  const redirectToHome = () => {
    const homeRoute = [{ name: 'Home' as const }];

    const mainNavRouter = [
      {
        name: 'Main' as const,
        state: {
          routes: homeRoute,
        },
      },
    ];

    navigation.reset({
      index: 0,
      routes: isMobile ? mainNavRouter : homeRoute,
    });
  };

  const handleGoBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      redirectToHome();
    }
  };

  useEffect(() => {
    if (!totalItems) {
      redirectToHome();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems]);

  return (
    <Layout padding withScroll showScrollIndicator={false}>
      <View style={styles.container}>
        <View style={styles.headerContainer}>
          <GoBackButton goBackAction={handleGoBack} gatoLogo={false} />
          <View style={styles.texts}>
            <CustomText color="greySix" size="xmedium" weight="interMedium" align="left">
              {i18n.t("search.results")}
            </CustomText>
            <CustomText color="white" size="mbig" weight="interSemi" align="left">
              {search}
            </CustomText>
          </View>
        </View>

        <View style={styles.filters}>
          <Pressable
            style={[styles.filter, optionSelected === FILTER_OPTIONS.all && styles.filterSelected]}
            onPress={() => setOptionSelect(FILTER_OPTIONS.all)}>
            <CustomText
              size="medium"
              color={optionSelected === FILTER_OPTIONS.all ? 'limeGreen' : 'greySix'}
              weight="interSemi"
              align="left">
              {i18n.t('search.all')} ({totalItems ?? 0})
            </CustomText>
          </Pressable>
          <Pressable
            style={[styles.filter, optionSelected === FILTER_OPTIONS.games && styles.filterSelected]}
            onPress={() => setOptionSelect(FILTER_OPTIONS.games)}>
            <CustomText
              size="medium"
              color={optionSelected === FILTER_OPTIONS.games ? 'limeGreen' : 'greySix'}
              weight="interSemi"
              align="left">
              {i18n.t('search.games')} ({games?.length ?? 0})
            </CustomText>
          </Pressable>
          <Pressable
            style={[styles.filter, optionSelected === FILTER_OPTIONS.profiles && styles.filterSelected]}
            onPress={() => setOptionSelect(FILTER_OPTIONS.profiles)}>
            <CustomText
              size="medium"
              color={optionSelected === FILTER_OPTIONS.profiles ? 'limeGreen' : 'greySix'}
              weight="interSemi"
              align="left">
              {i18n.t('search.profiles')} ({users?.length ?? 0})
            </CustomText>
          </Pressable>
        </View>

        {games && games?.length > 0 && optionSelected !== FILTER_OPTIONS.profiles && (
          <>
            <CustomText size="big" color="greySix" weight="interBold" customStyle={styles.title} align="left">
              {i18n.t('search.games')}
            </CustomText>
            <View style={styles.list}>
              {games?.map((item) => (
                <Game item={item} />
              ))}
            </View>
          </>
        )}
        {users && users?.length > 0 && optionSelected !== FILTER_OPTIONS.games && (
          <>
            <CustomText size="big" color="greySix" weight="interBold" customStyle={styles.title} align="left">
              {i18n.t('search.profiles')}
            </CustomText>
            <View style={styles.list}>
              {users?.map((item) => (
                <Profile item={item} />
              ))}
            </View>
          </>
        )}
      </View>
    </Layout>
  );
};
