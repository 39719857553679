import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const VerifiedIcon = ({ ...props }: SvgProps) => (
  <Svg width={15} height={15} fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_3505_65212)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 13.75a6.25 6.25 0 100-12.5 6.25 6.25 0 000 12.5z"
        fill="#387CFF"
        stroke="#FFF7F7"
        strokeWidth={0.7}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M5.52 7.138a.804.804 0 10-1.04 1.226l1.04-1.226zm.66 1.613l-.519.613.52-.613zm.917-.08l-.612-.52.612.52zM10.3 6.147a.804.804 0 00-1.225-1.042L10.3 6.146zM4.48 8.364l1.181 1L6.7 8.138l-1.18-1-1.04 1.226zm3.229.828l2.59-3.046-1.224-1.042-2.59 3.047 1.224 1.041zm-2.048.172c.621.526 1.528.44 2.048-.172l-1.224-1.04a.161.161 0 01.215-.013L5.661 9.363z"
        fill="#FCFCFC"
      />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_3505_65212">
        <Path fill="#fff" d="M0 0h15v15H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default VerifiedIcon;
