import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { DeleteTemporalAccountParams } from '@interfaces/auth';

async function deleteTemporalAccountService(data: DeleteTemporalAccountParams) {
  const response = await axiosAuthInstance.delete('/users/me/offline', {
    data,
  });
  return response.data;
}

export function useDeleteTemporalAccount() {
  return useMutation((data: DeleteTemporalAccountParams) => deleteTemporalAccountService(data));
}
