import * as React from 'react';
import Svg, { SvgProps, Path, Ellipse } from 'react-native-svg';

const GatoAlien = (props: SvgProps) => (
  <Svg width={143} height={173} fill="none" {...props}>
    <Path
      d="M53.744 25.363L8.32 140.843a6.46 6.46 0 00.18 5.171c.78 1.639 2.212 2.937 4 3.627 10.536 4.079 33.14 11.172 62.933 11.172 29.794 0 52.391-7.093 62.935-11.172 1.789-.69 3.221-1.989 3.999-3.627a6.465 6.465 0 00.18-5.171L97.121 25.363H53.744z"
      fill="#5E5E5E"
      fillOpacity={0.85}
    />
    <Path
      d="M27.562 40.02a4.233 4.233 0 004.228-4.239 4.233 4.233 0 00-4.228-4.237 4.233 4.233 0 00-4.228 4.237 4.233 4.233 0 004.228 4.238zM123.366 40.02a4.233 4.233 0 004.228-4.239 4.233 4.233 0 00-4.228-4.237 4.233 4.233 0 00-4.228 4.237 4.233 4.233 0 004.228 4.238zM75.466 46.98a4.233 4.233 0 004.228-4.239 4.233 4.233 0 00-4.228-4.238 4.233 4.233 0 00-4.228 4.238 4.233 4.233 0 004.228 4.238z"
      fill="#7E7E7E"
    />
    <Path
      d="M132.095 27.565c0 7.92-22.951 14.34-56.869 14.34-33.917 0-56.382-6.42-56.382-14.34s22.452-14.337 56.372-14.337 56.879 6.42 56.879 14.337z"
      fill="#8B889A"
      fillOpacity={0.97}
    />
    <Path
      d="M132.095 22.333c0 7.918-22.951 14.34-56.869 14.34-33.917 0-56.371-6.422-56.371-14.34s22.452-14.34 56.371-14.34c33.92 0 56.869 6.422 56.869 14.34z"
      fill="#fff"
      opacity={0.42}
    />
    <Path
      d="M75.521 27.146c9.691 0 18.8-1.168 26.727-3.223-1.725-12.697-13.521-22.52-27.817-22.52-14.08 0-25.734 9.53-27.731 21.952 8.403 2.406 18.269 3.791 28.821 3.791z"
      fill="#8B889A"
      fillOpacity={0.97}
    />
    <Path
      d="M90.249 13.436c.642-2.55-3.566-5.814-9.4-7.29-5.833-1.474-11.082-.603-11.724 1.948-.642 2.55 3.567 5.814 9.4 7.289 5.833 1.475 11.082.603 11.724-1.947z"
      fill="#fff"
      opacity={0.42}
    />
    <Path
      opacity={0.7}
      d="M124.41 54.8a2.604 2.604 0 00-3.391-1.448 2.614 2.614 0 00-1.444 3.398l11.204 27.896a2.604 2.604 0 003.391 1.447 2.614 2.614 0 001.444-3.398L124.41 54.799zM13.479 108.285c.639-1.554.078-3.259-1.252-3.809-1.331-.55-2.928.263-3.567 1.817l-6.788 16.499c-.64 1.553-.079 3.259 1.252 3.809 1.33.55 2.927-.264 3.567-1.817l6.788-16.499z"
      fill="#C2C0CE"
    />
    <Path
      d="M69.713 119.454c.23 15.202-1.876 21.257-4.737 22.25-6.759 2.585-4.295-16.14-3.97-20.495 0 0 8.477-16.957 8.707-1.755zM51.46 115.092c1.351 15.144-.302 21.338-3.082 22.541-6.548 3.079-5.473-15.778-5.47-20.146 0 0 7.202-17.54 8.553-2.395zM99.114 54.767a7.371 7.371 0 012.134 3.356c.411 1.364.401 2.953-.141 4.242-.684 1.62-2.061 2.533-3.087 3.828-1.235 1.558-1.948 3.686-2.3 5.852-.351 2.166-.371 4.394-.386 6.607-.034 5.144-.073 10.288-.107 15.427-.01 1.604-.098 3.424-1.065 4.462-.991 1.06-2.588.776-3.55-.325-.962-1.101-1.377-2.8-1.46-4.441-.083-1.642.132-3.283.205-4.924.337-7.457-2.1-14.672-2.457-22.128-.166-3.482.186-7.19 1.851-9.963 1.172-1.95 2.955-3.288 4.864-3.655 1.91-.367 3.927.241 5.5 1.662z"
      fill="#000"
    />
    <Path
      d="M94.433 98.819c1.06-1.138 1.162-3.047 1.172-4.746l.107-15.427c.015-2.16.03-4.394.376-6.538.38-2.355 1.148-4.31 2.217-5.663.342-.43.733-.829 1.114-1.212.761-.77 1.553-1.573 2.031-2.71.552-1.312.611-3.005.156-4.526a7.822 7.822 0 00-2.25-3.54c-1.642-1.483-3.8-2.139-5.802-1.75-2.017.387-3.878 1.782-5.109 3.832-1.475 2.454-2.114 5.884-1.91 10.2.157 3.224.704 6.444 1.236 9.558.699 4.09 1.416 8.317 1.226 12.527-.024.52-.058 1.038-.098 1.558-.078 1.111-.16 2.26-.107 3.403.068 1.41.396 3.366 1.558 4.693.61.697 1.475 1.117 2.305 1.117a2.38 2.38 0 001.778-.782v.005zm4.443-43.748a6.991 6.991 0 012.022 3.173c.401 1.337.357 2.815-.122 3.953-.42.991-1.118 1.704-1.86 2.46-.396.398-.801.812-1.168 1.274-1.152 1.457-1.977 3.55-2.383 6.04-.361 2.208-.376 4.478-.39 6.67l-.108 15.427c-.01 1.541-.093 3.261-.952 4.184a1.676 1.676 0 01-1.255.546c-.63 0-1.29-.326-1.763-.866-.787-.896-1.27-2.386-1.358-4.19-.054-1.09.025-2.217.103-3.303.039-.53.073-1.054.097-1.583.196-4.3-.532-8.574-1.235-12.71-.528-3.09-1.07-6.283-1.221-9.46-.2-4.143.405-7.415 1.787-9.722 1.119-1.861 2.804-3.13 4.615-3.482a5.72 5.72 0 011.036-.1c1.484 0 2.959.593 4.155 1.674v.015z"
      fill="#393939"
    />
    <Path
      d="M38.657 117.765a10.235 10.235 0 01-1.428-4.159c-.167-1.504.152.939 0-.226-.22-1.692.336-9.712 1.627-14.114-.224 1.678.304-1.663 0 0 .194-1.432 2.618-7.33 4.522-7.77 1.203-.277 3.466 4.468 4.392 5.05 1.188.75 1.989.748 3.36 1.036 1.162.189 1.774.047 4.133 0 .88-.015 2.04.131 2.735-.414.8-.624 1.02-1.72 1.282-2.7 1.046-3.944 3.735-7.294 6.596-10.084 3.097-3.02 7.45-5.58 11.828-5.826 6.378-.356 13.168 1.531 17.657 6.282 3.201 3.388 5.414 7.671 5.493 12.37.078 4.777-1.486 9.522-4.06 13.507-2.856 4.421-6.874 7.965-11.258 10.813-4.452 2.894-8.6 3.969-13.989 4.614-3.118.372-6.529 1.825-9.594 1.169-9.015-1.169-8.836-1.597-13.795-3.186-4.263-1.365-6.247-2.839-7.785-4.254a9.624 9.624 0 01-1.716-2.103v-.005z"
      fill="#000"
    />
    <Path
      d="M59.757 101.855c-.91.067-1.832.341-2.638.901-.782.543-1.132 1.355-1.197 2.278-.067.93.124 1.814.617 2.616.749 1.219 2.102 1.597 3.428 1.413a5.869 5.869 0 001.493-.439c1.532-.653 2.283-2.25 1.793-3.835-.342-1.105-1.069-1.925-1.994-2.591-.428-.31-.939-.275-1.502-.342v-.001z"
      fill="#fff"
    />
    <Path
      d="M59.74 108.071c.604-.01 1.196-.05 1.7-.47 1.114-.927 1.243-2.511.278-3.651a4.07 4.07 0 00-1.927-1.255c-.878-.275-2.11.397-2.566 1.364a2.981 2.981 0 00.64 3.428c.544.521 1.193.589 1.876.584z"
      fill="#000"
    />
    <Path
      d="M44.739 101.855c-1.317.053-2.416.541-3.083 1.811-.66 1.255-.642 2.482.12 3.663 1.277 1.977 3.768 2.362 5.548.869.833-.698 1.196-1.586 1.067-2.686-.07-.595-.107-1.171-.347-1.746-.489-1.175-1.352-1.762-2.605-1.859-.213-.017-.427-.032-.701-.052z"
      fill="#fff"
    />
    <Path
      d="M42.472 105.239c-.008 1.384.982 2.61 2.149 2.572 1.647-.053 2.722-.672 2.759-2.214.029-1.186-.754-2.721-2.574-2.706-1.225.011-2.328 1.123-2.334 2.347v.001zM90.572 101.722c1.046 18.591-2.022 25.39 1.959 26.045 9.422 1.839 8.235-15.738 7.404-20.989 0 0-10.41-23.647-9.363-5.056z"
      fill="#000"
    />
    <Path
      d="M50.03 111.91c.258.53.897 1.077 1.58 1.083.627 0 1.146-.394 1.608-1.065.354-.506-.146-.736-1.582-.748-1.38-.006-1.86.2-1.6.73h-.007z"
      fill="#fff"
    />
    <Path
      d="M58.365 97.325c1.12-1.252 3.23-4.532 4.78-5.05 1.938-.648 4.78 3.366 7.493 16.833"
      stroke="#393939"
      strokeWidth={1.445}
      strokeLinecap="round"
    />
    <Ellipse cx={75.433} cy={145.046} rx={67.567} ry={15.767} fill="#000" fillOpacity={0.15} />

    {/* <Defs>
      <ClipPath id="prefix__clip0_4902_81299">
        <Path fill="#fff" transform="translate(0 .997)" d="M0 0h143v172H0z" />
      </ClipPath>
    </Defs> */}
  </Svg>
);

export default GatoAlien;
