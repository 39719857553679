import React, { useEffect, useState } from 'react';
import { Animated, Easing, LayoutChangeEvent, StyleProp, View, ViewStyle } from 'react-native';

import { Colors } from '@theme';
import { isMobile } from '@constants/platform';

import { styles } from './styles';

interface SkeletonProps {
  children?: React.ReactNode;
  width?: number;
  height?: number;
  backColor?: string;
  loadingColor?: string;
  customStyle?: StyleProp<ViewStyle>;
}

const Skeleton = ({
  children,
  width = 200,
  height = 100,
  customStyle,
  backColor = isMobile ? Colors.black : Colors.blackTwo,
  loadingColor = isMobile ? Colors.darkGrey : Colors.greyTwo,
}: SkeletonProps) => {
  const animatedValue = new Animated.Value(0);

  useEffect(() => {
    Animated.loop(
      Animated.timing(animatedValue, {
        toValue: 1,
        duration: 1300,
        easing: Easing.linear.inOut,
        useNativeDriver: isMobile,
      })
    ).start();
  });

  const [layoutWidth, setWidth] = useState(width);
  const handleLayout = (event: LayoutChangeEvent) => {
    setWidth(event.nativeEvent.layout.width);
  };

  const translateX = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [-width * 2, width],
  });

  const translateXDesktop = animatedValue.interpolate({
    inputRange: [0, 1],
    outputRange: [-layoutWidth, layoutWidth],
  });

  const styleT = isMobile ? { translateX } : { translateX: translateXDesktop };

  return (
    <View
      onLayout={handleLayout}
      style={[
        styles.container,
        {
          height,
          width,
          backgroundColor: backColor,
        },
        customStyle,
      ]}>
      {children}
      <Animated.View
        style={[
          styles.linear,
          // eslint-disable-next-line react-native/no-inline-styles
          {
            width: isMobile ? width * 0.2 : '20%',
            height: isMobile ? height * 1.5 : '200%',
            backgroundColor: loadingColor,
            transform: [styleT, { rotate: '20deg' }, { translateY: -(height * 0.5) / 2 }],
          },
        ]}
      />
    </View>
  );
};
export default Skeleton;
