import React from 'react';
import { Pressable, View } from 'react-native';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { useTranslation } from 'react-i18next';

import { AvatarProfile } from '@atoms/AvatarProfile';
import CustomText from '@atoms/CustomText';
import TimeAgo from '@atoms/TimeAgo';
import CatIconNewStoreVersion from '@assets/svg/CatIconNewStoreVersion';
import { Notification } from '@interfaces/notifications';
import { getGameSlug, openURL } from '@utils/common';
import Verified from '@assets/svg/Verified';
import { isAndroid } from '@constants/platform';
import { setGameIdHandler, useStore } from '@store/index';

import { styles } from './styles';

const COMMENT_GAME = 'COMMENT_GAME';
const COMMENT_RESPONSE = 'COMMENT_RESPONSE';
const COMMENT_GAME_THREAD = 'COMMENT_GAME_THREAD';

const NotificationPage = ({
  created,
  readed,
  notificationType,
  userSender,
  game,
  handleClose,
}: Pick<Notification, 'created' | 'readed' | 'notificationType' | 'userSender' | 'game'>) => {
  const navigation = useNavigation();
  const i18n = useTranslation();

  const setGameId = useStore(setGameIdHandler);

  const getText = () => {
    if (notificationType === 'APP_UPDATE') return i18n.t('notifications.app_update');
    if (notificationType === 'FOLLOW') return i18n.t('notifications.followed_you');
    if (notificationType === 'LIKE_GAME') return `${i18n.t('notifications.liked_your_game')} ${game?.name}`;
    if (notificationType === 'COMMENT_GAME') return `${i18n.t('notifications.commented_the_game')} ${game?.name}`;
    if (notificationType === 'COMMENT_RESPONSE') return `${i18n.t('notifications.replied_thread')} ${game?.name}`;
    if (notificationType === 'COMMENT_GAME_THREAD') return `${i18n.t('notifications.replied_thread')} ${game?.name}`;

    return '';
  };

  const newVersion = notificationType === 'APP_UPDATE';

  const getIcon = () => {
    if (newVersion) return <CatIconNewStoreVersion />;

    return (
      <View>
        <AvatarProfile
          customContainerStyle={styles.avatar}
          imageUrl={userSender?.profileImage || ''}
          backgroundUrl={userSender?.backgroundProfileImage || ''}
          offsetKitten={2}
        />
        {userSender?.creator ? <Verified style={styles.creator} /> : null}
      </View>
    );
  };

  const handleOnPress = () => {
    if (handleClose) handleClose();
    if (newVersion) {
      const APP_STORE_URL = 'https://apps.apple.com/us/app/gato-prod/id6444835134';
      return openURL(APP_STORE_URL);
    }

    if (
      notificationType === COMMENT_GAME ||
      notificationType === COMMENT_RESPONSE ||
      notificationType === COMMENT_GAME_THREAD
    ) {
      if (!game) return null;
      setGameId(game.id);

      const gameSlug = getGameSlug(game.name);

      const navigateAction = CommonActions.navigate({
        name: 'GamePage',
        params: {
          name: gameSlug,
        },
        key: `GamePage/${gameSlug}`,
      });

      return navigation.dispatch(navigateAction);
    }
    const navigateAction = CommonActions.navigate({
      name: 'UserProfile',
      params: {
        userName: userSender?.userName,
      },
      key: `UserProfile-${userSender?.userName}`,
    });
    return navigation.dispatch(navigateAction);
  };

  if (isAndroid && newVersion) return null;

  return (
    <Pressable style={styles.container} onPress={handleOnPress}>
      {!readed ? <View style={styles.readed} /> : null}
      {getIcon()}
      <View style={styles.info}>
        <View style={styles.inline}>
          <CustomText color="purple" customStyle={styles.text}>
            {!newVersion ? `@${userSender?.userName || ''}` : null}{' '}
            <CustomText color="greySix" customStyle={styles.text}>
              {getText()}
            </CustomText>
          </CustomText>
        </View>
        <TimeAgo date={created} />
        {newVersion && (
          <View style={styles.button}>
            <CustomText>Install</CustomText>
          </View>
        )}
      </View>
    </Pressable>
  );
};

export default NotificationPage;
