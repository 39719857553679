import React, { useEffect, useState } from 'react';
import { Pressable, ViewStyle } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { CategoryData } from '@interfaces/explore';
import { FILTERS_TYPES_ICONS } from '@organisms/GameFilters/constants';
import { Colors } from '@theme';

import { styles } from './styles';

export type FilterTypeI = 'tap' | 'swipe' | 'gamepad' | 'singleplayer' | 'multiplayer';

type FilterProps = {
  onSelect: (item: CategoryData) => void;
  item: CategoryData;
  type: FilterTypeI;
  active: boolean;
  customStyle?: ViewStyle;
  lastIndex?: boolean;
};

const FilterCategoryItem = ({ onSelect, type, active, customStyle, lastIndex, item }: FilterProps) => {
  const [selected, setSelected] = useState(false);
  const i18n = useTranslations();

  const handlePress = () => {
    setSelected((prevSelected) => !prevSelected);
    onSelect(item);
  };

  useEffect(() => {
    setSelected(active);
  }, [active]);

  const icon = FILTERS_TYPES_ICONS[type];

  return (
    <Pressable
      style={[styles.container, lastIndex ? styles.noMargin : null, customStyle]}
      onPress={handlePress}>
      {icon(selected ? Colors.limeGreen : Colors.filterItemGrey)}
      <CustomText color={selected ? 'limeGreen' : 'greyFilter'}>{i18n.t(`explore.${type}`)}</CustomText>
    </Pressable>
  );
};

export default FilterCategoryItem;
