import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const VerticalDashedLine = (props: SvgProps) => (
  <Svg width={18} height={1004} fill="none" {...props}>
    <Path
      stroke="#272B30"
      strokeDasharray="8 8"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M8.742 2.221v999.999"
    />
  </Svg>
);

export default VerticalDashedLine;
