import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';

async function editBio(data: { newBio: string }, token: string) {
  const response = await axiosAuthInstance.put('/users/me/update-bio', data, {
    headers: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
}

export function useUpdateBio(token: string) {
  return useMutation((data: { newBio: string }) => editBio(data, token), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_USER_DATA);
      await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
    },
  });
}
