import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { LATERALPLUS } from 'app/theme/spacing';
import { WINDOW_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.black,
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  lottie: {
    width: moderateScale(288),
    height: moderateScale(262),
    marginBottom: moderateVerticalScale(40),
  },
  footer: {
    flex: 0.3,
    justifyContent: 'center',
    padding: LATERALPLUS,
  },
  text: {
    letterSpacing: -1.5,
    maxWidth: WINDOW_WIDTH * 0.71,
    lineHeight: 30,
  },
  button: {
    marginVertical: moderateVerticalScale(20),
    width: '100%',
  },
});
