import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SVGComponent = (props: SvgProps) => (
  <Svg width={17} height={20} viewBox="0 0 17 20" fill="none" {...props}>
    <Path
      d="M13.9746 10.5972C13.9894 9.44658 14.4716 8.34394 15.2766 7.54402C15.6591 7.16387 15.7985 6.54831 15.3977 6.18751C14.6156 5.48358 13.5889 5.05737 12.5309 5.02427C11.0477 4.86859 9.60989 5.91176 8.85411 5.91176C8.0837 5.91176 6.92005 5.03973 5.667 5.06551C4.0337 5.11828 2.51105 6.04688 1.71642 7.47482C0.00826644 10.4322 1.28239 14.7786 2.91866 17.1691C3.73732 18.3397 4.6941 19.6473 5.94602 19.6009C7.1711 19.5501 7.62865 18.8197 9.10743 18.8197C10.5725 18.8197 11.0018 19.6009 12.2791 19.5714C13.5936 19.5501 14.4219 18.3957 15.2118 17.214C15.6372 16.6107 15.9917 15.9616 16.269 15.2795C16.4499 14.8342 16.208 14.3488 15.8127 14.0755C14.6873 13.2976 13.9761 11.9896 13.9746 10.5972Z"
      fill="#FCFCFC"
    />
    <Path
      d="M11.5619 3.45213C12.0713 2.84068 12.397 2.10525 12.5122 1.33012C12.5897 0.808141 12.1068 0.405628 11.6067 0.573998C10.8808 0.818383 10.226 1.25092 9.71329 1.83495C9.19459 2.42526 8.85753 3.16383 8.74062 3.9375C8.6674 4.42202 9.08758 4.81976 9.56425 4.70614C10.3426 4.52062 11.0576 4.07937 11.5619 3.45213Z"
      fill="#FCFCFC"
    />
  </Svg>
);
export default SVGComponent;
