import React, { FC, memo, useMemo } from 'react';
import { Image } from 'react-native';
import { MotiPressable } from 'moti/interactions';
import { MotiView } from 'moti';

import { Avatar } from '@interfaces/avatar';
import { Colors } from '@theme';

import styles from './styles';

interface IAvatarItem {
  item: Avatar;
  selectedImage: Avatar;
  handleSelect: (avatar: Avatar) => void;
  fromBackgroundStep?: boolean;
}

const AvatarItem: FC<IAvatarItem> = ({ item, selectedImage, handleSelect, fromBackgroundStep }) => (
  <MotiPressable
    key={item.id}
    onPress={() => handleSelect(item)}
    animate={useMemo(
      () =>
        ({ pressed }) => {
          'worklet';

          return {
            opacity: pressed ? 0.5 : 1,
            translateY: pressed ? -10 : 0,
          };
        },
      []
    )}>
    <MotiView
      animate={{ borderColor: selectedImage.id === item.id ? Colors.purple : Colors.darkGrey }}
      style={[styles.items]}>
      <Image
        style={styles.imageItem}
        resizeMode="contain"
        source={{ uri: fromBackgroundStep ? item.url : item.selectorURL }}
      />
    </MotiView>
  </MotiPressable>
);
export default memo(AvatarItem);
