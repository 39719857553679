import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const DeviceAndroid = (props: SvgProps) => (
  <Svg width={51} height={47} fill="none" {...props}>
    <Rect width={50.273} height={46.242} y={0.422} fill="#1A1D1F" rx={9.248} />
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      d="M17.248 18.285a.739.739 0 0 1 1.01 0l2.338 2.232a10.096 10.096 0 0 1 4.541-1.068c1.64 0 3.185.386 4.542 1.067l2.338-2.231a.739.739 0 0 1 1.01 0 .66.66 0 0 1 0 .965l-2.105 2.01c2.415 1.697 3.982 4.43 3.982 7.513 0 .076-.001.152-.003.227H15.374a9.051 9.051 0 0 1-.003-.227c0-3.083 1.567-5.816 3.982-7.513l-2.105-2.01a.66.66 0 0 1 0-.965Zm4.078 7.532c.526 0 .953-.408.953-.91s-.427-.91-.953-.91-.953.408-.953.91.427.91.953.91Zm8.576-.91c0 .502-.427.91-.953.91-.527 0-.953-.408-.953-.91s.427-.91.953-.91.953.408.953.91Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default DeviceAndroid;
