import AboutGatoIcon from '@assets/svg/AboutGatoIcon';
import AccountSettingsIcon from '@assets/svg/AccountSettingsIcon';
// import AccountSettingsIcon from '@assets/svg/AccountSettingsIcon';
import HelpIcon from '@assets/svg/HelpIcon';
import LogoutIcon from '@assets/svg/LogoutIcon';
import UserIcon from '@assets/svg/UserIcon';

export const menuItems = [
  {
    id: 'about_GATO',
    route: 'AboutGATO',
    icon: AboutGatoIcon,
  },
  {
    id: 'need_help',
    route: 'NeedHelp',
    icon: HelpIcon,
  },
  {
    id: 'profile',
    route: 'MyProfile',
    icon: UserIcon,
  },
  {
    id: 'account_settings',
    route: 'AccountSettings',
    icon: AccountSettingsIcon,
  },
  {
    id: 'logout',
    route: 'Logout',
    icon: LogoutIcon,
  },
];
