import React, { memo, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import Lottie from 'lottie-react-native';
import * as ExpoImage from 'expo-image';
import { MotiPressable } from 'moti/interactions';
import { MotiView } from 'moti';
import { useMutation } from 'react-query';

import ChevronLeft from '@assets/svg/ChevronLeft';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import ButtonLine from '@atoms/ButtonLine';
import { setHasSeenAvatarAlert } from '@utils/avatar';
import { useMyProfile } from '@services/useMyProfile';
import { CustomModal } from '@molecules/CustomModal';
import {
  showAvatarSelector,
  useStore,
  setShowAvatarModalHandler,
  setShowOnboardingHandler,
  showOnboarding,
  showOnboardingSelector,
} from '@store/index';
import ChangeAvatarLayout from '@templates/ChangeAvatarLayout';
import { useGetAvatarImages } from '@services/useGetAvatarImages';
import AvatarImage from '@molecules/AvatarImage';
import * as UserService from '@services/UserService';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';
import { Colors } from '@theme';

import { styles } from './styles';
import AvatarSuccess from './AvatarSuccess';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const avatarFactory = require('../../../assets/lotties/avatar_factory.json');

const AvatarOnboarding = () => {
  const i18n = useTranslations();

  return (
    <View style={[styles.container]}>
      <View style={styles.content}>
        <Lottie source={avatarFactory} autoPlay loop style={styles.lottie} />
        <CustomText color="white" size="mlarge" weight="oswaldSemi" customStyle={styles.text}>
          {i18n.t('avatar.alert_title_1_web')} {'\n'}
          <CustomText color="limeGreen" size="mlarge" weight="oswaldSemi">
            {i18n.t('avatar.alert_title_2')}
          </CustomText>
        </CustomText>
      </View>
    </View>
  );
};

const Dots = ({ data, handlePress, selectedItem }) => {
  return (
    <MotiView
      style={styles.dotContainer}
      from={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'spring' }}
      delay={700}>
      {data?.map((item) => (
        <MotiPressable onPress={() => handlePress(item)} key={`${item.url}${item.id}`}>
          <MotiView
            style={[
              styles.dot,
              { backgroundColor: selectedItem.id === item.id ? Colors.limeGreen : Colors.whiteOpacity(0.2) },
            ]}
          />
        </MotiPressable>
      ))}
    </MotiView>
  );
};

const Arrow = ({ disabled, isLeft, handlePress }) => (
  <Pressable
    hitSlop={20}
    style={[styles.arrowButton, disabled && styles.disabled]}
    onPress={handlePress}
    disabled={disabled}>
    <MotiView
      style={styles.header}
      from={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ type: 'spring' }}
      delay={700}>
      <ChevronLeft style={isLeft ? styles.chevronLeft : styles.chevron} fill={Colors.greySix} />
    </MotiView>
  </Pressable>
);

export const AvatarAlert = () => {
  const i18n = useTranslations();
  const { data: profileData } = useMyProfile();
  const showAvatarModal = useStore(showAvatarSelector);
  const setShowAvatarModal = useStore(setShowAvatarModalHandler);
  const setShowOnboarding = useStore(setShowOnboardingHandler);
  const showOnboarding = useStore(showOnboardingSelector);
  const { data, isLoading } = useGetAvatarImages(showAvatarModal);
  const [selectedCat, setSelectedCat] = useState('');
  const [selectedBackground, setSelectedBackground] = useState('');
  const [step, setStep] = useState(1);

  const { mutate, isLoading: mutateLoading } = useMutation(
    () =>
      UserService.updateAvatar({
        avatarId: selectedCat?.id,
        backgroundId: selectedBackground?.id,
      }),
    {
      async onSuccess() {
        await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
        await client.invalidateQueries(QueryKeys.GET_USER_DATA);
        setShowAvatarModal();
      },
    }
  );

  const onDismiss = async () => {
    if (showOnboarding) {
      await setHasSeenAvatarAlert(profileData.userName);
      setShowOnboarding();
    }
    setShowAvatarModal();
    // navigation.goBack();
  };

  const onAccept = async () => {
    if (showOnboarding) {
      await setHasSeenAvatarAlert(profileData.userName);
      setShowOnboarding();
    } else if (step === 1) setStep(2);
    else if (step === 2) setStep(3);
    else if (step === 3) mutate();
  };

  const handleBack = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  useEffect(() => {
    if (data && !isLoading && !selectedCat && !selectedBackground) {
      setSelectedCat(data?.profileImages[1]);
      setSelectedBackground(data?.profileBackgroundImages[0]);
    }
  }, [data, isLoading]);

  const handleDots = (selected: any) => {
    if (step === 1) setSelectedCat(selected);
    else setSelectedBackground(selected);
  };

  const filteredProfileImages = useMemo(() => data?.profileImages.filter((el, index) => index !== 0), [data]);

  const handleNext = (index: number) => {
    if (step === 1) {
      const actualIndex = filteredProfileImages?.findIndex((item) => item.id === selectedCat.id);
      if (actualIndex !== undefined) {
        const nextItem = actualIndex + 1;
        if (nextItem < filteredProfileImages?.length)
          setSelectedCat(filteredProfileImages[nextItem]);
      }
    } else {
      const actualIndex = data?.profileBackgroundImages?.findIndex((item) => item.id === selectedBackground.id);
      if (actualIndex !== undefined) {
        const nextItem = actualIndex + 1;
        if (nextItem < data?.profileBackgroundImages?.length)
          setSelectedBackground(data?.profileBackgroundImages[nextItem]);
      }
    }
  };

  const handlePrevious = (index: number) => {
    if (step === 1) {
      const actualIndex = filteredProfileImages?.findIndex((item) => item.id === selectedCat.id);
      if (actualIndex) {
        const nextItem = actualIndex - 1;
        if (nextItem >= 0) setSelectedCat(filteredProfileImages[nextItem]);
      }
    } else {
      const actualIndex = data?.profileBackgroundImages?.findIndex((item) => item.id === selectedBackground.id);
      if (actualIndex) {
        const nextItem = actualIndex - 1;
        if (nextItem >= 0) setSelectedBackground(data?.profileBackgroundImages[nextItem]);
      }
    }
  };

  const getTitle = () => {
    if (step === 1) return i18n.t('avatar.choose_step_title_first');
    if (step === 2) return i18n.t('avatar.choose_step_title_second');
    if (step === 3) return i18n.t('avatar.choose_step_title_third');
  };

  const getAcceptText = () => {
    if (showOnboarding) return i18n.t('avatar.alert_cta_primary');
    if (step === 1) return i18n.t('avatar.choose_cta_primary');
    if (step === 2) return i18n.t('avatar.choose_cta_primary');
    if (step === 3) return i18n.t('avatar.choose_save_cta');
  };

  const title = useMemo(() => getTitle(), [step, showOnboarding]);
  const ctaText = useMemo(() => getAcceptText(), [step, showOnboarding]);

  const disabledArrowPrevious = () => {
    if (step === 1) {
      const actualIndex = filteredProfileImages?.findIndex((item) => item.id === selectedCat.id);
      if (actualIndex !== undefined) {
        const nextItem = actualIndex - 1;
        if (nextItem >= 0) return false;
        else return true;
      }
    }
    const actualIndex = data?.profileBackgroundImages?.findIndex((item) => item.id === selectedBackground.id);
    if (actualIndex !== undefined) {
      const nextItem = actualIndex - 1;
      if (nextItem >= 0) return false;
      else return true;
    }
  }

  const disabledArrowNext = () => {
    if (step === 1) {
      const actualIndex = filteredProfileImages?.findIndex((item) => item.id === selectedCat.id);
      if (actualIndex !== undefined) {
        const nextItem = actualIndex + 1;
        if (nextItem < filteredProfileImages?.length) return false;
        else return true;
      }
    }
    const actualIndex = data?.profileBackgroundImages?.findIndex((item) => item.id === selectedBackground.id);
    if (actualIndex !== undefined) {
      const nextItem = actualIndex + 1;
      if (nextItem < data?.profileBackgroundImages?.length) return false;
      else return true;
    }

  }

  const stepsLayout = () => (
    <ChangeAvatarLayout title={title} step={`${step}`} fromModal handleBack={handleBack}>
      {step < 3 ? (
        <View style={[styles.container, styles.spacing]}>
          <View
            style={{
              position: 'absolute',
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'space-between',
              top: '50%',
              paddingHorizontal: 50,
              transform: [
                {
                  translateY: '-100%',
                },
              ],
            }}>
            <Arrow disabled={disabledArrowPrevious()} handlePress={handlePrevious} />
            <Arrow isLeft disabled={disabledArrowNext()} handlePress={handleNext} />
          </View>
          <AvatarImage
            avatar={selectedCat}
            background={step >= 2 ? selectedBackground : undefined}
            transparent={step >= 2}
            delay={400}
          />
          <Dots
            data={step === 1 ? filteredProfileImages : data?.profileBackgroundImages}
            handlePress={handleDots}
            selectedItem={step === 1 ? selectedCat : selectedBackground}
          />
        </View>
      ) : (
        <AvatarSuccess
          selectedAvatar={selectedCat}
          selectedBackground={selectedBackground}
          userName={profileData.userName}
        />
      )}
      <View style={styles.footer}>
        <Button onPress={onAccept} customStyle={styles.button} loading={mutateLoading}>
          {ctaText}
        </Button>
        {step !== 3 && (
          <ButtonLine onPress={onDismiss} color="white" sizes="medium">
            {i18n.t('avatar.alert_cta_secondary')}
          </ButtonLine>
        )}
      </View>
    </ChangeAvatarLayout>
  );

  return (
    <CustomModal visible={showAvatarModal} onOverlayPress={onDismiss} customStyle={styles.modalStyle}>
      {showOnboarding ? <AvatarOnboarding /> : stepsLayout()}
      {showOnboarding ? (
        <View style={styles.footer}>
          <Button onPress={onAccept} customStyle={styles.button}>
            {ctaText}
          </Button>
          {step !== 3 && (
            <ButtonLine onPress={onDismiss} color="white" sizes="medium">
              {i18n.t('avatar.alert_cta_secondary')}
            </ButtonLine>
          )}
        </View>
      ) : null}
    </CustomModal>
  );
};

export default memo(AvatarAlert);
