import React, { useEffect, useState } from 'react';
import { Image, LayoutChangeEvent, View, ViewProps, Pressable, Linking } from 'react-native';
import Animated, {
  Easing,
  useAnimatedStyle,
  useSharedValue,
  withRepeat,
  withTiming,
} from 'react-native-reanimated';
import { useTranslation } from 'react-i18next';

import { isMobile } from '@constants/platform';
import CustomText from '@atoms/CustomText';
import SparkIcon from '@assets/svg/SparkIcon';
import useFeatureFlag, { christmasEventSelector, gameQuestEventSelector } from '@store/featureFlag';
import GameQuestLogo from '@assets/svg/GameQuestLogo';
import Star from '@assets/svg/Star';
import StarSecond from '@assets/svg/StarSecond';
import ChristmasStarPNG from '@assets/img/ChristmasStar.png';
import { Colors } from '@theme';
import links from '@constants/links';

import { styles } from './styles';

interface MarqueeProps {
  speed?: number;
}

const MarqueeContent = (props: ViewProps): JSX.Element => {
  // constants
  const textSize = isMobile ? 'msmall' : 'lmedium';
  const textWeight = isMobile ? 'interBlack' : 'interBold';

  // hooks
  const i18n = useTranslation();

  // store
  const isGGQ = useFeatureFlag(gameQuestEventSelector);
  const isChristmas = useFeatureFlag(christmasEventSelector);

  // render
  if (isGGQ) {
    return (
      <View style={styles.textWrapper} {...props}>
        <Star style={styles.spark} />
        <GameQuestLogo />
        <StarSecond style={styles.spark} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.play_and_vote')}
        </CustomText>
        <Star style={styles.spark} />
        <GameQuestLogo />
        <StarSecond style={styles.spark} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.upload_your_game')}
        </CustomText>
      </View>
    );
  }
  if (isChristmas && !isGGQ) {
    return (
      <View style={styles.textWrapper} {...props}>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.avatar_statement')}
        </CustomText>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.santa_claus')}
        </CustomText>
        <Image resizeMode="cover" style={[styles.christmasStar, styles.spark]} source={ChristmasStarPNG} />
        <CustomText size={textSize} weight={textWeight} color="white">
          {i18n.t('marquee.christmas.merry_christmas')}
        </CustomText>
      </View>
    );
  }

  return (
    <View style={styles.textWrapper} {...props}>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.free')}
      </CustomText>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.social')}
      </CustomText>
      <SparkIcon style={styles.spark} />
      <CustomText size={textSize} weight={textWeight} color="white">
        {i18n.t('marquee.awesome')}
      </CustomText>
    </View>
  );
};

const Marquee = ({ speed = 0.1 }: MarqueeProps): JSX.Element => {
  // states
  const [titleWidth, setTitleWidth] = useState<number>();
  // store
  const isChristmas = useFeatureFlag(christmasEventSelector);
  const isGGQ = useFeatureFlag(gameQuestEventSelector);

  // hooks
  const transformValue = useSharedValue(0);

  const animatedStyles = useAnimatedStyle(() => ({
    transform: [
      {
        translateX: transformValue.value,
      },
    ],
  }));

  // functions
  function handleLayout(event: LayoutChangeEvent): void {
    setTitleWidth(event.nativeEvent.layout.width);
  }
  // effects
  useEffect(() => {
    if (!titleWidth) return;
    transformValue.value = 0;
    transformValue.value = withRepeat(
      withTiming(-titleWidth, {
        duration: titleWidth / speed,
        easing: Easing.linear,
      }),
      -1,
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [titleWidth]);

  // render
  return (
    <Pressable onPress={() => Linking.openURL(links.gameQuestVoteLink)}>
      <Animated.View
        style={[
          styles.container,
          animatedStyles,
          { backgroundColor: isChristmas && !isGGQ ? Colors.christmasRed : Colors.purple },
        ]}>
        <MarqueeContent onLayout={handleLayout} />
        <MarqueeContent />
        <MarqueeContent />
        <MarqueeContent />
        <MarqueeContent />
        <MarqueeContent />
      </Animated.View>
    </Pressable>
  );
};

export default Marquee;
