import { DeviceEventEmitter } from 'react-native';

import { Colors } from '@theme';

import { mediumVibration } from './vibration';

export type ToastType = 'info' | 'success' | 'danger' | 'warning';

export type IconType = 'error' | 'success';
export interface ToastOptions {
  duration?: number;
  title: string;
  underlineTitle?: JSX.Element;
  type: ToastType;
  icon: IconType;
}
type Toast = Omit<ToastOptions, 'type'>;
export const toastColors = {
  info: Colors.lightGrey,
  success: Colors.success,
  danger: Colors.inputError,
  warning: Colors.orangeLight,
};
const toast = {
  info: (options: Toast) => {
    DeviceEventEmitter.emit('SHOW_TOAST_MESSAGE', { ...options, type: 'info' });
  },
  success: (options: Toast) => {
    DeviceEventEmitter.emit('SHOW_TOAST_MESSAGE', {
      ...options,
      type: 'success',
    });
    mediumVibration();
  },
  danger: (options: Toast) => {
    DeviceEventEmitter.emit('SHOW_TOAST_MESSAGE', {
      ...options,
      type: 'danger',
    });
    mediumVibration();
  },
  warning: (options: Toast) => {
    DeviceEventEmitter.emit('SHOW_TOAST_MESSAGE', {
      ...options,
      type: 'warning',
    });
  },
};
export default toast;
