import React, { useEffect, useState } from 'react';
import { View } from 'react-native';

import GatoAlien from '@assets/svg/GatoAlien';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Notification from '@atoms/Notification';
import ItemDivider from '@atoms/ItemDivider';
import { useGetNotifications } from '@services/useNotifications';
import Skeleton from '@atoms/Skeleton';
import { Colors, Spacing } from '@theme';
import { isAndroid, isMobile, SCREEN_WIDTH } from '@constants/platform';
import { Notification as INotification } from '@interfaces/notifications';

import { styles } from './styles';

interface NotificationsViewProps {
  onNotificationsChange?: (
    notifications: INotification[],
    unread: INotification[],
    read: INotification[]
  ) => void;
  handleClose?: () => void;
}

const NotificationsView = ({ onNotificationsChange, handleClose }: NotificationsViewProps) => {
  const i18n = useTranslations();

  const searchType = 'PAGINATION';
  const page = 1;
  const take = 20;
  const { data = [], isLoading } = useGetNotifications(searchType, page, take);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([]);

  function getUreadNotifications(notification: INotification) {
    return notification.readed === false;
  }

  function getReadedNotifications(notification: INotification) {
    return notification.readed === true;
  }

  useEffect(() => {
    if (data?.notifications) {
      const unreadNotificationsFilter = data?.notifications?.filter(getUreadNotifications);
      setUnreadNotifications(unreadNotificationsFilter);

      const readedNotificationsFilter = data?.notifications?.filter(getReadedNotifications);
      setNotifications(readedNotificationsFilter);

      if (onNotificationsChange)
        onNotificationsChange(data.notifications, unreadNotificationsFilter, readedNotificationsFilter);
    }
  }, [data, onNotificationsChange]);

  const hasOneNotificationUpdateAppAndIsAndroid =
    (unreadNotifications?.length === 1 && unreadNotifications[0]?.notificationType === 'APP_UPDATE') ||
    (notifications?.length === 1 && notifications[0]?.notificationType === 'APP_UPDATE' && isAndroid);

  const hasNotifications =
    (!unreadNotifications?.length && !notifications?.length) || hasOneNotificationUpdateAppAndIsAndroid;

  return (
    <View style={styles.container}>
      {!isLoading && hasNotifications && (
        <View style={styles.emptyContainer}>
          <GatoAlien />
          <CustomText color="greySix" weight="interSemi">
            {i18n.t('notifications.no_notifications_yet')}
          </CustomText>
        </View>
      )}

      {isLoading ? (
        <>
          <Skeleton
            backColor={Colors.blackTwo}
            loadingColor={Colors.greyFour}
            height={isMobile ? 100 : 80}
            width={SCREEN_WIDTH - Spacing.LATERALBIG * 2}
            customStyle={styles.skeletonItem}
          />
          <ItemDivider fullWidth />
          <Skeleton
            backColor={Colors.blackTwo}
            loadingColor={Colors.greyFour}
            height={isMobile ? 100 : 80}
            width={SCREEN_WIDTH - Spacing.LATERALBIG * 2}
            customStyle={styles.skeletonItem}
          />
          <ItemDivider fullWidth />
          <Skeleton
            backColor={Colors.blackTwo}
            loadingColor={Colors.greyFour}
            height={isMobile ? 100 : 80}
            width={SCREEN_WIDTH - Spacing.LATERALBIG * 2}
            customStyle={styles.skeletonItem}
          />
        </>
      ) : null}

      {!isLoading &&
      !hasNotifications &&
      unreadNotifications &&
      Object.keys(unreadNotifications).length > 0 ? (
        <>
          <CustomText color="greySix" align="left">
            Unread
          </CustomText>

          {/* <ScrollView
              showsHorizontalScrollIndicator={false}
              pagingEnabled
              decelerationRate="fast"
              contentContainerStyle={styles.scrollViewUnreadNotifications}> */}
          {unreadNotifications &&
            unreadNotifications?.map((notification: INotification) => (
              <>
                <Notification
                  created={notification?.created}
                  readed={notification?.readed}
                  notificationType={notification?.notificationType}
                  userSender={notification?.userSender}
                  game={notification?.game}
                  handleClose={handleClose}
                />
                <ItemDivider fullWidth />
              </>
            ))}
          {/* </ScrollView> */}
        </>
      ) : null}

      {!isLoading && !hasNotifications && notifications && Object.keys(notifications).length > 0 ? (
        <>
          <CustomText color="greySix" align="left" customStyle={unreadNotifications && styles.title}>
           {i18n.t('notifications.earlier')}
          </CustomText>

          {/* <ScrollView
              showsHorizontalScrollIndicator={false}
              pagingEnabled
              decelerationRate="fast"
              contentContainerStyle={styles.scrollView}> */}
          {notifications.map((notification: INotification) => (
            <>
              <Notification
                created={notification?.created}
                readed={notification?.readed}
                notificationType={notification?.notificationType}
                userSender={notification?.userSender}
                game={notification?.game}
                handleClose={handleClose}
              />
              <ItemDivider fullWidth />
            </>
          ))}
          {/* </ScrollView> */}
        </>
      ) : null}
    </View>
  );
};

export default NotificationsView;
