import React, { useCallback, useState } from 'react';
import { ActivityIndicator, FlatList, Pressable, View } from 'react-native';
import { useForm } from 'react-hook-form';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { MotiView } from 'moti';
import { Easing } from 'react-native-reanimated';
import { AxiosError } from 'axios';

import Field from '@molecules/Field';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { IComment } from '@interfaces/comments';
import Send from '@assets/svg/Send';
import { useAllComents, useNewcomment, useWebComments } from '@services/useComments';
import ItemDivider from '@atoms/ItemDivider';
import { Colors } from '@theme';
import toast from '@utils/toast';
import { authTokenSelector, useStore } from '@store/index';

import { styles } from './styles';
import CommentWithThread from './CommentWithThread';
import CommentField from './CommentField';

interface Props {
  gameId: number;
  handleComment: (value) => void;
}

// ToDo: Refactor all this functionality after the 24april Launch

const Comments = ({ gameId, handleComment }: Props) => {
  const navigation = useNavigation();
  const i18n = useTranslations();
  const [showMoreComments, setShowMoreComments] = useState(true);
  const [fieldFocus, setFieldFocus] = useState(false);
  const authToken = useStore(authTokenSelector);

  // const [page, setPage] = useState(1);

  const { data = [] } = useAllComents(gameId);
  // const { data = [] } = useWebComments(gameId, page);
  const newComment = useNewcomment();

  const total = data?.comments?.length;
  const hasMoreComments = data?.hasMoreComments;

  const formData = useForm<any>();

  const onSubmit = (data) => {
    const postData = {
      description: data.comment,
      game: Number(gameId),
    };

    newComment.mutate(postData, {
      onSuccess: () => {
        formData.resetField('comment');
        toast.success({ title: i18n.t('comments.comment_published'), icon: 'success' });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.comment_not_published') });
        }
      },
    });
  };

  const handleFocus = (val: boolean) => {
    if (!authToken) {
      navigation.navigate('Login');
      return;
    }
    setFieldFocus(val);
  };

  return (
    <View style={styles.container}>
      <CustomText customStyle={styles.title} weight="interSemi" align="left" size="xbig" color="white">
        {i18n.t('comments.title')} ({total})
      </CustomText>
      <CommentField
        formData={formData}
        isLoading={newComment.isLoading}
        fieldFocus={fieldFocus}
        setFieldFocus={handleFocus}
        onSubmit={onSubmit}
        fieldName="comment"
      />
      <View style={styles.comments}>
        {!data?.comments?.length ? (
          <CustomText customStyle={styles.emptyState} weight="interSemi" size="medium" color="whiteTwo">
            {i18n.t('comments.no_comments_yet')}
          </CustomText>
        ) : (
          <>
            <ItemDivider />
            <FlatList
              showsHorizontalScrollIndicator={false}
              ItemSeparatorComponent={ItemDivider}
              data={data?.comments.filter((item) => item?.description?.length > 0)}
              keyExtractor={(item: IComment) => item.id.toString()}
              scrollEnabled={showMoreComments}
              contentContainerStyle={{ paddingVertical: 10 }}
              renderItem={({ item }) => <CommentWithThread comment={item} key={item.id} />}
            />
            {hasMoreComments ? (
              <View style={styles.hasMoreComments}>
                <Pressable onPress={() => console.log('arre')}>
                  <CustomText
                    customStyle={styles.underline}
                    weight="interSemi"
                    size="medium"
                    align="left"
                    color="whiteTwo">
                    {i18n.t('comments.view_all')}
                  </CustomText>
                </Pressable>
              </View>
            ) : (
              <View style={styles.emptyContainer} />
            )}
          </>
        )}
      </View>
    </View>
  );
};

export default Comments;
