import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const LanguageIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.969 1.583c.328 0 .594.266.594.594v.198h8.312c.874 0 1.583.709 1.583 1.583v6.136a.99.99 0 01-.99.99H3.563v5.739a.594.594 0 01-1.187 0V2.177c0-.328.266-.594.594-.594zm2.573 11.084v-.396h6.927a2.177 2.177 0 002.177-2.177V5.542h.396c.874 0 1.583.709 1.583 1.583v5.542c0 .874-.709 1.583-1.583 1.583H7.125a1.583 1.583 0 01-1.583-1.583z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default LanguageIcon;
