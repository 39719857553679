import * as React from "react"
import Svg, { Ellipse, SvgProps } from "react-native-svg"

function Star({ fill, ...props }: SvgProps) {
    return (
        <Svg
            width={20}
            height={20}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <Ellipse cx={9.46772} cy={10} rx={1.75325} ry={9.72549} fill="#fff" />
            <Ellipse
                cx={9.81817}
                cy={10}
                rx={2.08403}
                ry={9.81818}
                transform="rotate(90 9.818 10)"
                fill="#fff"
            />
        </Svg>
    )
}

export default Star