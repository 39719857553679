import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MultiPlayerCatIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={38} height={41} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.768 18.905c3.451 0 6.25-2.985 6.25-6.667s-2.799-6.667-6.25-6.667c-3.452 0-6.25 2.985-6.25 6.667s2.798 6.667 6.25 6.667Zm0 16.666c6.04 0 10.937-2.984 10.937-6.666 0-3.682-4.897-6.667-10.937-6.667-6.041 0-10.938 2.985-10.938 6.667s4.897 6.666 10.938 6.666Zm6.19-16.974c1.488-1.648 2.403-3.89 2.403-6.359a9.684 9.684 0 0 0-.584-3.329c2.497.11 4.49 2.305 4.49 4.996 0 2.761-2.098 5-4.687 5-.57 0-1.117-.109-1.623-.308Zm7.09 10.307c0 .898-.187 1.766-.536 2.586 2.667-.881 4.443-2.456 4.443-4.252 0-2.51-3.47-4.59-7.99-4.946 2.517 1.668 4.083 4.014 4.083 6.612Z"
      fill={fill || '#FB8AD8'}
    />
  </Svg>
);

export default MultiPlayerCatIcon;
