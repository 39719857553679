import { StyleSheet } from 'react-native';

import { HEADER_HEIGHT } from '@constants/platform';
import { Spacing } from '@theme';

export const styles = StyleSheet.create({
  container: {
    height: HEADER_HEIGHT,
    width: '100%',
    paddingHorizontal: Spacing.LATERALPLUS,
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 10,
  },
  left: {
    flex: 0.5,
    justifyContent: 'center',
    height: '100%',
  },
  center: {
    flex: 2,
    justifyContent: 'center',
    height: '100%',
  },
  right: {
    flex: 0.5,
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
});
