import React from 'react';
import { Pressable, View, ViewStyle } from 'react-native';

import ChevronLeft from '@assets/svg/ChevronLeft';
import { Colors } from '@theme';

import { styles } from './styles';

interface ArrowsProps {
  onNext: () => void;
  onPrevious: () => void;
  isLastPage: boolean;
  isFirstPage: boolean;
  containerStyle?: ViewStyle;
  isDesktop?: boolean;
}

const Arrows = ({
  onNext,
  onPrevious,
  isFirstPage,
  isLastPage,
  containerStyle,
  isDesktop = false,
}: ArrowsProps) =>
  !isFirstPage || !isLastPage ? (
    <View style={[styles.container, containerStyle]}>
      <Pressable
        hitSlop={20}
        style={[styles.header, styles.button, isFirstPage && styles.disabled]}
        onPress={onPrevious}
        disabled={isFirstPage}>
        <View style={[styles.header, isDesktop && styles.chevronWhite]}>
          <ChevronLeft style={styles.chevron} fill={isDesktop ? Colors.white : Colors.greySix} />
        </View>
      </Pressable>
      <Pressable
        hitSlop={20}
        style={[styles.header, styles.button, isLastPage && styles.disabled]}
        onPress={onNext}
        disabled={isLastPage}>
        <View style={[styles.header, isDesktop && styles.chevronWhite]}>
          <ChevronLeft style={[styles.chevronLeft]} fill={isDesktop ? Colors.white : Colors.greySix} />
        </View>
      </Pressable>
    </View>
  ) : null;

export default Arrows;
