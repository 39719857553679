import { useMutation } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { TrackingAdsParams } from '@interfaces/trackingAds';
import { isWebResponsive, PLATFORM } from '@constants/platform';
import AsyncStorage from '@react-native-async-storage/async-storage';

async function trackingAds(data: TrackingAdsParams) {

  const response = await axiosGamesInstance.post<TrackingAdsParams>(`/carrousel/direct-sale/traking`, data);
  return response.data;
}

export function useTrackingAds() {
  return useMutation((data: TrackingAdsParams) => trackingAds(data));
}
