import { useQuery } from 'react-query';
import { Image } from 'react-native';

import { axiosAuthInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { Avatar } from '@interfaces/avatar';

async function getAvatarImages() {
  const response = await axiosAuthInstance.get<AvatarResponse>('/users/avatar');
  return response.data;
}

export function useGetAvatarImages() {
  return useQuery([QueryKeys.GET_AVATAR_IMAGES], () => getAvatarImages(), {
    async onSuccess(data) {
      const profileImages = data.profileImages.map((value) => Image.prefetch(value.url));
      const profileBackgroundImages = data.profileBackgroundImages.map((value) => Image.prefetch(value.url));
      await Promise.all([profileImages, profileBackgroundImages]);
    },
  });
}

export interface AvatarResponse {
  profileBackgroundImages: Avatar[];
  profileImages: Avatar[];
}
