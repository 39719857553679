import * as React from 'react';
import Svg, { SvgProps, G, Path, Ellipse, Defs, ClipPath } from 'react-native-svg';

const ExploreEmptyIcon = (props: SvgProps) => (
  <Svg width={298} height={240} fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_3932_73569)">
      <Path
        d="M124.167 34L60.635 195.135a8.997 8.997 0 00.253 7.216c1.089 2.285 3.092 4.097 5.594 5.059C81.217 213.102 112.83 223 154.5 223c41.669 0 73.274-9.898 88.02-15.59 2.502-.962 4.505-2.774 5.593-5.06a8.996 8.996 0 00.252-7.215L184.833 34h-60.666z"
        fill="#B5A6FF"
        fillOpacity={0.85}
      />
      <Path
        d="M87.548 54.45a5.913 5.913 0 100-11.826 5.913 5.913 0 000 11.827zM221.538 54.45a5.914 5.914 0 100-11.827 5.914 5.914 0 000 11.828zM154.546 64.162a5.913 5.913 0 100-11.826 5.913 5.913 0 000 11.826z"
        fill="#8B74FD"
      />
      <Path
        d="M233.748 37.073c0 11.052-32.099 20.008-79.537 20.008-47.437 0-78.856-8.956-78.856-20.008 0-11.051 31.402-20.005 78.842-20.005s79.551 8.957 79.551 20.005z"
        fill="#6546FC"
      />
      <Path
        d="M233.748 29.771c0 11.049-32.1 20.009-79.537 20.009-47.437 0-78.842-8.96-78.842-20.009 0-11.048 31.402-20.008 78.842-20.008s79.537 8.96 79.537 20.008z"
        fill="#fff"
        opacity={0.42}
      />
      <Path
        d="M154.624 36.488c13.554 0 26.292-1.63 37.381-4.497C189.591 14.274 173.094.566 153.1.566c-19.692 0-35.993 13.3-38.786 30.633 11.753 3.357 25.551 5.289 40.31 5.289z"
        fill="#6546FC"
      />
      <Path
        d="M175.222 17.357c.898-3.558-4.988-8.112-13.146-10.17-8.158-2.059-15.5-.842-16.398 2.716-.897 3.56 4.988 8.113 13.147 10.171 8.158 2.058 15.5.842 16.397-2.717z"
        fill="#fff"
        opacity={0.42}
      />
      <Path
        d="M223 75.074a3.644 3.644 0 10-6.762 2.722l15.671 38.924a3.646 3.646 0 006.762-2.723L223 75.074zM67.851 149.704c.894-2.168.11-4.547-1.75-5.314-1.862-.768-4.095.367-4.99 2.535l-9.493 23.022c-.894 2.168-.11 4.547 1.751 5.314 1.861.768 4.095-.367 4.988-2.535l9.494-23.022z"
        fill="#9B8EDB"
      />
      <Path
        d="M146.502 165.286c.31 21.213-2.637 29.663-6.637 31.051-9.448 3.612-5.991-22.519-5.533-28.597 0 0 11.859-23.667 12.17-2.454zM120.974 159.199c1.879 21.132-.434 29.777-4.321 31.457-9.155 4.301-7.641-22.014-7.634-28.109 0 0 10.076-24.48 11.955-3.348zM187.621 75.029a10.284 10.284 0 012.985 4.683c.574 1.902.56 4.119-.198 5.919-.956 2.26-2.882 3.534-4.317 5.34-1.728 2.174-2.725 5.144-3.217 8.166-.491 3.022-.519 6.132-.539 9.219-.048 7.178-.103 14.356-.15 21.526-.014 2.239-.137 4.778-1.489 6.226-1.387 1.478-3.62 1.083-4.966-.453-1.345-1.537-1.926-3.907-2.042-6.198-.116-2.29.184-4.58.287-6.87.471-10.404-2.937-20.472-3.436-30.876-.232-4.858.26-10.031 2.589-13.902 1.639-2.721 4.132-4.587 6.803-5.1 2.67-.512 5.491.337 7.69 2.32z"
        fill="#000"
      />
      <Path
        d="M181.073 136.496c1.483-1.588 1.626-4.251 1.64-6.622l.15-21.526c.02-3.014.041-6.131.526-9.123.533-3.286 1.605-6.015 3.101-7.902.478-.6 1.024-1.156 1.557-1.69 1.066-1.076 2.172-2.195 2.841-3.783.772-1.83.854-4.193.219-6.314a10.922 10.922 0 00-3.149-4.94c-2.295-2.07-5.314-2.984-8.114-2.443-2.821.541-5.423 2.488-7.144 5.348-2.063 3.425-2.958 8.21-2.671 14.231.219 4.5.984 8.992 1.728 13.339.977 5.707 1.981 11.604 1.714 17.479a79.165 79.165 0 01-.136 2.173c-.109 1.551-.226 3.154-.15 4.749.095 1.968.553 4.697 2.178 6.548.854.973 2.063 1.558 3.224 1.558a3.33 3.33 0 002.486-1.09v.008zm6.216-61.043a9.768 9.768 0 012.828 4.426c.56 1.866.498 3.93-.171 5.517-.588 1.383-1.564 2.378-2.603 3.432-.553.556-1.12 1.134-1.632 1.778-1.612 2.034-2.766 4.953-3.333 8.428-.505 3.081-.526 6.249-.546 9.307l-.151 21.526c-.013 2.151-.129 4.551-1.332 5.838a2.344 2.344 0 01-1.755.761c-.881 0-1.803-.453-2.466-1.207-1.099-1.251-1.775-3.329-1.898-5.846-.076-1.522.034-3.095.143-4.609.055-.739.102-1.471.137-2.21.273-6-.745-11.963-1.728-17.736-.738-4.309-1.496-8.765-1.708-13.199-.28-5.78.567-10.346 2.5-13.565 1.564-2.597 3.92-4.368 6.454-4.858.479-.088.963-.14 1.448-.14 2.077 0 4.139.828 5.813 2.335v.022z"
        fill="#393939"
      />
      <Path
        d="M103.067 162.932a14.26 14.26 0 01-1.997-5.802c-.235-2.1.212 1.31 0-.316-.309-2.36.469-13.551 2.276-19.694-.315 2.341.424-2.32 0 0 .27-1.998 3.66-10.226 6.323-10.841 1.683-.388 4.849 6.234 6.144 7.047 1.66 1.046 2.78 1.043 4.697 1.445 1.626.263 2.482.066 5.782 0 1.229-.022 2.852.183 3.825-.578 1.119-.871 1.426-2.4 1.792-3.768 1.464-5.503 5.224-10.178 9.227-14.07 4.331-4.215 10.418-7.785 16.542-8.129 8.919-.498 18.416 2.136 24.694 8.765 4.478 4.727 7.573 10.704 7.683 17.26.109 6.666-2.078 13.287-5.678 18.848-3.995 6.168-9.614 11.114-15.745 15.087-6.227 4.039-12.029 5.538-19.565 6.438-4.361.52-9.131 2.547-13.419 1.632-12.608-1.632-12.357-2.228-19.293-4.445-5.963-1.906-8.737-3.962-10.888-5.937a13.412 13.412 0 01-2.4-2.934v-.008z"
        fill="#000"
      />
      <Path
        d="M132.575 140.733c-1.271.093-2.561.475-3.689 1.257-1.093.758-1.582 1.891-1.674 3.179-.093 1.297.174 2.531.863 3.65 1.048 1.701 2.94 2.228 4.795 1.971.718-.099 1.418-.326 2.088-.612 2.143-.912 3.193-3.14 2.508-5.352-.478-1.541-1.495-2.686-2.789-3.615-.599-.432-1.313-.384-2.102-.476v-.002z"
        fill="#fff"
      />
      <Path
        d="M132.554 149.406c.844-.014 1.672-.07 2.378-.656 1.557-1.294 1.738-3.504.388-5.093-.729-.858-1.652-1.427-2.696-1.753-1.228-.383-2.952.554-3.588 1.904a4.152 4.152 0 00.895 4.783c.76.728 1.668.822 2.623.815z"
        fill="#000"
      />
      <Path
        d="M111.573 140.733c-1.841.073-3.378.755-4.311 2.526-.923 1.752-.897 3.464.169 5.112 1.785 2.758 5.268 3.295 7.759 1.212 1.165-.973 1.672-2.213 1.491-3.747-.097-.831-.149-1.634-.485-2.436-.683-1.64-1.891-2.459-3.643-2.595-.299-.023-.597-.044-.981-.072h.001z"
        fill="#fff"
      />
      <Path
        d="M108.402 145.455c-.011 1.93 1.374 3.641 3.005 3.588 2.304-.073 3.808-.938 3.86-3.089.039-1.655-1.055-3.797-3.601-3.776-1.713.015-3.255 1.567-3.264 3.275v.002zM175.674 140.546c1.463 25.941-2.828 35.429 2.74 36.342 13.178 2.566 11.518-21.96 10.355-29.286 0 0-14.558-32.997-13.095-7.056z"
        fill="#000"
      />
      <Path
        d="M118.972 154.763c.362.739 1.256 1.502 2.212 1.511.875 0 1.601-.551 2.247-1.487.495-.706-.204-1.026-2.212-1.043-1.929-.008-2.601.28-2.239 1.019h-.008z"
        fill="#fff"
      />
      <Path
        d="M130.63 134.411c1.566-1.746 4.517-6.323 6.685-7.046 2.711-.904 6.686 4.698 10.48 23.488"
        stroke="#393939"
        strokeWidth={1.445}
        strokeLinecap="round"
      />
      <Ellipse cx={154.5} cy={201} rx={94.5} ry={22} fill="#000" fillOpacity={0.15} />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_3932_73569">
        <Path fill="#fff" transform="translate(49)" d="M0 0h200v240H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default ExploreEmptyIcon;
