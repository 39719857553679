import React from 'react';
import { Linking, View } from 'react-native';
import { SvgProps } from 'react-native-svg';

import GatoBannerOne from '@assets/svg/GatoBannerOne';
import ShareBannerIcon from '@assets/svg/ShareBannerIcon';
import { useTranslations } from '@hooks/useTranslation';
import GatoBanner from '@molecules/GatoBanner';
import { isWebResponsive } from '@constants/platform';
import share from '@utils/shareApp';
import { useUploadingGameLink } from '@hooks/useUploadingGameLink';

import { styles } from './styles';

const BannerIcon1 = (props: SvgProps) => <ShareBannerIcon {...props} size={70} />;
const BannerIcon2 = (props: SvgProps) => <GatoBannerOne {...props} scale={1.5} />;

const GatoBannersDesktop = () => {
  const i18n = useTranslations();
  const link = useUploadingGameLink();

  return (
    <View style={styles.container}>
      <GatoBanner
        title={i18n.t('home.invite_friends')}
        description={i18n.t('home.share_app').toString()}
        buttonText={i18n.t('home.invite')}
        TitleIcon={BannerIcon1}
        containerStyle={[styles.banner, styles.banner1]}
        buttonAction={share}
      />
      {!isWebResponsive ? (
        <GatoBanner
          title={i18n.t('home.creator_title')}
          buttonText={i18n.t('settings.upload_game')}
          RightIcon={BannerIcon2}
          buttonAction={() => Linking.openURL(link)}
          containerStyle={[styles.banner, styles.banner2]}
          background="gradient"
          headerWidth={215}
        />
      ) : null}
    </View>
  );
};

export default GatoBannersDesktop;
