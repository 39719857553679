// Para cuando el user cierra la pestaña
import { useEffect } from 'react';

const useCloseTab = (handleBeforeUnload: any) => {
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useCloseTab;
