import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AxiosError } from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Keyboard, View } from 'react-native';

import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import toast from '@utils/toast';
import { passwordRegex } from '@utils/validations';
import { RootStackNavigator } from 'app/navigation/types';
import { useValidateCode } from '@services/useValidateCode';
import { isDesktop, isWebResponsive } from '@constants/platform';
import DesktopCenteredContainer from '@atoms/DesktopCenteredContainer';
import { Colors } from '@theme';
import { FooterButton } from '@atoms/FooterButton';

import { styles } from './styles';
import GoBackButton from '@molecules/GoBackButton';

type ResetPasswordProps = NativeStackScreenProps<RootStackNavigator, 'ResetPassword'>;
interface ResetPasswordForm {
  password: string;
  repeatPassword: string;
}

export const ResetPassword = ({ navigation, route }: ResetPasswordProps) => {
  useStatusBar('light');
  const userId = route?.params?.userId;
  const code = route?.params?.code;
  const i18n = useTranslations();
  const {
    control,
    getValues,
    watch,
    formState: { errors, isValid },
    handleSubmit,
    setValue,
  } = useForm<ResetPasswordForm>({ mode: 'onChange' });

  const resetPassword = useValidateCode();

  const passwordRef = React.useRef({});
  passwordRef.current = watch('password', '');
  const isDisabled = Boolean(!isValid);

  function onSubmit(values: ResetPasswordForm) {
    Keyboard.dismiss();

    const dataToSend = {
      code,
      password: values.password,
      type: 'PASSWORD',
      userId,
    };
    resetPassword.mutate(dataToSend, {
      onSuccess: (response) => {
        toast.success({ title: i18n.t(response.translate), icon: 'success' });
        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Login',
            },
          ],
        });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = i18n.t(error?.response?.data.translate);
          toast.danger({
            title: message,
            icon: 'error',
          });
        }
      },
    });
  }

  const textAlign = isDesktop && !isWebResponsive ? 'center' : 'left';

  return (
    <Layout
      withScroll
      verticallyCentered={isDesktop && !isWebResponsive}
      backgroundColor={isDesktop ? Colors.blackThree : undefined}
      hasBottomCTAS={
        <FooterButton>
          <Button
            disabled={isDisabled}
            loading={resetPassword.isLoading}
            customStyle={styles.button}
            onPress={handleSubmit(onSubmit)}>
            {i18n.t('reset.button_action')}
          </Button>
        </FooterButton>
      }>
      <View style={styles.container}>
      <GoBackButton />
        <DesktopCenteredContainer style={styles.desktopForm}>
          <View style={styles.body}>
            <CustomText weight="interSemi" align={textAlign} size="xlarge">
              {`${i18n.t('reset.title_1')}`}
            </CustomText>
            <CustomText customStyle={styles.description} weight="interSemi" align={textAlign} size="xbig">
              {i18n.t('reset.password_title')}
            </CustomText>
            <Field
              icon={PasswordInputIcon}
              name="password"
              control={control}
              placeholder={i18n.t('reset.password_placeholder')}
              rules={{
                required: i18n.t('reset.required'),
                pattern: {
                  value: passwordRegex,
                  message: i18n.t('reset.password_min_lenght_error'),
                },
              }}
              secure
              focusText={i18n.t('reset.password_text')}
              getValues={getValues}
              error={errors}
              onFocus={() => {
                setValue('repeatPassword', '');
              }}
            />
            <Field
              icon={PasswordInputIcon}
              name="repeatPassword"
              error={errors}
              getValues={getValues}
              secure
              control={control}
              placeholder={i18n.t('reset.repeat_password_placeholder')}
              rules={{
                required: i18n.t('reset.required'),
                validate: (value) =>
                  value === passwordRef.current || i18n.t('reset.repeat_password_pattern_error'),
              }}
            />
          </View>
          <View style={styles.footer}>
            <Button
              disabled={isDisabled}
              loading={resetPassword.isLoading}
              customStyle={styles.button}
              onPress={handleSubmit(onSubmit)}>
              {i18n.t('reset.button_action')}
            </Button>
          </View>
        </DesktopCenteredContainer>
      </View>
    </Layout>
  );
};
