import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SinglePlayerCatIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={40} height={40} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 18.333A6.667 6.667 0 1 0 20 5a6.667 6.667 0 0 0 0 13.333ZM20 35c6.443 0 11.666-2.985 11.666-6.666 0-3.682-5.223-6.667-11.666-6.667-6.444 0-11.667 2.985-11.667 6.666C8.333 32.016 13.556 35 20 35Z"
      fill={fill || '#FB8AD8'}
    />
  </Svg>
);

export default SinglePlayerCatIcon;
