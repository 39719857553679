import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon6 = ({ fill, ...props }: SvgProps) => (
  <Svg width={23} height={22} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1.833H6A3.667 3.667 0 002.333 5.5v11A3.667 3.667 0 006 20.167h11a3.667 3.667 0 003.667-3.667v-11A3.667 3.667 0 0017 1.833zm-7.797 11.4a.687.687 0 00-.91 1.03l.252.195c.15.103.363.237.636.369a5.307 5.307 0 002.319.527c.984 0 1.774-.262 2.32-.527a4.723 4.723 0 00.81-.5l.077-.063a.688.688 0 00-.91-1.03l-.128.095c-.098.068-.25.164-.45.26-.399.194-.984.39-1.719.39-.735 0-1.32-.195-1.72-.39a3.358 3.358 0 01-.45-.26l-.127-.096zm6.88-4.066a.917.917 0 11-1.833 0 .917.917 0 011.833 0zm-8.25.916a.917.917 0 100-1.833.917.917 0 000 1.833z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon6;
