import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SVGComponent = (props: SvgProps) => (
  <Svg width={14} height={16} viewBox="0 0 14 16" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.575988 1.13247C0.508796 1.28475 0.47168 1.47132 0.47168 1.6883V14.2824C0.47168 14.4999 0.508866 14.6865 0.576179 14.8388L7.42874 7.98523L0.575988 1.13247ZM0.971896 15.2484C1.22592 15.3571 1.5573 15.3227 1.91866 15.1178L9.98026 10.5367L7.83141 8.38789L0.971896 15.2484ZM10.4942 10.2453L13.0236 8.8082C13.8191 8.35548 13.8191 7.61576 13.0236 7.16361L10.4932 5.72576L8.23404 7.9852L10.4942 10.2453ZM9.97964 5.43396L1.91866 0.853489C1.55721 0.648089 1.22574 0.613874 0.971697 0.722848L7.83138 7.58253L9.97964 5.43396Z"
      fill="white"
    />
  </Svg>
);
export default SVGComponent;
