import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { ValidateCodeParams, ValidateForgotPasswordCodeResponse } from '@interfaces/auth';

async function validateCodeService(data: ValidateCodeParams) {
  const response = await axiosAuthInstance.post<ValidateForgotPasswordCodeResponse>(
    `/auth/verify/${data.userId}`,
    {
      code: data.code,
      type: data.type,
      password: data?.password
    }
  );
  return response.data;
}

export function useValidateCode() {
  return useMutation((data: ValidateCodeParams) => validateCodeService(data));
}
