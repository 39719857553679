import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon1 = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={25} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.143 6.877H8.5v-.5a4 4 0 014-4h6a4 4 0 014 4v6a4 4 0 01-4 4H18v-4.643a4.857 4.857 0 00-4.857-4.857zM6.5 22.377h6a4 4 0 004-4v-6a4 4 0 00-4-4h-6a4 4 0 00-4 4v6a4 4 0 004 4zm6.494-9.565a.75.75 0 01.07 1.058l-2.87 3.28a1.75 1.75 0 01-2.41.214l-1.753-1.402a.75.75 0 01.938-1.171l1.752 1.402a.25.25 0 00.345-.03l2.87-3.28a.75.75 0 011.058-.07z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon1;
