import React, { FC } from 'react';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  // DrawerItem,
  DrawerContentComponentProps,
} from '@react-navigation/drawer';
import { SvgProps } from 'react-native-svg';
import { ColorValue } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { Colors, Spacing } from '@theme';
import FullnameIcon from '@assets/svg/FullnameIcon';
import UsernameIcon from '@assets/svg/UsernameIcon';
import EmailIcon from '@assets/svg/EmailIcon';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import DeleteAccountIcon from '@assets/svg/DeleteAccountIcon';
import ChangeBio from '@screens/Main/AccountSettings/ChangeBio';
import ChangeEmail from '@screens/Main/AccountSettings/ChangeEmail';
import ChangeName from '@screens/Main/AccountSettings/ChangeName';
import ChangePassword from '@screens/Main/AccountSettings/ChangePassword';
import ChangeUsername from '@screens/Main/AccountSettings/ChangeUsername';
import DeleteAccount from '@screens/Main/AccountSettings/DeleteAccount';
// import LanguageIcon from '@assets/svg/LanguageIcon';
import Overview from '@screens/Main/AccountSettings/Overview';

import { AccountSettingsNavigatorParamList } from '../types';

import { styles } from './styles';

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const i18n = useTranslations();
  // const langIcon = () => <LanguageIcon />;
  return (
    <DrawerContentScrollView {...props} showsVerticalScrollIndicator={false}>
      <CustomText size="xbig" align="left" weight="interSemi" customStyle={styles.drawerTitle}>
        {i18n.t('settings.account_settings')}
      </CustomText>
      <DrawerItemList {...props} />
      {/* <DrawerItem
        label={`${i18n.t('account_settings.language_placeholder')}: English`}
        onPress={() => null}
        inactiveTintColor={Colors.greySix}
        icon={langIcon}
      /> */}
    </DrawerContentScrollView>
  );
};

const drawerItemIcon =
  (Icon: FC<SvgProps>, nonFocusColor?: ColorValue, focusColor: ColorValue = Colors.limeGreen) =>
  ({ focused }: { focused?: boolean }) =>
    <Icon fill={focused ? focusColor : nonFocusColor} />;

const Drawer = createDrawerNavigator<AccountSettingsNavigatorParamList>();

const AccountSettingsNavigator = () => {
  const i18n = useTranslations();
  return (
    <Drawer.Navigator
      screenOptions={{
        drawerType: 'permanent',
        headerShown: false,
        drawerActiveTintColor: Colors.limeGreen,
        drawerInactiveTintColor: Colors.greySix,
        drawerStyle: styles.drawer, // workarround default white border
        drawerItemStyle: styles.drawerItem,
        drawerLabelStyle: { marginHorizontal: -Spacing.SMALL },
      }}
      drawerContent={CustomDrawerContent}>
      <Drawer.Screen
        name="Overview"
        component={Overview}
        options={{
          drawerIcon: drawerItemIcon(UsernameIcon),
          title: i18n.t('account_settings.overview').toString(),
        }}
      />
      <Drawer.Screen
        name="ChangeName"
        component={ChangeName}
        options={{
          title: i18n.t('account_settings.fullname_placeholder').toString(),
          drawerIcon: drawerItemIcon(FullnameIcon),
        }}
      />
      <Drawer.Screen
        name="ChangeUsername"
        component={ChangeUsername}
        options={{
          title: i18n.t('account_settings.username_placeholder').toString(),
          drawerIcon: drawerItemIcon(UsernameIcon),
        }}
      />
      <Drawer.Screen
        name="ChangeBio"
        component={ChangeBio}
        options={{
          title: i18n.t('account_settings.bio_placeholder').toString(),
          drawerIcon: drawerItemIcon(FullnameIcon),
        }}
      />
      <Drawer.Screen
        name="ChangeEmail"
        component={ChangeEmail}
        options={{
          title: i18n.t('account_settings.email_placeholder').toString(),
          drawerIcon: drawerItemIcon(EmailIcon),
        }}
      />
      <Drawer.Screen
        name="ChangePassword"
        component={ChangePassword}
        options={{
          title: i18n.t('account_settings.password_placeholder').toString(),
          drawerIcon: drawerItemIcon(PasswordInputIcon),
        }}
      />
      <Drawer.Screen
        name="DeleteAccount"
        component={DeleteAccount}
        options={{
          title: i18n.t('account_settings.delete_account').toString(),
          drawerIcon: drawerItemIcon(DeleteAccountIcon, Colors.inputError, Colors.inputError),
          drawerActiveTintColor: Colors.inputError,
          drawerLabelStyle: { color: Colors.inputError, marginHorizontal: -Spacing.SMALL },
        }}
      />
    </Drawer.Navigator>
  );
};

export default AccountSettingsNavigator;
