import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { ActivityIndicator, Animated, Easing, Pressable, View } from 'react-native';
import Lottie from 'lottie-react-native';

import CustomText from '@atoms/CustomText';
import LikeIcon from '@assets/svg/Like';
import { useDislike } from '@services/useDislike';
import { useLike } from '@services/useLike';
import { Colors } from '@theme';
import { mediumVibration } from '@utils/vibration';
import { nFormatter } from '@utils/nFormatter';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/like.json');

interface LikesCTASProps {
  likesCount?: number;
  likeByYou?: boolean;
  dislikeByYou?: boolean;
  gameId: number;
  authToken?: string;
  navigation?: any;
}

export const LikeCTAS = ({
  likesCount = 0,
  likeByYou,
  dislikeByYou,
  gameId,
  authToken,
  navigation,
}: LikesCTASProps) => {
  const animationProgress = useRef(new Animated.Value(0));
  const [likeSelected, setLikeSelected] = useState<boolean | undefined>(likeByYou);
  const [dislikeSelected, setDislikeSelected] = useState<boolean | undefined>(dislikeByYou);

  const like = useLike();
  const dislike = useDislike();

  const animateLottie = useCallback((value: number) => {
    Animated.timing(animationProgress.current, {
      toValue: value,
      duration: value === 0 ? 0 : 5000,
      easing: Easing.linear,
      useNativeDriver: false,
    }).start();
  }, []);

  const onSocialPress = (value: 'like' | 'dislike') => {
    if (!authToken) {
      navigation.navigate('Login');
      return;
    }
    if (value === 'like') {
      like.mutate(gameId, {
        onSuccess: () => {
          setLikeSelected((prev) => !prev);
          setDislikeSelected(false);
          mediumVibration();
        },
      });
    }
    if (value === 'dislike') {
      dislike.mutate(gameId, {
        onSuccess: () => {
          setDislikeSelected((prev) => !prev);
          setLikeSelected(false);
          mediumVibration();
        },
      });
    }
  };

  useLayoutEffect(() => {
    if (likeSelected) {
      animateLottie(1);
    } else {
      animateLottie(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [likeSelected]);

  return (
    <View style={styles.socials}>
      <Pressable disabled={like.isLoading} onPress={() => onSocialPress('like')} style={styles.btn}>
        <View style={styles.socialButton}>
          {/* <LikeIcon selected={likeSelected ?? false} style={styles.mr6} /> */}
          <Lottie
            autoPlay={false}
            progress={animationProgress.current}
            source={lottie}
            style={styles.lottie}
            loop={false}
          />
          {like.isLoading ? (
            <ActivityIndicator color={Colors.greySix} />
          ) : (
            <CustomText color={likeSelected ? 'limeGreen' : 'greySix'}>
              {nFormatter(likesCount, 0)}
            </CustomText>
          )}
        </View>
      </Pressable>
      <Pressable disabled={like.isLoading} onPress={() => onSocialPress('dislike')} style={styles.btn}>
        <View style={styles.socialButton}>
          {dislike.isLoading ? (
            <ActivityIndicator color={Colors.greySix} />
          ) : (
            <LikeIcon selected={dislikeSelected ?? false} dislike />
          )}
        </View>
      </Pressable>
    </View>
  );
};
