import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CloseIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      d="M5.59 4.41a.833.833 0 00-1.18 1.18L8.823 10 4.41 14.41a.833.833 0 101.178 1.18L10 11.177l4.41 4.411a.833.833 0 001.18-1.178L11.177 10l4.411-4.41a.833.833 0 00-1.178-1.18L10 8.822l-4.41-4.41z"
      fill={fill || '#EFEFEF'}
    />
  </Svg>
);

export default CloseIcon;
