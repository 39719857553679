import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ToastErrorIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12c0 5.523-4.477 10-10 10H2.994c-.884 0-1.326-1.077-.702-1.707L4.25 18.32A9.959 9.959 0 012 12C2 6.477 6.477 2 12 2s10 4.477 10 10zm-6.793-3.207a1 1 0 010 1.414L13.414 12l1.793 1.793a1 1 0 01-1.414 1.414L12 13.414l-1.793 1.793a1 1 0 01-1.414-1.414L10.586 12l-1.793-1.793a1 1 0 011.414-1.414L12 10.586l1.793-1.793a1 1 0 011.414 0z"
      fill={fill || '#F5F5F5'}
    />
  </Svg>
);

export default ToastErrorIcon;
