import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SafeIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.428 4.638l4.165-1.85a3.334 3.334 0 012.708 0l4.148 1.843c1.213.54 2.01 1.75 1.924 3.076-.326 5.081-1.779 7.378-5.706 10.043a3.071 3.071 0 01-3.44 0C4.31 15.1 2.805 12.834 2.505 7.689c-.077-1.316.718-2.515 1.923-3.05zm8.49 4.626a.625.625 0 10-.942-.823l-2.391 2.733a.208.208 0 01-.287.025l-1.46-1.168a.625.625 0 10-.782.976l1.461 1.168c.61.488 1.495.41 2.008-.178l2.392-2.733z"
      fill={fill || '#FCFCFC'}
    />
  </Svg>
);

export default SafeIcon;
