import { AnalyticsEventsInterface } from "@interfaces/analytics";

/* eslint-disable @typescript-eslint/naming-convention */
export const AnalyticsEvents: AnalyticsEventsInterface = {
  APP_INIT: 'app_init',
  APP_CLOSE: 'app_close',
  AD_CLICK: 'ad_click',
  UPLOAD_GAME_BANNER: 'upload_game_banner',
  UPLOAD_GAME_MENU: 'upload_game_menu',
  OPEN_GAME_FROM_IMAGE: 'open_game_from_image',
  OPEN_GAME_FROM_BUTTON: 'open_game_from_button',
  AD_CAROUSEL_CLICK: 'ad_carousel_click',
  AD_HOME_CLICK: 'ad_home_click',
  AD_GAME_PAGE_CLICK: 'ad_game_page_click',
};
