import { StyleSheet } from 'react-native';

import { moderateScale, verticalScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  textError: { paddingHorizontal: moderateScale(10), marginBottom: moderateScale(20) },
  button: {
    width: '100%',
    marginTop: moderateScale(20),
  },
  dismiss: {
    marginTop: verticalScale(20),
    textDecorationLine: 'underline',
  },
});
