import * as RNLocalize from 'react-native-localize';

import { countries } from '@constants/countries';
import { isMobile } from '@constants/platform';

export function useCountryIsoCode() {
  const mobileISOCode = RNLocalize.getCountry();

  if (isMobile && mobileISOCode !== 'UN') return RNLocalize.getCountry();

  const USER_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const COUNTRY = countries.find((country: any) => country.timezones.includes(USER_TIMEZONE));

  if (COUNTRY) {
    return COUNTRY.code;
  }

  return 'UN';
}
