// Forms
const uploadingGameLink = 'https://bvdlvvfgjxn.typeform.com/to/vIdNixvp'; // Link for upload game
const gameQuestUploadGameLink = 'https://bvdlvvfgjxn.typeform.com/to/DLbaOETy'; // Link for upload game
const gameQuestLink = 'https://gamequest.gato.us/'; // Link for upload game

// Banners
const christmasDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-desktop.png';
const christmasMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-mobile.png';
const christmasTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/gato-christmas-2023/christmas-banner-tablet.png';

const gameQuestDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2023/GGQ_Desktop.gif';
const gameQuestMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2023/Taman%CC%83o%3DL%2C+Idioma%3DTodos%2C+Breakpoints%3DMobile+M%2C+compress%3Dtrue.png';
const gameQuestTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/game-quest-2023/Taman%CC%83o%3DL%2C+Idioma%3DTodos%2C+Breakpoints%3DTablet%2C+compress%3Dtrue.png';

const halloweenDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-desktop.png';
const halloweenMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-mobile.png';
const halloweenTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/halloween-2023/halloween-banner-tablet.png';

const avatarsMobileBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-mobile.png';
const avatarsDesktopBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-desktop.png';
const avatarsTabletBanner =
  'https://gato-files-prod.s3.amazonaws.com/gato-events/create-your-avatar-nov-23/avatars-banner-tablet.png';

// Sites
const gameQuestHomeLink = 'https://gamequest.gato.us/';
const gameQuestVoteLink = `${gameQuestHomeLink}vote/`;
const gatoAboutLink = 'https://gamequest.gato.us/about';
const avatarsGatoLink = 'https://avatars.gato.us/';

const links = {
  uploadingGameLink,
  gameQuestUploadGameLink,
  gameQuestLink,
  gameQuestDesktopBanner,
  gameQuestMobileBanner,
  gameQuestTabletBanner,
  gameQuestHomeLink,
  gameQuestVoteLink,
  halloweenDesktopBanner,
  halloweenMobileBanner,
  halloweenTabletBanner,
  avatarsGatoLink,
  gatoAboutLink,
  avatarsMobileBanner,
  avatarsDesktopBanner,
  avatarsTabletBanner,
  christmasTabletBanner,
  christmasMobileBanner,
  christmasDesktopBanner,
};

export default links;
