import React, { memo, useEffect } from 'react';
import { ActivityIndicator, Pressable, View } from 'react-native';
import { MotiView } from 'moti';
import { MotiPressable } from 'moti/interactions';
import { Easing } from 'react-native-reanimated';

import Field from '@molecules/Field';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Send from '@assets/svg/Send';
import { Colors } from '@theme';

import { styles } from './styles';

interface Props {
  formData: any;
  isLoading: boolean;
  fieldFocus: boolean;
  setFieldFocus: (values: any) => void;
  onSubmit: (values: any) => any;
  fromResponse?: boolean;
  handleDismiss?: () => void;
  fieldName: string;
}

// ToDo: Refactor all this functionality after the 24april Launch

const CommentField = ({
  formData,
  isLoading,
  fieldFocus,
  setFieldFocus,
  onSubmit,
  fromResponse,
  handleDismiss,
  fieldName,
}: Props) => {
  const i18n = useTranslations();

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    handleSubmit,
    setError,
    clearErrors,
  } = formData;

  const maxLength = 280;

  // const charactersLeft = maxLength - (watch(fieldName) ? Array.from(watch(fieldName)).length : 0);
  const charactersLeft = maxLength
    ? maxLength - (watch(fieldName) ? Array.from(watch(fieldName)).length : 0)
    : 0;

  // console.log(charactersLeft);

  // useEffect(() => {
  //   if (charactersLeft === -1) {
  //     setError(fieldName, { type: 'custom', message: 'error' });
  //   } else {
  //     clearErrors(fieldName);
  //   }
  // }, [charactersLeft, errors]);

  return isLoading ? (
    <View style={styles.loading}>
      <ActivityIndicator size={50} color={Colors.greySix} />
    </View>
  ) : (
    <View>
      <View style={[styles.messageContainer, fromResponse && styles.responseSpacing]}>
        <View style={styles.inputContainer}>
          <Field
            fromComments
            name={fieldName}
            control={control}
            placeholder={i18n.t(`${fromResponse ? 'comments.input_response' : 'comments.input'}`)}
            getValues={getValues}
            error={errors}
            containerStyles={styles.input}
            multiline
            numberOfLines={3}
            handleFocus={setFieldFocus}
            maxLength={280}
            autoFocus={fromResponse}
            withoutErrorText
          />
        </View>
        <Pressable onPress={handleSubmit(onSubmit)}>
          <MotiView
            style={styles.iconContainer}
            animate={{ opacity: fieldFocus ? 1 : 0.6 }}
            transition={{ duration: 300, easing: Easing.inOut(Easing.quad) }}>
            <Send />
          </MotiView>
        </Pressable>
        {fromResponse && (
          <MotiPressable
            onPress={handleDismiss}
            style={{ marginLeft: 13, marginBottom: 8 }}
            animate={({ hovered }) => {
              'worklet';

              return {
                opacity: hovered ? 1 : 0.6,
              };
            }}>
            <CustomText size="lmedium" weight="interMedium">
              {i18n.t('comments.report_comment_cancel')}
            </CustomText>
          </MotiPressable>
        )}
      </View>

      <CustomText
        align="left"
        color={!charactersLeft ? 'error' : 'greySix'}
        weight="interSemi"
        size="msmall"
        customStyle={[styles.charsLeft, fromResponse && styles.charsLeftResponse]}>
        {charactersLeft} {i18n.t('comments.chars_left')}
      </CustomText>
      {/* {charactersLeft > 0 || charactersLeft === undefined ? (
        <CustomText
          align="left"
          color="greySix"
          weight="interSemi"
          size="msmall"
          customStyle={[styles.charsLeft, fromResponse && styles.charsLeftResponse]}>
          {charactersLeft} {i18n.t('comments.chars_left')}
        </CustomText>
      ) : (
        <CustomText
          align="left"
          color="error"
          weight="interSemi"
          size="msmall"
          customStyle={[styles.charsLeft, fromResponse && styles.charsLeftResponse]}>
          {i18n.t('comments.max_length')}
        </CustomText>
      )} */}
    </View>
  );
};

export default memo(CommentField);
