import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const EmailFillIcon = (props: SvgProps) => (
  <Svg width={16} height={14} viewBox="0 0 16 14" fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.784404 2.7658L6.23549 6.73022C7.28744 7.49528 8.71256 7.49528 9.76451 6.73022L15.2156 2.7658C14.6262 1.29152 13.1847 0.25 11.5 0.25H4.5C2.81529 0.25 1.37384 1.29152 0.784404 2.7658ZM15.5 5.03195L10.9409 8.34769C9.18761 9.62278 6.8124 9.62278 5.05914 8.34769L0.5 5.03195V9.75C0.5 11.9591 2.29086 13.75 4.5 13.75H11.5C13.7091 13.75 15.5 11.9591 15.5 9.75V5.03195Z"
      fill="#777E91"
    />
  </Svg>
);
export default EmailFillIcon;
