import React, { useState } from 'react';
import { CommonActions, useNavigation } from '@react-navigation/core';
import { FlatList, ImageBackground, Pressable, View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import Lottie from 'lottie-react-native';
import { useTranslation } from 'react-i18next';

import { HelpVideoAdIcon } from '@assets/svg/HelpVideoAdIcon';
import { animateLayout } from '@utils/animation';
import CustomText from '@atoms/CustomText';
import { setGameIdHandler, useStore } from '@store/index';

import { limeGreen } from '../../../theme/colors';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/empty_state_gato_ovni.json');

interface GameItemProps {
  item: Game;
  index: number;
  length: number;
}

const GameItem = ({ item }: GameItemProps) => {
  const navigation = useNavigation();
  const setGameId = useStore(setGameIdHandler);

  const handlePress = () => {
    setGameId(item.id);

    const { gameSlug } = item;

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: gameSlug,
      },
      key: `GamePage/${gameSlug}`,
    });
    navigation.dispatch(navigateAction);
  };

  return (
    <Pressable onPress={handlePress}>
      <ImageBackground style={styles.item} source={{ uri: item?.principalImage }}>
        <LinearGradient
          start={{ x: 1, y: 0 }}
          end={{ x: 1, y: 1 }}
          colors={['rgba(0,0,0,0)', 'rgba(0,0,0,0.5)']}
          style={styles.gradient}
        />
        <CustomText size="mbig" weight="interSemi" customStyle={styles.text} align="left">
          {item?.name}
        </CustomText>
      </ImageBackground>
    </Pressable>
  );
};

interface CreatorGameListProps {
  games: Game[];
  isOwnProfile?: boolean;
  name?: string;
}

export const CreatorGameList = ({ games, isOwnProfile, name }: CreatorGameListProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const i18n = useTranslation();

  return (
    <View style={styles.container}>
      <View>
        {isOwnProfile ? (
          <Pressable
            onPress={() => {
              animateLayout();
              setShowTooltip((prev) => !prev);
            }}>
            <CustomText
              customStyle={styles.title}
              size="mlarge"
              align="left"
              weight="interSemi"
              color="greySix">
              {i18n.t('profile.your_games')} <HelpVideoAdIcon />
            </CustomText>
          </Pressable>
        ) : (
          <CustomText
            customStyle={styles.title}
            size="mlarge"
            align="left"
            weight="interSemi"
            color="greySix">
            {i18n.t('profile.games_by')}{' '}
            <CustomText
              size="mlarge"
              customStyle={styles.title}
              align="left"
              weight="interSemi"
              color="purple">
              {name}
            </CustomText>
          </CustomText>
        )}
      </View>
      {showTooltip && (
        <Pressable
          onPress={() => {
            setShowTooltip(false);
          }}
          style={styles.tooltipContainer}>
          <View style={styles.tooltipBackground}>
            <View style={styles.tooltipIndicator} />

            <View style={styles.tooltipTitle}>
              <HelpVideoAdIcon style={styles.icon} fill={limeGreen} />
              <CustomText weight="interSemi" align="left">
                Edit your games
              </CustomText>
            </View>
            <CustomText align="left" color="greySix" weight="interSemi">
              We are working on specific tools for game creators. If you need to edit your games, please
              contact us.
            </CustomText>
          </View>
        </Pressable>
      )}

      {!games || games?.length === 0 ? (
        <View style={styles.emptyContainer}>
          <Lottie source={lottie} autoPlay loop style={styles.lottie} />

          <CustomText color="greySix" weight="interSemi" size="big">
            {isOwnProfile ? "You haven't uploaded any game yet" : `${name}  hasn´t uploaded any game yet`}
          </CustomText>
        </View>
      ) : (
        <FlatList
          horizontal
          showsHorizontalScrollIndicator={false}
          data={games}
          keyExtractor={(item) => item.id.toString()}
          renderItem={({ item, index }) => <GameItem item={item} index={index} length={games?.length} />}
          contentContainerStyle={styles.itemContainer}
        />
      )}
    </View>
  );
};

export interface Game {
  id: number;
  name: string;
  gameSlug: string;
  principalImage: string;
  description: string;
  sensitiveMaterial: boolean;
  controlType: string;
  operatingSystem: string;
  status: string;
  platform: string;
  stars: null;
  points: null;
  pointsQuantity: null;
  numberOfPlayers: number;
  recomendedYear: null;
  createdDate: string;
  carrouselPrincipal: boolean;
  principalOrder: null;
  carrouselRecomended: boolean;
  recomendedOrder: number;
  carrouselSelected: boolean;
  selectedOrder: number;
  carrouselLikes: boolean;
  likesOrder: number;
}
