import * as React from 'react';
import { SVGProps } from 'react';

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={23} height={23} fill="none" {...props}>
    <path
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.fill || '#fff'}
      fillRule="evenodd"
      d="M20.621 5.526a3.147 3.147 0 0 1-5.598 1.975.688.688 0 0 1-.063.036l-6.058 3.03a.67.67 0 0 1-.067.028 3.155 3.155 0 0 1 0 1.552.67.67 0 0 1 .067.028l6.058 3.03a.688.688 0 0 1 .063.036 3.147 3.147 0 1 1-.6 1.2.699.699 0 0 1-.066-.03l-6.059-3.03a.681.681 0 0 1-.063-.035 3.147 3.147 0 1 1 0-3.95.68.68 0 0 1 .063-.036l6.059-3.03a.683.683 0 0 1 .066-.028 3.147 3.147 0 1 1 6.198-.776Z"
      clipRule="evenodd"
    />
  </svg>
);
export default ShareIcon;
