import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const BioIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={21} height={21} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.625 2.625h1.75a7.875 7.875 0 010 15.75H5.25a3.5 3.5 0 01-3.5-3.5V10.5a7.875 7.875 0 017.875-7.875zm-2.146 9.176a.656.656 0 00-.958.898c1.182 1.26 2.548 1.957 3.979 1.957 1.43 0 2.797-.696 3.979-1.957a.656.656 0 00-.958-.898c-1.005 1.073-2.046 1.543-3.021 1.543-.975 0-2.016-.47-3.021-1.543z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default BioIcon;
