import React, { useEffect, useRef, useState } from 'react';
import { ActivityIndicator, View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useFocusEffect } from '@react-navigation/core';
import { AxiosError } from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';

import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { Colors } from '@theme';
import useTimer from '@hooks/useTimer';
import useAppCurrentVisibility from '@hooks/useAppCurrentVisibility';
import { useMetricsSession } from '@services/useMetricsSession';
import toast from '@utils/toast';
import { useStore, authTokenSelector } from '@store/index';
import { useUserData } from '@services/useUserData';
import useCloseTab from '@hooks/useCloseTab';

import { RootStackNavigator } from '../../../navigation/types';

import useLeaveGameModal from './useLeaveGameModal';
import { styles } from './styles';

type GameWebViewProps = NativeStackScreenProps<RootStackNavigator, 'GameWebView'>;

const GameWebView = ({ route, navigation }: GameWebViewProps) => {
  const resourceLink = route?.params?.resourceLink;
  const backInRight = route?.params?.backInRight;
  const { name, gameId } = route?.params;
  const [isLoading, setIsLoading] = useState(true);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { seconds, startTimer, pauseTimer, resumeTimer, resetTimer } = useTimer();
  const metricsSession = useMetricsSession();
  const authToken = useStore(authTokenSelector);
  const userId = useUserData(authToken || '');
  const appVisibility = useAppCurrentVisibility();
  const [inactivityDate, setInactivityDate] = useState<Date | null>();

  async function saveSessionInDatabase() {
    await AsyncStorage.getItem('sessionSeconds').then((res) => {
      const values = {
        gameId,
        totalTime: Number(res) || seconds,
        userId: userId?.data?.id || '',
      };
      metricsSession.mutate(values, {
        onSuccess: () => {
          setInactivityDate(null);
          resetTimer();
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = error?.response?.data.message;
            toast.danger({
              title: message,
              icon: 'error',
            });
          }
        },
      });
    });
  }

  const calculatePlayTime = async () => {
    const dateNow = new Date();

    if (!inactivityDate) return;

    // Calculate the time difference in milliseconds
    const timeDifferenceMilliseconds = dateNow.getTime() - inactivityDate.getTime();

    // Convert milliseconds to seconds
    const timeDifferenceSeconds = Math.round(Math.round(timeDifferenceMilliseconds) / 1000);

    const timeInMinutes = Math.round(timeDifferenceSeconds / 60);

    if (timeInMinutes < 30) {
      setInactivityDate(null);
      resumeTimer();
      return;
    }

    pauseTimer();
    saveSessionInDatabase();
  };

  const handleLeaveGame = useLeaveGameModal(calculatePlayTime, name);

  useCloseTab(saveSessionInDatabase);

  useEffect(() => {
    const handleBackgroundActivity = () => {
      pauseTimer();
      setInactivityDate(new Date());
    };

    const handleActiveActivity = () => {
      if (seconds === 0) {
        startTimer();
      } else {
        calculatePlayTime();
      }
    };

    if (appVisibility === 'background' || appVisibility === 'inactive') {
      handleBackgroundActivity();
    } else if (appVisibility === 'active') {
      handleActiveActivity();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVisibility]);

  const handleError: React.ReactEventHandler<HTMLIFrameElement> = (syntheticEvent) => {
    const { nativeEvent } = syntheticEvent;
    // eslint-disable-next-line no-console
    console.warn('WebView error: ', nativeEvent);
  };

  useFocusEffect(() => {
    navigation.setOptions({
      title: `Game | ${name}`,
    });
  });

  const handleIframeLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      startTimer();
      handleIframeLoad();
    }, 3000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   window.addEventListener("popstate", e => {
  //     calculatePlayTime()
  //   })
  // }, [navigation])

  return (
    <Layout padding={false} withScroll={false}>
      {isLoading ? (
        <View style={styles.loading}>
          <ActivityIndicator size={64} color={Colors.greySix} />
        </View>
      ) : (
        <iframe
          title={`Game | ${name}`}
          src={resourceLink}
          height="100%"
          width="100%"
          style={styles.iframe}
          onError={handleError}
          ref={iframeRef}
          onLoad={handleIframeLoad}
        />
      )}
      <View style={[styles.header, backInRight ? styles.backInRight : null]}>
        <GoBackButton fromRight={backInRight} gatoLogo={false} goBackAction={handleLeaveGame} />
      </View>
    </Layout>
  );
};

export default GameWebView;
