import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isMobile } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    height: moderateScale(24),
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: moderateScale(32),
    backgroundColor: isMobile ? Colors.darkGrey : undefined,
    minHeight: moderateScale(48),
    marginTop: moderateScale(20),
  },
  readed: {
    width: moderateScale(8),
    height: moderateScale(8),
    backgroundColor: Colors.purple,
    borderRadius: moderateScale(8) / 2,
    marginRight: moderateScale(12),
  },
  avatar: {
    width: moderateScale(40),
    height: moderateScale(40),
    borderRadius: moderateScale(40 / 2),
    marginTop: 0,
  },
  creator: {
    position: 'absolute',
    zIndex: 99,
    top: -8,
    right: -8,
    transform: [{ scale: 0.72 }],
  },
  info: {
    flex: 1,
    alignItems: 'flex-start',
    marginLeft: moderateScale(12),
  },
  inline: {
    flexDirection: 'row',
  },
  text: {
    textAlign: 'left',
    lineHeight: moderateScale(24),
  },
  button: {
    position: 'absolute',
    right: 0,
    backgroundColor: Colors.greyTwo,
    paddingVertical: moderateScale(9),
    paddingHorizontal: moderateScale(16),
    borderRadius: moderateScale(90),
  },
});
