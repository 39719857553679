import { StyleSheet } from 'react-native';

import { isMobile, SCREEN_WIDTH } from '@constants/platform';
import { Colors, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  footerButton: {
    width: isMobile ? SCREEN_WIDTH - Spacing.LATERAL * 2 : '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(24),
  },
  marginBottom: {
    marginBottom: moderateVerticalScale(82),
  },
  footerButtonText: {
    fontSize: moderateScale(18),
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  description: {
    marginLeft: moderateScale(9),
  },
  header: {
    paddingHorizontal: moderateScale(20),
    justifyContent: 'space-between',
    marginTop: moderateScale(16),
  },
  descriptionWrapper: {
    marginTop: moderateScale(7),
  },
  headTitle: {
    alignSelf: 'center',
    flex: 1,
    marginLeft: moderateScale(-35),
  },
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    zIndex: 3,
  },
  item: {
    padding: moderateScale(16),
    borderRadius: moderateScale(16),
    backgroundColor: Colors.blackTwo,
    marginBottom: moderateScale(16),
  },
  itemTitle: {
    marginBottom: moderateScale(10),
  },
  roundedIcon: {
    width: moderateScale(64),
    height: moderateScale(64),
    borderRadius: moderateScale(64) / 2,
    borderWidth: 1,
    borderColor: Colors.greyFour,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: moderateScale(12),
  },
  later: {
    paddingHorizontal: moderateScale(30),
  },
  items: {
    marginTop: moderateScale(20),
  },
  itemDescription: {
    maxWidth: '75%',
  },
  itemRow: {
    flexDirection: 'row',
    marginTop: moderateScale(16),
  },
  itemTexts: {
    maxWidth: moderateScale(300),
    marginLeft: moderateScale(20),
  },
  neededItemTitle: {
    marginBottom: moderateScale(5),
  },
  neededItemDescription: {
    // maxWidth: '85%',
    letterSpacing: 0.5,
  },
  shareItem: {
    width: '30.75%',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: moderateScale(12),
    borderRadius: moderateScale(12),
    borderWidth: 1,
    borderColor: Colors.greyFour,
  },
  middle: {
    marginHorizontal: moderateScale(12),
  },
  shareItems: {
    justifyContent: 'space-between',
    marginTop: moderateScale(16),
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: {
    flex: 0.6,
    alignItems: 'center',
  },
  stepContainer: {
    flex: 0.4,
  },
  text: {
    width: '100%',
  },
});
