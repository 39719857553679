import React from 'react';
import CustomText from '@atoms/CustomText';
import { Layout } from '@organisms/Layout';
import { Spacing } from '@theme';
import { View } from 'react-native';
import { isWebResponsive } from '@constants/platform';
import { Policys } from './constants';
import { styles } from './styles';

interface BodyTextProps{
  body: string[]
  id: number
}

const PolicyOfPrivacy = () => {
  const BodyText = ({ body, id }: BodyTextProps) => (
    body.map((paragraph: string) => {
      return  (
        <CustomText 
          key={`$body-${id}`}                
          weight="interSemi"
          align='left'
          >
          {paragraph}
        </CustomText>)
    })
  )

  return(
    <>
      <Layout style={styles.container}>
        <CustomText 
          customStyle={{marginVertical: Spacing.MEDIUM}} 
          size="large" 
          weight="interSemi" 
          color="greySix"
          align={isWebResponsive? 'left' : 'center'}
        >
          GATO Games Global LLC Privacy Policy
        </CustomText>
        <CustomText weight='interMedium' align='left'>
            Last Updated: June 21, 2023
        </CustomText>
        <CustomText weight='interMedium' align='left'>Welcome to GATO, an application developed and maintained by GATO Games Global LLC ("we", "us", "our"). This Privacy Policy explains how we collect, use, and share your information when you use our GATO application ("App") and our commitment to protecting your privacy rights.</CustomText>
          { Policys.map(({ id, title, subtitule, body }) => (
              <View key={id}>
                {title &&
                  <CustomText
                    size='xmedium'
                    align='left'
                    customStyle={{
                      marginVertical: Spacing.SMALL
                    }} 
                  >
                    {title}
                  </CustomText>
                }
                {subtitule && 
                  <CustomText
                    weight='interMedium' 
                    align='left'
                    customStyle={{
                      textDecorationLine: 'underline', 
                      marginVertical: Spacing.SMALL
                    }} 
                  >
                    {subtitule}
                  </CustomText>
                }
                <BodyText body={body} id={id} />
              </View>
            ))
          }
      </Layout>
    </> 
  )
};

export default PolicyOfPrivacy;
