import React, { FC, memo } from 'react';
import { Pressable, TextStyle, ViewStyle } from 'react-native';

import CustomText, { Colors, Sizes } from '@atoms/CustomText';

import styles from './styles';

interface IButtonLine {
  onPress: () => void;
  children: any;
  customTextStyle?: TextStyle;
  customContainerStyle?: ViewStyle | ViewStyle[];
  hasDecoration?: boolean;
  color?: Colors;
  sizes?: Sizes;
}

const ButtonLine: FC<IButtonLine> = ({
  onPress,
  children,
  customTextStyle = {},
  customContainerStyle = {},
  hasDecoration,
  color = 'greySix',
  sizes = 'small',
}) => (
  <Pressable onPress={onPress} style={customContainerStyle}>
    <CustomText
      weight="interBold"
      color={color}
      customStyle={[hasDecoration ? styles.decoration : {}, customTextStyle]}
      size={sizes}>
      {children}
    </CustomText>
  </Pressable>
);

export default memo(ButtonLine);
