/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/naming-convention */
import axios from 'axios';

import toast from '@utils/toast';
import { getLanguage } from '@utils/getLanguage';

export const buildLink = async (key: 'games' | 'profile', id: number | string) => {
  try {
    const link = await axios({
      method: 'POST',
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyB_WQrjWYYG2bAYiPHF3_TcWvFQGHBaiDA`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix: 'https://gatogames.page.link',
          link: `https://gato.us/${key}/${id}`,
          androidInfo: {
            androidPackageName: 'com.gatogames.app',
          },
          iosInfo: {
            iosBundleId: 'com.gatogames.app.dev',
          },
        },
      },
    });
    if (link.status === 200) {
      return link.data.shortLink;
    }
  } catch (error) {
    toast.danger({ title: getLanguage() === 'en' ? 'Something went wrong' : 'Ha ocurrido un error', icon: 'error' });
  }
};

// eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
export const lottie = require('../../../assets/lotties/share.json');
