import React, { useEffect, useState } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import { CompositeScreenProps } from '@react-navigation/core';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';

import Skeleton from '@atoms/Skeleton';
import {
  isDesktop,
  isMobile,
  IS_SMALL_DEVICE,
  SCREEN_WIDTH,
  WINDOW_WIDTH,
  MIN_RESOLUTION_SUPPORTED,
  isWebResponsive,
} from '@constants/platform';
import { useStatusBar } from '@hooks/useStatusBar';
import GatoBanners from '@molecules/GatoBanners';
import Marquee from '@molecules/Marquee';
import { CustomModal } from '@molecules/CustomModal';
import { Layout } from '@organisms/Layout';
import Slider from '@organisms/Slider';
import { useHomeCarousels } from '@services/useHomeCarousels';
import { useGetLastVersion } from '@services/useGetLastVersion';
import { Colors, Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import UpdateApp from '@molecules/UpdateApp';
import { ShareAppBanner } from '@atoms/ShareAppBanner';
import { authTokenSelector, useStore } from '@store/index';
import GatoBannersDesktop from '@molecules/GatoBannersDesktop';
import { MainNavigatorParamList, RootStackNavigator } from 'app/navigation/types';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';

import { prefetchMyProfile } from '../../../services/useMyProfile';

import { styles } from './styles';
import useCheckAvatar from './useCheckAvatar';
import { BANNER_SIZE, CARD_SIZE } from '@constants/sizes';
import AdImageComponent from '@organisms/AdComponent';
import { GoogleAdManagerUnitIdEnum } from '@atoms/GoogleAdManagerBanner/constants';

const PAGE_WIDTH = isMobile ? SCREEN_WIDTH : WINDOW_WIDTH;
const MAIN_SLIDER_HEIGHT = 408;
const SLIDER_WIDTH_MOBILE = PAGE_WIDTH - Spacing.LATERALBIG * 2;

type HomeProps = CompositeScreenProps<
  | BottomTabScreenProps<MainNavigatorParamList, 'Home'>
  | NativeStackScreenProps<MainNavigatorParamList, 'Home'>,
  NativeStackScreenProps<RootStackNavigator>
>;

const Home = ({ navigation }: HomeProps) => {
  const authToken = useStore(authTokenSelector);
  const [showUpdateApp, setShowUpdateApp] = useState(false);
  useStatusBar('light');
  const [sliderWidth, setSliderWidth] = useState(isMobile ? SLIDER_WIDTH_MOBILE : undefined);

  const WEB_SMALL_RESOLUTION = Boolean(sliderWidth && sliderWidth < MIN_RESOLUTION_SUPPORTED) ?? false;

  const countryISOCode = useCountryIsoCode();

  const { data, isLoading, refetch: refetchGames } = useHomeCarousels(WEB_SMALL_RESOLUTION, countryISOCode);

  const { data: lastVersion } = useGetLastVersion();

  // function preloadImages(images: Array<{ uri: string }>) {
  //   FastImage.preload(images);
  // }

  // useEffect(() => {
  //   if (data) {
  // const info = data.map((i) =>
  //   i.items.map((j) => {
  //     if (j?.game) return j.game.principalImage;
  //     return j.advertising.imageUrl;
  //   })
  // );
  // const uriArr = info.flat().map((url) => ({
  //   uri: url,
  // }));
  // preloadImages(uriArr);
  // preloadImages(images);
  // }
  // }, [data]);

  useEffect(() => {
    if (lastVersion === false) {
      setShowUpdateApp(true);
    }
  }, [lastVersion]);

  useEffect(() => {
    if (authToken) prefetchMyProfile();
  }, [authToken]);

  const handleLayout = (event: LayoutChangeEvent) => {
    if (isDesktop) {
      setSliderWidth(event.nativeEvent.layout.width);
      refetchGames();
    }
  };

  useCheckAvatar(navigation);

  const renderShareBanner = () => {
    if (isMobile)
      return (
        <>
          <ShareAppBanner />
          <GatoBanners />
        </>
      );
    if (isWebResponsive) return <ShareAppBanner />;
    return <GatoBannersDesktop />;
  };

  const renderCarousel = (carousel: any, index: any, type: 'small' | 'big' | 'principal', googleAdManagerUnitId) => {
    switch (type) {
      case 'small':
        return (
          <Slider
            qtyToSlide={2}
            bannerDimentions={{
              width: CARD_SIZE.HOME_CARD_MOBILE.WIDTH,
              height: CARD_SIZE.HOME_CARD_MOBILE.HEIGHT,
            }}
            title={carousel.translate}
            banners={carousel.items}
            arrows={isDesktop}
            directSale={
              carousel.items.find((item) => item.type === 'ADVERTISING')?.advertising?.direct_sale || false
            }
            googleAdManagerUnitId={googleAdManagerUnitId}
          />
        );
      case 'big':
        return (
          <Slider
            showTags
            tagColor={Colors.limeGreen}
            tagTextColor={Colors.black}
            key={`${carousel.title + index}`}
            qtyToSlide={1}
            bannerDimentions={{
              width: CARD_SIZE.HOME_CARD_DESKTOP.WIDTH,
              height: CARD_SIZE.HOME_CARD_DESKTOP.HEIGHT,
            }}
            title={carousel.translate}
            banners={carousel.items}
            arrows
            directSale={
              carousel.items.find((item) => item.type === 'ADVERTISING')?.advertising?.direct_sale || false
            }
            googleAdManagerUnitId={googleAdManagerUnitId}
          />
        );
      case 'principal':
        return (
          <Slider
            key={`${(carousel.title || 'ppal') + index}`}
            ppal
            pagination
            tagColor={Colors.purple}
            showTags
            qtyToSlide={1}
            innerContainerStyles={{ marginTop: moderateScale(36) }}
            bannersGap={Spacing.LATERALPLUS}
            bannerDimentions={{
              width: sliderWidth,
              height: moderateScale(MAIN_SLIDER_HEIGHT),
            }}
            banners={carousel.items}
            containerStyles={styles.sliderContainer}
            arrows
            arrowsStyle={styles.arrowsStyle}
            directSale={data?.direct_sale}
            googleAdManagerUnitId={googleAdManagerUnitId}
          />
        );
      default:
        return (
          <Slider
            qtyToSlide={2}
            bannerDimentions={{
              width: CARD_SIZE.HOME_CARD_MOBILE.WIDTH,
              height: CARD_SIZE.HOME_CARD_MOBILE.HEIGHT,
            }}
            title={carousel.translate}
            banners={carousel.items}
            arrows={isDesktop}
            directSale={
              carousel.items.find((item) => item.type === 'ADVERTISING')?.advertising?.direct_sale || false
            }
          />
        );
    }
  };

  const getCarouselSize = (name: string) => {
    if (
      isMobile ||
      isWebResponsive ||
      name === 'gatosTopPicks' ||
      name === 'gamesForYou' ||
      name === 'bestForDesktop' ||
      name === 'bestForMobile'
    ) {
      return 'small';
    }
    return 'big';
  };

  const renderAdBanner = (index: number) => {
    let width;
    let height;
    let googleAdManagerUnitId: GoogleAdManagerUnitIdEnum | undefined;
    if (!isWebResponsive) {
      // Is web desktop
      if (index === 1 || index === 3) {
        width = BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.WIDTH;
        height = BANNER_SIZE.GAME_BANNER_LARGE_DESKTOP.HEIGHT;
        googleAdManagerUnitId = index === 1 ? GoogleAdManagerUnitIdEnum.HOME_BANNER_728_X_90_1 : GoogleAdManagerUnitIdEnum.HOME_BANNER_728_X_90_2;
      } else if (index === 2 || index === 5) {
        width = BANNER_SIZE.HOME_BANNER_DESKTOP.WIDTH;
        height = BANNER_SIZE.HOME_BANNER_DESKTOP.HEIGHT;
        googleAdManagerUnitId = index === 2 ? GoogleAdManagerUnitIdEnum.HOME_BANNER_970_X_250_1 : GoogleAdManagerUnitIdEnum.HOME_BANNER_970_X_250_2;
      }
    } else {
      if (index === 1 || index === 5) {
        width = BANNER_SIZE.GAME_BANNER_MOBILE.WIDTH;
        height = BANNER_SIZE.GAME_BANNER_MOBILE.HEIGHT;
        googleAdManagerUnitId = index === 1 ? GoogleAdManagerUnitIdEnum.HOME_BANNER_320_X_100_1 : GoogleAdManagerUnitIdEnum.HOME_BANNER_320_X_100_2;
      } else if (index === 3) {
        width = BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.WIDTH;
        height = BANNER_SIZE.GAME_BANNER_SMALL_DESKTOP.HEIGHT;
        googleAdManagerUnitId = GoogleAdManagerUnitIdEnum.HOME_BANNER_300_X_250_1
      }
    }
    if (width && height && googleAdManagerUnitId) {
      return (
        <View style={{ marginBottom: Spacing.XLARGE }}>
          <AdImageComponent
            width={width}
            height={height}
            analyticsEvent={AnalyticsEvents.AD_HOME_CLICK}
            googleAdManagerUnitId={googleAdManagerUnitId}
          />
        </View>
      );
    }
  };
  const getGoogleAdUnitTypeForCarrouselCardByName = (name: string) => {
    // Now, this is hardcoded beacuse we know the order of the carrousels and which one has ads and which one not in web desktop view
    if (!isWebResponsive) {
      // Is web desktop
      if (name === 'latestReleases') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_1;
      }
      if (name === 'mostPlayed') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_2;
      }
      if (name === 'toBeDiscovered') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_3;
      }
    } else {
      if (name === 'latestReleases') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_1;
      }
      if (name === 'mostPlayed') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_2;
      }
      if (name === 'toBeDiscovered') {
        return GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_3;
      }
    }
    return undefined;
  }
  return (
    <>
      <Marquee />
      <Layout
        padding={isDesktop}
        withScroll
        showScrollIndicator={false}
        tabOffset={IS_SMALL_DEVICE ? 100 : 80}
        paddingTop={0}
        desktopFooter>
        {isLoading ? (
          <View style={styles.skeletonContainer}>
            <Skeleton
              customStyle={styles.ppalSkeleton}
              width={sliderWidth}
              height={moderateScale(MAIN_SLIDER_HEIGHT)}
            />
            <View style={styles.sliderSkeleton}>
              <Skeleton customStyle={styles.slideSkeleton} width={147} height={moderateScale(190)} />
              <Skeleton width={147} height={moderateScale(190)} />
            </View>
          </View>
        ) : null}
        <View onLayout={handleLayout} />
        {sliderWidth &&
          !isLoading &&
          data &&
          data?.carrosuels.map((carousel, index) => {
            if (index === 0) {
              return renderCarousel(carousel, index, 'principal', getGoogleAdUnitTypeForCarrouselCardByName(carousel.name));
            }
            if (carousel.items.length === 1 && carousel.items[0].type === 'ADVERTISING') {
              // Keep this to prevent downtime only
            }
            return (
              <View key={`${carousel.title + index}`}>
                {renderCarousel(carousel, index, getCarouselSize(carousel.name), getGoogleAdUnitTypeForCarrouselCardByName(carousel.name))}
                {index === 2 && renderShareBanner()}
                {renderAdBanner(index)}
              </View>
            );
          })}
      </Layout>
      {showUpdateApp ? (
        <CustomModal visible={showUpdateApp} onOverlayPress={() => setShowUpdateApp(false)}>
          <UpdateApp handleDismiss={() => setShowUpdateApp(false)} />
        </CustomModal>
      ) : null}
    </>
  );
};

export default Home;
