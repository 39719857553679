import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const DeviceWebDesktop = (props: SvgProps) => (
  <Svg width={51} height={47} fill="none" {...props}>
    <Rect width={50.273} height={46.242} fill="#1A1D1F" rx={9.248} />
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      d="M20 15.611a2.371 2.371 0 0 0-2.372 2.372v8.423h15.019v-8.423a2.371 2.371 0 0 0-2.372-2.372H20Zm-3.544 12.202a.704.704 0 0 0-.704.704c0 1.167.946 2.112 2.112 2.112h14.55a2.112 2.112 0 0 0 2.111-2.112.704.704 0 0 0-.704-.704H16.456Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default DeviceWebDesktop;
