import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

const DeviceWebMobile = (props: SvgProps) => (
  <Svg width={51} height={47} fill="none" {...props}>
    <Rect width={50.273} height={46.242} y={0.242} fill="#1A1D1F" rx={9.248} />
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      d="M32.536 17.814a3.7 3.7 0 0 0-3.7-3.7h-7.398a3.7 3.7 0 0 0-3.7 3.7v11.098a3.7 3.7 0 0 0 3.7 3.7h7.398a3.7 3.7 0 0 0 3.7-3.7V17.814Zm-8.324 12.023a.925.925 0 1 0 1.85 0 .925.925 0 0 0-1.85 0Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default DeviceWebMobile;
