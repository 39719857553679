/* eslint-disable react-native/no-color-literals */
import { StyleSheet } from 'react-native';

import { SCREEN_HEIGHT, isMobile } from '@constants/platform';
import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  headerTop: {
    position: 'absolute',
    top: moderateScale(20),
    left: moderateScale(20),
    zIndex: 10000000,
  },
  linear: { position: 'absolute', width: '100%', height: '100%', zIndex: 1 },
  container: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'black',
  },
  content: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 5000,
    alignItems: 'flex-end',
  },
  footerButton: {
    backgroundColor: 'rgba(255,255,255,.5)',
    paddingVertical: moderateScale(12),
    paddingHorizontal: moderateScale(36),
    position: 'absolute',
    bottom: 40,
    right: 60,
    zIndex: 6000,
    alignSelf: 'center',
  },
  footerButtonText: {
    color: Colors.white,
    fontSize: moderateScale(18),
  },
  rowheader: { paddingHorizontal: 20, justifyContent: 'space-between' },
  video: { ...StyleSheet.absoluteFillObject, width: '100%', height: '100%' },
  header: { ...StyleSheet.absoluteFillObject },
  row: { flexDirection: 'row', alignItems: 'center' },
  tooltipCta: { borderRadius: 30, overflow: 'hidden' },
  tooltipUi: {
    paddingVertical: 5,
    paddingHorizontal: 12,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  },
  seconds: { paddingRight: 20, marginTop: 20 },
  close: {
    height: 40,
    width: 40,
    justifyContent: 'center',
    alignItems: 'center',
  },
  ml6: { marginLeft: 6 },
  tooltipContainer: {
    backgroundColor: '#312F40',
    width: isMobile ? '90%' : undefined,
    position: 'absolute',
    top: 60,
    zIndex: 2147483647,
    alignSelf: isMobile ? 'center' : 'flex-end',
    right: isMobile ? undefined : 65,
    borderRadius: 16,
    padding: 16,
  },
  overlay: { backgroundColor: 'rgba(255,255,255,0.5)', padding: 5, borderRadius: 20 },
  tooltipIndicator: {
    backgroundColor: '#312F40',
    height: 10,
    width: 10,
    position: 'absolute',
    zIndex: 3,
    transform: [{ rotate: '45deg' }],
    top: -5,
    right: 30,
  },
  inear: { position: 'absolute', width: '100%', height: '100%', zIndex: 1 },
  mr6: { marginRight: 6 },
  tooltipDescription: { marginTop: 6, color: '#717788' },
  loading: {
    position: 'absolute',
    top: SCREEN_HEIGHT / 2 - (SCREEN_HEIGHT / 2 - SCREEN_HEIGHT / 2.5),
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 4,
    height: SCREEN_HEIGHT / 4,
  },

  desktopVideo: {
    width: '100%',
    height: '100%',
  },
});
