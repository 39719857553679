import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { isMobile } from '@constants/platform';

const AVATAR_WIDTH = isMobile ? 56 : 80;
const AVATAR_HEIGHT = isMobile ? 56 : 80;

export const styles = StyleSheet.create({
  container: {
    paddingVertical: moderateScale(20),
    // paddingHorizontal: isMobile ? moderateScale(20) : moderateScale(100),
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: moderateScale(44),
  },
  texts: {
    marginLeft: moderateScale(16),
  },
  filters: {
    flexDirection: 'row',
    marginBottom: moderateScale(44),
  },
  filter: {
    borderWidth: 2,
    borderRadius: moderateScale(30),
    borderColor: `${Colors.greyEight}50`,
    marginRight: moderateScale(6),
    paddingVertical: moderateScale(10),
    paddingHorizontal: moderateScale(19),
    backgroundColor: Colors.black,
  },
  filterSelected: {
    backgroundColor: `${Colors.limeGreen}20`,
    borderColor: isMobile ? `${Colors.black}20` : Colors.black,
  },
  title: {
    marginBottom: moderateScale(30),
  },
  list: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: moderateScale(30),
  },
  item: {
    width: moderateScale(274),
    height: moderateScale(255),
    borderRadius: 16,
    paddingHorizontal: 14,
    paddingVertical: 16,
    justifyContent: 'flex-end',
    overflow: 'hidden',
    marginRight: moderateScale(24),
  },
  itemMobile: {
    width: moderateScale(152),
    height: moderateScale(190),
    flexWrap: 'wrap',
    marginBottom: moderateScale(16),
  },
  gradient: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    borderRadius: moderateScale(10),
  },
  genreContainer: {
    position: 'absolute',
    zIndex: 3,
    top: moderateScale(21),
    left: moderateScale(15),
    backgroundColor: Colors.limeGreen,
    paddingHorizontal: moderateScale(11),
    paddingVertical: moderateScale(6),
    borderRadius: moderateScale(28),
    marginBottom: moderateScale(30),
  },
  genre: {},
  textContainer: {
    paddingHorizontal: moderateScale(12),
  },
  text: {
    zIndex: 3,
    marginBottom: moderateScale(6),
  },
  profileContainer: {
    backgroundColor: Colors.socialBackground,
    width: moderateScale(165),
    height: moderateScale(188),
    marginRight: moderateScale(30),
    borderRadius: moderateScale(16),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(isMobile ? 16 : 30),
  },
  profileContainerMobile: {
    width: '100%',
    height: moderateScale(88),
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingHorizontal: moderateScale(16),
  },
  avatar: {
    width: moderateScale(AVATAR_WIDTH),
    height: moderateScale(AVATAR_HEIGHT),
    borderRadius: moderateScale(AVATAR_WIDTH / 2),
    marginTop: 0,
    marginBottom: moderateScale(isMobile ? 0 : 12),
    marginRight: moderateScale(isMobile ? 12 : 0),
  },
  verified: {
    position: 'absolute',
    top: 0,
    right: isMobile ? 10 : 2,
    transform: [{ scale: isMobile ? 1.2 : 2 }],
    zIndex: 3,
  },
});
