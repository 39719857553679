import React from 'react';
import { Circle, Path, Rect, Svg } from 'react-native-svg';

export const GATOIcon = (): JSX.Element => (
  <Svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <Rect width="40" height="40" rx="8" fill="#E5F68C" />
    <Circle
      cx="13.9186"
      cy="13.9186"
      r="13.9186"
      transform="matrix(-1 0 0 1 33.9186 6.08142)"
      fill="#E5F68C"
    />
    <Path
      d="M20.2461 8.60527C20.246 8.60512 20.2461 8.60487 20.2463 8.60487C22.1087 8.60181 23.8679 9.04341 25.4236 9.83034C25.9125 10.078 26.3628 10.4013 26.7256 10.8129C27.2121 11.3649 27.4645 11.954 27.4195 12.5021C27.3312 13.5895 25.9654 14.6833 23.3504 13.0383C21.449 11.8422 18.0253 11.3448 15.4398 12.8682C14.5104 13.4158 15.5489 15.2255 16.5189 15.6974C17.0906 15.9758 18.2672 16.6232 19.2805 18.7382C19.76 19.7389 20.1913 19.8107 20.6471 19.7665C21.1455 19.7184 21.6431 19.0694 21.9507 16.9071C22.2582 14.7448 22.6549 14.4396 23.0373 14.4286C23.1735 14.4244 23.3548 14.5226 23.5512 14.6941C24.3373 15.3808 24.5903 16.114 25.625 15.9772C26.7611 15.827 28.392 14.956 29.1658 14.1107C29.2274 14.0434 29.2919 13.9968 29.3587 13.9783C29.6134 13.9073 29.913 14.1502 30.1875 14.494C30.398 14.7574 30.5683 15.05 30.7063 15.3568C31.3388 16.762 31.6968 18.3179 31.7094 19.9566C31.7583 26.2456 26.6483 31.4408 20.3593 31.4897C13.9638 31.5402 8.771 26.3434 8.82541 19.9471C8.87904 13.7049 14.0038 8.61701 20.246 8.60566C20.2462 8.60566 20.2463 8.60542 20.2461 8.60527Z"
      fill="black"
    />
    <Path
      d="M28.9644 17.7418C28.618 17.7418 28.3382 18.0922 28.3396 18.5239C28.3396 18.9555 28.6207 19.3046 28.9671 19.3046C29.3135 19.3046 29.5932 18.9542 29.5919 18.5225C29.5919 18.0909 29.3108 17.7418 28.9644 17.7418Z"
      fill="#E5F68C"
    />
    <Path
      d="M24.8416 17.7655C24.4685 17.7655 24.1661 18.1386 24.1674 18.5969C24.1674 19.0565 24.4712 19.4282 24.8442 19.4269C25.2173 19.4256 25.5197 19.0538 25.5184 18.5955C25.5184 18.1359 25.2146 17.7642 24.8416 17.7655Z"
      fill="#E5F68C"
    />
    <Rect
      width="2.32589"
      height="0.930358"
      rx="0.465179"
      transform="matrix(-1 0 0 1 26.0266 17.5519)"
      fill="black"
    />
    <Rect
      width="2.32589"
      height="0.930358"
      rx="0.465179"
      transform="matrix(-1 0 0 1 30.0982 17.5519)"
      fill="black"
    />
  </Svg>
);
