import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { View } from 'react-native';
import { AxiosError } from 'axios';

import { useValidateUsername } from '@services/useValidateUsername';
import Button from '@atoms/Button';
import { FooterButton } from '@atoms/FooterButton';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { useEditUserInfo } from '@services/useEditUserInfo';
import { authTokenSelector, setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import toast from '@utils/toast';
import { RootStackNavigator } from 'app/navigation/types';
import UsernameIcon from '@assets/svg/UsernameIcon';
import { useDebounce } from '@hooks/useDebounce';
import { usernameRegex } from '@utils/validations';
import { useUserData } from '@services/useUserData';
import { isDesktop, isMobile, isWebResponsive } from '@constants/platform';

import { styles } from '../styles';
import { ScreenTitle } from '..';

type ChangeUsernameProps = NativeStackScreenProps<RootStackNavigator, 'ChangeUsername'>;

interface ChangeUsernameParams {
  newUserName: string;
}

const ChangeUsername = ({ navigation }: ChangeUsernameProps) => {
  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading: isLoadingData } = useUserData(authToken || '');
  const [userNameAvailable, setUserNameAvailable] = useState(false);

  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const i18n = useTranslations();
  const updateInfo = useEditUserInfo(authToken || '');
  const validUserName = useValidateUsername();

  const cancelChangeUsername = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    handleSubmit,
  } = useForm<ChangeUsernameParams>({ mode: 'onChange' });

  const newUserName = watch('newUserName');

  const debouncedUserName = useDebounce(newUserName, 1000);

  const isDisabled = newUserName === userData?.userName || !newUserName;

  const handleGoBack = () => {
    navigation.goBack();
  };

  const onSubmit = (values: ChangeUsernameParams) => {
    toggleModal();
    setModalInfo(undefined);
    const dataToSend = {
      newUserName: values.newUserName,
    };
    updateInfo.mutate(dataToSend, {
      onSuccess: () => {
        toast.success({ icon: 'success', title: i18n.t('account_settings.changes_saved') });
        handleGoBack();
      },
      onError: (error) => {
        toast.danger({ icon: 'error', title: i18n.t(error?.response?.data.translate) });
        handleGoBack();
      },
    });
  };

  const handleChangeUsername = () => {
    setModalInfo({
      message: i18n.t('account_settings.change_confirm_message'),
      confirmText: i18n.t('account_settings.change_confirm_text'),
      cancelText: i18n.t('account_settings.change_cancel_text'),
      confirmAction: handleSubmit(onSubmit),
      cancelAction: () => cancelChangeUsername(),
      bottomFixed: false,
    });
    toggleModal();
  };

  useEffect(() => {
    if (debouncedUserName && !errors.newUserName?.message) {
      validUserName.mutate(debouncedUserName, {
        onSuccess: () => {
          clearErrors('newUserName');
          setUserNameAvailable(true);
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = i18n.t('error_login.credentials_incorrect');
            setError('newUserName', { message });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserName]);

  return (
    <Layout
      padding={isMobile}
      hasBottomCTAS={
        <FooterButton>
          <Button
            loading={updateInfo.isLoading}
            disabled={isDisabled}
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            onPress={handleChangeUsername}>
            {i18n.t('account_settings.change')}
          </Button>
        </FooterButton>
      }
      verticallyCentered={isDesktop}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          {isMobile || isWebResponsive ? (
            <View style={styles.goBack}>
              <GoBackButton goBackAction={handleGoBack} />
            </View>
          ) : null}
          <ScreenTitle>{i18n.t('account_settings.change_username')}</ScreenTitle>
          <View style={styles.formBody}>
            <Field
              editable={false}
              error={errors}
              icon={UsernameIcon}
              name="oldUserName"
              control={control}
              getValues={getValues}
              defaultValue={userData?.userName}
              loading={isLoadingData}
            />
            <Field
              error={errors}
              icon={UsernameIcon}
              placeholder={i18n.t('account_settings.new_username_placeholder').toString()}
              focusText={i18n.t('account_settings.new_username_focus_text').toString()}
              name="newUserName"
              control={control}
              getValues={getValues}
              isAvailable={userNameAvailable}
              rules={{
                required: i18n.t('register.required').toString(),
                maxLength: {
                  value: 20,
                  message: i18n.t('register.username_max_lenght_error'),
                },
                minLength: {
                  value: 3,
                  message: i18n.t('register.username_min_length_error'),
                },
                pattern: {
                  value: usernameRegex,
                  message: i18n.t('register.username_pattern_error'),
                },
              }}
            />
            {isDesktop && !isWebResponsive ? (
              <Button
                loading={updateInfo.isLoading}
                disabled={isDisabled}
                customStyle={styles.footerButton}
                customTextStyle={styles.footerButtonText}
                onPress={handleChangeUsername}>
                {i18n.t('account_settings.change')}
              </Button>
            ) : null}
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default ChangeUsername;
