import React from 'react';
import { ActivityIndicator, Pressable, TextStyle, ViewStyle } from 'react-native';

import CustomText from '@atoms/CustomText';
import { Colors } from '@theme';
import { isMobile } from '@constants/platform';

import styles from './styles';

interface Props {
  onPress: () => any;
  children: any;
  disabled?: boolean;
  loading?: boolean;
  customStyle?: ViewStyle | ViewStyle[];
  fixed?: boolean;
  secondary?: boolean;
  terciary?: boolean;
  testID?: string;
  accessibilityLabelValue?: string;
  customTextStyle?: TextStyle | TextStyle[];
}

const Button = ({
  onPress,
  children,
  disabled,
  loading,
  customStyle = {},
  customTextStyle = {},
  fixed,
  secondary,
  terciary,
  testID = '',
  accessibilityLabelValue = '',
}: Props) => {
  const onPressHandler = () => {
    if (!disabled && onPress) onPress();
  };
  return (
    <Pressable
      accessibilityLabel={accessibilityLabelValue}
      onPress={onPressHandler}
      testID={testID}
      disabled={loading || disabled}
      style={({ pressed }) => [
        styles.button,
        (disabled || loading) && styles.disabled,
        fixed && styles.fixedBottom,
        secondary && styles.secondary,
        terciary && styles.terciary,
        secondary && disabled && styles.disabledSecondary,
        terciary && disabled && styles.disabledTerciary,
        pressed && (!secondary || !terciary) && { backgroundColor: Colors.buttonPrimPressed },
        pressed && secondary && { backgroundColor: Colors.blackTwo },
        pressed && terciary && { backgroundColor: Colors.purple },
        customStyle,
      ]}>
      {loading ? (
        <ActivityIndicator color={secondary || terciary ? Colors.white : Colors.black} size="small" />
      ) : (
        <CustomText
          size={isMobile ? 'medium' : 'xmedium'}
          customStyle={customTextStyle}
          color={secondary || terciary || disabled ? 'white' : 'black'}
          weight="interSemi">
          {children}
        </CustomText>
      )}
    </Pressable>
  );
};

export default React.memo(Button);
