import { useLayoutEffect } from 'react';
import { StatusBar } from 'react-native';

import { isAndroid } from '@constants/platform';
import { Colors } from '@theme';

export function useStatusBar(type: 'light' | 'dark') {
  useLayoutEffect(() => {
    if (type === 'light') {
      if (isAndroid) {
        StatusBar.setBackgroundColor(Colors.black);
      }
      StatusBar.setBarStyle('light-content');
    } else {
      if (isAndroid) {
        StatusBar.setBackgroundColor(Colors.white);
      }
      StatusBar.setBarStyle('dark-content');
    }
  }, [type]);
}
