import React, { useEffect, useState } from 'react';
import { Pressable, ViewStyle } from 'react-native';
import Animated, {
  interpolateColor,
  useAnimatedStyle,
  useDerivedValue,
  withTiming,
} from 'react-native-reanimated';

import { Colors } from '@theme';

import { styles } from './styles';

interface FilterItemI {
  name: string;
  id: number | string;
}

type FilterProps = {
  onSelect: (item: FilterItemI) => void;
  item: FilterItemI;
  children: JSX.Element;
  active: boolean;
  customStyle?: ViewStyle;
};

const FilterItem = ({ onSelect, item, children, active, customStyle }: FilterProps) => {
  const [selected, setSelected] = useState(false);
  //   const [selectedValue, setSelectedValue] = useState(active ? 1 : 0);
  const selectedValue = useDerivedValue(() => withTiming(selected ? 1 : 0));

  const handlePress = () => {
    setSelected((prevSelected) => !prevSelected);
    // setSelectedValue((prevSelectedValue) => (prevSelectedValue === 1 ? 0 : 1));
    onSelect(item);
  };
  const bgAnimated = useAnimatedStyle(() => {
    const backgroundColor = interpolateColor(
      selectedValue.value,
      [0, 1],
      [Colors.filterItemBlack, Colors.filterItemLime]
    );
    const borderColor = interpolateColor(
      selectedValue.value,
      [0, 1],
      [Colors.filterItemGrey, Colors.transparentWhite]
    );
    return {
      backgroundColor,
      borderColor,
    };
  });

  useEffect(() => {
    setSelected(active);
  }, [active]);

  return (
    <Pressable onPress={handlePress}>
      <Animated.View style={[styles.container, selected ? styles.active : null, customStyle, bgAnimated]}>
        {children}
      </Animated.View>
    </Pressable>
  );
};

export default FilterItem;
