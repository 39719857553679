import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateVerticalScale } from '@utils/scaling';
import { DESKTOP_CONTAINER_WIDTH } from 'app/theme/spacing';
import { moderateScale } from '@utils/scaling.web';
import { isWebResponsive } from '@constants/platform';

export const styles = StyleSheet.create({
  adContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 0,
    width: '100%',
    maxWidth: DESKTOP_CONTAINER_WIDTH,
    paddingHorizontal: Spacing.BIG,
    alignSelf: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
});
