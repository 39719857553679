import React from 'react';
import { Pressable, ViewStyle } from 'react-native';

import { styles } from './styles';

interface RoundedButtonProps {
  dimentions?: number;
  action?: () => void;
  children: JSX.Element;
  customStyle?: ViewStyle;
}

const RoundedButton = ({ dimentions, action, children, customStyle }: RoundedButtonProps) => {
  const handlePress = () => {
    if (!action) return;
    action();
  };
  return (
    <Pressable
      onPress={handlePress}
      hitSlop={20}
      pointerEvents={!action ? 'none' : 'auto'}
      style={[
        styles.container,
        dimentions ? { width: dimentions, height: dimentions, borderRadius: dimentions / 2 } : null,
        customStyle,
      ]}>
      {children}
    </Pressable>
  );
};

export default RoundedButton;
