import { CommonActions, useNavigation } from '@react-navigation/core';
import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import { Pressable, View } from 'react-native';

import Image from '@atoms/FastImage';
import { Colors } from '@atoms/CustomText';
import GameTag from '@atoms/GameTag';
import { isDesktop, isWebResponsive } from '@constants/platform';
import { setGameIdHandler, useStore } from '@store/index';
import useFeatureFlag, { gameQuestEventSelector } from '@store/featureFlag';

import { styles } from './styles';

type GameBannerProps = {
  dimentions: { width: string | number; height: string | number };
  item: any;
  title?: React.ReactNode;
  tag?: string;
  tagColor?: string;
  tagTextColor?: string;
  ppal?: boolean;
};

const GameBanner = ({ dimentions, item, title, tag, tagColor, tagTextColor, ppal }: GameBannerProps) => {
  const navigation = useNavigation();
  const setGameId = useStore(setGameIdHandler);
  const isGGQ = useFeatureFlag(gameQuestEventSelector);

  const handlePress = () => {
    if (isGGQ && ppal && item.id === 0) {
      window.open(item.link, '_blank');
      return;
    }

    setGameId(item.id);

    const navigateAction = CommonActions.navigate({
      name: 'GamePage',
      params: {
        name: item.gameSlug,
      },
      key: `GamePage/${item.gameSlug}`,
    });
    navigation.dispatch(navigateAction);
  };

  if (!item) return null;

  return (
    <Pressable onPress={handlePress} style={{ ...dimentions }}>
      <LinearGradient
        start={{ x: 1, y: 0 }}
        end={{ x: 1, y: 1 }}
        locations={[0.5, 1]}
        colors={['rgba(0,0,0,0)', ppal ? 'rgba(0,0,0,0.7)' : 'rgba(0,0,0,0.6)']}
        style={styles.linear}
      />
      <Image
        style={styles.bannerImage}
        // source={{ uri: item?.principalImage, priority: FastImage.priority.high }}
        source={{ uri: isDesktop && !isWebResponsive ? item.principalImage_web : item?.principalImage }}
      />
      {title && <View style={[styles.titleWrapper, { maxWidth: +dimentions.width - 25 }]}>{title}</View>}
      {tag && (
        <GameTag
          color={tagColor as Colors}
          text={tag}
          textColor={tagTextColor as Colors}
          bigText={isDesktop && ppal}
        />
      )}
    </Pressable>
  );
};

export default GameBanner;
