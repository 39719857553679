export enum GoogleAdManagerUnitIdEnum {
    HOME_BANNER_728_X_90_1 = 'HOME_BANNER_728_X_90_1',
    HOME_BANNER_728_X_90_2 = 'HOME_BANNER_728_X_90_2',
    HOME_BANNER_970_X_250_1 = 'HOME_BANNER_970_X_250_1',
    HOME_BANNER_970_X_250_2 = 'HOME_BANNER_970_X_250_2',
    HOME_BANNER_320_X_100_1 = 'HOME_BANNER_320_X_100_1',
    HOME_BANNER_320_X_100_2 = 'HOME_BANNER_320_X_100_2',
    HOME_BANNER_300_X_250_1 = 'HOME_BANNER_300_X_250_1',
    HOME_CARROUSEL_CARD_BANNER_274_X_255_1 = 'HOME_CARROUSEL_CARD_BANNER_274_X_255_1',
    HOME_CARROUSEL_CARD_BANNER_274_X_255_2 = 'HOME_CARROUSEL_CARD_BANNER_274_X_255_2',
    HOME_CARROUSEL_CARD_BANNER_274_X_255_3 = 'HOME_CARROUSEL_CARD_BANNER_274_X_255_3',
    HOME_CARROUSEL_CARD_BANNER_147_X_190_1 = 'HOME_CARROUSEL_CARD_BANNER_147_X_190_1',
    HOME_CARROUSEL_CARD_BANNER_147_X_190_2 = 'HOME_CARROUSEL_CARD_BANNER_147_X_190_2',
    HOME_CARROUSEL_CARD_BANNER_147_X_190_3 = 'HOME_CARROUSEL_CARD_BANNER_147_X_190_3',
    GAME_BANNER_728_X_90_1 = 'GAME_BANNER_728_X_90_1',
    GAME_BANNER_300_X_600_1 = 'GAME_BANNER_300_X_600_1',
    GAME_BANNER_300_X_250_1 = 'GAME_BANNER_300_X_250_1',
    GAME_BANNER_300_X_250_2 = 'GAME_BANNER_300_X_250_2',
    GAME_BANNER_320_X_100_1 = 'GAME_BANNER_320_X_100_1',
}

export const unitsIdData = {
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_728_X_90_1]: {
        id: 'div-gpt-ad-1701808749158-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_728_X_90_1',
        size: {
            width: 728,
            height: 90,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_728_X_90_2]: {
        id: 'div-gpt-ad-1701808795375-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_728_X_90_2',
        size: {
            width: 728,
            height: 90,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_970_X_250_1]: {
        id: 'div-gpt-ad-1701986507500-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_970_X_250_1',
        size: {
            width: 970,
            height: 250,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_970_X_250_2]: {
        id: 'div-gpt-ad-1701809940133-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_970_X_250_2',
        size: {
            width: 970,
            height: 250,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_320_X_100_1]: {
        id: 'div-gpt-ad-1701888252087-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_320_X_100_1',
        size: {
            width: 320,
            height: 100,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_320_X_100_2]: {
        id: 'div-gpt-ad-1701888224314-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_320_X_100_2',
        size: {
            width: 320,
            height: 100,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_BANNER_300_X_250_1]: {
        id: 'div-gpt-ad-1701888436640-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_BANNER_300_X_250_1',
        size: {
            width: 300,
            height: 250,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_1]: {
        id: 'div-gpt-ad-1701883368333-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_274_X_255_1',
        size: {
            width: 274,
            height: 255,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_2]: {
        id: 'div-gpt-ad-1701883720085-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_274_X_255_2',
        size: {
            width: 274,
            height: 255,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_274_X_255_3]: {
        id: 'div-gpt-ad-1701883792471-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_274_X_255_3',
        size: {
            width: 274,
            height: 255,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_1]: {
        id: 'div-gpt-ad-1701888831534-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_147_X_190_1',
        size: {
            width: 147,
            height: 190,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_2]: {
        id: 'div-gpt-ad-1701888877113-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_147_X_190_2',
        size: {
            width: 147,
            height: 190,
        }
    },
    [GoogleAdManagerUnitIdEnum.HOME_CARROUSEL_CARD_BANNER_147_X_190_3]: {
        id: 'div-gpt-ad-1701888920999-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/HOME_CARROUSEL_CARD_BANNER_147_X_190_3',
        size: {
            width: 147,
            height: 190,
        }
    },
    [GoogleAdManagerUnitIdEnum.GAME_BANNER_728_X_90_1]: {
        id: 'div-gpt-ad-1701881060194-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/GAME_BANNER_728_X_90_1',
        size: {
            width: 728,
            height: 90,
        }
    },
    [GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_600_1]: {
        id: 'div-gpt-ad-1701881684467-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/GAME_BANNER_300_X_600_1',
        size: {
            width: 300,
            height: 600,
        }
    },
    [GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_250_1]: {
        id: 'div-gpt-ad-1701882015514-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/GAME_BANNER_300_X_250_1',
        size: {
            width: 300,
            height: 250,
        }
    },
    [GoogleAdManagerUnitIdEnum.GAME_BANNER_300_X_250_2]: {
        id: 'div-gpt-ad-1701893204046-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/GAME_BANNER_300_X_250_2',
        size: {
            width: 300,
            height: 250,
        }
    },
    [GoogleAdManagerUnitIdEnum.GAME_BANNER_320_X_100_1]: {
        id: 'div-gpt-ad-1701893109810-0',
        path: '/23031154824/ca-pub-3402581483331773-tag/GAME_BANNER_320_X_100_1',
        size: {
            width: 320,
            height: 100,
        }
    },

}