import React from 'react';

import Skeleton from '@atoms/Skeleton';
import { SCREEN_WIDTH } from '@constants/platform';
import { Colors, Spacing } from '@theme';

import { styles } from './styles';

const CommentSkeleton = () => (
  <Skeleton
    backColor={Colors.blackTwo}
    loadingColor={Colors.greyFour}
    height={100}
    width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
    customStyle={styles.skeletonItem}
  />
);

export default CommentSkeleton;
