import React from 'react';
import { Animated, Pressable, View, ViewStyle } from 'react-native';

import { isDesktop, SCREEN_WIDTH } from '@constants/platform';
import { Colors } from '@theme';

import { styles } from './styles';

interface DotsProps {
  quantity: number;
  scrollX: any;
  customContainerStyle?: ViewStyle;
  customDotStyle?: ViewStyle;
  dotColor?: string;
  sliderWidth?: number;
  scrollTo?: (pageIdx: number) => void;
}

const Dots = ({
  quantity,
  scrollX,
  customDotStyle,
  customContainerStyle,
  dotColor,
  sliderWidth = SCREEN_WIDTH,
  scrollTo = () => null,
}: DotsProps) => {
  const dotsQtyArr = Array.from(Array(quantity).keys());
  return (
    <View style={[styles.container, customContainerStyle]}>
      {dotsQtyArr.map((item, index) => {
        const inputRange = [(index - 1) * sliderWidth, index * sliderWidth, (index + 1) * sliderWidth];
        const bg = scrollX.interpolate({
          inputRange,
          outputRange: [Colors.whiteOpacity(0.2), dotColor || Colors.limeGreen, Colors.whiteOpacity(0.2)],
          extrapolate: 'clamp',
        });
        if (isDesktop) {
          return (
            <Pressable key={item} onPress={() => scrollTo(index)}>
              <Animated.View style={[styles.dot, customDotStyle, { backgroundColor: bg }]} />
            </Pressable>
          );
        }
        return <Animated.View key={item} style={[styles.dot, customDotStyle, { backgroundColor: bg }]} />;
      })}
    </View>
  );
};

export default Dots;
