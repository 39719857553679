import { useQuery } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';
import { authTokenSelector, useStore } from '@store/index';

async function myProfileService() {
  const response = await axiosAuthInstance.get(`/users/profile`);
  return response.data;
}

export function useMyProfile() {
  const authToken = useStore(authTokenSelector);
  return useQuery(QueryKeys.GET_USER_PROFILE, () => myProfileService(), {
    enabled: !!authToken
  });
}

export async function prefetchMyProfile() {
  await client.prefetchQuery(QueryKeys.GET_USER_PROFILE, () => myProfileService());
}
