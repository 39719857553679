import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { CommentParams, CommentResponseParams } from '@interfaces/comments';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';

async function newCommentService(data: CommentParams) {
  const response = await axiosGamesInstance.post('/comment/new', data);
  return response.data;
}

export function useNewcomment() {
  return useMutation((data: CommentParams) => newCommentService(data), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function responseCommentService(data: CommentResponseParams) {
  const response = await axiosGamesInstance.post('/comment/response/new', data);
  return response.data;
}

export function useResponsecomment() {
  return useMutation((data: CommentResponseParams) => responseCommentService(data), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function getLastTwoComments(id: number) {
  const response = await axiosGamesInstance.get(`/comment/last-two/${id}`);
  return response.data;
}

export function useLastTwoComments(id: number) {
  return useQuery([QueryKeys.GET_LAST_TWO_COMMENTS, id], () => getLastTwoComments(id), {
    enabled: !!id,
  });
}

async function getAllComments(id: number) {
  const response = await axiosGamesInstance.get(`/comment/all/${id}`);
  return response.data;
}

export function useAllComents(id: number) {
  return useQuery([QueryKeys.GET_ALL_COMMENTS, id], () => getAllComments(id), {
    enabled: !!id,
  });
}

async function getThreadComments(id: number) {
  const response = await axiosGamesInstance.get(`/comment/responses/${id}`);
  return response.data;
}

export function useThreadComments(id: number) {
  return useQuery([QueryKeys.GET_THREAD_COMMENTS, id], () => getThreadComments(id), {
    enabled: !!id,
  });
}

async function deleteComment(id: number) {
  const response = await axiosGamesInstance.delete(`/comment/${id}`);
  return response.data;
}

export function useDeleteComment() {
  return useMutation((id: number) => deleteComment(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function deleteResponseComment(id: number) {
  const response = await axiosGamesInstance.delete(`/comment/response/${id}`);
  return response.data;
}

export function useDeleteResponseComment() {
  return useMutation((id: number) => deleteResponseComment(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function likeCommentService(id: number, isResponse: boolean) {
  const SERVICE_URL = isResponse ? `/comment/response/like/${id}` : `/comment/like/${id}`;
  const response = await axiosGamesInstance.put(SERVICE_URL);
  return response.data;
}

export function useLikeComment(isResponse: boolean) {
  return useMutation((id: number) => likeCommentService(id, isResponse), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function reportComment(id: number) {
  const response = await axiosGamesInstance.put(`/comment/report/${id}`);
  return response.data;
}

export function useReportComment() {
  return useMutation((id: number) => reportComment(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function reportReponseComment(id: number) {
  const response = await axiosGamesInstance.put(`/comment/response/report/${id}`);
  return response.data;
}

export function useReportReponseComment() {
  return useMutation((id: number) => reportReponseComment(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_LAST_TWO_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_ALL_COMMENTS);
      await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
    },
  });
}

async function getWebComments(id: number, page: number) {
  const response = await axiosGamesInstance.get(`/comment/desktop/${id}/${page}`);
  return response.data;
}

export function useWebComments(id: number, page: number) {
  return useQuery([QueryKeys.GET_WEB_COMMENTS, id, page], () => getWebComments(id, page), {
    enabled: !!id,
    keepPreviousData: true,
  });
}
