import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

export const styles = StyleSheet.create({
  messageContainer: {
    paddingHorizontal: moderateScale(20),
    flex: isDesktop ? undefined : 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: isDesktop ? undefined : moderateVerticalScale(80),
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: isDesktop ? moderateScale(33) : undefined,
  },
  inputContainer: {
    flex: isDesktop ? 1 : 0.9,
  },
  iconContainer: {
    flex: isDesktop ? undefined : 0.14,
    marginTop: moderateScale(6),
    marginBottom: isDesktop ? Spacing.SMALL : undefined,
  },
  input: {
    borderRadius: 16,
    marginRight: moderateScale(8),
    backgroundColor: Colors.greyTwo,
  },
  charsLeft: {
    paddingHorizontal: moderateScale(20),
    marginTop: moderateScale(-8),
    minHeight: isDesktop ? 0 : moderateScale(32),
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: moderateScale(33),
    marginBottom: moderateScale(24),
    paddingHorizontal: moderateScale(24),
    backgroundColor: Colors.socialBackground,
    borderRadius: 16,
    minHeight: moderateScale(90),
    width: '100%',
  },
  cancel: { marginLeft: 13, marginBottom: 8 },
  responseSpacing: {
    marginTop: Spacing.SMALL,
    paddingHorizontal: 0,
  },
  charsLeftResponse: {
    paddingHorizontal: 0,
  },
});
