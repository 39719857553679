import * as React from "react"
import Svg, { Path, Defs, LinearGradient, Stop, SvgProps } from "react-native-svg"

function GameQuestFilterLogo({ fill, ...props }: SvgProps) {
  return (
    <Svg
      width={100}
      height={13}
      viewBox="0 0 100 13"
      fill="none"
      {...props}
    >
      <Path
        d="M6.242 11.634V13H1.56v-1.56H0V3.637h1.56v-1.56h7.802v3.12h-1.56v-1.56h-4.68v7.802h2.925l.196-.196V8.317h-1.56v-1.56h4.68V13h-1.56v-1.56H6.437l-.195.195zM14.042 9.879v3.12h-3.12V3.637h1.56v-1.56h6.241v1.56h1.56V13h-3.12v-3.12h-3.121zm3.12-1.56v-4.68h-3.12v4.68h3.12zM23.403 8.319v4.68h-1.56V2.077h3.12v3.12h4.68v-3.12h3.12V13h-3.12v-4.68h-1.56v3.12h-3.12v-3.12h-1.56zM34.325 12.999V2.077h7.801v1.56h-4.68v3.12h4.68v1.56h-4.68v3.12h4.68v1.56h-7.801V13zM56.508 11.439h1.365v1.56h-1.56v-1.366l-.195-.196h-1.17l-.196.196v1.366h-4.68v-1.56h-1.56V3.637h1.56v-1.56h6.241v1.56h1.56V9.88h-1.56v1.366l.196.195-.001-.001zm-1.95 0l.196-.196V3.637h-3.12v7.802h2.925-.001zM65.673 2.077h3.12V13h-7.801v-1.56h-1.56V2.077h3.12v9.362h3.12V2.077h.001zM70.353 12.999V2.077h7.802v1.56h-4.68v3.12h4.68v1.56h-4.68v3.12h4.68v1.56h-7.802V13zM85.957 3.637h-3.12v3.12h4.68v1.56h1.56v3.12h-1.56v1.56h-6.242v-1.56h-1.56v-1.56h3.12v1.56h3.12v-3.12h-4.68v-1.56h-1.56v-3.12h1.56v-1.56h6.242v1.56h1.56v1.56h-3.12v-1.56zM99.998 2.077v1.56h-3.12V13h-3.12V3.637h-3.12v-1.56H100h-.002z"
        fill="#0B0B0D"
      />
      <Path
        d="M6.242 10.074v1.366H1.56V9.88H0V2.077h1.56V.517h7.802v3.12h-1.56v-1.56h-4.68V9.88h2.925l.196-.196V6.757h-1.56v-1.56h4.68v6.242h-1.56v-1.56H6.437l-.195.195z"
        fill="url(#paint0_linear_68_8065)"
      />
      <Path
        d="M14.042 8.319v3.12h-3.12V2.077h1.56V.517h6.241v1.56h1.56v9.362h-3.12v-3.12h-3.121zm3.12-1.56V2.077h-3.12v4.68h3.12v.002z"
        fill="url(#paint1_linear_68_8065)"
      />
      <Path
        d="M23.403 6.759v4.68h-1.56V.517h3.12v3.12h4.68V.517h3.12V11.44h-3.12v-4.68h-1.56v3.12h-3.12v-3.12h-1.56z"
        fill="url(#paint2_linear_68_8065)"
      />
      <Path
        d="M34.325 11.439V.517h7.801v1.56h-4.68v3.12h4.68v1.56h-4.68v3.12h4.68v1.56h-7.801v.002z"
        fill="url(#paint3_linear_68_8065)"
      />
      <Path
        d="M56.508 9.879h1.365v1.56h-1.56v-1.366l-.195-.196h-1.17l-.196.196v1.366h-4.68v-1.56h-1.56V2.077h1.56V.517h6.241v1.56h1.56V8.32h-1.56v1.366l.196.195-.001-.001zm-1.95 0l.196-.196V2.077h-3.12V9.88h2.925-.001z"
        fill="url(#paint4_linear_68_8065)"
      />
      <Path
        d="M65.673.517h3.12V11.44h-7.801v-1.56h-1.56V.517h3.12V9.88h3.12V.517h.001z"
        fill="url(#paint5_linear_68_8065)"
      />
      <Path
        d="M70.353 11.439V.517h7.802v1.56h-4.68v3.12h4.68v1.56h-4.68v3.12h4.68v1.56h-7.802v.002z"
        fill="url(#paint6_linear_68_8065)"
      />
      <Path
        d="M85.957 2.077h-3.12v3.12h4.68v1.56h1.56v3.12h-1.56v1.56h-6.242v-1.56h-1.56v-1.56h3.12v1.56h3.12v-3.12h-4.68v-1.56h-1.56v-3.12h1.56V.517h6.242v1.56h1.56v1.56h-3.12v-1.56z"
        fill="url(#paint7_linear_68_8065)"
      />
      <Path
        d="M99.998.517v1.56h-3.12v9.362h-3.12V2.077h-3.12V.517H100h-.002z"
        fill="url(#paint8_linear_68_8065)"
      />
      <Defs>
        <LinearGradient
          id="paint0_linear_68_8065"
          x1={4.68006}
          y1={11.4387}
          x2={4.68006}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint1_linear_68_8065"
          x1={15.601}
          y1={11.4372}
          x2={15.601}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint2_linear_68_8065"
          x1={27.3016}
          y1={11.4372}
          x2={27.3016}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint3_linear_68_8065"
          x1={38.2243}
          y1={11.4372}
          x2={38.2243}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint4_linear_68_8065"
          x1={53.1913}
          y1={11.4372}
          x2={53.1913}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint5_linear_68_8065"
          x1={64.1112}
          y1={11.4372}
          x2={64.1112}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint6_linear_68_8065"
          x1={74.2531}
          y1={11.4372}
          x2={74.2531}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint7_linear_68_8065"
          x1={84.3944}
          y1={11.4358}
          x2={84.3944}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
        <LinearGradient
          id="paint8_linear_68_8065"
          x1={95.3175}
          y1={11.4372}
          x2={95.3175}
          y2={0.51709}
          gradientUnits="userSpaceOnUse"
        >
          <Stop stopColor="#DB264D" />
          <Stop offset={1} stopColor="#D1718F" />
        </LinearGradient>
      </Defs>
    </Svg>
  )
}

export default GameQuestFilterLogo
