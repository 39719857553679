import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.blackTwo,
    borderRadius: moderateScale(16),
    padding: moderateScale(16),
    marginBottom: moderateScale(16),
  },
  left: {
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.8,
  },
  icon: {
    width: moderateScale(40),
    height: moderateScale(40),
    borderRadius: moderateScale(40) / 2,
    borderWidth: 1.25,
    borderColor: Colors.greyFour,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: moderateScale(12),
  },
  chevron: {
    transform: [{ rotate: '180deg' }, { scale: 0.75 }],
  },
  value: {
    marginTop: moderateScale(6),
  },
});
