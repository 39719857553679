import FullnameIcon from '@assets/svg/FullnameIcon';
import PasswordInputIcon from '@assets/svg/PasswordInputIcon';
import UsernameIcon from '@assets/svg/UsernameIcon';
import LanguageIcon from '@assets/svg/LanguageIcon';
import DeleteAccountIcon from '@assets/svg/DeleteAccountIcon';
import EmailIcon from '@assets/svg/EmailIcon';
import { LanguageLabel, getLanguage } from '@utils/getLanguage';

const language = getLanguage();
export const accountItems = [
  {
    id: 'fullname_placeholder',
    route: 'ChangeName',
    icon: FullnameIcon,
    value: {
      userData: true,
      key: 'fullName',
    },
  },
  {
    id: 'username_placeholder',
    route: 'ChangeUsername',
    icon: UsernameIcon,
    value: {
      userData: true,
      key: 'userName',
    },
  },
  {
    id: 'avatar_placeholder',
    route: 'ChangeAvatarFirstStep',
    icon: FullnameIcon,
    value: 'account_settings.change_avatar',
  },
  {
    id: 'bio_placeholder',
    route: 'ChangeBio',
    icon: FullnameIcon,
    value: 'account_settings.change_bio',
  },
  {
    id: 'email_placeholder',
    route: 'ChangeEmail',
    icon: EmailIcon,
    value: {
      userData: true,
      key: 'email',
    },
  },
  {
    id: 'password_placeholder',
    route: 'ChangePassword',
    icon: PasswordInputIcon,
    value: '**********',
  },
  {
    id: 'language_placeholder',
    route: 'ChangeLanguage',
    icon: LanguageIcon,
    value: LanguageLabel[language],
  },
  {
    id: 'delete_account_placeholder',
    route: 'DeleteAccount',
    icon: DeleteAccountIcon,
  },
];
