import React from 'react';

import CustomText from '@atoms/CustomText';

interface CountDownTimerProps {
  initialValue: number;
  onTimeFinish: () => void;
}

export const CountDownTimer = ({ initialValue, onTimeFinish }: CountDownTimerProps) => {
  const [time, setTime] = React.useState(initialValue || 10);
  const timerRef = React.useRef(time);

  React.useEffect(() => {
    const timerId = setInterval(() => {
      timerRef.current -= 1;
      if (timerRef.current < 0) {
        onTimeFinish();
        clearInterval(timerId);
      } else {
        setTime(timerRef.current);
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomText color="greySeven" weight="interSemi" size="medium">
      {`0${time}`.slice(-2)}
    </CustomText>
  );
};
