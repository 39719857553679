import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon4 = ({ fill, ...props }: SvgProps) => (
  <Svg width={23} height={22} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.333 5.5A3.667 3.667 0 016 1.833h11A3.667 3.667 0 0120.667 5.5v5.912l-2.06-1.348a4.354 4.354 0 00-5.141.273l-4.802 3.928a2.98 2.98 0 01-3.518.187l-2.813-1.84V5.5zm15.521 5.714l2.813 1.84V16.5A3.667 3.667 0 0117 20.167H6A3.667 3.667 0 012.333 16.5v-2.245l2.06 1.348a4.354 4.354 0 005.142-.273l4.801-3.929a2.979 2.979 0 013.518-.187zm-9.562-1.13a2.292 2.292 0 100-4.584 2.292 2.292 0 000 4.583z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon4;
