import React, { useMemo } from 'react';
import { ActivityIndicator, Keyboard, Pressable, View } from 'react-native';
import { AxiosError } from 'axios';

import { Colors } from '@theme';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { AvatarProfile } from '@atoms/AvatarProfile';
import { capitalizeFirstLetters } from '@utils/common';
import TimeAgo from '@atoms/TimeAgo';
import Reply from '@assets/svg/Reply';
import Verified from '@assets/svg/Verified';
import CommentMenu from '@assets/svg/CommentMenu';
import {
  toggleModalHandler,
  useStore,
  setModalInfoHandler,
  setSubMenuModalInfoHandler,
  authTokenSelector,
  toggleSubMenuModalHandler,
} from '@store/index';
import { useUserData } from '@services/useUserData';
import { useDeleteComment, useReportComment } from '@services/useComments';
import toast from '@utils/toast';
import Trash from '@assets/svg/Trash';
import { LikeCommentCTA } from '@molecules/LikeCommentCTA';
import Report from '@assets/svg/Report';

import { styles } from './styles';

const Comment = ({ comment }: any) => {
  const { id, description, response, user, createdDate, likesCount, likeByYou } = comment;

  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading: userIsLoading } = useUserData(authToken || '');
  const deleteComment = useDeleteComment();
  const reportComment = useReportComment();

  const i18n = useTranslations();

  const setModalInfo = useStore(setModalInfoHandler);
  const setSubMenuModalInfo = useStore(setSubMenuModalInfoHandler);
  const toggleModal = useStore(toggleModalHandler);
  const toggleSubMenuModal = useStore(toggleSubMenuModalHandler);

  const getReplyText = () => {
    if (!response || response?.length < 1) return i18n.t('comments.reply');
    if (response?.length === 1) return `1 ${i18n.t('comments.reply')}`;

    return `${response?.length} ${i18n.t('comments.replies')}`;
  };

  const cancelModal = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const handleDeleteComment = () => {
    Keyboard.dismiss();
    toggleModal();
    setModalInfo(undefined);

    deleteComment.mutate(id, {
      onSuccess: async () => {
        toast.success({ title: i18n.t('comments.delete_comment_success'), icon: 'success' });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.delete_comment_error') });
        }
      },
    });
  };

  const handleDeleteCommentMenu = () => {
    Keyboard.dismiss();
    setSubMenuModalInfo(undefined);
    toggleSubMenuModal();

    setModalInfo({
      message: i18n.t('comments.delete_comment_question'),
      confirmText: i18n.t('comments.delete_comment_confirm'),
      cancelText: i18n.t('comments.delete_comment_cancel'),
      confirmAction: () => handleDeleteComment(),
      cancelAction: () => cancelModal(),
      bottomFixed: false,
    });
    toggleModal();
  };

  const handleReportComment = () => {
    Keyboard.dismiss();
    toggleModal();
    setModalInfo(undefined);

    reportComment.mutate(id, {
      onSuccess: async () => {
        toast.success({ title: i18n.t('comments.report_comment_success'), icon: 'success' });
      },
      onError: (error) => {
        if (error instanceof AxiosError) {
          const message = error?.response?.data?.message;
          toast.danger({
            title: message,
            icon: 'error',
          });
        } else {
          toast.danger({ icon: 'error', title: i18n.t('comments.report_comment_error') });
        }
      },
    });
  };

  const handleReportCommentMenu = () => {
    Keyboard.dismiss();
    setSubMenuModalInfo(undefined);
    toggleSubMenuModal();

    setModalInfo({
      message: i18n.t('comments.report_comment_question'),
      body: i18n.t('comments.report_comment_body'),
      confirmText: i18n.t('comments.report_comment_confirm'),
      cancelText: i18n.t('comments.report_comment_cancel'),
      confirmAction: () => handleReportComment(),
      cancelAction: () => cancelModal(),
      bottomFixed: true,
    });
    toggleModal();
  };

  const showCommentMenu = useMemo(() => userData?.id === user?.id, [user?.id, userData?.id]);

  const handleSubMenu = () => {
    Keyboard.dismiss();
    if (showCommentMenu) {
      setSubMenuModalInfo({
        menues: [
          {
            id: 1,
            text: i18n.t('comments.delete_comment'),
            icon: <Trash />,
            buttonAction: () => handleDeleteCommentMenu(),
          },
        ],
      });
    } else {
      setSubMenuModalInfo({
        menues: [
          {
            id: 1,
            text: i18n.t('comments.report_comment'),
            icon: <Report />,
            buttonAction: () => handleReportCommentMenu(),
          },
        ],
      });
    }

    toggleSubMenuModal();
  };

  return deleteComment.isLoading || userIsLoading ? (
    <View style={styles.loading}>
      <ActivityIndicator size="large" color={Colors.greySix} />
    </View>
  ) : (
    <View style={styles.container} key={id}>
      <View style={styles.user}>
        <AvatarProfile
          customContainerStyle={styles.avatar}
          imageUrl={user.profileImage ?? undefined}
          backgroundUrl={user.backgroundProfileImage ?? undefined}
          offsetKitten={5}
        />
        <View style={styles.info}>
          <View style={styles.header}>
            <View style={styles.name}>
              <CustomText weight="interSemi" align="left" size="medium" color="white">
                {capitalizeFirstLetters(user?.fullName)}
              </CustomText>

              {user?.creator ? <Verified style={styles.creator} /> : null}
            </View>

            <Pressable style={styles.menu} onPress={handleSubMenu}>
              <CommentMenu />
            </Pressable>
          </View>

          <TimeAgo date={createdDate} />
        </View>
      </View>
      <View style={styles.comment}>
        <CustomText weight="interSemi" align="left" size="medium" color="whiteTwo">
          {description}
        </CustomText>
      </View>
      <View style={styles.actions}>
        <LikeCommentCTA likeByYou={likeByYou} likesCount={likesCount || 0} commentId={id} />

        <Reply />
        <CustomText
          customStyle={styles.replyNumber}
          weight="interSemi"
          align="left"
          size="medium"
          color="whiteTwo">
          {getReplyText()}
        </CustomText>
      </View>
    </View>
  );
};

export default Comment;
