import React from "react";
import FastImage from "@atoms/FastImage";
import { isDesktop } from "@constants/platform";
import { Video, ResizeMode } from "expo-av";
import { styles } from "../styles";

interface GameImageI {
    image: string;
}

export const GameImage = ({ image }: GameImageI) => {
    const video = React.useRef<Video>(null);
  
    const isVideoAvi = image.includes('.avi');
    const isVideo = image.includes('.mp4');
    if (isVideoAvi) {
      return null;
    }
    if (isVideo) {
      return (
        <Video
          ref={video}
          useNativeControls
          source={{ uri: image }}
          style={styles.previewImg}
          resizeMode={isDesktop ? ResizeMode.CONTAIN : undefined}
          videoStyle={isDesktop && styles.desktopPreviewVideo}
        />
      );
    }
    return <FastImage style={styles.previewImg} source={{ uri: image }} />;
  };