import React, { useRef } from 'react';
import { View } from 'react-native';
import AnimatedLottieView from 'lottie-react-native';

import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@constants/platform';
import useSplash from '@hooks/useSplash';

import { styles } from './styles';

export const Splash = ({ handleAnimationFinish }: { handleAnimationFinish: () => void }) => {
  const animation = useRef(null);
  const splash = useSplash();

  return (
    <View style={styles.splashContainer}>
      <AnimatedLottieView
        autoPlay
        ref={animation}
        onAnimationFinish={handleAnimationFinish}
        style={{
          width: SCREEN_WIDTH / 4,
          height: SCREEN_HEIGHT / 4,
        }}
        source={splash}
        loop={false}
      />
    </View>
  );
};
