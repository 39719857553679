import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';

const MailIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Rect x={2.5} y={3} width={20} height={18} rx={4} stroke={fill || '#777E91'} strokeWidth={1.5} />
    <Path
      d="M2.969 6.414a.75.75 0 10-.938 1.172l.938-1.172zm20 1.172a.75.75 0 10-.938-1.172l.938 1.172zM14.998 13l-.469-.586.469.586zM2.03 7.586l7.502 6 .937-1.17-7.501-6.002-.938 1.172zm13.436 6l7.501-6-.936-1.172-7.502 6.001.937 1.172zm-5.934 0a4.75 4.75 0 005.934 0l-.937-1.17a3.25 3.25 0 01-4.06 0l-.937 1.17z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default MailIcon;
