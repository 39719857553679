import { StyleSheet } from 'react-native';

import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';
import { WINDOW_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  body: {
    paddingTop: Spacing.XLARGE,
    flex: 1,
    maxWidth: moderateScale(900),
  },
  row: {
    marginBottom: Spacing.MEDIUM,
  },
  fieldTitle: {
    marginBottom: Spacing.MEDIUM,
  },
  editFieldContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  screenContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    minWidth: 'fit-content',
    marginRight: WINDOW_WIDTH > 1500 ? 370 : 0,
  },
  screenInnerContainer: {
    flex: 1,
    maxWidth: moderateScale(900),
    paddingRight: Spacing.BIG,
  },
  avatarSection: {
    flexDirection: 'row',
    marginBottom: Spacing.LARGE,
    maxWidth: '50vw',
  },
  avatarContainer: {
    marginRight: Spacing.MEDIUM,
  },
  avatarData: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  avatarSkeleton: {
    borderRadius: 55,
  },
  inputWrapper: {
    flexDirection: 'row',
  },
  inputContainer: {
    flex: 1,
  },
  linkText: {
    paddingTop: 18,
    paddingLeft: Spacing.TINY,
  },
});
