import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import GoBackButton from '@molecules/GoBackButton';
import { Layout } from '@organisms/Layout';
import { RootStackNavigator } from 'app/navigation/types';
import AccountItem from '@molecules/AccountItem';
import { authTokenSelector, useStore } from '@store/index';
import { useUserData } from '@services/useUserData';
import Skeleton from '@atoms/Skeleton';
import { HEADER_HEIGHT, isMobile, isWebResponsive, SCREEN_WIDTH } from '@constants/platform';
import { Colors, Spacing } from '@theme';

import { styles } from './styles';
import { accountItems } from './utils';

export const ScreenTitle = ({ children }: { children: React.ReactNode }) =>
  isMobile || isWebResponsive ? (
    <CustomText
      size="xmedium"
      weight="interSemi"
      color="greySix"
      customStyle={{ marginTop: HEADER_HEIGHT / 4 }}>
      {children}
    </CustomText>
  ) : (
    <CustomText size="large" weight="interSemi" align="left">
      {children}
    </CustomText>
  );

type AccountSettingsProps = NativeStackScreenProps<RootStackNavigator, 'AccountSettings'>;

const AccountSettings = ({ navigation }: AccountSettingsProps) => {
  // hooks
  const { replace, navigate, goBack, canGoBack } = navigation;
  const i18n = useTranslations();
  const authToken = useStore(authTokenSelector);
  const { data: userData, isLoading } = useUserData(authToken || '');
  // functions
  const handleGoBack = (): void => {
    if (canGoBack()) {
      goBack();
    } else if (isWebResponsive) {
      replace('Home');
    } else {
      replace('AccountSettings');
    }
  };
  const handleNavigate = (route: any): void => {
    navigate(route);
  };

  // render
  return (
    <Layout style={{ paddingTop: HEADER_HEIGHT / 3 }}>
      <View style={styles.goBack}>
        <GoBackButton goBackAction={handleGoBack} />
      </View>
      <CustomText
        size="xmedium"
        weight="interSemi"
        color="greySix"
        customStyle={{ marginTop: isWebResponsive ? HEADER_HEIGHT / 4 : 0 }}>
        {i18n.t('account_settings.account_settings')}
      </CustomText>
      <View style={styles.body}>
        {!userData && isLoading ? (
          <>
            <Skeleton
              backColor={Colors.blackTwo}
              loadingColor={Colors.greyFour}
              height={80}
              width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
              customStyle={styles.skeletonItem}
            />
            <Skeleton
              backColor={Colors.blackTwo}
              loadingColor={Colors.greyFour}
              height={80}
              width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
              customStyle={styles.skeletonItem}
            />
            <Skeleton
              backColor={Colors.blackTwo}
              loadingColor={Colors.greyFour}
              height={80}
              width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
              customStyle={styles.skeletonItem}
            />
            <Skeleton
              backColor={Colors.blackTwo}
              loadingColor={Colors.greyFour}
              height={80}
              width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
              customStyle={styles.skeletonItem}
            />
            <Skeleton
              backColor={Colors.blackTwo}
              loadingColor={Colors.greyFour}
              height={80}
              width={SCREEN_WIDTH - Spacing.LATERALPLUS * 2}
              customStyle={styles.skeletonItem}
            />
          </>
        ) : (
          userData &&
          accountItems.map(({ id, route, icon, value }) => {
            const itemValue: string =
              typeof value === 'string' && value?.includes('account_settings')
                ? i18n.t(value)
                : (value as string);

            return (
              <AccountItem
                key={id}
                action={handleNavigate}
                text={`account_settings.${id}`}
                item={{
                  route,
                  icon,
                  value: typeof value === 'string' ? itemValue : userData[value?.key],
                }}
              />
            );
          })
        )}
      </View>
    </Layout>
  );
};

export default AccountSettings;
