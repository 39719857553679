import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const SVGComponent = (props: SvgProps) => (
  <Svg width={30} height={31} viewBox="0 0 30 31" fill="none" {...props}>
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8725 4.04395C10.8707 4.04395 7.50334 7.13089 7.0396 11.2246L6.61219 14.9976C6.50599 15.9351 6.12602 16.8174 5.52251 17.5277C4.24179 19.0352 5.28124 21.3943 7.22612 21.3943H22.5189C24.4638 21.3943 25.5032 19.0352 24.2225 17.5277C23.619 16.8174 23.239 15.9351 23.1328 14.9976L22.7054 11.2246C22.2417 7.13089 18.8743 4.04395 14.8725 4.04395ZM18.5559 23.9616C17.9891 25.3591 16.5537 26.3516 14.8725 26.3516C13.1913 26.3516 11.756 25.3591 11.1891 23.9616C11.1655 23.9033 11.1546 23.8408 11.1546 23.7779C11.1546 23.4882 11.3894 23.2533 11.6792 23.2533H18.0659C18.3556 23.2533 18.5905 23.4882 18.5905 23.7779C18.5905 23.8408 18.5795 23.9033 18.5559 23.9616Z"
    />
  </Svg>
);
export default SVGComponent;
