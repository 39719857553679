import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { ForgotPasswordParams, ForgotPasswordResponse } from '@interfaces/auth';

async function forgotPasswordService(data: ForgotPasswordParams) {
  const response = await axiosAuthInstance.post<ForgotPasswordResponse>(`/auth/recover`, {
    ...data,
  });
  return response.data;
}

export function useForgotPassword() {
  return useMutation((data: ForgotPasswordParams) => forgotPasswordService(data));
}
