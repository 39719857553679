import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  contentContainer: {
    padding: moderateScale(20),
  },
  header: {
    paddingTop: moderateScale(20),
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    paddingBottom: moderateScale(16),
  },
  filterCategory: {
    marginBottom: moderateScale(24),
  },
  filterItems: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: moderateScale(16),
  },
  controlItems: {
    justifyContent: 'space-between',
  },
  categoryItemsContainer: {
    marginRight: -moderateScale(9),
  },
  footer: {
    paddingTop: moderateScale(20),
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateScale(30),
    backgroundColor: Colors.greyFour,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mood: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  moodText: {
    marginLeft: moderateScale(5),
  },
  moodIcon: {
    height: 24,
    width: 24,
    marginLeft: -5,
  },
});
