import * as React from 'react';

import { ErrorFallback } from '@screens/ErrorFallback';

class ErrorHandler extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = { errorOccurred: false };
  }
  static getDerivedStateFromError(error) {
    return { errorOccurred: true };
  }
  componentDidCatch(error, info) {}
  render() {
    if (this.state.errorOccurred) {
      return <ErrorFallback />;
    } else {
      return this.props.children;
    }
  }
}
export default React.memo(ErrorHandler);
