import { StyleSheet } from 'react-native';

import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.socialBackground,
  },
  body: {},
  description: {
    marginTop: moderateScale(16),
    marginBottom: moderateScale(30),
  },
  footer: { justifyContent: 'flex-end', alignItems: 'center', flex: 1 },
  button: {
    width: '100%',
    paddingVertical: moderateScale(14),
  },
  register: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: moderateScale(20),
  },
  underline: {
    textDecorationLine: 'underline',
  },
  checkContainer: {
    width: '90%',
  },
  checkPress: {
    marginTop: -2,
  },
  contentContainer: {
    padding: moderateScale(20),
    backgroundColor: Colors.greyTwo,
    paddingTop: moderateScale(10),
  },
  header: {
    backgroundColor: Colors.blackTwo,
    paddingTop: moderateScale(20),
    paddingHorizontal: moderateScale(20),
    paddingBottom: moderateScale(10),
  },
  border: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: Colors.greyFour,
    paddingBottom: moderateScale(16),
  },
  modalFooter: {
    paddingTop: moderateScale(16),
    paddingHorizontal: moderateScale(24),
    paddingBottom: moderateScale(20),
    alignItems: 'center',
    backgroundColor: Colors.blackTwo,
  },
  modalButton: {
    width: '100%',
    paddingVertical: moderateScale(14),
    marginBottom: moderateScale(20),
  },
  termsText: {
    lineHeight: moderateScale(24),
  },
  responsesContainer: {
    marginTop: moderateVerticalScale(20),
    marginLeft: moderateScale(24),
  },
  line: {
    position: 'absolute',
    zIndex: 2,
  },
});
