import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon2 = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={25} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 3.376h2a9 9 0 110 18h-7a4 4 0 01-4-4v-5a9 9 0 019-9zm-3 11.75a.75.75 0 010-1.5h4a.75.75 0 010 1.5h-4zm0-4a.75.75 0 010-1.5h8a.75.75 0 010 1.5h-8z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon2;
