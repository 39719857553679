import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const Reply = (props: any) => (
  <Svg width={17} height={17} fill="none" {...props}>
    <Path
      d={props.showReply ? 'm11.993 11.127 3.75-3.75-3.75-3.75' : 'm11.993 10.684 3.75-3.75-3.75-3.75'}
      stroke={props?.showReply ? '#E5F68C' : '#777E91'}
      strokeWidth={1.38}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d={props.showReply ? 'M3.743 15.627v-5.25a3 3 0 0 1 3-3h9' : 'M3.743 15.184v-5.25a3 3 0 0 1 3-3h9'}
      stroke={props?.showReply ? '#E5F68C' : '#777E91'}
      strokeWidth={1.38}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

export default Reply;
