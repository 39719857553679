import { useMutation } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { ValidateCodeParams, ValidateCodeResponse } from '@interfaces/auth';

async function validateForgotPasswordCodeService(data: ValidateCodeParams) {
  const response = await axiosAuthInstance.post<ValidateCodeResponse>(`/auth/check-code/${data.userId}`, {
    code: data.code,
    type: data.type,
  });
  return response.data;
}

export function useForgotPasswordCode() {
  return useMutation((data: ValidateCodeParams) => validateForgotPasswordCodeService(data));
}
