/* eslint-disable @typescript-eslint/no-var-requires */
import { AnimationObject } from 'lottie-react-native';

import useFeatureFlag, { christmasEventSelector, halloweenEventSelector } from '../store/featureFlag';

const defaultLottie = require('../assets/lotties/splashes/default_splash.json');
const halloweenLottie = require('../assets/lotties/splashes/halloween_splash.json');
const christmasLottie = require('../assets/lotties/splashes/christmas_splash.json');

const useSplash = (): AnimationObject => {
  // store
  const halloweenEvent: boolean = useFeatureFlag(halloweenEventSelector);
  const christmasEvent: boolean = useFeatureFlag(christmasEventSelector);
  // constants
  const isHoliday: boolean = halloweenEvent || christmasEvent;

  const specialSplash: AnimationObject = halloweenEvent ? halloweenLottie : christmasLottie;

  const splash: AnimationObject = isHoliday ? specialSplash : defaultLottie;

  return splash;
};

export default useSplash;
