export interface AdvertisingInterface {
    id: number;
    name: string;
    imageUrl: string;
    type: AdTypeEnum;
    size: AdDesktopSizeEnum;
    siteUrl: string;
    package_impression_day: number;
    package_impression_month: number;
    package_total_impression: number;
    direct_sale: boolean;
    platform: AdPlatformEnum;
}

export enum AdTypeEnum {
    VIDEO = "VIDEO",
    IMAGE = "IMAGE",
    IMAGE_HOME_CARD = "IMAGE_HOME_CARD",
    IMAGE_HOME_BANNER = "IMAGE_HOME_BANNER",
    IMAGE_GAME_BANNER = "IMAGE_GAME_BANNER",

    DIRECT_SALE_VIDEO = "DIRECT_SALE_VIDEO",
    DIRECT_SALE_IMAGE = "DIRECT_SALE_IMAGE",
    DIRECT_SALE_HOME_CARD = "DIRECT_SALE_HOME_CARD",
    DIRECT_SALE_GAME_BANNER = "DIRECT_SALE_GAME_BANNER",
}

export enum AdDesktopSizeEnum {
    LARGE = "LARGE",
    SMALL = "SMALL",
}

export enum AdPlatformEnum {
    MOBILE = "MOBILE",
    DESKTOP = "DESKTOP",
}