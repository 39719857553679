import { View, Text, Modal, Linking } from 'react-native';
import React, { useState } from 'react';
import { MotiView } from 'moti';

import { Colors } from '@theme';
import Button from '@atoms/Button';
import { GATOIcon } from '@assets/svg/GATOIcon';
import { URLs } from '@utils/shareApp';
import { isBrowseriniOS } from '@utils/iOSBrowser';
import { useTranslations } from '@hooks/useTranslation';

import { styles } from './styles';

// TODO: check com.gato.app linking to dynamically render button title and change primary CTA function
export const DownloadCTA = () => {
  // states
  const [open, setOpen] = useState<boolean>(true);
  // hooks
  const i18n = useTranslations();
  // constants
  const iOS: boolean = isBrowseriniOS();

  //   functions
  function handleRedirect() {
    setOpen(false);
    return Linking.openURL(AppStoresLinking[iOS ? 'ios' : 'android']);
  }
  // render
  return (
    <Modal transparent visible={open} animationType="slide">
      <MotiView
        from={{ bottom: -50, opacity: 0 }}
        animate={{ bottom: 0, opacity: 1 }}
        transition={{ type: 'timing', duration: 1000 }}
        exit={{ bottom: 50, opacity: 0 }}
        delay={1000}
        style={styles.mainContainer}>
        <View style={styles.textWrapper}>
          <GATOIcon />
          <Text style={styles.text}>
            <Text style={{ fontWeight: '700' }}>{i18n.t('download.download_gato_app')}</Text>{' '}
            {i18n.t('download.play_statement')}
          </Text>
        </View>
        <View style={styles.buttonWrapper}>
          <Button
            onPress={() => {
              setOpen(false);
            }}
            customStyle={[styles.button, { backgroundColor: Colors.greyThree }]}
            customTextStyle={styles.secondaryButtonText}>
            {i18n.t('download.decline_action')}
          </Button>
          <Button
            onPress={handleRedirect}
            customStyle={styles.button}
            customTextStyle={styles.primaryButtonText}>
            {i18n.t('download.download_action')}
          </Button>
        </View>
      </MotiView>
    </Modal>
  );
};

type MobilePlatforms = 'ios' | 'android';

type URLType = {
  [x in MobilePlatforms]: string;
};

const AppStoresLinking: URLType = {
  android: URLs.android,
  ios: URLs.ios,
};
