import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  bannerImage: {
    borderRadius: moderateScale(10),
    width: '100%',
    height: '100%',
  },
  titleWrapper: {
    position: 'absolute',
    zIndex: 5,
    left: moderateScale(15),
    bottom: moderateScale(16),
  },
  linear: { position: 'absolute', width: '100%', height: '100%', zIndex: 3, borderRadius: moderateScale(10) },
});
