import NetInfo from '@react-native-community/netinfo';
import { StatusBar } from 'expo-status-bar';
// import { I18n } from 'i18n-js';
import React, { useEffect, useState } from 'react';
import { StyleSheet, LogBox } from 'react-native';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { QueryClientProvider } from 'react-query';
// import notifee from '@notifee/react-native';

import { Toast } from '@atoms/Toast';
import { AnalyticsEvents } from '@constants/analyticsEvents';
import useCachedResources from '@hooks/useCachedResources';
// import { useEnableFirebaseNotification } from '@hooks/useNofitication';
import { CustomModal } from '@molecules/CustomModal';
import NoInternet from '@molecules/NoInternet';
import { Colors } from '@theme';
import handleAnalyticsEvent from '@utils/analytics';
import { isDesktop } from '@constants/platform';
// import { useCheckPermissions } from '@hooks/useCheckPermissions';
import { AppNavigator } from './app/navigation';
import { client } from './app/react-query';
import globalEn from './app/translations/en/global.json';
import globalEs from './app/translations/es/global.json';
import globalPt from './app/translations/pt/global.json';
import ErrorHandler from './app/utils/ErrorHandler';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { getLanguage } from '@utils/getLanguage';
import AsyncStorage from '@react-native-async-storage/async-storage';

LogBox.ignoreAllLogs();

const styles = StyleSheet.create({
  container: { flex: 1, backgroundColor: Colors.black, overflow: isDesktop ? 'hidden' : undefined },
});

const App = () => {
  const [inetStatus, setInetStatus] = useState<boolean | null>(null);
  const [inetModal, setInetModal] = useState(false);


  i18next.init({
    lng: getLanguage(),
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: globalEs,
      },
      en: {
        translation: globalEn,
      },
      pt: {
        translation: globalPt,
      }
    },
  });

  const isLoadingComplete = useCachedResources();

  useEffect(() => {
    if (inetStatus === false) {
      setInetModal(true);
    } else if (inetStatus) {
      setInetModal(false);
    }
  }, [inetStatus]);

  useEffect(() => {
    handleAnalyticsEvent(AnalyticsEvents.APP_INIT);
    // askIosNotificationPermission();
    return () => {
      handleAnalyticsEvent(AnalyticsEvents.APP_CLOSE);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // TODO: we need firebase proyect to download GoogleServices.json for android (and add sha1 & sha256) and for ios
    const removeNetInfoSubscription = NetInfo.addEventListener((state) => {
      setInetStatus(state.isConnected);
    });

    return () => removeNetInfoSubscription();
  }, []);

  if (!isLoadingComplete) {
    return null;
  }

  //--------FORCE LOGOUT PATCH START----------------
  const forceLogout = async () => {
    const logedOut = await AsyncStorage.getItem('logedOut');
    if (logedOut === 'true') {
      // await AsyncStorage.removeItem('logedOut');

      return
    }
    await AsyncStorage.removeItem('refreshToken');
    await AsyncStorage.removeItem('authToken');
    await AsyncStorage.setItem('logedOut', 'true');
    window.location.reload();
  };

  forceLogout();
  //--------FORCE LOGOUT PATCH END----------------

  return (
    <GestureHandlerRootView style={styles.container}>
      <SafeAreaProvider>
        <Toast />
        <I18nextProvider i18n={i18next}>
          <ErrorHandler>
            <QueryClientProvider client={client}>
              <StatusBar />
              <AppNavigator />
              <CustomModal dontClose visible={inetModal} onOverlayPress={() => setInetModal(false)}>
                <NoInternet />
              </CustomModal>
            </QueryClientProvider>
          </ErrorHandler>
        </I18nextProvider>
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

export default App;