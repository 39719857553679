import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AvailableIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={24} height={25} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.707 6.793a1 1 0 010 1.414l-8.586 8.586a3 3 0 01-4.242 0l-3.586-3.586a1 1 0 111.414-1.414l3.586 3.586a1 1 0 001.414 0l8.586-8.586a1 1 0 011.414 0z"
      fill={fill || '#7CE259'}
    />
  </Svg>
);

export default AvailableIcon;
