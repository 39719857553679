import { useQueryClient } from 'react-query';

import { clearData } from '@utils/session';
import { setAuthTokenHandler, setModalInfoHandler, toggleModalHandler, useStore } from '@store/index';
import { QueryKeys } from 'app/react-query/query-keys';

import { useTranslations } from './useTranslation';

export function useLogoutMessage() {
  const i18n = useTranslations();
  const toggleModal = useStore(toggleModalHandler);
  const setModalInfo = useStore(setModalInfoHandler);
  const setAuthToken = useStore(setAuthTokenHandler);
  const queryClient = useQueryClient();

  const confirmLogout = async () => {
    toggleModal();
    await clearData();
    setAuthToken(undefined);
    queryClient.removeQueries(QueryKeys.GET_USER_PROFILE);
  };

  const cancelLogout = () => {
    toggleModal();
    setModalInfo(undefined);
  };

  const logoutMessage = () => {
    setModalInfo({
      message: i18n.t('settings.logout_confirm_message'),
      confirmText: i18n.t('settings.logout_confirm_text'),
      cancelText: i18n.t('settings.logout_cancel_text'),
      confirmAction: () => confirmLogout(),
      cancelAction: () => cancelLogout(),
    });
    toggleModal();
  };

  return logoutMessage;
}
