import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  lottie: {
    marginRight: moderateScale(5),
    width: 18,
    height: 18,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: moderateScale(16),
  },
});
