import AsyncStorage from '@react-native-async-storage/async-storage';

export async function getHasOnboardingSeen() {
  try {
    const hasSeen = await AsyncStorage.getItem('hasSeenOnboarding');
    return hasSeen === 'yes';
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}

export async function setHasOnboardingSeen() {
  try {
    await AsyncStorage.setItem('hasSeenOnboarding', 'yes');
  } catch (err) {
    throw new Error('Something went wrong when setting storage.');
  }
}
