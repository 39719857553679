import { useMutation } from 'react-query';

import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';

async function dislikeService(id: number) {
  const response = await axiosGamesInstance.put(`/game/dislike/${id}`);
  return response.data;
}

export function useDislike() {
  return useMutation((id: number) => dislikeService(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_GAME_DETAIL);
      await client.invalidateQueries(QueryKeys.GET_LIKED_GAMES);
      await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
    },
  });
}
