import React, { useEffect } from 'react';
import { Linking, Pressable, View, ViewStyle } from 'react-native';
import Image from '@atoms/FastImage';

import CustomText from '@atoms/CustomText';
import { isMobile, SCREEN_WIDTH } from '@constants/platform';
import { useTranslations } from '@hooks/useTranslation';
import { Spacing } from '@theme';
import handleAnalyticsEvent from '@utils/analytics';

import { styles } from './styles';
import { useTrackingAds } from '@services/useTrackingAds';
import { useCountryIsoCode } from '@hooks/useCountryIsoCode';
import { AdPlatformEnum } from '@interfaces/advertising';
import { TrackingAdsParams } from '@interfaces/trackingAds';

type AddBannerProps = {
  bannerDimentions?: {
    width: number;
    height: number;
  };
  customStyles?: ViewStyle;
  analyticsEvent: string;
  items: AdvertisingItem[];
  creatorId?: number;
  isDirectSale?: boolean;
};

const AdMarker = () => {
  const i18n = useTranslations();

  return (
    <View style={styles.absolute}>
      <CustomText size="msmall" weight="interSemi">
        {i18n.t('game_page.ad')}
      </CustomText>
    </View>
  );
};

const AddBanner = React.memo(({ bannerDimentions, customStyles, analyticsEvent, items, creatorId, isDirectSale }: AddBannerProps) => {
  const [impressionSended, setImpressionSended] = React.useState(false);
  const directSale = useTrackingAds();

  const countryISOCode = useCountryIsoCode();

  useEffect(() => {
    if (!items[0]?.advertising?.siteUrl || !isDirectSale || impressionSended) return;

    const values: TrackingAdsParams = {
      location: countryISOCode,
      clicks: 0,
      impressions: 1,
      game_creator_id: creatorId ? creatorId : 0,
      advertising_id: items[0]?.advertising?.id,
      is_mobile: items[0]?.advertising?.platform === AdPlatformEnum.MOBILE ? true : false,
    };

    if (!impressionSended) {
      directSale.mutate(values, {
        onSuccess: () => {
          setImpressionSended(true);
        },
        onError: (error) => {
          console.error(error);
        },
      });
    }
  }, [items]);

  const handleAdClick = () => {
    if (!items[0]?.advertising?.siteUrl || !isDirectSale) return;

    const values: TrackingAdsParams = {
      location: countryISOCode,
      clicks: 1,
      impressions: 0,
      game_creator_id: creatorId ? creatorId : 0,
      advertising_id: items[0]?.advertising?.id,
      is_mobile: items[0]?.advertising?.platform === AdPlatformEnum.MOBILE ? true : false,
    };

    directSale.mutate(values, {
      onSuccess: () => { },
      onError: (error) => {
        console.error(error);
      },
    });
    handleAnalyticsEvent(analyticsEvent);
    Linking.openURL(items[0]?.advertising?.siteUrl);
  };



  return (
    <Pressable
      onPress={handleAdClick}
      style={[
        styles.container,
        customStyles,
        {
          width: bannerDimentions?.width || SCREEN_WIDTH - Spacing.LATERALPLUS * 2,
          height: bannerDimentions?.height || SCREEN_WIDTH - Spacing.LATERALPLUS * 2,
        },
      ]}>
      {/* <BannerAd
        unitId={unitId}
        size={BannerAdSize.LARGE_BANNER}
        requestOptions={{
          requestNonPersonalizedAdsOnly: true,
        }}
      /> */}
      <Image style={styles.image} source={{ uri: items[0]?.advertising?.imageUrl }} />
      <AdMarker />
    </Pressable>
  );
}, (prevProps, nextProps) => {
  // This will prevent the re-rendering of the component.
  //If you return true:  it will not re render
  //If you return false:  it will re render
  // We MUST prevent re rendering to prevent tracking impressions for the same ad multiple tims
  return (
    true
  )
});

export default AddBanner;

export interface AdvertisingResponse {
  id: number;
  name: string;
  title: string;
  width: string;
  height: string;
  carrouselPrincipal: boolean;
  active: boolean;
  items: AdvertisingItem[];
}

export interface AdvertisingItem {
  id: number;
  type: string;
  order: null;
  advertising: Advertising;
  game: null;
}

export interface Advertising {
  id: number;
  name: string;
  imageUrl: string;
  siteUrl: string;
  platform: AdPlatformEnum;
}
