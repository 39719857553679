import React from 'react';
import ReactTimeAgo from 'react-time-ago';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import es from 'javascript-time-ago/locale/es.json';

import CustomText from '@atoms/CustomText';

// TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(en);
TimeAgo.addLocale(es);

const Time = ({ children }) => (
  <CustomText weight="interSemi" align="left" size="xsmall" color="greyThree">
    {children.replace('minutes', 'mins')}
  </CustomText>
);

const TimeComp = (props) => <ReactTimeAgo {...props} component={Time} />;

export default TimeComp;
