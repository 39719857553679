import { useQuery } from 'react-query';

import { isDesktop } from '@constants/platform';
import { axiosGamesInstance } from '@config/api';
import { GenresTypes } from '@interfaces/explore';
import { QueryKeys } from 'app/react-query/query-keys';

async function getGenres() {
  const path = `/genre/all${isDesktop ? '/desktop' : ''}`;
  const response = await axiosGamesInstance.get<GenresTypes[]>(path);
  return response.data;
}

export function useGenres() {
  return useQuery([QueryKeys.GET_GENRES], getGenres);
}
