import { isMobile } from '@constants/platform';
import { moderateScale } from '@utils/scaling';

export const TINY = 8;
export const SMALL = moderateScale(TINY * 2);
export const MEDIUM = moderateScale(TINY * 3);
export const BIG = moderateScale(TINY * 4);
export const LARGE = moderateScale(TINY * 5);
export const XLARGE = moderateScale(TINY * 7);
export const XXLARGE = moderateScale(TINY * 9);
export const LATERALSMALL = moderateScale(12);
export const LATERAL = moderateScale(16);
export const LATERALBIG = moderateScale(20);
export const LATERALPLUS = moderateScale(24);
export const BANNERBOTTOM = isMobile ? moderateScale(30) : moderateScale(80);
export const LATERALLARGE = moderateScale(100);

export const DESKTOP_CONTAINER_WIDTH = 1236;
