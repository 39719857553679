import { registerRootComponent } from 'expo';
// import messaging from '@react-native-firebase/messaging';
import { enableExperimentalWebImplementation } from 'react-native-gesture-handler';

// import { localNotificationService } from '@config/LocalNotificationService';

import App from './App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately

// Storybook

// import StorybookUIRoot from './storybook';
// registerRootComponent(StorybookUIRoot);

// messaging().setBackgroundMessageHandler(async (remoteMessage) => {
//   console.log('remoteMessage background', remoteMessage);
//   if (remoteMessage) {
//     const notify = remoteMessage.data;
//     localNotificationService.showNotification(notify.title, notify.body, notify);
//   }
// });
enableExperimentalWebImplementation(true);
registerRootComponent(App);
