import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SearchIcon = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      fill="#A4ADC2"
      fillRule="evenodd"
      d="M14.691 8.813a6.136 6.136 0 1 1-12.27 0 6.136 6.136 0 0 1 12.27 0Zm-6.135 7.323a7.323 7.323 0 1 0 0-14.646 7.323 7.323 0 0 0 0 14.646Zm8.646 1.323c-.61.61-1.6.61-2.21 0l-1.483-1.482a8.756 8.756 0 0 0 2.21-2.21l1.483 1.482c.61.61.61 1.6 0 2.21Z"
      clipRule="evenodd"
    />
  </Svg>
);
export default SearchIcon;
