import React from 'react';
import { ImageBackground, Image, View, ViewStyle, Platform } from 'react-native';

import FastImage from '@atoms/FastImage';

import { styles } from './styles';

interface AvatarProfileProps {
  imageUrl: string;
  backgroundUrl?: string;
  customContainerStyle?: ViewStyle;
  offsetKitten?: number;
  fromSettings?: boolean;
  imageUrlPlaceholder?: string;
}

const CustomImage = Platform.OS !== 'web' ? FastImage : Image;

export const AvatarProfile = ({
  imageUrl,
  backgroundUrl,
  customContainerStyle,
  offsetKitten = 15,
  fromSettings,
}: AvatarProfileProps) => {
  const Wrapper = fromSettings ? React.Fragment : ImageBackground;

  const wrapperProps = fromSettings ? {} : { style: styles.background, source: { uri: backgroundUrl } };

  return (
    <View style={[styles.container, customContainerStyle]}>
      <Wrapper {...wrapperProps}>
        <CustomImage
          resizeMode={!imageUrl.includes('/avatar/') ? 'cover' : 'contain'}
          style={[
            styles.cat,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              marginTop:
                imageUrl.includes('/profile/profile-default.png') || !imageUrl.includes('/avatar/')
                  ? 0
                  : offsetKitten,
            },
          ]}
          source={{ uri: imageUrl, priority: FastImage?.priority?.high }}
        />
        {/* <CustomImage style={styles.background} source={{ uri: backgroundUrl }} /> */}
      </Wrapper>
    </View>
  );
};
