import { StyleSheet } from 'react-native';

import { SCREEN_HEIGHT, SCREEN_WIDTH, isAndroid } from '@constants/platform';
import { Colors, Fonts, Spacing } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';

const SIZE = moderateScale(90);

export default StyleSheet.create({
  content: {
    paddingTop: Spacing.BIG,
  },

  item: {
    marginBottom: Spacing.MEDIUM,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  image: {
    width: SIZE,
    height: SIZE,
    borderRadius: moderateScale(16),
    marginRight: Spacing.MEDIUM,
  },
});
