import React from 'react';
import { View } from 'react-native';

import Skeleton from '@atoms/Skeleton';

import { styles } from './styles';
import { BANNER_DIMENTIONS } from './constants';

export const Loading = () => (
  <View style={styles.skeletonContainer}>
    {[...Array(6).keys()].map((idx) => (
      <Skeleton
        customStyle={styles.skeletonItem}
        width={BANNER_DIMENTIONS.width}
        height={BANNER_DIMENTIONS.height}
        key={`exp-loading-${idx}`}
      />
    ))}
  </View>
);
