/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';
import { isDesktop, isIos } from '@constants/platform';

import pkg from '../../package.json';

async function getLastVersionService() {
  if (isDesktop) return true;
  const SO = isIos ? 'IOS' : 'ANDROID';
  const response = await axiosGamesInstance.get<boolean>('/game/last-version', {
    headers: { so_name: SO, so_version: pkg.version },
  });
  return response.data;
}

export function useGetLastVersion() {
  return useQuery([QueryKeys.GET_LAST_VERSION], getLastVersionService);
}
