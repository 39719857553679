import * as React from 'react';
import Svg, { Ellipse, Path, SvgProps, Rect, Mask } from 'react-native-svg';

const Onboarding2 = ({ ...props }: SvgProps) => (
  <Svg width={269} height={176} viewBox="0 0 269 176" fill="none" {...props}>
    <Ellipse cx={133.372} cy={33.632} rx={1.47458} ry={8.25766} fill="#FCFCFC" />
    <Ellipse
      cx={133.669}
      cy={33.6322}
      rx={1.7695}
      ry={8.25766}
      transform="rotate(90 133.669 33.6322)"
      fill="#FCFCFC"
    />
    <Ellipse cx={146.477} cy={16.5266} rx={0.842619} ry={4.71866} fill="#FCFCFC" />
    <Ellipse
      cx={146.643}
      cy={16.5266}
      rx={1.01114}
      ry={4.71866}
      transform="rotate(90 146.643 16.5266)"
      fill="#FCFCFC"
    />
    <Path
      d="M176.403 79.6808C176.626 77.8982 176.002 72.9292 171.723 67.314"
      stroke="#96A353"
      strokeWidth={2.00543}
      strokeLinecap="round"
    />
    <Rect
      x={164.837}
      y={61.2647}
      width={0.668477}
      height={3.34239}
      transform="rotate(-35.3892 164.837 61.2647)"
      fill="#E5F68C"
      stroke="#96A353"
      strokeWidth={0.668477}
    />
    <Rect
      x={168.848}
      y={58.5908}
      width={0.668477}
      height={3.34239}
      transform="rotate(-35.3892 168.848 58.5908)"
      fill="#E5F68C"
      stroke="#96A353"
      strokeWidth={0.668477}
    />
    <Rect
      x={164.37}
      y={65.0443}
      width={10.0596}
      height={6.0163}
      rx={2.00543}
      transform="rotate(-34.6839 164.37 65.0443)"
      fill="#E5F68C"
    />
    <Mask id="path-9-inside-1_6422_74554" fill="white">
      <Path d="M199.648 74.3789C197.828 69.8959 194.582 66.964 192.148 66.0549C188.505 64.695 183.268 67.6426 179.166 72.1781C175.065 76.7135 173.019 86.0149 173.019 86.0149L171.653 84.8804C171.653 84.8804 172.337 83.7459 162.089 82.6114C151.84 81.4769 148.88 86.4682 149.107 88.9625C149.335 91.4569 155.028 91.6848 157.761 93.7259C160.494 95.767 158.9 98.7172 157.077 99.6238C155.254 100.53 141.82 103.481 136.808 115.73C131.797 127.979 136.58 139.774 136.58 139.774C134.757 140.228 129.974 140.456 126.56 137.505C123.143 134.558 125.194 121.856 131.113 114.142C137.035 106.431 139.313 104.162 139.539 93.7285C139.765 83.2952 128.835 78.3039 121.548 76.7161C114.262 75.1283 101.964 79.8917 96.0421 86.4708C90.1206 93.0499 90.3495 101.214 96.0421 102.121C101.735 103.027 101.964 102.121 108.566 97.8107C115.169 93.5006 122.914 99.8517 116.537 106.884C110.161 113.917 106.062 120.268 107.201 132.289C108.34 144.31 118.589 149.527 126.331 150.436C134.073 151.342 140.789 147.9 140.789 147.9C143.293 156.745 164.135 167.448 175.065 168.811C185.995 170.17 210.82 173.121 228.356 162.687C245.892 152.254 252.723 130.704 256.14 116.869C259.558 103.033 259.558 78.5345 258.19 71.0514C256.824 63.5657 256.152 59.6305 253.418 60.3091C250.685 60.9904 241.791 70.1422 241.335 69.689C238.602 68.1012 230.01 64.5729 230.239 64.1197C230.239 50.9757 227.538 47.7568 219.887 56.4986C213.216 64.1196 208.769 67.8732 204.444 75.8122L199.646 74.3816L199.648 74.3789Z" />
    </Mask>
    <Path
      d="M199.648 74.3789C197.828 69.8959 194.582 66.964 192.148 66.0549C188.505 64.695 183.268 67.6426 179.166 72.1781C175.065 76.7135 173.019 86.0149 173.019 86.0149L171.653 84.8804C171.653 84.8804 172.337 83.7459 162.089 82.6114C151.84 81.4769 148.88 86.4682 149.107 88.9625C149.335 91.4569 155.028 91.6848 157.761 93.7259C160.494 95.767 158.9 98.7172 157.077 99.6238C155.254 100.53 141.82 103.481 136.808 115.73C131.797 127.979 136.58 139.774 136.58 139.774C134.757 140.228 129.974 140.456 126.56 137.505C123.143 134.558 125.194 121.856 131.113 114.142C137.035 106.431 139.313 104.162 139.539 93.7285C139.765 83.2952 128.835 78.3039 121.548 76.7161C114.262 75.1283 101.964 79.8917 96.0421 86.4708C90.1206 93.0499 90.3495 101.214 96.0421 102.121C101.735 103.027 101.964 102.121 108.566 97.8107C115.169 93.5006 122.914 99.8517 116.537 106.884C110.161 113.917 106.062 120.268 107.201 132.289C108.34 144.31 118.589 149.527 126.331 150.436C134.073 151.342 140.789 147.9 140.789 147.9C143.293 156.745 164.135 167.448 175.065 168.811C185.995 170.17 210.82 173.121 228.356 162.687C245.892 152.254 252.723 130.704 256.14 116.869C259.558 103.033 259.558 78.5345 258.19 71.0514C256.824 63.5657 256.152 59.6305 253.418 60.3091C250.685 60.9904 241.791 70.1422 241.335 69.689C238.602 68.1012 230.01 64.5729 230.239 64.1197C230.239 50.9757 227.538 47.7568 219.887 56.4986C213.216 64.1196 208.769 67.8732 204.444 75.8122L199.646 74.3816L199.648 74.3789Z"
      fill="black"
      stroke="#3F4042"
      strokeWidth={4.81487}
      mask="url(#path-9-inside-1_6422_74554)"
    />
    <Mask id="path-10-inside-2_6422_74554" fill="white">
      <Path d="M106.33 99.9223C106.58 99.9223 106.825 99.904 107.064 99.8699C109.784 99.4638 111.692 97.5956 112.983 96.3275C112.983 96.3275 112.562 96.3511 111.968 96.448C111.373 96.545 111.158 96.6288 111.158 96.6288C109.961 97.8052 109.04 97.9755 106.83 98.3056C105.012 98.5755 102.608 97.3074 100.853 95.1484C99.6907 93.7179 97.1996 89.8296 99.1515 84.6601C99.5934 83.4863 100.293 82.2705 101.23 81.0417C102.045 79.9701 102.803 79.3963 103.371 79.4225C103.855 79.446 104.271 79.902 104.534 80.3264L105.917 79.6216C105.244 78.5342 104.386 77.8897 103.445 77.8452C102.284 77.7875 101.153 78.5238 99.9617 80.088C98.9331 81.4373 98.1597 82.7893 97.6625 84.1046C96.1079 88.2208 96.8392 92.7222 99.6171 96.144C101.532 98.5048 104.092 99.9249 106.33 99.9249V99.9223Z" />
    </Mask>
    <Path
      d="M106.33 99.9223C106.58 99.9223 106.825 99.904 107.064 99.8699C109.784 99.4638 111.692 97.5956 112.983 96.3275C112.983 96.3275 112.562 96.3511 111.968 96.448C111.373 96.545 111.158 96.6288 111.158 96.6288C109.961 97.8052 109.04 97.9755 106.83 98.3056C105.012 98.5755 102.608 97.3074 100.853 95.1484C99.6907 93.7179 97.1996 89.8296 99.1515 84.6601C99.5934 83.4863 100.293 82.2705 101.23 81.0417C102.045 79.9701 102.803 79.3963 103.371 79.4225C103.855 79.446 104.271 79.902 104.534 80.3264L105.917 79.6216C105.244 78.5342 104.386 77.8897 103.445 77.8452C102.284 77.7875 101.153 78.5238 99.9617 80.088C98.9331 81.4373 98.1597 82.7893 97.6625 84.1046C96.1079 88.2208 96.8392 92.7222 99.6171 96.144C101.532 98.5048 104.092 99.9249 106.33 99.9249V99.9223Z"
      fill="#E5F68C"
      stroke="#E5F68C"
      strokeWidth={1.43161}
      mask="url(#path-10-inside-2_6422_74554)"
    />
    <Mask id="path-11-inside-3_6422_74554" fill="white">
      <Path d="M118.456 104.165C118.456 104.165 118.664 103.822 118.769 103.468C118.872 103.117 118.982 102.63 118.982 102.63C118.461 102.499 117.912 102.347 117.407 102.176C107.534 98.8514 108.847 89.005 109.483 86.0679C110.72 80.3691 114.331 75.4276 117.073 75.7001C117.793 75.7708 118.272 76.0878 118.409 76.4127L120.182 76.494C119.635 75.294 118.517 74.2538 117.23 74.1254C113.137 73.7166 109.21 79.832 107.931 85.7325C107.355 88.3814 106.992 92.3352 108.523 96.1213C109.991 99.7554 112.808 102.297 116.899 103.672C117.443 103.856 117.896 104.021 118.459 104.162L118.456 104.165Z" />
    </Mask>
    <Path
      d="M118.456 104.165C118.456 104.165 118.664 103.822 118.769 103.468C118.872 103.117 118.982 102.63 118.982 102.63C118.461 102.499 117.912 102.347 117.407 102.176C107.534 98.8514 108.847 89.005 109.483 86.0679C110.72 80.3691 114.331 75.4276 117.073 75.7001C117.793 75.7708 118.272 76.0878 118.409 76.4127L120.182 76.494C119.635 75.294 118.517 74.2538 117.23 74.1254C113.137 73.7166 109.21 79.832 107.931 85.7325C107.355 88.3814 106.992 92.3352 108.523 96.1213C109.991 99.7554 112.808 102.297 116.899 103.672C117.443 103.856 117.896 104.021 118.459 104.162L118.456 104.165Z"
      fill="#E5F68C"
      stroke="#E5F68C"
      strokeWidth={1.43161}
      mask="url(#path-11-inside-3_6422_74554)"
    />
    <Mask id="path-12-inside-4_6422_74554" fill="white">
      <Path d="M110.414 115.153L111.314 113.631C110.588 112.502 110.43 111.556 110.456 110.172C110.517 107.188 112.458 104.046 116.073 101.08C123.736 94.7974 136.87 90.9537 142.642 91.9572C143.355 92.0803 144.231 92.3686 144.354 93.0236C144.581 94.2341 143.242 96.4612 139.246 98.8534L138.809 100.973C143.121 98.3923 146.322 94.9101 145.917 92.7354C145.756 91.8812 145.107 90.7834 142.918 90.4009C136.697 89.3161 123.175 93.2123 115.068 99.8595C111.085 103.124 108.944 106.68 108.873 110.138C108.839 111.841 109.517 113.754 110.417 115.15L110.414 115.153Z" />
    </Mask>
    <Path
      d="M110.414 115.153L111.314 113.631C110.588 112.502 110.43 111.556 110.456 110.172C110.517 107.188 112.458 104.046 116.073 101.08C123.736 94.7974 136.87 90.9537 142.642 91.9572C143.355 92.0803 144.231 92.3686 144.354 93.0236C144.581 94.2341 143.242 96.4612 139.246 98.8534L138.809 100.973C143.121 98.3923 146.322 94.9101 145.917 92.7354C145.756 91.8812 145.107 90.7834 142.918 90.4009C136.697 89.3161 123.175 93.2123 115.068 99.8595C111.085 103.124 108.944 106.68 108.873 110.138C108.839 111.841 109.517 113.754 110.417 115.15L110.414 115.153Z"
      fill="#E5F68C"
      stroke="#E5F68C"
      strokeWidth={1.43161}
      mask="url(#path-12-inside-4_6422_74554)"
    />
    <Mask id="path-13-inside-5_6422_74554" fill="white">
      <Path d="M107.526 123.873L107.799 122.188C106.784 121.446 105.952 120.537 106.347 119.188C106.657 118.127 107.497 117.393 108.912 116.945C114.11 115.305 124.419 118.242 128.026 119.879C127.981 119.935 127.844 119.935 127.763 119.935L127.108 121.509C129.515 121.433 130.359 120.933 130.53 120.419C130.583 120.265 130.706 119.72 130.12 119.253C128.986 118.349 124.964 116.916 120.965 115.989C117.89 115.276 112.174 114.257 108.43 115.438C106.497 116.049 105.282 117.162 104.819 118.748C104.311 120.487 105.508 122.405 107.523 123.873H107.526Z" />
    </Mask>
    <Path
      d="M107.526 123.873L107.799 122.188C106.784 121.446 105.952 120.537 106.347 119.188C106.657 118.127 107.497 117.393 108.912 116.945C114.11 115.305 124.419 118.242 128.026 119.879C127.981 119.935 127.844 119.935 127.763 119.935L127.108 121.509C129.515 121.433 130.359 120.933 130.53 120.419C130.583 120.265 130.706 119.72 130.12 119.253C128.986 118.349 124.964 116.916 120.965 115.989C117.89 115.276 112.174 114.257 108.43 115.438C106.497 116.049 105.282 117.162 104.819 118.748C104.311 120.487 105.508 122.405 107.523 123.873H107.526Z"
      fill="#E5F68C"
      stroke="#E5F68C"
      strokeWidth={1.43161}
      mask="url(#path-13-inside-5_6422_74554)"
    />
    <Mask id="path-14-inside-6_6422_74554" fill="white">
      <Path d="M125.582 126.698C129.575 125.623 132.613 123.249 133.563 120.097C134.894 115.675 138.569 113.917 141.418 113.22C148.549 111.475 158.056 114.378 163.536 119.969L164.672 118.863C158.832 112.903 148.673 109.816 141.042 111.682C136.446 112.806 133.252 115.633 132.045 119.642C131.056 122.925 128.417 124.258 126.021 124.903L125.584 126.695L125.582 126.698Z" />
    </Mask>
    <Path
      d="M125.582 126.698C129.575 125.623 132.613 123.249 133.563 120.097C134.894 115.675 138.569 113.917 141.418 113.22C148.549 111.475 158.056 114.378 163.536 119.969L164.672 118.863C158.832 112.903 148.673 109.816 141.042 111.682C136.446 112.806 133.252 115.633 132.045 119.642C131.056 122.925 128.417 124.258 126.021 124.903L125.584 126.695L125.582 126.698Z"
      fill="#E5F68C"
      stroke="#E5F68C"
      strokeWidth={1.43161}
      mask="url(#path-14-inside-6_6422_74554)"
    />
    <Path
      d="M194.428 140.793C175.877 140.793 160.838 125.814 160.838 107.337C160.838 88.8595 175.877 73.8806 194.428 73.8806C212.979 73.8806 228.018 88.8595 228.018 107.337C228.018 125.814 212.979 140.793 194.428 140.793Z"
      fill="#E5F68C"
    />
    <Path d="M180.47 75.3499L228.219 102.246L227.434 103.628L179.685 76.732L180.47 75.3499Z" fill="#96A353" />
    <Path
      d="M167.84 84.1512L228.418 106.602L227.862 108.091L167.284 85.6393L167.84 84.1512Z"
      fill="#96A353"
    />
    <Path
      d="M214.752 78.7141L222.724 99.2382L221.237 99.8111L213.265 79.287L214.752 78.7141Z"
      fill="#96A353"
    />
    <Path
      d="M202.403 73.1279L214.966 94.8966L213.583 95.6879L201.021 73.9191L202.403 73.1279Z"
      fill="#96A353"
    />
    <Path
      d="M164.364 90.2465L228.052 109.41L227.591 110.93L163.903 91.7664L164.364 90.2465Z"
      fill="#96A353"
    />
    <Path
      d="M216.929 106.759L218.043 107.895L185.083 139.954L183.969 138.818L216.929 106.759Z"
      fill="#96A353"
    />
    <Path
      d="M211.883 104.887L212.873 106.131L174.417 136.511L173.426 135.267L211.883 104.887Z"
      fill="#96A353"
    />
    <Path
      d="M207.015 116.845L225.722 118.583L225.574 120.164L206.867 118.426L207.015 116.845Z"
      fill="#96A353"
    />
    <Path
      d="M201.76 122.196L222.289 131.095L221.653 132.551L201.124 123.652L201.76 122.196Z"
      fill="#96A353"
    />
    <Path
      d="M204.131 102.554L204.953 103.915L165.464 127.585L164.642 126.224L204.131 102.554Z"
      fill="#96A353"
    />
    <Path
      d="M182.042 96.2122L185.423 114.891L183.854 115.173L180.473 96.4939L182.042 96.2122Z"
      fill="#96A353"
    />
    <Path
      d="M170.743 93.2082L172.333 93.3234L170.066 124.355L168.477 124.24L170.743 93.2082Z"
      fill="#96A353"
    />
    <Path
      d="M191.705 99.0971L191.886 111.235L190.292 111.258L190.111 99.1206L191.705 99.0971Z"
      fill="#96A353"
    />
    <Path
      d="M161.336 98.1775L171.152 99.9861L170.862 101.547L161.046 99.7388L161.336 98.1775Z"
      fill="#96A353"
    />
    <Path
      d="M160.36 107.256L170.381 116.328L169.309 117.503L159.288 108.431L160.36 107.256Z"
      fill="#96A353"
    />
    <Path
      d="M174.384 152.477C169.373 155.199 165.958 151.57 167.323 145.219C168.689 138.868 173.471 129.341 172.106 121.855C170.741 114.37 172.79 109.381 178.254 111.875C185.209 115.051 180.303 137.28 180.303 137.28C181.442 134.786 188.729 125.484 198.749 126.619C207.601 127.62 216.739 135.239 214.461 149.301L174.379 152.477H174.384Z"
      fill="black"
    />
    <Path
      d="M171.223 154.19C172.319 154.19 173.522 153.85 174.769 153.174C175.155 152.964 175.297 152.482 175.087 152.097C174.876 151.711 174.392 151.57 174.006 151.78C172.17 152.778 170.539 152.864 169.41 152.026C167.934 150.928 167.448 148.447 168.105 145.384C168.51 143.505 169.231 141.304 169.994 138.978C171.817 133.426 173.882 127.132 172.89 121.711C172.077 117.257 172.551 113.733 174.127 112.514C175.018 111.825 176.294 111.852 177.923 112.596C183.147 114.98 180.85 131.099 179.525 137.11C179.438 137.505 179.664 137.906 180.051 138.032C180.437 138.16 180.858 137.977 181.029 137.607C182.197 135.05 189.284 126.341 198.66 127.407C203.408 127.944 207.733 130.358 210.526 134.026C213.549 137.995 214.638 143.235 213.675 149.175C213.604 149.608 213.901 150.016 214.335 150.084C214.767 150.155 215.18 149.859 215.248 149.427C216.282 143.047 215.088 137.387 211.794 133.064C208.74 129.053 204.016 126.414 198.839 125.827C193.206 125.191 188.59 127.722 185.704 129.959C184.12 131.188 182.789 132.532 181.766 133.748C182.116 131.599 182.505 128.744 182.681 125.812C183.176 117.55 181.798 112.617 178.586 111.149C175.831 109.892 174.108 110.515 173.148 111.26C171.038 112.892 170.373 116.804 171.32 121.994C172.238 127.02 170.239 133.109 168.476 138.483C167.7 140.851 166.964 143.089 166.543 145.049C165.751 148.735 166.464 151.816 168.452 153.297C169.252 153.891 170.191 154.187 171.217 154.187L171.223 154.19Z"
      fill="#393939"
    />
    <Path
      d="M186.457 73.9936C186.457 73.9936 188.28 75.1281 188.506 77.1691C188.735 79.2102 190.556 88.0557 194.428 90.3247C198.3 92.5938 200.804 91.006 201.033 82.1579C201.262 73.3123 195.796 67.4145 192.152 66.052L186.46 73.9909L186.457 73.9936Z"
      fill="black"
    />
    <Path
      d="M221.986 140.909C225.174 134.786 223.351 109.381 222.215 103.483C221.076 97.5853 224.948 95.7722 229.502 99.1731C234.055 102.577 237.472 119.586 237.017 135.011L221.986 140.909V140.909Z"
      fill="black"
    />
    <Path
      d="M188.829 66.9499C193.663 66.047 197.228 71.2231 198.195 73.8713"
      stroke="#3F4042"
      strokeWidth={2.40744}
    />
    <Path
      d="M221.982 141.703C222.271 141.703 222.547 141.545 222.689 141.273C226.099 134.723 224.044 108.786 222.992 103.331C222.542 100.999 222.913 99.2724 224.01 98.5886C225.165 97.8707 227.038 98.3239 229.019 99.8069C233.235 102.956 236.666 119.725 236.216 134.987C236.203 135.425 236.55 135.792 236.989 135.805C236.997 135.805 237.005 135.805 237.013 135.805C237.442 135.805 237.797 135.464 237.81 135.034C238.239 120.553 235.193 102.432 229.976 98.5362C227.446 96.6471 224.899 96.1624 223.166 97.2419C221.479 98.2899 220.848 100.619 221.429 103.63C222.692 110.175 224.197 134.93 221.277 140.542C221.074 140.932 221.227 141.409 221.616 141.614C221.735 141.674 221.858 141.703 221.982 141.703V141.703Z"
      fill="#393939"
    />
    <Path
      d="M224.009 85.8446C223.265 86.2964 221.981 86.4665 221.027 85.9272C220.155 85.4261 219.718 84.5202 219.559 83.3155C219.432 82.402 220.293 82.5162 222.299 83.6505C224.222 84.7467 224.743 85.3877 224 85.8395L224.009 85.8446Z"
      fill="white"
      fillOpacity={0.5}
    />
    <Path
      d="M215.044 69.6441L214.22 70.9429L212.797 74.1758L215.887 78.8027L220.182 79.9883L223.339 77.9976L218.95 72.5456L215.044 69.6441Z"
      fill="#FCFCFC"
      stroke="black"
      strokeWidth={0.36281}
    />
    <Path
      d="M219.126 71.5373C217.927 70.3771 215.218 67.1811 213.505 69.5375C211.71 72.0078 211.658 75.1208 213.82 77.7664C215.777 80.1582 220.086 81.8783 222.661 80.0379C223.499 79.4386 224.594 78.6118 224.211 77.6371C223.472 75.7625 222.021 74.3382 219.131 71.5418L219.126 71.5373ZM220.617 78.8736C218.304 79.3226 216.532 78.2003 215.207 76.6954C214.482 75.8723 213.961 74.7429 213.861 73.6453C213.739 72.2807 214.603 70.6771 215.185 70.4287C215.685 70.2141 216.686 71.2963 217.317 71.8334C215.975 72.2358 215.643 74.3078 216.326 75.3993C217.324 76.9984 219.747 77.0614 220.953 75.6834C221.009 75.737 221.063 75.7976 221.117 75.8535C221.751 76.5049 222.374 77.177 222.298 77.7526C222.233 78.2365 221.659 78.6722 220.617 78.8736Z"
      fill="black"
    />
    <Path
      d="M229.761 80.7091L229.776 82.3474L230.376 86.0601L235.817 88.4057L240.341 86.9786L242.01 83.3716L234.931 81.0407L229.761 80.7091Z"
      fill="#FCFCFC"
      stroke="black"
      strokeWidth={0.386324}
    />
    <Path
      d="M241.408 81.8133C239.899 80.9053 238.044 80.6358 236.333 80.3656C234.472 80.0739 232.519 79.8383 230.636 79.9207C229.416 79.9756 228.863 80.5045 228.811 81.7735C228.666 85.2185 230.225 88.4349 234.845 89.1089C237.95 89.5643 242.143 88.1946 242.796 84.3871C243.003 83.1721 242.484 82.4564 241.409 81.8099L241.408 81.8133ZM238.534 87.0159C237.512 87.4869 236.395 87.6138 234.703 87.3542C231.945 86.9301 230.563 84.7707 230.434 82.5211C230.353 81.1108 231.607 81.5917 232.734 81.6139C232.979 81.6185 233.554 81.8192 233.544 81.8314C232.656 83.2774 233.704 84.7985 235.317 85.1269C237.003 85.4695 238.494 84.0822 238.458 82.4738C239.752 82.7416 240.839 83.1416 240.892 83.7859C240.981 84.8612 239.699 86.4741 238.531 87.0153L238.534 87.0159Z"
      fill="black"
    />
    <Path
      d="M105.938 98.444L105.571 98.267C105.023 98.267 104.66 98.1897 104.694 98.4215C105.988 107.105 104.523 112.465 100.146 114.653L100.036 114.708C99.8715 114.817 99.7619 114.927 99.5975 114.982C99.5427 114.817 99.4331 114.653 99.3783 114.434C95.6517 105.939 89.5687 106.487 87.815 106.871C84.3076 107.583 81.5675 110.433 81.5675 113.283C81.5675 115.42 82.8827 118.38 85.6777 119.859C88.0342 121.065 92.0896 121.613 98.5015 117.777C99.1591 121.723 97.4054 125.23 96.145 127.203C93.6788 130.929 90.4455 133.012 89.1302 133.012C78.2245 134.053 15.612 135.314 -0.5 135.807V137.615C15.7216 137.122 78.6081 135.807 89.5139 134.766C89.6235 134.766 89.6783 134.711 89.7879 134.711C92.1444 134.272 95.4325 131.532 97.6794 128.134C99.1591 125.833 101.242 121.558 100.146 116.736C100.42 116.571 100.694 116.352 100.968 116.133C105.996 113.698 107.855 107.736 106.495 98.4441C106.321 98.4441 106.119 98.541 105.938 98.444ZM86.5545 118.38C84.2528 117.174 83.4308 114.763 83.4308 113.393C83.4308 111.42 85.5681 109.337 88.2534 108.734C88.637 108.68 89.1302 108.57 89.6783 108.57C91.9252 108.57 95.3229 109.611 97.789 115.201C97.8986 115.475 98.0083 115.749 98.1179 116.023C92.0896 119.75 88.5274 119.421 86.5545 118.38Z"
      fill="#E5F68C"
    />
  </Svg>
);

export default Onboarding2;
