import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const UploadIcon3 = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={25} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 5.376v4.126a8.462 8.462 0 00-5-1.626 8.462 8.462 0 00-5 1.626V5.376a2 2 0 012-2h6a2 2 0 012 2zm5.53 6.53a.75.75 0 00-1.06-1.06l-1 1a.75.75 0 001.06 1.06l1-1zm-2.06 7.94a.75.75 0 011.06 0l1 1a.75.75 0 01-1.06 1.06l-1-1a.75.75 0 010-1.06zm-19-7.94a.75.75 0 011.06-1.06l1 1a.75.75 0 01-1.06 1.06l-1-1zm2.06 7.94a.75.75 0 00-1.06 0l-1 1a.75.75 0 001.06 1.06l1-1a.75.75 0 000-1.06zm8.47 3.53a7 7 0 100-14 7 7 0 000 14zm.75-10a.75.75 0 00-1.085-.67l-1 .5a.75.75 0 00.585 1.378v4.043h-.25a.75.75 0 000 1.5h2a.75.75 0 000-1.5h-.25v-5.25z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default UploadIcon3;
