/* eslint-disable no-magic-numbers */
import { Animated } from 'react-native';

import { isMobile } from '@constants/platform';

export const PIN_QUANTITY = [0, 1, 2, 3, 4];

export const SHAKE_RANGE = 4.7;
export const SHAKE_DURATION = 10;

export const getShake = (animation: Animated.Value, shouldSubstract = false) =>
  Animated.timing(animation, {
    toValue: shouldSubstract ? -SHAKE_RANGE : SHAKE_RANGE,
    duration: SHAKE_DURATION,
    useNativeDriver: isMobile,
  });
