import { useQuery } from 'react-query';

import { axiosNotificationsInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';
import { isDesktop } from '@constants/platform';

async function getNotifications(searchType: string, page: number, take: number) {
  const response = await axiosNotificationsInstance.get(
    `/notifications${isDesktop ? '/desktop' : ''}/user?searchType=${searchType}&page=${page}&take=${take}`
  );
  return response.data;
}

export function useGetNotifications(searchType: string, page: number, take: number) {
  return useQuery([QueryKeys.GET_ALL_NOTIFICATIONS], () => getNotifications(searchType, page, take));
}

async function getNotificationsUnreadCount() {
  const response = await axiosNotificationsInstance.get(`/notifications${isDesktop ? '/desktop' : ''}/count`);
  return response.data;
}

export function useNotificationsUnreadCount() {
  return useQuery([QueryKeys.GET_NOTIFICATIONS_UNREAD_COUNT], () => getNotificationsUnreadCount(), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_ALL_NOTIFICATIONS);
    },
  });
}
