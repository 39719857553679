import {Vibration} from 'react-native';
import ReactNativeHapticFeedback from 'react-native-haptic-feedback';
import {isIos} from '@constants/platform';

export function mediumVibration() {
  if (isIos) {
    return ReactNativeHapticFeedback.trigger('impactMedium');
  }
  return Vibration.vibrate(50);
}
