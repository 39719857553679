import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const SinglePlayerIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={41} height={40} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.173 18.333a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zM20.172 35c6.443 0 11.667-2.985 11.667-6.667s-5.224-6.666-11.667-6.666S8.505 24.65 8.505 28.333C8.505 32.015 13.73 35 20.172 35z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default SinglePlayerIcon;
