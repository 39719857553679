import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { TINY } from 'app/theme/spacing';

const DIMENTIONS = moderateScale(40);
export const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    marginHorizontal: TINY / 2,
  },
  header: {
    width: DIMENTIONS,
    height: DIMENTIONS,
    borderRadius: DIMENTIONS / 2,
    backgroundColor: Colors.greyTwo,
    justifyContent: 'center',
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  chevronWhite: {
    backgroundColor: Colors.whiteOpacity(0.4),
  },
  chevron: {
    transform: [{ scale: 0.66 }],
  },
  chevronLeft: {
    transform: [{ scale: 0.66 }, { rotate: '180deg' }],
  },
  disabled: {
    opacity: 0.5,
  },
});
