import React, { useState } from 'react';
import { View } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';

import CustomText from '@atoms/CustomText';
import { Layout } from '@organisms/Layout';
import GoBackButton from '@molecules/GoBackButton';
import { useTranslations } from '@hooks/useTranslation';
import { RootStackNavigator } from 'app/navigation/types';
import NotificationsView from '@organisms/NotificationsView';
import { isDesktop, isMobile } from '@constants/platform';

import { styles } from './styles';

type NotificationsProps = NativeStackScreenProps<RootStackNavigator, 'Notifications'>;

const Notifications = ({ navigation }: NotificationsProps) => {
  const i18n = useTranslations();
  const [isScrollable, setScrollable] = useState(false);

  const handleGoBack = () => {
    navigation.goBack();
  };

  const handleNotificationsChange = (_: any[], unread: any[], read: any[]) => {
    setScrollable(unread.length > 3 || read.length > 3);
  };

  return (
    <Layout withScroll={isScrollable || isDesktop} tabOffset={isMobile ? 600 : 0}>
      <>
        {isMobile && (
          <View style={styles.goBack}>
            <GoBackButton goBackAction={handleGoBack} />
          </View>
        )}
        <CustomText size="xmedium" weight="interSemi" color="greySix">
          {i18n.t('notifications.title')}
        </CustomText>
      </>
      <View style={styles.container}>
        <NotificationsView onNotificationsChange={handleNotificationsChange} />
      </View>
    </Layout>
  );
};

export default Notifications;
