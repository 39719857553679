import React, { useEffect } from 'react';
import { Linking, Pressable, View, ViewStyle } from 'react-native';
import i18next from 'i18next';

import { isWebResponsive } from '@constants/platform';
import { Spacing } from '@theme';
import { useGameEvents } from '@services/useGameEvents';
import { EventNameEnum } from '@interfaces/event';
import gamequestBannerDesktopEn from '@assets/img/game_quest_desktop_en.png';
import gamequestBannerDesktopEs from '@assets/img/game_quest_desktop_es.png';
import gamequestBannerMobileEn from '@assets/img/game_quest_mobile_en.png';
import gamequestBannerMobileEs from '@assets/img/game_quest_mobile_es.png';
import FastImage from '@atoms/FastImage';
import links from '@constants/links';

import { styles } from './styles';

type AddBannerProps = {
  bannerDimentions?: {
    width: number;
    height: number;
  };
  customStyles?: ViewStyle;
  gameId: number;
  gameSlug: string;
};

const GameQuestBanner = ({ bannerDimentions, customStyles, gameId, gameSlug }: AddBannerProps) => {
  const { data } = useGameEvents(gameId);
  const [isGameOnGameQuest, setIsGameOnGameQuest] = React.useState(false);
  const { language } = i18next;

  useEffect(() => {
    if (data && data.gameEvents.length > 0) {
      for (const event of data.gameEvents) {
        if (event.event.name === EventNameEnum.GATO_GAME_QUEST) {
          setIsGameOnGameQuest(true);
          break;
        }
      }
    }
  }, [data]);

  const handleAdClick = () => {
    Linking.openURL(links.gameQuestVoteLink + gameSlug);
  };

  const getImage = () => {
    if (isWebResponsive) {
      if (language.toLocaleLowerCase() === 'es') {
        return gamequestBannerMobileEs;
      }
      return gamequestBannerMobileEn;
    }
    if (language.toLocaleLowerCase() === 'es') {
      return gamequestBannerDesktopEs;
    }
    return gamequestBannerDesktopEn;
  };

  if (isGameOnGameQuest) {
    return (
      <View style={{ marginBottom: Spacing.XLARGE }}>
        <Pressable
          onPress={handleAdClick}
          style={[
            styles.container,
            customStyles,
            {
              width: bannerDimentions?.width || '100%',
              height: bannerDimentions?.height || '100%',
            },
          ]}>
          <FastImage source={getImage()} style={styles.image} resizeMode="stretch" />
        </Pressable>
      </View>
    );
  }
  return <View />;
};
export default GameQuestBanner;
