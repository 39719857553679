import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const MultiPlayerIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={38} height={40} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.047 18.333c3.452 0 6.25-2.984 6.25-6.666 0-3.682-2.798-6.667-6.25-6.667s-6.25 2.985-6.25 6.667 2.798 6.666 6.25 6.666zm0 16.667c6.04 0 10.937-2.985 10.937-6.667s-4.897-6.666-10.937-6.666c-6.041 0-10.938 2.984-10.938 6.666C5.11 32.015 10.006 35 16.047 35zm6.19-16.974c1.488-1.648 2.404-3.89 2.404-6.359a9.684 9.684 0 00-.585-3.329c2.498.11 4.49 2.305 4.49 4.996 0 2.761-2.098 5-4.687 5-.57 0-1.117-.109-1.623-.308zm7.09 10.307c0 .898-.187 1.766-.535 2.586 2.666-.882 4.442-2.457 4.442-4.253 0-2.51-3.469-4.588-7.99-4.945 2.517 1.668 4.084 4.014 4.084 6.612z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default MultiPlayerIcon;
