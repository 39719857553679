import { Platform, StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { LATERALPLUS } from 'app/theme/spacing';
import { SCREEN_HEIGHT, SCREEN_WIDTH } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.blackTwo,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textError: { paddingHorizontal: moderateScale(40), marginVertical: moderateScale(8) },
  icon: {
    marginTop: moderateScale(60),
    width: Platform.OS !== 'web' ? SCREEN_WIDTH - LATERALPLUS * 2 : undefined,
    height: SCREEN_HEIGHT / 3,
  },
  footer: {
    flex: 0.3,
    justifyContent: 'center',
    padding: LATERALPLUS,
  },
  restart: { marginVertical: 20, width: '100%' },
  backToHome: {
    textDecorationLine: 'underline',
  },
});
