import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';
import { LATERALPLUS, TINY } from 'app/theme/spacing';
import { WINDOW_WIDTH } from '@constants/platform';

const DIMENTIONS = moderateScale(40);

export const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: moderateScale(288),
    height: moderateScale(262),
    // marginBottom: moderateVerticalScale(40),
  },
  footer: {
    flex: 0.3,
    justifyContent: 'center',
    padding: LATERALPLUS,
  },
  text: {
    letterSpacing: -1.5,
    maxWidth: WINDOW_WIDTH * 0.71,
    lineHeight: 30,
  },
  button: {
    marginVertical: moderateVerticalScale(20),
    width: '100%',
    maxWidth: 289,
    paddingVertical: moderateScale(14),
  },
  modalStyle: {
    width: '100%',
    maxWidth: 457,
    overflow: 'hidden',
  },
  spacing: {
    marginTop: moderateScale(60),
  },
  dotContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: moderateVerticalScale(16),
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginHorizontal: 4,
  },
  arrowButton: {
    marginHorizontal: TINY / 2,
  },
  header: {
    width: DIMENTIONS,
    height: DIMENTIONS,
    borderRadius: DIMENTIONS / 2,
    backgroundColor: Colors.socialBackground,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chevron: {
    transform: [{ scale: 0.66 }],
  },
  chevronLeft: {
    transform: [{ scale: 0.66 }, { rotate: '180deg' }],
  },
  disabled: {
    opacity: 0.5,
  },
});
