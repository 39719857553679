import { StyleSheet } from 'react-native';

import { Colors } from '@theme';

export const styles = StyleSheet.create({
  positionWebWideScreen: {
    position: 'absolute',
    top: 90,
    width: '100%',
    borderRadius: 0,
    height: '100%',
  },
  background: {
    backgroundColor: Colors.black,
  },
  container: {
    flex: 1,
  },
  innerContainer: {
    flex: 1,
  },
});
