import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale, verticalScale } from '@utils/scaling';
import { isWebResponsive } from '@constants/platform';

const BAR_DIMENTIONS = moderateScale(6);

export const tabbarStyles = StyleSheet.create({
  container: {
    position: 'absolute',
    width: '100%',
    alignSelf: 'center',
    paddingTop: verticalScale(16),
    paddingBottom: verticalScale(40),
    paddingHorizontal: moderateScale(35.8),
    backgroundColor: Colors.black,
    bottom: 0,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: isWebResponsive ? 'space-around' : 'space-between',
    alignSelf: 'center',
  },
  itemContainer: {},
  itemContent: {
    flex: 1,
  },
  item: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    height: moderateScale(24),
    width: moderateScale(24),
  },
  back: {
    backgroundColor: Colors.tabColorActive,
    // position: 'absolute',
    width: BAR_DIMENTIONS,
    height: BAR_DIMENTIONS,
    borderRadius: BAR_DIMENTIONS / 2,
  },
});
