import { useQuery } from 'react-query';

import { axiosAuthInstance } from '@config/api';
import { QueryKeys } from 'app/react-query/query-keys';
import { client } from 'app/react-query';

async function getFollows(type: 'followings' | 'followers', userId: number, token: string) {
  const response = await axiosAuthInstance.get(`/users/${type}/${userId}`, {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    headers: { Authorization: `Bearer ${token}` },
  });
  return response.data;
}

export function useFollows(type: 'followings' | 'followers', userId: number, token: string) {
  return useQuery([QueryKeys.GET_FOLLOWS, type, userId], () => getFollows(type, userId, token), {
    enabled: !!type && !!userId,
  });
}

export async function prefetchFollows(type: 'followings' | 'followers', userId: number, token: string) {
  await client.prefetchQuery(QueryKeys.GET_FOLLOWS, () => getFollows(type, userId, token));
}
