import React, { useRef } from 'react';
import { Platform, View, Share, Pressable } from 'react-native';
import Lottie from 'lottie-react-native';

import toast from '@utils/toast';
import ShareBannerIcon from '@assets/svg/ShareBannerIcon';
import CustomText from '@atoms/CustomText';
import { mediumVibration } from '@utils/vibration';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';
import ShareAction from '@utils/shareApp';

import { styles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const lottie = require('../../../assets/lotties/invite.json');

const url = Platform.select({
  ios: 'https://apps.apple.com/ar/app/id6444835134',
  android: 'https://play.google.com/store/apps/details?id=com.gatogames.app',
});
const message = `Let's play on GATO! ^-^ GATO is a free social gaming platform where you can play your favorite games, connect with your friends and express yourself! Get it at ${url}`;

export const ShareAppBanner = (): JSX.Element => {
  const animation = useRef<Lottie>(null);
  const i18n = useTranslations();

  function playLottie() {
    mediumVibration();
    animation?.current?.play();
  }

  async function handleShare() {
    return ShareAction();
  }
  return (
    <View style={styles.container}>
      <View style={styles.row}>
        <ShareBannerIcon />
        <View style={styles.description}>
          <CustomText align="left">{i18n.t('home.invite_friends')}</CustomText>
          <CustomText weight="interSemi" customStyle={styles.description2} align="left">
            {i18n.t('home.share_app')}
          </CustomText>
        </View>
      </View>
      <View style={styles.btnContainer}>
        <Pressable onPress={playLottie}>
          <View>
            <Lottie
              autoPlay={false}
              ref={animation}
              style={styles.lottie}
              source={lottie}
              loop={false}
              onAnimationFinish={handleShare}
            />
          </View>
        </Pressable>
        {/* <Button onPress={share} customStyle={styles.btn}>
            Invite
          </Button> */}
      </View>
    </View>
  );
};
