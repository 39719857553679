import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';

export const styles = StyleSheet.create({
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    flexDirection: 'row',
    alignItems: 'center',
    zIndex: 3,
  },
  header: {
    paddingBottom: moderateScale(32),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    flex: 1,
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: moderateScale(20),
    marginRight: moderateScale(34),
  },
  avatarStyle: {
    width: moderateScale(56),
    height: moderateScale(56),
    borderRadius: moderateScale(56 / 2),
    marginTop: 0,
    marginRight: moderateScale(16),
  },
  btn: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ctaFollow: {
    paddingVertical: moderateScale(8),
    paddingHorizontal: moderateScale(16),
    borderRadius: moderateScale(90),
  },
  unfollow: {
    borderWidth: 2,
    borderColor: Colors.greySix,
  },
  follow: {
    backgroundColor: Colors.greyTwo,
  },
});
