import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Haptics from 'expo-haptics';

import ChangeAvatarLayout from '@templates/ChangeAvatarLayout';
import { AvatarResponse, useGetAvatarImages } from '@services/useGetAvatarImages';
import Button from '@atoms/Button';
import AvatarItem from '@molecules/AvatarItem';
import AvatarImage from '@molecules/AvatarImage';
import { createMatrix } from '@utils/createMatrix';
import { Avatar } from '@interfaces/avatar';
import { RootStackNavigator } from 'app/navigation/types';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';

import styles from './styles';

const getMatrix = (data: AvatarResponse | undefined) => {
  if (!data) return null;
  const filtered = data?.profileImages.filter((avatar) => !avatar.default);
  return createMatrix(filtered);
};

type Props = NativeStackScreenProps<RootStackNavigator, 'ChangeAvatarFirstStep'>;

const ChangeAvatarFirstStep: FC<Props> = ({ navigation }) => {
  const i18n = useTranslations();

  const { data, isLoading } = useGetAvatarImages();
  const [selectedCat, setSelectedCat] = useState<Avatar | null>(null);

  useEffect(() => {
    if (data?.profileImages.length && !isLoading) {
      setSelectedCat(data.profileImages[1]);
    }
  }, [data, isLoading]);

  const handleGoBack = () => (isWebResponsive ? navigation.navigate('AccountSettings') : navigation.goBack());

  const handleSelect = async (item: Avatar) => {
    if (!isWebResponsive) {
      await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    setSelectedCat(item);
  };

  const handleNext = () => {
    if (!selectedCat) return;
    navigation.navigate('ChangeAvatarSecondStep', selectedCat);
  };

  const filteredAvatarMatrix: Avatar[][] = useMemo(() => getMatrix(data), [data]);

  return (
    <ChangeAvatarLayout title={i18n.t('avatar.choose_step_title_first')} step="1" handleBack={handleGoBack}>
      {isLoading || !data || !selectedCat ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      ) : (
        <>
          <AvatarImage avatar={selectedCat} />

          <View style={styles.bottom}>
            <View style={styles.bottomContent}>
              <ScrollView
                scrollEnabled
                contentContainerStyle={
                  isWebResponsive
                    ? {
                        alignItems: 'center',
                        paddingVertical: 0,
                        zIndex: 999,
                      }
                    : styles.matrixContainer
                }>
                {filteredAvatarMatrix?.map((elements, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <View style={styles.itemsContainer} key={index}>
                    {elements?.map((item) => (
                      <AvatarItem
                        key={item.id}
                        item={item}
                        handleSelect={handleSelect}
                        selectedImage={selectedCat}
                      />
                    ))}
                  </View>
                ))}
              </ScrollView>
              <View style={styles.ctas}>
                <Button
                  onPress={handleGoBack}
                  customTextStyle={[styles.btnText, styles.btnTextMaybe]}
                  customStyle={[styles.btns, styles.btnMaybe]}>
                  {i18n.t('avatar.alert_cta_secondary')}
                </Button>
                <Button customTextStyle={styles.btnText} onPress={handleNext} customStyle={styles.btns}>
                  {i18n.t('avatar.choose_cta_primary')}
                </Button>
              </View>
            </View>
          </View>
        </>
      )}
    </ChangeAvatarLayout>
  );
};

export default memo(ChangeAvatarFirstStep);
