import { useFocusEffect } from '@react-navigation/core';
import React, { useCallback } from 'react';
import { View } from 'react-native';
import Animated, {
  interpolate,
  useAnimatedStyle,
  useSharedValue,
  withDelay,
  withSpring,
} from 'react-native-reanimated';

import { styles } from './styles';

interface NavbarProps {
  left?: JSX.Element;
  right?: JSX.Element;
  title?: JSX.Element;
}

export const Navbar = ({ left, title, right }: NavbarProps) => {
  const translateY = useSharedValue(0);

  useFocusEffect(
    useCallback(() => {
      translateY.value = withDelay(500, withSpring(1, { stiffness: 100, damping: 30 }));

      return () => {
        setTimeout(() => {
          translateY.value = withSpring(0);
        }, 200);
      };
    }, [translateY])
  );

  const titleStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateY: interpolate(translateY.value, [0, 1], [-100, 0]) }],
    }),
    []
  );
  const leftStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateX: interpolate(translateY.value, [0, 1], [-100, 0]) }],
    }),
    []
  );
  const rightStyle = useAnimatedStyle(
    () => ({
      transform: [{ translateX: interpolate(translateY.value, [0, 1], [100, 0]) }],
    }),
    []
  );
  return (
    <View style={[styles.container]}>
      {left && <Animated.View style={[styles.left, leftStyle]}>{left}</Animated.View>}
      {title && <Animated.View style={[styles.center, titleStyle]}>{title}</Animated.View>}
      {right && <Animated.View style={[styles.right, rightStyle]}>{right}</Animated.View>}
    </View>
  );
};
