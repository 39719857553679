import React, { ReactNode } from 'react';
import { Text, Animated, TextStyle } from 'react-native';
import Reanimated from 'react-native-reanimated';

import styles from './styles';

export type Colors =
  | 'darkGrey'
  | 'white'
  | 'whiteTwo'
  | 'black'
  | 'blackTwo'
  | 'limeGreen'
  | 'grey'
  | 'pinkBubblegum'
  | 'liliac'
  | 'pumpkin'
  | 'greyTwo'
  | 'greyThree'
  | 'greySix'
  | 'greyFive'
  | 'greySeven'
  | 'greyEight'
  | 'error'
  | 'greyFilter'
  | 'purple'
  | 'lightGrey';

export type Sizes =
  | 'xsmall'
  | 'msmall'
  | 'small'
  | 'medium'
  | 'xmedium'
  | 'lmedium'
  | 'big'
  | 'mbig'
  | 'xbig'
  | 'large'
  | 'mlarge'
  | 'xlarge'
  | 'xxlarge';

interface Props {
  size?: Sizes;
  weight?:
    | 'interBlack'
    | 'interBold'
    | 'interBoldIta'
    | 'interSemi'
    | 'interSemiIta'
    | 'interMedium'
    | 'interMediumIta'
    | 'interRegular'
    | 'interRegularIta'
    | 'oswaldRegular'
    | 'oswaldSemi'
    | 'oswaldBold';
  color?: Colors;
  reanimated?: boolean;
  children?: ReactNode;
  customStyle?: TextStyle | TextStyle[];
  align?: 'left' | 'center' | 'right';
  animated?: boolean;
  testID?: string;
  rStyle?: any;
  numberOfLines?: number;
}

const CustomText = ({
  size = 'medium',
  weight = 'interBold',
  align = 'center',
  customStyle,
  rStyle,
  children,
  animated,
  color = 'white',
  reanimated,
  testID = '',
  ...props
}: Props) => {
  const isSmall = size === 'small';

  if (animated) {
    return (
      <Animated.Text
        allowFontScaling={false}
        testID={testID}
        style={[
          isSmall && styles.lineHeight,
          styles.default,
          styles[size],
          styles[weight],
          styles[color],
          styles[align],
          customStyle,
        ]}
        {...props}>
        {children}
      </Animated.Text>
    );
  }

  if (reanimated) {
    return (
      <Reanimated.Text
        allowFontScaling={false}
        testID={testID}
        style={[
          isSmall && styles.lineHeight,
          styles.default,
          styles[size],
          styles[weight],
          styles[color],
          styles[align],
          customStyle,
          rStyle.rFontStyle,
          rStyle.labelTranslateStyle,
        ]}
        {...props}>
        {children}
      </Reanimated.Text>
    );
  }
  return (
    <Text
      allowFontScaling={false}
      testID={testID}
      style={[
        isSmall && styles.lineHeight,
        styles.default,
        styles[size],
        styles[weight],
        styles[color],
        styles[align],
        customStyle,
      ]}
      {...props}>
      {children}
    </Text>
  );
};

export default React.memo(CustomText);
