interface BodyPolicysProps {
    id: number
    title?: string | null
    subtitule?: string | null
    body: string[]
}


export const Policys: BodyPolicysProps[] = [
    {
        id: 1,
        title: '1. Information We Collect',
        subtitule: '1.1 Personal Data',
        body: [`When you register for and use our App, we may collect the following personal data:`,
        `User credentials such as username and password`,
        `Contact information such as email address`,
        `User content such as user profiles and in-app posts or messages`,
        `Device information like device ID, device type, and operating system`,
        `Location data to provide personalized content and for advertising and promotional purposes`,
        ` `,
        `1.2 Non-Personal Data`,
        `We may also collect data in a form that does not, on its own, permit direct association with any specific individual. Non-personal data includes:`,
        `Gameplay data`,
        `Information about your device, hardware, and software, such as your IP address, browser type, domain names, access times, and referring website addresses`,
        ` Aggregate user data, such as information about how you use our App`
        ],
    },
    {
        id: 2,
        title: '2. Use of Information',
        subtitule: 'We use the information we collect for the following purposes:',
        body: [`To operate our App and provide you with our services`,
        `To personalize your experience on our App`,
        `To manage and improve our App and services`,
        `For customer service and support`,
        `For research and analytical purposes`,
        `To provide relevant advertising and promotional content`
        ],
    },
    {
        id: 3,
        title: '3. Sharing of Information',
        subtitule: 'We may share your information:',
        body: [`With third-party service providers to provide necessary services on our behalf`,
        `With other users, when you share information or content via the App`,
        `With third parties, in the event of a merger, acquisition, or sale of all or a portion of our assets`,
        `As required by law, such as to comply with a subpoena, or similar legal process`
        ],
    },
    {
        id: 4,
        title: '4. Third-Party Integrations',
        subtitule: null,
        body: [`Our App may be integrated with other platforms and services for an enhanced user experience. These third parties have their own privacy policies, which govern your interaction with them. We encourage you to review these policies before using such services.`
        ],
    },
    {
        id: 5,
        title: '5. Compliance with GDPR, CCPA, and LGPD',
        subtitule: null,
        body: [`5.1 GDPR`,
        `For users in the European Economic Area (EEA), we adhere to the General Data Protection Regulation (GDPR). We collect and process personal data with your consent or if it's necessary to provide our services, fulfill our contractual obligations, or comply with legal requirements.`,
        `5.2 CCPA`,
        `For our users in California, we comply with the California Consumer Privacy Act (CCPA). We do not sell your personal data and uphold your rights to know about the personal information we collect, the purpose for its collection, and your rights to opt out of its sale.`,
        `5.3 LGPD`,
        `For our users in Brazil, we adhere to the Lei Geral de Proteção de Dados (LGPD). We respect your rights to access, correct, anonymize, block, or delete unnecessary or excessive data or data that is not being processed in compliance with this law.`
        
        ],
    },
    {
        id: 6,
        title: '6. Changes to this Privacy Policy',
        subtitule: null,
        body: [`We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.`,
        ],
    },
    {
        id: 7,
        title: '7. Contact Us',
        subtitule: null,
        body: [`If you have any questions about this Privacy Policy, please contact us at hello@gato.us`,
        ],
    }
]

