import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const DeleteAccountIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      d="M15.634 7.17a.594.594 0 00-1.184-.09l1.184.09zm-1.16 7.323l.593.045-.592-.045zm-9.949 0l-.592.045.592-.045zm.025-7.414a.594.594 0 10-1.184.092l1.184-.092zm11.815-1.004a.594.594 0 10.52-1.067l-.52 1.067zM2.115 5.008a.594.594 0 10.52 1.067l-.52-1.067zm5.208-1.05a.594.594 0 101.187 0H7.323zm3.167 0a.594.594 0 101.187 0H10.49zm-1.98 4.75a.594.594 0 10-1.187 0H8.51zm-1.187 4.75a.594.594 0 001.187 0H7.323zm4.354-4.75a.594.594 0 10-1.187 0h1.187zm-1.187 4.75a.594.594 0 101.187 0H10.49zm3.96-6.379l-.567 7.368 1.184.091.567-7.367-1.184-.092zm-3.132 9.744H7.682v1.187h3.636v-1.187zm-6.2-2.376L4.55 7.08l-1.184.092.567 7.367 1.184-.09zm2.564 2.376a2.573 2.573 0 01-2.565-2.376l-1.184.091a3.76 3.76 0 003.75 3.472v-1.187zm6.2-2.376a2.573 2.573 0 01-2.564 2.376v1.187a3.76 3.76 0 003.749-3.472l-1.184-.09zM9.5 4.552c2.537 0 4.892.562 6.865 1.523l.52-1.067C14.745 3.965 12.21 3.365 9.5 3.365v1.187zM2.635 6.075c1.973-.96 4.328-1.523 6.865-1.523V3.365c-2.71 0-5.244.6-7.385 1.643l.52 1.067zM8.51 3.958v-.791H7.323v.791H8.51zm1.98-.791v.791h1.187v-.791H10.49zm-.99-.99a.99.99 0 01.99.99h1.187A2.177 2.177 0 009.5.99v1.187zm-.99.99a.99.99 0 01.99-.99V.99a2.177 2.177 0 00-2.177 2.177H8.51zM7.323 8.708v4.75H8.51v-4.75H7.323zm3.167 0v4.75h1.187v-4.75H10.49z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default DeleteAccountIcon;
