import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

export const styles = StyleSheet.create({
  container: {
    padding: moderateScale(15),
    borderRadius: moderateScale(12),
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: moderateScale(9),
    backgroundColor: Colors.greyFour,
    flex: 1,
    height: moderateScale(100),
    ...(isDesktop && {
      minWidth: 100,
      marginBottom: moderateScale(9),
      justifyContent: 'space-evenly',
    }),
  },
  noMargin: {
    marginRight: 0,
  },
});
