import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale, moderateVerticalScale } from '@utils/scaling';

const DOT_WIDTH = 55;
const DOT_HEIGHT = 55;

const CONT_SIZE = 45;

export default StyleSheet.create({
  content: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: moderateScale(CONT_SIZE),
    height: moderateScale(CONT_SIZE),
  },
  contentTouch: {
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    width: moderateScale(DOT_WIDTH),
    height: moderateVerticalScale(DOT_HEIGHT),
  },
  spacing: {
    marginRight: moderateScale(20),
  },
  bullet: {
    width: moderateScale(DOT_WIDTH),
    height: moderateVerticalScale(DOT_HEIGHT),
    borderRadius: 16,
    backgroundColor: Colors.darkGrey,
    borderWidth: 2,
    borderColor: Colors.black,
    opacity: 0.7,
  },
  text: {
    position: 'absolute',
  },
  empty: {
    borderWidth: 2,
    borderColor: Colors.transparentBlack,
    color: Colors.blackTwo,
  },
  error: {
    borderWidth: 2,
    borderColor: Colors.error,
  },
  filled: {
    borderWidth: 2,
    borderColor: Colors.greyFour,
  },
  isNext: {
    borderWidth: 2,
    borderColor: Colors.limeGreen,
  },
});
