import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const MouseIconBig = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={40} viewBox="0 0 25 40" fill="none" {...props}>
    <Path
      d="M17.2728 7.94474C16.9712 7.75541 12.259 4.88741 7.50474 6.47918C3.19223 7.9237 1.4532 11.8856 1.08857 12.8393C1.06052 12.9094 1.03949 12.9795 1.01144 13.0426C0.45046 14.6554 0.289184 16.3103 0.534611 18.0002L4.6087 30.1664C4.6087 30.1664 4.6087 30.1664 4.6087 30.1734C7.56084 35.3134 13.6054 37.1296 18.8365 34.4649C22.7212 32.4875 25.0423 27.9926 24.4042 23.6381C24.3972 23.596 24.3972 23.5609 24.3972 23.5329L20.3301 11.3947C19.5587 10.0063 18.535 8.86334 17.2728 7.94474ZM20.8069 22.7826C21.9219 26.2115 20.3651 29.921 17.1746 31.5408C13.5072 33.3991 8.94926 31.8073 7.27334 28.0698C7.10505 27.6981 6.9648 27.3125 6.83157 26.9268C5.927 24.2411 5.02242 21.5484 4.12486 18.8557C3.10809 15.7844 4.21602 12.4466 6.86663 10.5883C6.95779 10.5252 7.06298 10.4621 7.19621 10.371C7.57487 11.5139 7.94651 12.6079 8.31115 13.7018C8.67578 14.7887 9.0334 15.8685 9.39804 16.9554C9.73462 17.9512 10.6322 18.435 11.5648 18.1195C12.4834 17.8109 12.9112 16.8853 12.5886 15.9106C11.9014 13.842 11.2002 11.7734 10.513 9.69779C10.4709 9.57858 10.4288 9.45937 10.3587 9.242C11.0038 9.28407 11.6069 9.25602 12.1749 9.3612C14.9868 9.87309 16.9502 11.4789 17.9109 14.1716C18.9346 17.0326 19.8743 19.9076 20.8069 22.7826Z"
      fill={fill || '#777E90'}
    />
  </Svg>
);
export default MouseIconBig;
