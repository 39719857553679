import { StyleSheet } from 'react-native';

import { Colors, Fonts } from '@theme';
import { isIos } from '@constants/platform';

export default StyleSheet.create({
  default: {
    color: Colors.black,
    fontFamily: Fonts.INTER_BOLD,
    fontSize: Fonts.SIZES.xMedium,
    textAlign: 'center',
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  center: {
    textAlign: 'center',
  },
  error: {
    color: Colors.inputError,
  },
  black: {
    color: Colors.black,
  },
  blackTwo: {
    color: Colors.blackTwo,
  },
  darkGrey: {
    color: Colors.darkGrey,
  },
  white: {
    color: Colors.white,
  },
  whiteTwo: {
    color: Colors.whiteTwo,
  },
  limeGreen: {
    color: Colors.limeGreen,
  },
  greySeven: {
    color: Colors.greySeven,
  },
  grey: {
    color: Colors.grey,
  },
  greyEight: {
    color: Colors.greyEight,
  },
  greyFive: {
    color: Colors.greyFive,
  },
  greyTwo: {
    color: Colors.greyTwo,
  },
  greyFilter: {
    color: Colors.greySix,
  },
  greySix: {
    color: Colors.greySix,
  },
  greyThree: {
    color: Colors.greyThree,
  },
  pinkBubblegum: {
    color: Colors.pinkBubblegum,
  },
  liliac: {
    color: Colors.liliac,
  },
  purple: {
    color: Colors.purple,
  },
  pumpkin: {
    color: Colors.pumpkin,
  },
  lightGrey: {
    color: Colors.lightGrey,
  },
  interBlack: {
    fontFamily: Fonts.INTER_BLACK,
  },
  interBold: {
    fontFamily: Fonts.INTER_BOLD,
  },
  interBoldIta: {
    fontFamily: Fonts.INTER_BOLD_ITA,
  },
  interSemi: {
    fontFamily: Fonts.INTER_SEMI,
  },
  interSemiIta: {
    fontFamily: Fonts.INTER_SEMI_ITA,
  },
  interMedium: {
    fontFamily: Fonts.INTER_MEDIUM,
  },
  interMediumIta: {
    fontFamily: Fonts.INTER_MEDIUM_ITA,
  },
  interRegular: {
    fontFamily: Fonts.INTER_REGULAR,
  },
  interRegularIta: {
    fontFamily: Fonts.INTER_REGULAR_ITA,
  },
  oswaldBold: {
    fontFamily: Fonts.OSWALD_BOLD,
  },
  oswaldSemi: {
    fontFamily: Fonts.OSWALD_SEMI,
  },
  oswaldRegular: {
    fontFamily: Fonts.OSWALD_REGULAR,
  },
  xsmall: {
    fontSize: Fonts.SIZES.xSmall,
  },
  msmall: {
    fontSize: Fonts.SIZES.mSmall,
  },
  small: {
    fontSize: Fonts.SIZES.small,
  },
  medium: {
    fontSize: Fonts.SIZES.medium,
  },
  xmedium: {
    fontSize: Fonts.SIZES.xMedium,
  },
  lmedium: {
    fontSize: Fonts.SIZES.lMedium,
  },
  big: {
    fontSize: Fonts.SIZES.big,
  },
  mbig: {
    fontSize: Fonts.SIZES.mBig,
  },
  xbig: {
    fontSize: Fonts.SIZES.xBig,
  },
  large: {
    fontSize: Fonts.SIZES.large,
  },
  mlarge: {
    fontSize: Fonts.SIZES.mlarge,
  },
  xlarge: {
    fontSize: Fonts.SIZES.xLarge,
  },
  xxlarge: {
    fontSize: Fonts.SIZES.xxLarge,
  },
  lineHeight: {
    lineHeight: isIos ? 21.752 : 24.752,
  },
});
