import { useMutation } from 'react-query';

import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';

async function likeService(id: number) {
  const response = await axiosGamesInstance.put(`/game/like/${id}`);
  return response.data;
}

export function useLike() {
  return useMutation((id: number) => likeService(id), {
    onSuccess: async () => {
      await client.invalidateQueries(QueryKeys.GET_GAME_DETAIL);
      await client.invalidateQueries(QueryKeys.GET_LIKED_GAMES);
      await client.invalidateQueries(QueryKeys.GET_USER_PROFILE);
    },
  });
}
