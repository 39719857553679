import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import { Portal } from '@gorhom/portal';

import CustomText from '@atoms/CustomText';
import { nFormatter } from '@utils/nFormatter';
import { CustomModal } from '@molecules/CustomModal';
import Follows from '@screens/Main/Follows';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import { styles } from './styles';
import { useNavigation } from '@react-navigation/core';
import { RootStackNavigator } from 'app/navigation/types';
import { useTranslation } from 'react-i18next';

interface UserFollowingProps {
  userType: 'PLAYER' | 'CREATOR';
  likes: number;
  games: number;
  followings: number;
  followers: number;
  userId: number;
  authToken?: string;
}

export const UserFollowing = ({
  userType,
  likes = 0,
  games = 0,
  followers = 0,
  followings = 0,
  userId,
  authToken,
}: UserFollowingProps) => {
  const [showUpdateApp, setShowUpdateApp] = useState(false);
  const [type, setType] = useState('');
  const i18n = useTranslation()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackNavigator>>();

  const handleFollow = (value: 'followings' | 'followers') => {
    if (!authToken) return navigation.navigate('Login')
    setType(value);
    setShowUpdateApp(true);
  };
  return (
    <>
      <View style={styles.container}>
        <View style={styles.flex}>
          <CustomText size="xbig" weight="interSemi">
            {userType === 'PLAYER' ? nFormatter(likes, 0) : nFormatter(games, 0)}
          </CustomText>
          <CustomText customStyle={styles.description} weight="interSemi" color="greySix">
            {userType === 'PLAYER' ? i18n.t('profile.likes') : i18n.t('profile.games')}
          </CustomText>
        </View>
        <View style={styles.divider} />
        <View style={styles.flex}>
          <Pressable disabled={followings === 0} onPress={() => handleFollow('followings')}>
            <>
              <CustomText size="xbig" weight="interSemi">
                {nFormatter(followings, 0)}
              </CustomText>
              <CustomText customStyle={styles.description} weight="interSemi" color="greySix">
              {i18n.t('profile.following')}
              </CustomText>
            </>
          </Pressable>
        </View>
        <View style={styles.divider} />
        <View style={styles.flex}>
          <Pressable disabled={followers === 0} onPress={() => handleFollow('followers')}>
            <>
              <CustomText size="xbig" weight="interSemi">
                {nFormatter(followers, 0)}
              </CustomText>
              <CustomText customStyle={styles.description} weight="interSemi" color="greySix">
              {i18n.t('profile.followers')}
              </CustomText>
            </>
          </Pressable>
        </View>
      </View>
      <Portal>
        <CustomModal
          visible={showUpdateApp}
          onOverlayPress={() => setShowUpdateApp(false)}
          bottomFixed={false}
          customCardStyle={styles.modal}>
          <Follows
            type={type}
            userId={userId}
            authToken={authToken}
            closeModal={() => setShowUpdateApp(false)}
          />
        </CustomModal>
      </Portal>
    </>
  );
};
