import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const TwitterIcon = (props: SvgProps) => (
  <Svg width={14} height={12} viewBox="0 0 14 12" fill="none" {...props}>
    <Path
      d="M0 10.2801C1.50699 10.3634 2.82479 10.0173 4.10997 9.10103C2.81174 8.88317 1.91147 8.30006 1.42218 7.09541C1.80056 7.04415 2.13328 7.14026 2.5247 6.98648C1.24604 6.43541 0.417523 5.60881 0.384904 4.17989C0.789377 4.21193 1.096 4.47464 1.57876 4.4362C0.404478 3.30844 0.0456663 2.07815 0.782852 0.58515C2.00932 1.96922 3.44455 2.91116 5.19945 3.35329C5.2973 3.37892 5.38863 3.40455 5.48649 3.42378C5.93663 3.5263 6.47158 3.74416 6.74558 3.71212C7.21529 3.65445 6.74558 3.12261 6.86301 2.43058C7.23486 0.277579 9.64865 -0.702805 11.4362 0.559519C11.9581 0.931167 12.3691 0.92476 12.8844 0.700489C13.1519 0.58515 13.4259 0.469811 13.7456 0.335248C13.6738 0.950391 13.1911 1.29 12.8062 1.74495C13.2432 1.84107 13.5955 1.71932 14 1.59116C13.863 2.0333 13.5238 2.2832 13.2171 2.52669C12.8975 2.77659 12.7735 3.03931 12.7605 3.44941C12.5648 9.6585 5.38211 14.4579 0.469716 10.6389C-0.006519 10.2672 0.456664 10.6389 0 10.2801Z"
      fill="#777E91"
    />
  </Svg>
);
export default TwitterIcon;
