import React, { FC, useEffect } from 'react';
import { ColorValue, View } from 'react-native';
import { NavigationContainer, DefaultTheme, ParamListBase } from '@react-navigation/native';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
  createDrawerNavigator,
} from '@react-navigation/drawer';
import { SvgProps } from 'react-native-svg';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';

import CustomText from '@atoms/CustomText';
import { Colors } from '@theme';
import Analytics from '@assets/svg/Analytics';
import { useTranslations } from '@hooks/useTranslation';
import PaymentAndCollection from '@assets/svg/PaymentAndCollection';
import MyGames from '@assets/svg/MyGames';
import { useMyProfile } from '@services/useMyProfile';

import { styles } from './styles';
import { AnalyticsScreen } from './Analytics';

const Drawer = createDrawerNavigator();

const drawerItemIcon =
  (Icon: FC<SvgProps>, nonFocusColor?: ColorValue, focusColor: ColorValue = Colors.limeGreen) =>
  ({ focused }: { focused?: boolean }) =>
    <Icon fill={focused ? focusColor : nonFocusColor} />;

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const i18n = useTranslations();

  return (
    <DrawerContentScrollView {...props} showsVerticalScrollIndicator={false} style={styles.drawerContainer}>
      <CustomText
        size="msmall"
        align="left"
        weight="interRegular"
        color="greySix"
        customStyle={styles.drawerTitle}>
        {i18n.t('dashboard.sidebar.title')}
      </CustomText>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  );
};

const ComingSoon = () => <View style={{ backgroundColor: Colors.black }} />;

const MyTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    border: 'rgb(0, 0, 0)',
  },
};

export const Dashboard = ({
  navigation,
}: {
  navigation: NativeStackNavigationProp<ParamListBase, string, undefined>;
}): JSX.Element => {
  // hooks
  const { navigate } = navigation;
  const i18n = useTranslations();
  const { data } = useMyProfile();

  // effects
  useEffect(() => {
    if (data && data?.type !== 'CREATOR') {
      navigate('Home');
    }
  }, [data, navigate]);

  // render
  return (
    <NavigationContainer independent theme={MyTheme}>
      <Drawer.Navigator
        screenOptions={{
          drawerType: 'permanent',
          headerShown: false,
          drawerActiveTintColor: Colors.limeGreen,
          drawerInactiveTintColor: Colors.greySix,
          drawerItemStyle: styles.drawerItem,

          drawerLabelStyle: { width: '100%', alignSelf: 'flex-start' },
        }}
        drawerContent={CustomDrawerContent}>
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.analytics')}
          component={AnalyticsScreen}
          options={{
            drawerIcon: drawerItemIcon(Analytics),
            title: i18n.t('dashboard.sidebar.analytics')!,
          }}
        />
        <Drawer.Screen name={i18n.t('dashboard.sidebar.coming_soon_in_gato')} component={ComingSoon} />
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.payments_and_billing')}
          component={AnalyticsScreen}
          options={{
            drawerIcon: drawerItemIcon(PaymentAndCollection),
            title: i18n.t('dashboard.sidebar.payments_and_billing')!,
            drawerItemStyle: styles.drawerItemDisable,
          }}
        />
        <Drawer.Screen
          name={i18n.t('dashboard.sidebar.my_games')}
          component={AnalyticsScreen}
          options={{
            drawerIcon: drawerItemIcon(MyGames),
            title: i18n.t('dashboard.sidebar.my_games')!,
            drawerItemStyle: styles.drawerItemDisable,
          }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};
