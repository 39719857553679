import * as React from 'react';
import { SVGProps } from 'react';

const GameUploadFormIconWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg width={400} height={296} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path fill="#3D2A97" d="m221.949 282.262-1.169-19.714 19.964-4.511 1.838 33.831-20.633-9.606Z" />
      <path
        fill="#777E91"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m39.01 120.789 84.37 155.958 213.93-97.15-89.298-147.27L39.011 120.79Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.8}
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m59.06 111.184 63.485 165.564 224.706-68.665-69.584-157.628-218.608 60.729Z"
      />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m360.197 232.808-229.801 49.035-44.774-155.54 10.107-17.459 208.751-39.01 55.717 162.974Z"
      />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m129.978 102.411-29.571 75.264 14.869 51.54L169.74 94.977l-39.762 7.434Z"
      />
      <path
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m117.031 135.658 178.178-34.583"
      />
      <path
        fill="#3D2A97"
        d="M220.779 138.082v1.922s-.167 3.174 2.757 3.926c2.923.668 107.675 4.093 107.675 4.093l-4.344-12.781-106.088 2.84Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m294.791 2.672-71.422 114.024-2.589 23.389 19.296-11.945L318.18 18.961l-23.389-16.29Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M288.859 12.112s.585 5.43 8.604 11.026c8.855 6.098 14.034 5.263 14.034 5.263l6.432-9.022L294.79 2.59l-5.931 9.523Z"
      />
      <path
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M223.369 116.695s.669 5.012 5.43 8.521c4.761 3.508 11.277 2.923 11.277 2.923l-19.296 11.946 2.589-23.39Z"
      />
      <path
        fill="#6546FC"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M221.782 131.23s.418 1.588 2.339 2.841c1.921 1.253 3.926 1.503 3.926 1.503l-7.267 4.511 1.002-8.855Z"
      />
      <path
        fill="#fff"
        fillOpacity={0.8}
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m95.73 108.843-10.108 17.458 14.117-3.257-4.01-14.201Z"
      />
      <path
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m123.464 165.313 178.261-34.583M129.981 194.967l178.177-34.583M136.408 224.623l178.177-34.583"
      />
      <path
        fill="#3D2A97"
        d="m87.543 133.067-7.434 1.838 45.609 140.838 2.673-.835-40.848-141.841ZM53.71 148.021l10.777-4.093-9.607-23.39 6.85-2.172 8.938 23.223 48.7 127.723-65.657-121.291Z"
      />
      <path
        fill="#6546FC"
        d="m215.934 124.799-15.036-22.721-3.257 13.114-44.691-32.41 25.895 35.752-17.458 2.422 31.743 15.036-4.344 6.516 30.239 8.019"
      />
      <path
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m215.934 124.799-15.036-22.721-3.257 13.114-44.691-32.41 25.895 35.752-17.458 2.422 31.743 15.036-4.344 6.516 30.239 8.019"
      />
      <path
        fill="#BBACED"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M317.858 19.338c2.572-3.548-.52-10.179-6.907-14.81-6.387-4.63-13.65-5.508-16.222-1.96-2.573 3.549.519 10.18 6.906 14.81 6.387 4.63 13.65 5.509 16.223 1.96Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M315.171 16.956c-.251.334-.585.668-1.002.835-2.172.919-6.433-.25-10.359-3.174-4.427-3.175-6.933-7.351-6.014-9.607l.25-.501c1.588-2.172 6.683-1.17 11.445 2.339 4.678 3.425 7.267 7.936 5.68 10.108Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M314.169 17.793c-2.172.919-6.433-.251-10.359-3.175-4.427-3.174-6.933-7.35-6.014-9.606 2.172-.919 6.432.25 10.358 3.174 4.344 3.091 6.933 7.268 6.015 9.607Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m260.71 69.415-27.315 41.683c-.251.418-.334.919-.251 1.42a4.562 4.562 0 0 0 2.59 3.007c.919.418 1.67.752 2.172.919.751.251 1.503 0 1.921-.668l28.986-40.681c.668-.919.251-2.256-.835-2.59-.752-.25-1.587-.668-2.339-1.086a7.842 7.842 0 0 1-2.089-1.92c-.668-1.087-2.171-1.087-2.84-.084Z"
      />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m347.919 196.973-13.199 41.265 25.478-5.429-12.279-35.836Z"
      />
      <path fill="#3D2A97" d="M96.732 123.797v4.344l-10.526.25-.584-2.088 11.11-2.506Z" />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M264.718 246.925c0-3.425-2.339-6.432-5.597-7.351l-8.187-2.256-2.004-8.102s-.836-.418-3.509.668c-2.088.835-6.933 4.344-9.021 5.931l-1.003.752a13.321 13.321 0 0 0-1.671 2.004c-1.67 2.924-9.272 33.08-11.945 44.023-.501 2.172.752 4.344 2.924 4.845l30.824 7.768 8.186-5.429-4.01-16.373 4.929-26.062c.084 0 .084-.168.084-.418Z"
      />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M250.768 229.466c-2.506-.668-5.179-.167-7.1 1.587a13.27 13.27 0 0 0-1.671 2.005c-2.088 3.508-12.864 47.865-12.864 47.865l34.666 8.771 9.106-47.865v-.334c0-3.425-2.339-6.432-5.597-7.351l-16.54-4.678Z"
      />
      <path
        fill="#E5F68C"
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M235.816 254.11s-2.339.334-4.928 1.755c-2.59 1.503-2.84 2.422-2.84 2.422l-6.015 23.974c-.585 2.339.835 4.595 3.091 5.179l30.49 7.685 8.186-5.429 5.179-27.232-33.163-8.354Z"
      />
      <path fill="#E5F68C" d="m234.478 259.454-5.429 21.552 34.666 8.771" />
      <path
        stroke="#3D2A97"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m234.478 259.454-5.429 21.552 34.666 8.771"
      />
      <path
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="m267.389 247.846-7.1 36.17"
      />
      <path
        fill="#fff"
        stroke="#3D2A97"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={0.325}
        d="M260.29 285.686h-.334c-.919-.167-1.504-1.086-1.337-1.921l7.184-36.254c.167-.918 1.086-1.503 1.921-1.336.919.167 1.504 1.086 1.337 1.921l-7.184 36.254c-.167.752-.835 1.336-1.587 1.336Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M38.592 0h322.023v295.626H38.592z" />
      </clipPath>
    </defs>
  </svg>
);
export default GameUploadFormIconWeb;
