import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { isDesktop } from '@constants/platform';
import { GraphicsTypes } from '@interfaces/explore';
import { QueryKeys } from 'app/react-query/query-keys';

async function getGraphicStyles() {
  const path = `/graphic/all${isDesktop ? '/desktop' : ''}`;
  const response = await axiosGamesInstance.get<GraphicsTypes[]>(path);
  return response.data;
}

export function useGraphicStyles() {
  return useQuery([QueryKeys.GET_GRAPHIC_STYLES], getGraphicStyles);
}
