import { useTranslation } from 'react-i18next';
import { Linking } from 'react-native';

import toast from './toast';

export function capitalizeFirstLetters(value: string) {
  if (!value) return '';

  return value
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export async function openURL(url: string) {
  const i18n = useTranslation()
  const supported = await Linking.canOpenURL(url);
  if (supported) {
    await Linking.openURL(url);
  } else {
    toast.danger({ title: i18n.t('error.error_generic'), icon: 'error' });
  }
}

export function getGameSlug(name: string) {
  const gameSlug = name
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

  return gameSlug;
}
