import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Haptics from 'expo-haptics';

import ChangeAvatarLayout from '@templates/ChangeAvatarLayout';
import { useGetAvatarImages } from '@services/useGetAvatarImages';
import Button from '@atoms/Button';
import { RootStackNavigator } from 'app/navigation/types';
import AvatarItem from '@molecules/AvatarItem';
import AvatarImage from '@molecules/AvatarImage';
import { createMatrix } from '@utils/createMatrix';
import { Avatar } from '@interfaces/avatar';
import { useTranslations } from '@hooks/useTranslation';
import { isWebResponsive } from '@constants/platform';

import commonStyles from '../ChangeAvatarFirstStep/styles';

type Props = NativeStackScreenProps<RootStackNavigator, 'ChangeAvatarSecondStep'>;

const ChangeAvatarSecondStep: FC<Props> = ({ navigation, route }) => {
  const i18n = useTranslations();

  const { data, isLoading } = useGetAvatarImages();
  const [selectedBackground, setselectedBackground] = useState<null | Avatar>(null);

  const selectedAvatar = route.params;

  useEffect(() => {
    if (data && !isLoading) {
      setselectedBackground(data.profileBackgroundImages[0]);
    }
  }, [data, isLoading]);

  const handleGoBack = () => navigation.navigate('AccountSettings');

  const handleSelect = async (item: Avatar) => {
    if (!isWebResponsive) {
      await Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
    setselectedBackground(item);
  };

  const handleNext = () => {
    if (!selectedBackground) return;
    navigation.navigate('ChangeAvatarThirdStep', { selectedAvatar, selectedBackground });
  };

  const backgroundMatrix: Avatar[][] = useMemo(() => createMatrix(data?.profileBackgroundImages), [data]);

  return (
    <ChangeAvatarLayout title={i18n.t('avatar.choose_step_title_second')} step="2">
      {isLoading || !data || !selectedBackground ? (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <ActivityIndicator />
        </View>
      ) : (
        <>
          <AvatarImage avatar={selectedAvatar} background={selectedBackground} transparent />
          <View style={commonStyles.bottom}>
            <View style={commonStyles.bottomContent}>
              <ScrollView
                scrollEnabled
                contentContainerStyle={{
                  alignItems: 'center',
                  paddingVertical: 0,
                  zIndex: 999,
                }}>
                {backgroundMatrix?.map((elements, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <View style={commonStyles.itemsContainer} key={index}>
                    {elements?.map((item) => (
                      <AvatarItem
                        key={item.id}
                        item={item}
                        handleSelect={handleSelect}
                        selectedImage={selectedBackground}
                        fromBackgroundStep
                      />
                    ))}
                  </View>
                ))}
              </ScrollView>
              <View style={commonStyles.ctas}>
                <Button
                  onPress={handleGoBack}
                  customTextStyle={[commonStyles.btnText, commonStyles.btnTextMaybe]}
                  customStyle={[commonStyles.btns, commonStyles.btnMaybe]}>
                  {i18n.t('avatar.alert_cta_secondary')}
                </Button>
                <Button
                  customTextStyle={commonStyles.btnText}
                  onPress={handleNext}
                  customStyle={commonStyles.btns}>
                  {i18n.t('avatar.choose_cta_primary')}
                </Button>
              </View>
            </View>
          </View>
        </>
      )}
    </ChangeAvatarLayout>
  );
};

export default memo(ChangeAvatarSecondStep);
