import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';

const EmailIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={22} height={23} fill="none" {...props}>
    <Rect
      x={1.834}
      y={3.25}
      width={18.333}
      height={16.5}
      rx={3.542}
      stroke={fill || '#777E91'}
      strokeWidth={1.328}
    />
    <Path
      d="M2.249 6.398a.664.664 0 10-.83 1.037l.83-1.037zm18.333 1.037a.664.664 0 00-.83-1.037l.83 1.037zM8.788 12.48l-.415.518.415-.518zM1.42 7.435l6.954 5.563.83-1.037-6.954-5.563-.83 1.037zm12.209 5.563l6.954-5.563-.83-1.037-6.954 5.563.83 1.037zm-5.255 0a4.206 4.206 0 005.255 0l-.83-1.037a2.878 2.878 0 01-3.595 0l-.83 1.037z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default EmailIcon;
