/* eslint-disable @typescript-eslint/naming-convention */
import { useQuery } from 'react-query';

import { QueryKeys } from 'app/react-query/query-keys';
import { axiosGamesInstance } from '@config/api';
import { AdvertisingInterface } from '@interfaces/advertising';

async function getAdvertisingByDimension(width: number, height: number, format: 'VIDEO' | 'IMAGE', location: string) {
  const path = `/advertising/?width=${width}&height=${height}&format=${format}&location=${location}`;
  const response = await axiosGamesInstance.get<{ advertising: AdvertisingInterface }>(path);
  return response.data;
}

export function useAdvertisingByDimension(width: number, height: number, format: 'VIDEO' | 'IMAGE', location: string) {
  return useQuery([QueryKeys.GET_ADVERTISING_BY_DIMENSION, { width, height, format, location }], () => getAdvertisingByDimension(width, height, format, location), {
    enabled: !!location
  });
}
