import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ClockIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={24} height={23} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.775 20.895a9.396 9.396 0 100-18.791 9.396 9.396 0 000 18.791zm.704-14.093a.705.705 0 10-1.409 0V11.5c0 .303.194.572.482.668l2.819.94a.705.705 0 10.445-1.337l-2.337-.779v-4.19z"
      fill={fill || '#E5F68C'}
    />
  </Svg>
);

export default ClockIcon;
