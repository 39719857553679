import { useQuery } from 'react-query';

import { axiosGamesInstance } from '@config/api';
import { MoodsTypes } from '@interfaces/explore';
import { QueryKeys } from 'app/react-query/query-keys';
import { isDesktop } from '@constants/platform';

async function getMoods() {
  const path = `/mood/all${isDesktop ? '/desktop' : ''}`;
  const response = await axiosGamesInstance.get<MoodsTypes[]>(path);
  return response.data;
}

export function useMoods() {
  return useQuery([QueryKeys.GET_MOODS], getMoods);
}
