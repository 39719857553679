import { StyleSheet } from 'react-native';

import { isWebResponsive, SCREEN_WIDTH } from '@constants/platform';
import { Spacing } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    width: SCREEN_WIDTH * 4,
    paddingVertical: moderateScale(14),

    overflow: 'hidden',
    flexDirection: 'row',
  },
  textWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  spark: {
    marginHorizontal: isWebResponsive ? moderateScale(30) : Spacing.XLARGE,
  },
  christmasStar: { width: 31, height: 31 },
});
