import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Spacing } from '@theme';

export const styles = StyleSheet.create({
  header: {
    paddingTop: Spacing.LATERALBIG,
  },
  goBack: {
    position: 'absolute',
    top: moderateScale(10),
    left: moderateScale(20),
    zIndex: 3,
  },
  step: {
    position: 'absolute',
    top: moderateScale(20),
    right: moderateScale(20),
    zIndex: 3,
  },
  stepContainer: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 100,
  },
});
