import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useState } from 'react';
import { Pressable, View } from 'react-native';
import Animated, {
  FadeIn,
  FadeOut,
  SlideInLeft,
  SlideInRight,
  SlideOutLeft,
  SlideOutRight,
} from 'react-native-reanimated';

import Image from '@atoms/FastImage';
import onboarding1 from '@assets/img/onboarding1.png';
import onboarding2 from '@assets/img/onboarding2.png';
import Button from '@atoms/Button';
import CustomText from '@atoms/CustomText';
import { useStatusBar } from '@hooks/useStatusBar';
import { useTranslations } from '@hooks/useTranslation';
import { Layout } from '@organisms/Layout';
import { Colors } from '@theme';
import { setHasOnboardingSeen } from '@utils/onboarding';
import { RootStackNavigator } from 'app/navigation/types';

import { styles } from './styles';

type OnboardingProps = NativeStackScreenProps<RootStackNavigator, 'Onboarding'>;

const Onboarding = ({ navigation }: OnboardingProps) => {
  const [step, setStep] = useState(1);
  const [touchX, setTouchX] = useState(0);
  const i18n = useTranslations();
  useStatusBar('light');

  const handleLogin = async () => {
    await setHasOnboardingSeen();
    navigation.reset({ index: 0, routes: [{ name: 'Login' }] });
  };

  const handleReleaseTouch = (endX: number) => {
    const notScroll =
      (step === 2 && !(endX > touchX + 50)) ||
      (step === 1 && !(endX < touchX - 50)) ||
      (step === 1 && endX > touchX) ||
      (step === 2 && endX < touchX);
    if (notScroll) return null;
    if (step === 1) return setStep(2);
    return setStep(1);
  };

  async function handleRegister() {
    await setHasOnboardingSeen();
    navigation.reset({ index: 0, routes: [{ name: 'Register' }] });
  }

  const next = () => {
    if (step === 1) return setStep(2);
    return handleRegister();
  };

  return (
    <Layout withScroll={false} padding={false} backgroundColor={Colors.black}>
      <View style={styles.dots}>
        {step === 1 ? (
          <Animated.View entering={FadeIn.duration(700)} exiting={FadeOut.duration(700)}>
            <LinearGradient
              style={styles.dot}
              start={{ x: 0.1, y: 0 }}
              end={{ x: 1, y: 1 }}
              locations={[0.3, 0.9]}
              colors={['#7154FF', '#3A298E']}
            />
          </Animated.View>
        ) : null}
        {step === 2 ? (
          <Pressable hitSlop={10} onPress={() => setStep(1)}>
            <Animated.View
              entering={FadeIn.duration(700)}
              exiting={FadeOut.duration(700)}
              style={[styles.dot]}
            />
          </Pressable>
        ) : null}
        {step === 2 ? (
          <Animated.View entering={FadeIn.duration(700)} exiting={FadeOut.duration(700)}>
            <LinearGradient
              style={styles.dot}
              start={{ x: 0.1, y: 0 }}
              end={{ x: 1, y: 1 }}
              locations={[0.3, 0.9]}
              colors={['#7154FF', '#3A298E']}
            />
          </Animated.View>
        ) : null}
        {step === 1 ? (
          <Pressable hitSlop={10} onPress={() => setStep(2)}>
            <Animated.View
              entering={FadeIn.duration(700)}
              exiting={FadeOut.duration(700)}
              style={[styles.dot]}
            />
          </Pressable>
        ) : null}
      </View>
      <View style={styles.container}>
        {step === 1 ? (
          <Animated.View
            onTouchEnd={(e) => handleReleaseTouch(e.nativeEvent.locationX)}
            onTouchStart={(e) => setTouchX(e.nativeEvent.locationX)}
            entering={SlideInLeft.damping(10).springify().delay(50).stiffness(50)}
            exiting={SlideOutLeft.duration(500)}>
            <Image resizeMode="contain" style={styles.image1} source={onboarding1} />
          </Animated.View>
        ) : null}
        {step === 2 ? (
          <Animated.View
            onTouchEnd={(e) => handleReleaseTouch(e.nativeEvent.locationX)}
            onTouchStart={(e) => setTouchX(e.nativeEvent.locationX)}
            entering={SlideInRight.damping(10).springify().delay(50).stiffness(50)}
            exiting={SlideOutRight.duration(500)}>
            <Image resizeMode="cover" style={styles.image2} source={onboarding2} />
          </Animated.View>
        ) : null}
        {step === 1 ? (
          <Animated.View style={styles.itemText} entering={FadeIn} exiting={FadeOut}>
            <CustomText size="mbig" customStyle={styles.title} weight="interSemi">
              {i18n.t(`onboarding.title_1`)}
            </CustomText>
            <CustomText customStyle={styles.description} size="xmedium" color="whiteTwo" weight="interMedium">
              {i18n.t(`onboarding.description_1`)}
            </CustomText>
          </Animated.View>
        ) : null}
        {step === 2 ? (
          <Animated.View style={styles.itemText} entering={FadeIn} exiting={FadeOut}>
            <CustomText size="mbig" customStyle={styles.title} weight="interSemi">
              {i18n.t(`onboarding.title_2`)}
            </CustomText>
            <CustomText customStyle={styles.description} size="xmedium" color="whiteTwo" weight="interMedium">
              {i18n.t(`onboarding.description_2`)}
            </CustomText>
          </Animated.View>
        ) : null}
      </View>
      <View style={styles.bottom}>
        <Button customTextStyle={styles.buttonText} onPress={next} customStyle={styles.btnTop}>
          {step === 1 ? i18n.t(`onboarding.button_action_0`) : null}
          {step === 2 ? i18n.t(`onboarding.button_action_2`) : null}
        </Button>
        <View style={styles.login}>
          <CustomText color="greyTwo" size="medium" weight="interBold">
            {`${i18n.t('onboarding.already_have_account')}  `}
          </CustomText>
          <Pressable hitSlop={10} onPress={handleLogin}>
            <CustomText customStyle={styles.underline} size="medium" color="greySix" weight="interBold">
              {i18n.t('onboarding.login')}
            </CustomText>
          </Pressable>
        </View>
      </View>
    </Layout>
  );
};

export default Onboarding;
