import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const AboutGatoIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.127 3.887H5.873a2.418 2.418 0 00-2.418 2.418v7.254a2.418 2.418 0 002.418 2.418h7.254a2.418 2.418 0 002.418-2.418V6.305a2.418 2.418 0 00-2.418-2.418zm-5.142 7.518a.453.453 0 00-.6.68l.166.127c.099.068.24.156.42.244a3.5 3.5 0 001.529.347 3.5 3.5 0 001.53-.347 3.134 3.134 0 00.534-.33l.05-.042a.453.453 0 00-.6-.68l-.083.064a2.22 2.22 0 01-.297.172c-.264.127-.65.257-1.134.257-.485 0-.87-.13-1.134-.257a2.216 2.216 0 01-.297-.172l-.084-.063zm4.538-2.682a.605.605 0 11-1.21 0 .605.605 0 011.21 0zm-5.441.604a.605.605 0 100-1.209.605.605 0 000 1.21z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default AboutGatoIcon;
