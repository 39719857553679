import React, { useEffect, useState } from 'react';
import { Pressable } from 'react-native';
import Animated, {
  interpolate,
  interpolateColor,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from 'react-native-reanimated';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

import { styles } from './styles';

const TRANSLATEX = moderateScale(26);

type SwitchProps = {
  active: boolean;
  onSelect: () => void;
};

const Switch = ({ active, onSelect }: SwitchProps) => {
  const [selected, setSelected] = useState(false);
  const translateX = useSharedValue(0);

  const handleSelect = () => {
    translateX.value = withTiming(selected ? 0 : 1);
    setSelected((prevSelected) => !prevSelected);
    onSelect();
  };

  const switchAnim = useAnimatedStyle(
    () => ({
      transform: [{ translateX: interpolate(translateX.value, [0, 1], [2, TRANSLATEX]) }],
    }),
    []
  );
  const backAnim = useAnimatedStyle(
    () => ({
      backgroundColor: interpolateColor(translateX.value, [0, 1], [Colors.darkGrey, Colors.limeGreen]),
    }),
    []
  );

  useEffect(() => {
    setSelected(active);
    if (active) {
      translateX.value = withTiming(1);
    } else {
      translateX.value = withTiming(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);

  return (
    <Pressable onPress={handleSelect}>
      <Animated.View style={[styles.container, backAnim]}>
        <Animated.View style={[styles.switch, switchAnim]} />
      </Animated.View>
    </Pressable>
  );
};

export default Switch;
