import React from 'react';
import { View, StyleProp, ViewStyle } from 'react-native';

import { Colors } from '@theme';

import { styles } from './styles';

type FooterProps = {
  children: React.ReactNode;
  backgroundColor?: string;
  style?: StyleProp<ViewStyle>;
};

export const FooterButton = ({ children, backgroundColor = Colors.darkGrey, style }: FooterProps) => (
  <View pointerEvents="box-none" style={[style, styles.container, { backgroundColor }]}>
    {children}
  </View>
);
