import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ActivityIndicator, FlatList, Keyboard, Pressable, View } from 'react-native';
import { useForm } from 'react-hook-form';
import { AxiosError } from 'axios';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetScrollView,
  BottomSheetTextInput,
} from '@gorhom/bottom-sheet';

import Field from '@molecules/Field';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { IComment } from '@interfaces/comments';
import toast from '@utils/toast';
import Send from '@assets/svg/Send';
import { useAllComents, useNewcomment, useResponsecomment } from '@services/useComments';
import Comment from '@organisms/Comments/Comment';
import ItemDivider from '@atoms/ItemDivider';
import { RootStackNavigator } from 'app/navigation/types';
import { Layout } from '@organisms/Layout';
import GoBackButton from '@molecules/GoBackButton';
import { Colors } from '@theme';
import CommentSkeleton from '@molecules/CommentSkeleton';
import { client } from 'app/react-query';
import { QueryKeys } from 'app/react-query/query-keys';

import { styles } from './styles';
import Thread from './Thread';

type CommentsProps = NativeStackScreenProps<RootStackNavigator, 'Comments'>;

const CommentPage = ({ route }: CommentsProps) => {
  const gameId = route?.params?.gameId;
  // const autoFocus = route?.params?.autoFocus;
  const [autoFocus, setAutoFocus] = useState<boolean>(route?.params?.autoFocus);

  const i18n = useTranslations();

  const newComment = useNewcomment();
  const responseComment = useResponsecomment();

  const { data = [], isLoading } = useAllComents(gameId);

  const [comment, setComment] = useState({});

  const bottomSheetRef = useRef<BottomSheet>(null);

  const {
    control,
    getValues,
    setValue,
    watch,
    resetField,
    formState: { errors },
    handleSubmit,
  } = useForm<any>();

  const maxLength = 280;

  const hasMessage = watch('description');

  const total = data?.comments?.length;

  function onSubmit(values: IComment & { commentIdResponse: number }) {
    if (!hasMessage || hasMessage.length >= maxLength) return;

    // New Comment
    if (!values.commentIdResponse) {
      const postData = {
        description: values.description,
        game: Number(gameId),
      };

      newComment.mutate(postData, {
        onSuccess: () => {
          resetField('description');
          toast.success({ title: i18n.t('comments.comment_published'), icon: 'success' });
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = error?.response?.data?.message;
            toast.danger({
              title: message,
              icon: 'error',
            });
          } else {
            toast.danger({ icon: 'error', title: i18n.t('comments.comment_not_published') });
          }
        },
      });
    } else {
      // Responding existing Comment

      const postData = {
        description: values.description,
        comment: values.commentIdResponse,
      };

      responseComment.mutate(postData, {
        onSuccess: () => {
          resetField('description');
          resetField('commentIdResponse');
          toast.success({ title: i18n.t('comments.comment_published'), icon: 'success' });
        },
        onError: (error) => {
          if (error instanceof AxiosError) {
            const message = error?.response?.data?.message;
            toast.danger({
              title: message,
              icon: 'error',
            });
          } else {
            toast.danger({ icon: 'error', title: i18n.t('comments.comment_not_published') });
          }
        },
      });
    }
  }

  const sendMessage = (item) => {
    setAutoFocus(false);
    Keyboard.dismiss();

    if (item) {
      setValue('commentIdResponse', item?.id);
    } else {
      setValue('commentIdResponse', null);
    }
    handleSubmit(onSubmit)();
  };

  const charactersLeft = maxLength
    ? maxLength - (watch('description') ? Array.from(watch('description')).length : 0)
    : 0;

  const snapPoints = useMemo(() => ['88%'], []);

  const handleSnapPress = useCallback(async (item) => {
    setComment(item);
    bottomSheetRef.current?.snapToIndex(0);
    await client.invalidateQueries(QueryKeys.GET_THREAD_COMMENTS);
  }, []);

  const handleClose = () => {
    bottomSheetRef.current?.close();
  };

  const renderBackdrop = useCallback(
    (props) => <BottomSheetBackdrop {...props} opacity={0.75} appearsOnIndex={0} disappearsOnIndex={-1} />,
    []
  );

  const iconColor = useCallback(() => {
    if (hasMessage && hasMessage.length < maxLength) return Colors.purple;
    return `${Colors.purple}60`;
  }, [hasMessage]);

  return (
    <>
      <Layout
        padding={false}
        withScroll={false}
        showScrollIndicator={false}
        backgroundColor={Colors.socialBackground}
        hasBottomCTAS={
          newComment.isLoading ? (
            <View style={styles.loading}>
              <ActivityIndicator size="large" color={Colors.greySix} />
            </View>
          ) : (
            <View style={styles.inputModalContainer}>
              <View style={styles.inputLineContainer}>
                <View style={styles.inputContainer}>
                  <Field
                    name="description"
                    control={control}
                    placeholder={i18n.t('comments.input')}
                    getValues={getValues}
                    error={errors}
                    containerStyles={[styles.input, charactersLeft <= 0 && styles.inputError]}
                    multiline
                    maxLength={280}
                    rules={{
                      maxLength: {
                        value: 280,
                        message: i18n.t('comments.max_length'),
                      },
                    }}
                    autoFocus={autoFocus}
                    autoCapitalize="sentences"
                  />
                </View>
                <View style={styles.iconContainer}>
                  <Send onPress={() => sendMessage(null)} fill={iconColor()} />
                </View>
              </View>

              {charactersLeft > 0 || charactersLeft === undefined ? (
                <CustomText
                  align="left"
                  color="greySix"
                  weight="interSemi"
                  size="msmall"
                  customStyle={styles.charsLeftModal}>
                  {charactersLeft} {i18n.t('comments.chars_left')}
                </CustomText>
              ) : (
                <CustomText
                  align="left"
                  color="error"
                  weight="interSemi"
                  size="msmall"
                  customStyle={styles.charsLeftModal}>
                  {i18n.t('comments.max_length')}
                </CustomText>
              )}
            </View>
          )
        }>
        <View style={styles.goBack}>
          <GoBackButton />
        </View>
        <CustomText customStyle={styles.header} size="xmedium" weight="interSemi" color="greySix">
          {i18n.t('comments.title')} {isLoading ? `` : `(${total})`}
        </CustomText>
        <View style={styles.container}>
          <View style={styles.comments}>
            {isLoading ? (
              <>
                <CommentSkeleton />
                <CommentSkeleton />
                <CommentSkeleton />
              </>
            ) : null}

            {!data?.comments && !isLoading ? (
              <CustomText customStyle={styles.emptyState} weight="interSemi" size="medium" color="whiteTwo">
                {i18n.t('comments.no_comments_yet')}
              </CustomText>
            ) : (
              <>
                <ItemDivider />
                <FlatList
                  showsHorizontalScrollIndicator={false}
                  ItemSeparatorComponent={ItemDivider}
                  data={data?.comments}
                  keyExtractor={(item: IComment) => item.id.toString()}
                  renderItem={({ item }) => (
                    <Pressable onPress={() => handleSnapPress(item)}>
                      <Comment comment={item} />
                    </Pressable>
                  )}
                />
              </>
            )}
          </View>
        </View>
      </Layout>

      <BottomSheet
        ref={bottomSheetRef}
        enablePanDownToClose
        index={-1}
        snapPoints={snapPoints}
        onClose={handleClose}
        keyboardBehavior="interactive"
        keyboardBlurBehavior="restore"
        android_keyboardInputMode="adjustResize"
        backgroundStyle={{ backgroundColor: Colors.socialBackground }}
        backdropComponent={renderBackdrop}>
        <View style={styles.bottomSheetHeader}>
          <GoBackButton goBackAction={handleClose} />

          <CustomText customStyle={styles.bottomSheetTitle} size="lmedium" align="left" color="greyFilter">
            {i18n.t('comments.thread')}
          </CustomText>
        </View>
        <BottomSheetScrollView>
          <Thread comment={comment} />
        </BottomSheetScrollView>
        {newComment.isLoading || responseComment.isLoading ? (
          <View style={styles.loading}>
            <ActivityIndicator size="large" color={Colors.greySix} />
          </View>
        ) : (
          <>
            <View style={styles.threadMessageContainer}>
              <View style={styles.threadInputContainer}>
                <BottomSheetTextInput
                  onChangeText={(text) => {
                    setValue('description', text);
                  }}
                  style={styles.threadInputModal}
                  maxLength={280}
                  placeholderTextColor={Colors.textInputGrey}
                  placeholder={i18n.t('comments.input')}
                  selectionColor={Colors.limeGreen}
                  multiline
                />
              </View>
              <View style={styles.threadIconContainer}>
                <Send onPress={() => sendMessage(comment)} fill={iconColor()} />
              </View>
            </View>
            <CustomText
              align="left"
              color="greySix"
              weight="interSemi"
              size="msmall"
              customStyle={styles.threadCharsLeft}>
              {charactersLeft} {i18n.t('comments.chars_left')}
            </CustomText>
          </>
        )}
      </BottomSheet>
    </>
  );
};

export default CommentPage;
