import React from 'react';
import { View } from 'react-native';

import CustomText, { Colors } from '@atoms/CustomText';

import { styles } from './styles';

interface GameTagProps {
  text: string;
  color?: Colors;
  textColor?: Colors;
  bigText?: boolean;
}

const GameTag = ({ text, color = 'purple', textColor, bigText }: GameTagProps) => (
  <View style={[styles.container, { backgroundColor: color }]}>
    <CustomText size={bigText ? 'lmedium' : 'msmall'} weight="interSemi" color={textColor}>
      {text}
    </CustomText>
  </View>
);

export default GameTag;
