import { StyleSheet } from 'react-native';

import { moderateScale } from '@utils/scaling';
import { Colors } from '@theme';
import { isMobile } from '@constants/platform';

export const styles = StyleSheet.create({
  lottie: { width: moderateScale(72), height: moderateScale(33) },
  container: {
    marginBottom: moderateScale(30),
    paddingHorizontal: 16,
    paddingVertical: 20,
    backgroundColor: Colors.shareAppBackground,
    marginHorizontal: isMobile ? 20 : 0,
    borderRadius: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  row: { flexDirection: 'row', alignItems: 'center' },
  description: { marginLeft: 12 },
  description2: { color: Colors.shareAppDescription, marginTop: 4 },
  btnContainer: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  btn: { alignSelf: 'flex-end' },
});
