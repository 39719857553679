import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Pressable, View } from 'react-native';
import { Link } from '@react-navigation/native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import Field from '@molecules/Field';
import { Layout } from '@organisms/Layout';
import FullnameIcon from '@assets/svg/FullnameIcon';
import { authTokenSelector, setShowAvatarModalHandler, useStore } from '@store/index';
import { useUserData } from '@services/useUserData';
import Row from '@atoms/Row';
import { Spacing } from '@theme';
import UsernameIcon from '@assets/svg/UsernameIcon';
import { AvatarProfile } from '@atoms/AvatarProfile';
import Skeleton from '@atoms/Skeleton';

import { ScreenTitle } from '..';

import { styles } from './styles';

interface UserDataParams {
  fullName: string;
  userName: string;
  bio?: string | null;
}

const InputWrapper = ({ path, children }: { path: string; children: React.ReactNode }) => {
  const i18n = useTranslations();
  return (
    <View style={styles.inputWrapper}>
      <View style={styles.inputContainer}>{children}</View>
      <Link to={`/account-settings/${path}`} style={styles.linkText}>
        <CustomText customStyle={styles.linkText} color="limeGreen">
          {i18n.t('account_settings.edit')}
        </CustomText>
      </Link>
    </View>
  );
};

const ProfileOverview = () => {
  const authToken = useStore(authTokenSelector);
  const setShowAvatarModal = useStore(setShowAvatarModalHandler);
  const { data: userData, isLoading } = useUserData(authToken || '');
  const i18n = useTranslations();

  const { control, getValues, setValue } = useForm<UserDataParams>({ mode: 'onChange' });

  useEffect(() => {
    if (!userData) return;
    setValue('fullName', userData.fullName);
    setValue('userName', userData.userName);
    setValue('bio', userData.bio || undefined);
  }, [setValue, userData]);

  const handleChangeAvatar = () => {
    setShowAvatarModal();
  };

  return (
    <Layout paddingTop={Spacing.LARGE} verticallyCentered padding={false}>
      <View style={styles.screenContainer}>
        <View style={styles.screenInnerContainer}>
          <ScreenTitle>{i18n.t('account_settings.overview')}</ScreenTitle>
          <View style={styles.body}>
            <View style={[styles.avatarSection]}>
              <View style={styles.avatarContainer}>
                {userData ? (
                  <AvatarProfile
                    customContainerStyle={{ margin: 0 }}
                    imageUrl={userData.avatar || ''}
                    backgroundUrl={userData.backgroundProfileImage || ''}
                    fromSettings
                  />
                ) : (
                  <Skeleton height={110} width={110} customStyle={styles.avatarSkeleton} />
                )}
              </View>
              <View style={styles.avatarData}>
                {userData && (
                  <CustomText size="xlarge" align="left" numberOfLines={1}>
                    {userData?.userName}
                  </CustomText>
                )}
                <Pressable onPress={handleChangeAvatar}>
                  <CustomText color="limeGreen" size="lmedium" align="left">
                    {i18n.t('account_settings.change_avatar')}
                  </CustomText>
                </Pressable>
              </View>
            </View>
            <Row gap={Spacing.BIG} style={styles.row}>
              <View key="fullname-input">
                <CustomText align="left" size="big" customStyle={styles.fieldTitle}>
                  {i18n.t('account_settings.fullname_placeholder')}
                </CustomText>
                <InputWrapper path="change-name">
                  <Field
                    editable={false}
                    icon={FullnameIcon}
                    name="fullName"
                    control={control}
                    getValues={getValues}
                    error={{}}
                    onPress={() => null}
                    loading={isLoading}
                  />
                </InputWrapper>
              </View>
              <View key="username-input">
                <CustomText align="left" size="big" customStyle={styles.fieldTitle}>
                  {i18n.t('account_settings.username_placeholder')}
                </CustomText>
                <InputWrapper path="change-username">
                  <Field
                    editable={false}
                    icon={UsernameIcon}
                    name="userName"
                    control={control}
                    getValues={getValues}
                    error={{}}
                    onPress={() => null}
                    loading={isLoading}
                  />
                </InputWrapper>
              </View>
            </Row>
            <View style={styles.row}>
              <View key="bio-input">
                <CustomText align="left" size="big" customStyle={styles.fieldTitle}>
                  {i18n.t('account_settings.bio_placeholder')}
                </CustomText>
                <InputWrapper path="change-bio">
                  <Field
                    editable={false}
                    icon={UsernameIcon}
                    name="bio"
                    control={control}
                    getValues={getValues}
                    error={{}}
                    onPress={() => null}
                    loading={isLoading}
                    placeholder={i18n.t('account_settings.bio_input_placeholder').toString()}
                    multiline
                    textAlignVertical="top"
                  />
                </InputWrapper>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Layout>
  );
};

export default ProfileOverview;
