import { StyleSheet } from 'react-native';

import { moderateVerticalScale } from '@utils/scaling';
import { Colors } from '@theme';
import { WINDOW_WIDTH, isMobile, isWebResponsive } from '@constants/platform';

const AVATAR_SIZE = isMobile ? WINDOW_WIDTH * 0.66 : 188;

export default StyleSheet.create({
  imageContainer: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    marginTop: isMobile || isWebResponsive ? moderateVerticalScale(35) : 0,
    borderRadius: AVATAR_SIZE,
    overflow: 'hidden',
    backgroundColor: Colors.greySix,
    alignSelf: 'center',
  },
  mt40: {
    marginVertical: moderateVerticalScale(3),
    zIndex: 3,
    marginLeft: -1,
  },
  imageItem: {
    width: '100%',
    height: '100%',
  },
  cat: {
    position: 'absolute',
    marginTop: moderateVerticalScale(3),
    zIndex: 10,
  },
  // eslint-disable-next-line react-native/no-color-literals
  transparent: { backgroundColor: 'transparent' },
});
