import * as React from 'react';
import Svg, { SvgProps, Path, G, ClipPath, Defs } from 'react-native-svg';

const UploadGameIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={19} height={19} fill="none" {...props}>
    <G clipPath="url(#prefix__clip0_2438_2058)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5.73c.338 0 .611.275.611.613v.815a.612.612 0 01-1.223 0v-.815c0-.338.274-.612.612-.612zm.677 4.184l3.263 1.489c.09.04.174.09.253.144L9.5 8.805 5.306 6.547c.08-.055.164-.103.254-.144l3.263-1.489c.43-.196.924-.196 1.354 0zM4.635 7.575c-.02.102-.03.206-.03.312v4.042c0 .639.373 1.219.955 1.484l3.263 1.488c.021.01.043.02.065.028V9.865l-4.253-2.29zm5.477 7.354l.065-.028 3.263-1.488c.581-.265.954-.845.954-1.484V7.887c0-.106-.01-.21-.03-.311l-4.252 2.29v5.063zm0 1.913a.612.612 0 00-1.224 0v.816a.612.612 0 001.223 0v-.816zm6.405-14.358a.612.612 0 010 .865l-.577.577a.612.612 0 11-.865-.866l.577-.576a.612.612 0 01.865 0zM3.925 15.94a.612.612 0 10-.866-.865l-.576.576a.612.612 0 10.865.866l.577-.577zM18.269 9.5a.612.612 0 01-.612.612h-.816a.612.612 0 110-1.224h.816c.338 0 .612.274.612.612zm-16.111.612a.612.612 0 100-1.224h-.816a.612.612 0 100 1.224h.816zm14.358 6.405a.612.612 0 01-.865 0l-.577-.577a.612.612 0 11.865-.865l.577.576a.612.612 0 010 .866zM3.06 3.926a.612.612 0 10.865-.866l-.576-.576a.612.612 0 10-.866.865l.577.577z"
        fill={fill || '#777E91'}
      />
    </G>
    <Defs>
      <ClipPath id="prefix__clip0_2438_2058">
        <Path fill="#fff" d="M0 0h19v19H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default UploadGameIcon;
