import { AnimationObject } from 'lottie-react-native';

import uploadgame from '@assets/lotties/uploadgame_tab.json';
import home from '@assets/lotties/home_tab.json';
import explore from '@assets/lotties/explore_tab.json';
import profile from '@assets/lotties/profile.json';

export const OPTIONS: OptionsType = {
  Home: {
    id: 'home',
    icon: home,
  },
  Explore: {
    id: 'explore',
    icon: explore,
  },
  UploadGame: {
    id: 'uploadgame',
    icon: uploadgame,
  },
  MyProfile: {
    id: 'myprofile',
    icon: profile,
  },
};

export type OptionsList = 'Home' | 'Explore' | 'UploadGame' | 'MyProfile';

type OptionsType = {
  [x in OptionsList]: {
    id: string;
    icon: AnimationObject;
  };
};
