import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const ChevronLeft = ({ fill, ...props }: SvgProps) => (
  <Svg width={10} height={16} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.679.988c.65.651.65 1.706 0 2.357L4.024 8l4.655 4.655a1.667 1.667 0 11-2.358 2.357L.488 9.179a1.667 1.667 0 010-2.358L6.321.988c.651-.65 1.707-.65 2.358 0z"
      fill={fill || '#FCFCFD'}
    />
  </Svg>
);

export default ChevronLeft;
