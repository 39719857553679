import React, { useState } from 'react';
import { SvgProps } from 'react-native-svg';
import { Pressable, View } from 'react-native';

import ChevronLeft from '@assets/svg/ChevronLeft';
import CustomText from '@atoms/CustomText';
import RoundedButton from '@atoms/RoundedButton';
import { useTranslations } from '@hooks/useTranslation';
import { Colors } from '@theme';

import { styles } from './styles';

interface ItemProps {
  route: string;
  icon: (props: SvgProps) => JSX.Element;
  value?: string;
}

type Props = {
  action?: (item: string) => void;
  text: string;
  item: ItemProps;
};

const AccountItem = ({ action, text, item }: Props) => {
  const [pressing, setPressing] = useState(false);
  const i18n = useTranslations();
  const { icon: Icon } = item;
  const handlePress = () => {
    if (!action) return;
    action(item.route);
  };
  return (
    <Pressable
      onPressIn={() => setPressing(true)}
      onPressOut={() => setPressing(false)}
      onPress={handlePress}
      style={styles.container}>
      <View style={styles.left}>
        <View style={styles.icon}>
          <Icon fill={pressing ? Colors.limeGreen : undefined} />
        </View>
        <View>
          <CustomText color={pressing ? 'limeGreen' : 'white'} size="medium" weight="interBold" align="left">
            {i18n.t(text)}
          </CustomText>
          {item.value ? (
            <CustomText
              numberOfLines={1}
              customStyle={styles.value}
              size="medium"
              weight="interBold"
              color="greyFive"
              align="left">
              {item.value}
            </CustomText>
          ) : null}
        </View>
      </View>
      {action ? (
        <RoundedButton customStyle={{ backgroundColor: Colors.greyFour }}>
          <ChevronLeft fill={pressing ? Colors.limeGreen : Colors.greySix} style={styles.chevron} />
        </RoundedButton>
      ) : null}
    </Pressable>
  );
};

export default AccountItem;
