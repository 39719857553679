import { StyleSheet } from 'react-native';

import { Colors, Spacing } from '@theme';

export const styles = StyleSheet.create({
  positionWebWideScreen: {
    position: 'absolute',
    top: 90,
    width: '100%',
    borderRadius: 0,
    height: '100%',
  },
  positionWideScreen: {
    position: 'absolute',
    top: 90,
    width: 399,
    right: Spacing.BIG,
  },
  background: {
    backgroundColor: Colors.black,
  },
  positionFullScreen: {
    position: 'absolute',
    top: 90,
    width: 399,
    right: '50%',
    transform: [{ translateX: 667 }],
  },
  container: {
    flex: 1,
    minHeight: 403,
    maxHeight: '77vh',
    paddingRight: 15,
    marginRight: -15,
  },
  innerContainer: {
    flex: 1,
  },
  title: {
    paddingBottom: Spacing.TINY,
  },
});
