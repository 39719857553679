import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

const FacebookIcon = (props: SvgProps) => (
  <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <Path
      d="M12.5 0.499878H3.5C1.84315 0.499878 0.5 1.84302 0.5 3.49988V12.4999C0.5 14.1567 1.84315 15.4999 3.5 15.4999H6.875V10.9999H4.25V8.74988H6.875V6.91654C6.875 5.44379 8.06891 4.24988 9.54167 4.24988H11.75V6.49988H9.79167C9.42348 6.49988 9.125 6.79835 9.125 7.16654V8.74988H11.75V10.9999H9.125V15.4999H12.5C14.1569 15.4999 15.5 14.1567 15.5 12.4999V3.49988C15.5 1.84302 14.1569 0.499878 12.5 0.499878Z"
      fill="#777E91"
    />
  </Svg>
);
export default FacebookIcon;
