import { Linking, Pressable, View } from 'react-native';
import Clipboard from '@react-native-clipboard/clipboard';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import Animated, {
  SlideInLeft,
  SlideOutLeft,
  SlideInRight,
  SlideOutRight,
  FadeInLeft,
  FadeOutLeft,
  FadeInRight,
  FadeOutRight,
} from 'react-native-reanimated';

import { Layout } from '@organisms/Layout';
import GameUploadFormIconWeb from '@assets/svg/GameUploadFormIconWeb';
import GameUploadIconWeb from '@assets/svg/GameUploadIconWeb';
import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import { FooterButton } from '@atoms/FooterButton';
import Button from '@atoms/Button';
import { RootStackNavigator } from 'app/navigation/types';
import ClockIcon from '@assets/svg/ClockIcon';
import CatIcon from '@assets/svg/CatIcon';
import GoBackButton from '@molecules/GoBackButton';
import UploadIcon1 from '@assets/svg/UploadIcon1';
import UploadIcon2 from '@assets/svg/UploadIcon2';
import UploadIcon3 from '@assets/svg/UploadIcon3';
import UploadIcon4 from '@assets/svg/UploadIcon4';
import UploadIcon5 from '@assets/svg/UploadIcon5';
import UploadIcon6 from '@assets/svg/UploadIcon6';
import UploadIcon7 from '@assets/svg/UploadIcon7';
import CopyLinkIcon from '@assets/svg/CopyLinkIcon';
import WhatsappIcon from '@assets/svg/WhatsappIcon';
import MailIcon from '@assets/svg/MailIcon';
import toast from '@utils/toast';
import { isDesktop, isMobile } from '@constants/platform';
import { useUploadingGameLink } from '@hooks/useUploadingGameLink';

import { styles } from './styles';

type UploadGameProps = NativeStackScreenProps<RootStackNavigator, 'UploadGame'>;

const UploadGame = ({ navigation }: UploadGameProps) => {
  const [step, setStep] = useState(1);
  const i18n = useTranslations();

  const link = useUploadingGameLink();

  const handleContinue = () => {
    if (step === 1) return setStep(2);
    if (isDesktop) return Linking.openURL(link);

    return navigation.navigate('GameWebView', {
      resourceLink: link,
      backInRight: true,
    });
  };

  const handleGoBack = () => {
    if (step === 2) {
      setStep(1);
    } else {
      navigation.goBack();
    }
  };

  const handleCopyLink = () => {
    Clipboard.setString(
      `Here's the link to upload your game to GATO. ${link} \n\nGATO is a social gaming platform that connects game developers and gamers from all over the world.\n\nJoin GATO! #FreeGamingForever`
    );
    toast.success({ icon: 'success', title: i18n.t('upload_game.link_copied') });
  };
  const handleShareByEmail = () => {
    Linking.openURL(
      `mailto:?subject=Upload your game, join GATO!&body=Here's the link to upload your game to GATO. ${link} \n\nGATO is a social gaming platform that connects game developers and gamers from all over the world.\n\nJoin GATO! #FreeGamingForever`
    );
  };
  const handleShareByWhatsapp = () => {
    Linking.openURL(
      `whatsapp://send?text=Here's the link to upload your game to GATO. ${link} \n\nGATO is a social gaming platform that connects game developers and gamers from all over the world.\n\nJoin GATO! #FreeGamingForever`
    );
  };

  return (
    <Layout
      verticallyCentered={isDesktop}
      padding={isDesktop}
      hasBottomCTAS={
        <FooterButton>
          <Button
            customStyle={styles.footerButton}
            customTextStyle={styles.footerButtonText}
            onPress={handleContinue}>
            {i18n.t(`upload_game.page_${step}_button_action`)}
          </Button>
        </FooterButton>
      }>
      {/* <View style={[styles.row]}> */}
      <View style={isMobile && styles.goBack}>
        <GoBackButton goBackAction={handleGoBack} />
      </View>
      {isMobile && (
        <CustomText size="xmedium" weight="interSemi" color="greySix">
          {i18n.t('upload_game.upload_game')}
        </CustomText>
      )}
      {/* </View> */}
      <View style={styles.container}>
        <View style={styles.leftContainer}>
          {step === 1 ? <GameUploadIconWeb /> : <GameUploadFormIconWeb />}
        </View>
        <View style={styles.stepContainer}>
          {step === 1 ? (
            <Animated.View entering={FadeInLeft} exiting={FadeOutLeft}>
              <View style={[styles.row, styles.header]}>
                <View>
                  <CustomText weight="interSemi" size={isMobile ? 'xbig' : 'large'} align="left">
                    {i18n.t(`upload_game.page_1_title${isDesktop ? '_lg' : ''}`)}
                  </CustomText>
                  <View style={[styles.row, styles.descriptionWrapper]}>
                    <CatIcon />
                    <CustomText
                      weight="interSemi"
                      customStyle={styles.description}
                      size={isMobile ? 'xmedium' : 'xbig'}
                      color="limeGreen"
                      align="left">
                      {i18n.t('upload_game.page_1_description')}
                    </CustomText>
                  </View>
                </View>
              </View>
            </Animated.View>
          ) : null}
          {step === 2 ? (
            <Animated.View entering={FadeInRight} exiting={FadeOutRight}>
              <View style={[styles.row, styles.header]}>
                <View>
                  <CustomText weight="interSemi" size={isMobile ? 'xbig' : 'large'} align="left">
                    {i18n.t(`upload_game.page_2_title${isDesktop ? '_lg' : ''}`)}
                  </CustomText>
                  <View style={[styles.row, styles.descriptionWrapper]}>
                    <ClockIcon />
                    <CustomText
                      weight="interSemi"
                      customStyle={styles.description}
                      size={isMobile ? 'xmedium' : 'xbig'}
                      color="limeGreen"
                      align="left">
                      {i18n.t('upload_game.page_2_description')}
                    </CustomText>
                  </View>
                </View>
              </View>
            </Animated.View>
          ) : null}
          {step === 1 ? (
            <View style={styles.items}>
              <Animated.View style={[styles.row, styles.item]} exiting={SlideOutLeft} entering={SlideInLeft}>
                <View style={styles.roundedIcon}>
                  <UploadIcon1 />
                </View>
                <View>
                  <CustomText align="left" weight="interBold" customStyle={styles.itemTitle}>
                    {i18n.t('upload_game.page_1_item_1_title')}
                  </CustomText>
                  <CustomText
                    numberOfLines={6}
                    customStyle={styles.itemDescription}
                    color="greyEight"
                    align="left"
                    weight="interBold"
                    size="msmall">
                    {i18n.t('upload_game.page_1_item_1_description')}
                  </CustomText>
                </View>
              </Animated.View>
              <Animated.View
                style={[styles.row, styles.item]}
                exiting={SlideOutLeft.delay(50)}
                entering={SlideInLeft.delay(50)}>
                <View style={styles.roundedIcon}>
                  <UploadIcon2 />
                </View>
                <View style={styles.text}>
                  <CustomText align="left" weight="interBold" customStyle={styles.itemTitle}>
                    {i18n.t('upload_game.page_1_item_2_title')}
                  </CustomText>
                  <CustomText
                    numberOfLines={6}
                    customStyle={styles.itemDescription}
                    color="greyEight"
                    align="left"
                    weight="interBold"
                    size="msmall">
                    {i18n.t('upload_game.page_1_item_2_description')}
                  </CustomText>
                </View>
              </Animated.View>
              <Animated.View
                style={[styles.row, styles.item]}
                exiting={SlideOutLeft.delay(100)}
                entering={SlideInLeft.delay(100)}>
                <View style={styles.roundedIcon}>
                  <UploadIcon3 />
                </View>
                <View>
                  <CustomText align="left" weight="interBold" customStyle={styles.itemTitle}>
                    {i18n.t('upload_game.page_1_item_3_title')}
                  </CustomText>
                  <CustomText
                    numberOfLines={4}
                    customStyle={styles.itemDescription}
                    color="greyEight"
                    align="left"
                    weight="interBold"
                    size="msmall">
                    {i18n.t('upload_game.page_1_item_3_description')}
                  </CustomText>
                </View>
              </Animated.View>
            </View>
          ) : null}
          {step === 2 ? (
            <View style={styles.items}>
              <Animated.View style={[styles.item]} exiting={SlideOutRight} entering={SlideInRight.delay(150)}>
                <CustomText size="lmedium" align="left" color="greySix" weight="interBold">
                  {i18n.t('upload_game.you_need')}
                </CustomText>
                <View style={styles.itemRow}>
                  <UploadIcon4 />
                  <View style={[styles.text, styles.itemTexts]}>
                    <CustomText align="left" weight="interBold" customStyle={styles.neededItemTitle}>
                      {i18n.t('upload_game.page_2_item_1_title')}
                    </CustomText>
                    <CustomText
                      numberOfLines={4}
                      customStyle={styles.neededItemDescription}
                      color="greyEight"
                      align="left"
                      weight="interBold"
                      size="msmall">
                      {i18n.t('upload_game.page_2_item_1_description')}
                    </CustomText>
                  </View>
                </View>
                <View style={styles.itemRow}>
                  <UploadIcon5 />
                  <View style={styles.itemTexts}>
                    <CustomText align="left" weight="interBold" customStyle={styles.neededItemTitle}>
                      {i18n.t('upload_game.page_2_item_2_title')}
                    </CustomText>
                    <CustomText
                      numberOfLines={4}
                      customStyle={styles.neededItemDescription}
                      color="greyEight"
                      align="left"
                      weight="interBold"
                      size="msmall">
                      {i18n.t('upload_game.page_2_item_2_description')}
                    </CustomText>
                  </View>
                </View>
                <View style={styles.itemRow}>
                  <UploadIcon6 />
                  <View style={styles.itemTexts}>
                    <CustomText align="left" weight="interBold" customStyle={styles.neededItemTitle}>
                      {`${i18n.t('upload_game.page_2_item_3_title')} `}
                      <CustomText align="left" weight="interSemiIta">
                        {i18n.t('upload_game.optional')}
                      </CustomText>
                    </CustomText>
                    <CustomText
                      numberOfLines={4}
                      customStyle={styles.neededItemDescription}
                      color="greyEight"
                      align="left"
                      weight="interBold"
                      size="msmall">
                      {i18n.t('upload_game.page_2_item_3_description')}
                    </CustomText>
                  </View>
                </View>
                <View style={styles.itemRow}>
                  <UploadIcon7 />
                  <View style={styles.itemTexts}>
                    <CustomText align="left" weight="interBold" customStyle={styles.neededItemTitle}>
                      {`${i18n.t('upload_game.page_2_item_4_title')} `}
                      <CustomText align="left" weight="interSemiIta">
                        {i18n.t('upload_game.optional')}
                      </CustomText>
                    </CustomText>
                    <CustomText
                      numberOfLines={4}
                      customStyle={styles.neededItemDescription}
                      color="greyEight"
                      align="left"
                      weight="interBold"
                      size="msmall">
                      {i18n.t('upload_game.page_2_item_4_description')}
                    </CustomText>
                  </View>
                </View>
              </Animated.View>
              {isMobile && (
                <Animated.View
                  style={[styles.item]}
                  exiting={SlideOutRight.delay(50)}
                  entering={SlideInRight.delay(200)}>
                  <CustomText customStyle={styles.later} weight="interBold" color="greyFive">
                    {i18n.t('upload_game.do_it_later')}
                  </CustomText>
                  <View style={[styles.row, styles.shareItems]}>
                    <Pressable onPress={handleCopyLink} style={styles.shareItem}>
                      <CopyLinkIcon />
                    </Pressable>
                    <Pressable onPress={handleShareByWhatsapp} style={[styles.shareItem, styles.middle]}>
                      <WhatsappIcon />
                    </Pressable>
                    <Pressable onPress={handleShareByEmail} style={styles.shareItem}>
                      <MailIcon />
                    </Pressable>
                  </View>
                </Animated.View>
              )}
            </View>
          ) : null}
          {isDesktop && (
            <Button
              customStyle={styles.footerButton}
              customTextStyle={styles.footerButtonText}
              onPress={handleContinue}>
              {i18n.t(`upload_game.page_${step}_button_action`)}
            </Button>
          )}
        </View>
      </View>
    </Layout>
  );
};

export default UploadGame;
