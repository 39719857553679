import React from 'react';
import RNRestart from 'react-native-restart';
import { View } from 'react-native';

import CustomText from '@atoms/CustomText';
import { useTranslations } from '@hooks/useTranslation';
import ExploreEmptyIcon from '@assets/svg/ExploreEmptyIcon';
import Button from '@atoms/Button';
import { moderateVerticalScale } from '@utils/scaling';
import { isDesktop } from '@constants/platform';

import { styles } from './styles';

const NoInternet = () => {
  const i18n = useTranslations();

  const handleRefresh = () => {
    RNRestart.Restart();
  };

  return (
    <View style={[styles.container, isDesktop && { height: moderateVerticalScale(500) }]}>
      <CustomText color="limeGreen" customStyle={[styles.textError]} size="xlarge" weight="interBold">
        {i18n.t('no_internet.title')}
      </CustomText>
      <CustomText customStyle={[styles.textError]} size="big" weight="interMedium">
        {i18n.t('no_internet.subtitle')}
      </CustomText>
      <ExploreEmptyIcon />
      <Button customStyle={styles.button} onPress={handleRefresh}>
        {i18n.t('no_internet.button_action')}
      </Button>
    </View>
  );
};

export default NoInternet;
