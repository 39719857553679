import { StyleSheet } from 'react-native';

import { Colors } from '@theme';
import { moderateScale } from '@utils/scaling';

export const styles = StyleSheet.create({
  container: {
    paddingHorizontal: moderateScale(16),
    paddingVertical: moderateScale(10),
    borderRadius: moderateScale(30),
    borderWidth: 2,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
    marginRight: moderateScale(6),
    marginBottom: moderateScale(12),
  },
  active: {
    backgroundColor: Colors.filterItemLime,
  },
});
