import React, { FC, memo, ReactNode } from 'react';
import { View } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/core';

import { Layout } from '@organisms/Layout';
import GoBackButton from '@molecules/GoBackButton';
import CustomText from '@atoms/CustomText';

import { styles } from './styles';

interface IChangeAvatarLayout {
  title: string;
  step: string;
  children: ReactNode;
  fromModal?: boolean;
  handleBack?: () => void;
}

const LAYOUT_PROPS = {
  withScroll: false,
  padding: false,
  backgroundColor: 'transparent',
  withGradient: true,
};

const ChangeAvatarLayout: FC<IChangeAvatarLayout> = ({ title, step, children, fromModal, handleBack }) => {
  const navigation = useNavigation();
  const handleGoBack = () => {
    if (handleBack) handleBack();
    else navigation.goBack();
  };

  const Wrapper = fromModal ? View : Layout;
  const props = fromModal ? {} : LAYOUT_PROPS;

  return (
    <Wrapper {...props}>
      <View style={styles.goBack}>
        <GoBackButton goBackAction={handleGoBack} gatoLogo={false} />
      </View>
      <CustomText customStyle={styles.header} size="xmedium" weight="interSemi">
        {title}
      </CustomText>
      <View style={styles.step}>
        <LinearGradient
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
          locations={[0.1, 0.4, 1]}
          colors={['#7154FF', '#7154FF', '#3A298E']}
          style={styles.stepContainer}>
          <CustomText weight="interSemi">{`${step}/3`}</CustomText>
        </LinearGradient>
      </View>
      {children}
    </Wrapper>
  );
};

export default memo(ChangeAvatarLayout);
