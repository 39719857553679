import * as React from 'react';
import Svg, { Ellipse, Path, SvgProps, Circle } from 'react-native-svg';

const ExploreEmptyIcon = (props: SvgProps) => (
  <Svg width={208} height={215} fill="none" {...props}>
    <Path
      d="M112.301 66c-25.6 4.4-51.334 15.167-61 20l48 31.5 63-30c-17.2-10.4-40.5-18.667-50-21.5z"
      fill="#6A53DA"
    />
    <Path d="M112.301 109V66.5c-35.2 4.8-55.667 16.333-61.5 21.5l47.5 30 14-9z" fill="#593DE0" />
    <Path
      d="M81.367 88.01c-3.2-.4-3.333 11.5-3 17.5-12.4 5.2-14.5 9.5-14 11 4 5.2 11 7.166 14 7.5 0 13.2 3.667 15.833 5.5 15.5 4-1.2 10-9.5 12.5-13.5 8.4 4 14.5 4 16.5 3.5 1.6-6-2.666-13.167-5-16 7.2-9.6 6.334-13.667 5-14.5-3.2-2-12.333-.167-16.5 1-7.6-10-13.166-12.167-15-12z"
      fill="#F6C37E"
    />
    <Path
      d="M66.367 113.01c-1.019 1.493-1.874 4.946 2.61 4.946 5.603 0 12.226-.996 13.754.498 1.223 1.194 1.868 7.466 2.038 10.452.34.664 1.222 1.792 2.037.996 1.02-.996 4.076-7.964 7.132-7.964 3.057 0 11.717 4.479 13.246 3.484 1.528-.995 1.019-3.484.509-5.973-.509-2.489-1.528-3.982-1.019-4.977.51-.996 4.585-6.969 5.604-7.964.106-.103.198-.219.279-.343.223-.706.438-1.362.64-1.913.03-.124.057-.21.091-.242l-.091.242c-.1.42-.218 1.272-.64 1.913-.884 2.801-1.903 6.385-2.85 7.311-1.223 1.195.509 3.484 1.019 3.982 1.552 1.991 2.978 6.073 2.571 12.444.17.663-.611 1.692-5.094.497-4.483-1.194-9.34-2.82-11.208-3.484-.34-.332-1.223-.697-2.038.498-1.868 3.484-7.422 10.504-11.09 12.097s-5.5-9.5-5.212-15.083c0-.83.893-.996-1.552-.996-3.056 0-10.164-1.991-11.693-3.982-1.528-1.991-1.59-4.448.957-6.439z"
      fill="#EEB26D"
    />
    <Path d="M83.367 92.01l12 12 13-3" stroke="#FADBB8" strokeWidth={2} strokeLinecap="round" />
    <Path
      d="M123.922 77.573c-2.8-1.6-7.5 9.333-9.5 15-13.446.031-17.038 3.193-17.153 4.77 1.692 6.338 7.397 10.845 10.038 12.307-5.076 12.185-2.705 16.026-.884 16.423 4.154.431 12.884-4.923 16.731-7.654 6.215 6.923 11.846 9.269 13.884 9.576 3.785-4.923 2.603-13.179 1.538-16.692 10.339-6.092 11.103-10.18 10.193-11.462-2.185-3.076-11.321-4.897-15.616-5.422-3.169-12.154-7.475-16.295-9.231-16.846z"
      fill="#F6C37E"
    />
    <Path
      d="M100.461 94.88c-1.515.987-3.632 3.846.506 5.57 5.173 2.155 11.669 3.783 12.505 5.749.67 1.573-1.147 7.61-2.139 10.433.059.743.44 2.124 1.499 1.702 1.323-.527 6.824-5.784 9.646-4.608 2.821 1.175 9.093 8.641 10.886 8.31 1.794-.331 2.281-2.824 2.768-5.318.487-2.493.12-4.263.974-4.986.853-.723 6.912-4.669 8.235-5.196.137-.055.267-.126.39-.209.477-.566.927-1.089 1.326-1.52.075-.103.134-.171.177-.189l-.177.189c-.254.349-.691 1.09-1.326 1.52-1.894 2.246-4.212 5.162-5.443 5.652-1.588.633-.87 3.412-.591 4.068.667 2.435.414 6.751-2.412 12.475-.099.678-1.216 1.327-4.894-1.5-3.679-2.826-7.537-6.195-9.006-7.526-.186-.437-.86-1.114-2.072-.324-3.065 2.497-10.891 6.841-14.89 6.901-3.998.059-1.423-10.885.99-15.928.319-.765 1.207-.575-1.05-1.516-2.821-1.175-8.617-5.747-9.262-8.172-.645-2.426.243-4.718 3.36-5.576z"
      fill="#EEB26D"
    />
    <Path
      d="M124.23 82.034l6.462 15.692 13.154 2.23"
      stroke="#FADBB8"
      strokeWidth={2}
      strokeLinecap="round"
    />
    <Path
      d="M39.3 164.5c10 0 17.5-3.667 20-5.5l12.5 11.5c-5.666 3-17.8 9.2-21 10-4 1-10-1-13.5 0s-7 13.5-8.5 15.5-6.5 5.5-11 8-6.5 0-7.5 0c-.8 0-5-4.333-7-6.5-4.4-2.8-1.833-5.167 0-6 17-2.5 13.5-13 17.5-18.5s6-8.5 18.5-8.5z"
      fill="#9CAEF3"
    />
    <Path
      d="M51.8 90l47.5 26.313-3 66.029c-4 1.192-6.666.497-7.5 0-10.5-3.475-37-28.298-38-30.781-.8-1.985-1.333-5.792-1.5-7.447L51.8 90z"
      fill="#6A53DA"
    />
    <Path d="M50.3 130.71v-20.355l4.547-9.433 44.454 14.894-1.516 21.845-47.484-6.951z" fill="#593DE0" />
    <Path
      d="M173.302 117.5c-4.4-2-13.167.833-17 2.5-2.334 8.167-6.2 23.2-3 18 4-6.5 10.5-7.5 12-8.5s10.5-1 16-2c4.4-.8 4.166-1.667 3.5-2-2-1.833-7.1-6-11.5-8z"
      fill="#9CAEF3"
    />
    <Path
      d="M160.801 131l-3-11.5c-2.154 4.5-12.663 10.333-13.5 12.5.574 2 5.624 9.667 7.897 13 .957-.667 2.158-3.517 2.872-7 .718-3.5 4.774-5.667 5.731-7z"
      fill="#7C98E9"
    />
    <Path
      d="M163.801 186c-6.8-1.2-21.5-19.167-28-28l-13.5 10.5c6.166 6.667 20 21.5 26 27.5 7.5 7.5 23 2.5 25.5 2.5s3 2 9 11c4.8 7.2 12 5.333 15 3.5 2.5-2.167 7.8-7.4 9-11 1.2-3.6-1.167-3.833-2.5-3.5-6 3.5-11.5 1.5-13.5 0s-2.5-7-6.5-10-12-1-20.5-2.5z"
      fill="#9CAEF3"
    />
    <Path
      d="M186.301 191c-1.6 4.4-6.5 8.333-9 10 .8 2 4.333 7.833 6.5 10 4-2 11-8.333 12.5-10.5-1 0-4.5-.5-6.5-3.5-2.046-3.068-2.667-5.5-3.5-6z"
      fill="#7C98E9"
    />
    <Path
      d="M63.8 62.5c-3.5-6.166-11.2-17.3-14-12.5-1 .667.4 4.1 14 12.5zM29.3 62.5c7.2-7.6 22 5.833 28.5 13.5-11.2-8.4-20-9.834-23-9.5-6 .4-6.166-2.5-5.5-4zM133.301 48.67c3.5-6.167 11.2-17.3 14-12.5 1 .666-.4 4.1-14 12.5zM167.801 48.67c-7.2-7.6-22 5.832-28.5 13.5 11.2-8.4 20-9.834 23-9.5 6 .4 6.167-2.5 5.5-4z"
      fill="#F6C37E"
    />
    <Path
      d="M150.801 152c-6.4 4.4-39.334 22.167-55 30.5l3.5-64.5 62-26c-2.334 17.167-7.1 52.3-7.5 55.5-.4 3.2-2.167 4.333-3 4.5z"
      fill="#7F66F8"
    />
    <Ellipse cx={120.301} cy={139.5} rx={4} ry={5.5} fill="#283871" />
    <Ellipse cx={143.301} cy={130.5} rx={3} ry={5.5} fill="#283871" />
    <Path
      d="M131.8 143.5c1.2.8 3.167 0 4-.5 2.8-4 3.834-2.334 4-1 0 17.5-13 13.5-12.5 5-.5-2.5 3-4.5 4.5-3.5z"
      fill="#293971"
    />
    <Path d="M128.301 151.5c1.056-1.997 7.333-5.766 10.5-3 2.173 1.897-6.88 10.836-10.5 3z" fill="#D4599A" />
    <Ellipse
      cx={117.885}
      cy={152.728}
      rx={5.086}
      ry={2.033}
      transform="rotate(-21.101 117.885 152.728)"
      fill="#DA6BA1"
    />
    <Ellipse
      cx={146.663}
      cy={140.038}
      rx={3.935}
      ry={1.921}
      transform="rotate(-21.101 146.663 140.038)"
      fill="#DA6BA1"
    />
    <Circle cx={97.301} cy={6} r={6} fill="#D9D9D9" />
    <Circle cx={126.301} cy={21} r={4} fill="#D9D9D9" />
    <Circle cx={121.749} cy={75.296} r={3.368} transform="rotate(6.939 121.749 75.296)" fill="#D9D9D9" />
    <Circle cx={89.95} cy={77.65} r={2.38} transform="rotate(6.939 89.95 77.65)" fill="#D9D9D9" />
    <Circle cx={64.301} cy={27} r={4} fill="#D9D9D9" />
    <Circle cx={124.801} cy={42.5} r={2.5} fill="#D9D9D9" />
    <Circle cx={72.801} cy={68.5} r={2.5} fill="#D9D9D9" />
    <Circle cx={30.801} cy={48.5} r={2.5} fill="#D9D9D9" />
    <Circle cx={169.801} cy={68.5} r={2.5} fill="#D9D9D9" />
    <Path
      d="M35.8 182l-14-10.5c-3 4.5-4.333 10.833-5.5 13 .8 2 7.834 9.667 11 13 1.334-.667 3.006-3.517 4-7 1-3.5 3.167-7.167 4.5-8.5z"
      fill="#7C98E9"
    />
    <Path
      d="M40.802 98.5l10.5-12.5 48 30c-4 3.667-12.4 11.4-14 13-1.6 1.6-6.334.667-8.5 0-11-7.667-33.6-23.4-36-25-2.4-1.6-1-4.333 0-5.5z"
      fill="#7F66F8"
    />
    <Path
      d="M97.3 138l1.538-20.5 55.851-16.5 4.612 12-.513 2c-15.576 8.8-47.482 19-61.487 23z"
      fill="#6A53DA"
    />
    <Path
      d="M161.801 87.5l-60 27c-2 0-1.833 2.333-1.5 3.5 3 4 9.2 12.2 10 13 .8.8 4 .333 5.5 0 18.167-8.5 54.8-25.6 56-26 1.2-.4.5-2.834 0-4-2-3.334-6.2-10.4-7-12-.8-1.6-2.333-1.667-3-1.5z"
      fill="#7F66F8"
    />
    <Path
      d="M96.3 175.5c.667-11.833 2-35.9 2-37.5M104.3 117.254c18.033-7.723 56.101-25.262 58.501-26.388M92.821 116.254c-15.5-8.754-39.6-25.128-42-26.254"
      stroke="#9B86FF"
      strokeWidth={3}
      strokeLinecap="round"
    />
    <Path
      d="M85.3 13c-3.2-.4-3.332 11.5-3 17.5-12.4 5.2-14.5 9.5-14 11 4 5.2 11 7.167 14 7.5 0 13.2 3.668 15.833 5.5 15.5 4-1.2 10-9.5 12.501-13.5 8.4 4 14.5 4 16.5 3.5 1.6-6-2.667-13.167-5-16 7.2-9.6 6.333-13.667 5-14.5-3.2-2-12.334-.167-16.5 1-7.6-10-13.167-12.167-15-12z"
      fill="#F6C37E"
    />
    <Path
      d="M70.3 38c-1.018 1.493-1.873 4.946 2.61 4.946 5.604 0 12.226-.995 13.755.498 1.222 1.194 1.867 7.466 2.037 10.452.34.664 1.223 1.792 2.038.996 1.019-.996 4.075-7.964 7.132-7.964 3.057 0 11.717 4.48 13.245 3.484 1.529-.995 1.019-3.484.51-5.973-.51-2.488-1.529-3.982-1.019-4.977.509-.996 4.585-6.969 5.604-7.964.105-.103.198-.219.279-.342.223-.706.437-1.363.64-1.914.029-.123.057-.21.091-.242-.03.078-.061.159-.091.242-.101.42-.219 1.272-.64 1.914-.885 2.8-1.903 6.384-2.851 7.31-1.222 1.195.51 3.485 1.019 3.982 1.553 1.991 2.979 6.073 2.571 12.444.17.664-.611 1.692-5.094.498-4.483-1.195-9.339-2.82-11.207-3.485-.34-.331-1.223-.696-2.038.498-1.868 3.484-7.422 10.504-11.09 12.097s-5.5-9.5-5.212-15.083c0-.83.893-.996-1.552-.996-3.057 0-10.165-1.99-11.693-3.982-1.528-1.99-1.59-4.448.957-6.439z"
      fill="#EEB26D"
    />
    <Path d="M87.3 17l12 12 13.001-3" stroke="#FADBB8" strokeWidth={2} strokeLinecap="round" />
  </Svg>
);

export default ExploreEmptyIcon;
