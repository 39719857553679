import React, { FC, memo } from 'react';
import { Platform, ViewStyle, Image } from 'react-native';
// import { Image } from 'expo-image';
import { MotiView } from 'moti';

import FastImage from '@atoms/FastImage';
import { Avatar } from '@interfaces/avatar';

import styles from './styles';

interface IAvatarImage {
  avatar: Avatar;
  background?: Avatar;
  customStyle?: ViewStyle;
  withAnimation?: boolean;
  delay?: number;
  transparent?: boolean;
}

const blurHash =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj[';

const CustomImage = Platform.OS !== 'web' ? FastImage : Image;

const AvatarImage: FC<IAvatarImage> = ({
  avatar,
  background,
  transparent = false,
  delay = 1000,
  customStyle,
  ...props
}) => (
  <MotiView
    style={[styles.imageContainer, transparent ? styles.transparent : {}, customStyle || {}]}
    from={{ top: -50, opacity: 0 }}
    animate={{ top: 0, opacity: 1 }}
    transition={{ type: 'spring' }}
    delay={delay}
    {...props}>
    <CustomImage
      style={[styles.imageItem, styles.mt40, background ? styles.cat : {}]}
      resizeMode="cover"
      placeholder={blurHash}
      source={{ uri: avatar.url, priority: FastImage?.priority?.high }}
    />
    {background && (
      <CustomImage
        style={[styles.imageItem, styles.mt40]}
        resizeMode="cover"
        source={{ uri: background.url, priority: FastImage?.priority?.high }}
      />
    )}
  </MotiView>
);

export default memo(AvatarImage);
