import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

import { Colors } from '@theme';

interface LikeProps extends SvgProps {
  dislike?: boolean;
  selected: boolean;
}

const LikeIcon = (props: LikeProps) => {
  const { dislike, selected, ...svgProps } = props;
  return (
    <Svg
      width={25}
      height={28}
      style={{ transform: [{ rotate: dislike ? '180deg' : '0deg' }] }}
      fill="none"
      {...svgProps}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.55 23.147H16.9c1.944 0 3.638-1.281 4.11-3.108l1.716-6.652c.334-1.294-.677-2.549-2.055-2.549h-5.228l1.383-2.298c1.03-1.71-.243-3.856-2.286-3.856h-1.215L9.23 11.628c-.091.155-.14.33-.14.508v8.41c0 .344.178.664.472.854l1.638 1.058c.696.45 1.513.69 2.35.69zM4.325 10.838h1.059c1.17 0 2.118.919 2.118 2.052v7.18c0 1.133-.948 2.051-2.118 2.051H4.326c-1.17 0-2.117-.918-2.117-2.051v-7.18c0-1.133.948-2.052 2.117-2.052z"
        fill={selected ? Colors.limeGreen : '#777E91'}
      />
    </Svg>
  );
};

export default LikeIcon;
