import { StyleSheet } from 'react-native';

import { IS_SMALL_DEVICE, SCREEN_HEIGHT } from '@constants/platform';
import { moderateScale, verticalScale } from '@utils/scaling';
import { LATERALPLUS } from 'app/theme/spacing';
import { Spacing } from '@theme';

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  innerContainer: {
    height: '90%',
  },
  image: {
    width: '100%',
    height: IS_SMALL_DEVICE ? SCREEN_HEIGHT / 2.2 : SCREEN_HEIGHT / 2.5,
    marginLeft: undefined,
  },
  itemText: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: moderateScale(18),
    paddingHorizontal: LATERALPLUS * 2,
    marginVertical: verticalScale(50),
  },
  title: {
    lineHeight: moderateScale(26),
    marginBottom: moderateScale(16),
    paddingHorizontal: IS_SMALL_DEVICE ? 0 : LATERALPLUS,
  },
  description: {
    paddingHorizontal: IS_SMALL_DEVICE ? 0 : moderateScale(20),
  },
  onboarding1: { alignSelf: 'flex-end', marginRight: -Spacing.MEDIUM },
  onboarding2: { paddingTop: '0.5%', marginLeft: -Spacing.MEDIUM * 2 - 1 },
});
