/* eslint-disable @typescript-eslint/naming-convention */
import UploadGameIcon from '@assets/svg/UploadGameIcon';
import AboutGatoIcon from '@assets/svg/AboutGatoIcon';
import HelpIcon from '@assets/svg/HelpIcon';
import AccountSettingsIcon from '@assets/svg/AccountSettingsIcon';

export const SETTINGS_OPTIONS = [
  { id: 'upload_game', route: 'UploadGame', Icon: UploadGameIcon },
  { id: 'about_gato', route: 'AboutGATO', Icon: AboutGatoIcon },
  { id: 'need_help', route: 'Help', Icon: HelpIcon },
  { id: 'account_settings', route: 'AccountSettings', Icon: AccountSettingsIcon },
];
