import React from 'react';
import { View, ViewStyle } from 'react-native';

import { styles } from './styles';

interface DesktopCenteredContainerProps {
  children: React.ReactNode;
  style?: ViewStyle;
}

const DesktopCenteredContainer = ({ children, style }: DesktopCenteredContainerProps) => (
  <View style={styles.container}>
    <View style={[styles.innerContainer, style]}>{children}</View>
  </View>
);

export default DesktopCenteredContainer;
