import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';

const Report = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <G fill="#DD438D" clipPath="url(#a)">
      <Path
        fillRule="evenodd"
        d="m16 9 3.17-3.698C20.282 4.004 19.36 2 17.652 2H4v14h13.652c1.708 0 2.63-2.004 1.518-3.302L16 9ZM6 14h11.652l-4.286-5 4.286-5H6v10Z"
        clipRule="evenodd"
      />
      <Path d="M4 1a1 1 0 0 1 2 0v22a1 1 0 1 1-2 0V1Z" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default Report;
