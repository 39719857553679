import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EyeClosed = (props: SvgProps) => (
  <Svg width={22} height={23} fill="none" {...props}>
    <Path
      d="M4.197 3.636a.75.75 0 10-1.061 1.061l1.06-1.06zm13.606 15.728a.75.75 0 101.06-1.06l-1.06 1.06zm-4.47-5.255a.75.75 0 00-1-1.118l1 1.118zM9.51 10.167a.75.75 0 10-1.118-1l1.118 1zM2.63 13.468l-.543.517.543-.517zm16.74-3.936l.543-.517-.544.517zm0 3.936l.543.517-.544-.517zM2.63 9.532l-.543-.517.543.517zm14.851 4.71a.75.75 0 10.985 1.13l-.985-1.13zM8.404 4.745a.75.75 0 00.448 1.431l-.448-1.431zM6.362 7.369a.75.75 0 10-.808-1.265l.808 1.265zm9.759 9.728a.75.75 0 00-.774-1.286l.774 1.286zM3.136 4.697l14.667 14.667 1.06-1.06L4.197 3.635 3.136 4.697zM7.5 11.5A3.5 3.5 0 0011 15v-1.5a2 2 0 01-2-2H7.5zM11 15a3.49 3.49 0 002.333-.891l-1-1.118a1.99 1.99 0 01-1.333.51V15zM8.39 9.167A3.49 3.49 0 007.5 11.5H9c0-.513.192-.979.509-1.333l-1.118-1zm-6.304 4.818c1.808 1.903 5.08 4.682 8.913 4.682v-1.5c-3.162 0-6.05-2.346-7.826-4.216l-1.087 1.034zm17.826-4.97C18.104 7.112 14.833 4.333 11 4.333v1.5c3.162 0 6.049 2.346 7.826 4.216l1.087-1.034zm0 4.97a3.577 3.577 0 000-4.97l-1.087 1.034a2.078 2.078 0 010 2.902l1.087 1.034zM3.173 12.95a2.078 2.078 0 010-2.902L2.088 9.015a3.578 3.578 0 000 4.97l1.087-1.034zm15.293 2.422c.544-.474 1.03-.95 1.447-1.388l-1.087-1.034c-.39.41-.842.852-1.345 1.29l.985 1.132zM11 4.333c-.904 0-1.774.155-2.596.413l.448 1.431A7.165 7.165 0 0111 5.833v-1.5zM5.554 6.104c-1.404.897-2.59 1.989-3.467 2.911l1.087 1.034c.824-.868 1.918-1.87 3.188-2.68l-.808-1.265zM11 18.667c1.892 0 3.638-.678 5.12-1.57l-.773-1.286c-1.336.805-2.814 1.356-4.347 1.356v1.5z"
      fill="#777E91"
    />
  </Svg>
);

export default EyeClosed;
