import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const EyeOpened = (props: SvgProps) => (
  <Svg width={22} height={23} fill="none" {...props}>
    <Path
      d="M2.631 13.468l-.498.474.498-.474zm0-3.936l-.498-.473.498.473zm16.74 3.936l.497.474-.498-.474zm0-3.936l.497-.473-.498.473zm-.5 3.463c-1.777 1.87-4.68 4.234-7.87 4.234v1.375c3.805 0 7.06-2.76 8.867-4.662l-.996-.947zm-7.87 4.234c-3.19 0-6.093-2.363-7.871-4.234l-.997.947C3.94 15.843 7.196 18.604 11 18.604V17.23zM3.13 10.006C4.908 8.135 7.81 5.77 11 5.77V4.396c-3.804 0-7.06 2.761-8.867 4.663l.997.947zM11 5.77c3.19 0 6.093 2.364 7.872 4.235l.996-.947c-1.807-1.902-5.062-4.663-8.867-4.663v1.375zm-7.87 7.224a2.14 2.14 0 010-2.99l-.997-.946a3.515 3.515 0 000 4.883l.997-.947zm16.738.947a3.515 3.515 0 000-4.883l-.996.947a2.14 2.14 0 010 2.989l.996.947zM13.063 11.5c0 1.14-.923 2.063-2.062 2.063v1.375a3.438 3.438 0 003.437-3.438h-1.375zm-2.062 2.063A2.063 2.063 0 018.938 11.5H7.563a3.438 3.438 0 003.438 3.438v-1.375zM8.938 11.5c0-1.139.924-2.062 2.063-2.062V8.063A3.438 3.438 0 007.563 11.5h1.375zm2.063-2.062c1.139 0 2.062.923 2.062 2.062h1.375a3.438 3.438 0 00-3.437-3.437v1.375z"
      fill="#777E91"
    />
  </Svg>
);

export default EyeOpened;
