import * as React from 'react';
import { memo } from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const GatoBannerOne = (props: SvgProps) => (
  <Svg width={164} height={143} fill="none" {...props}>
    <Path
      d="M72.304 143c22.726 0 41.149-2.619 41.149-5.85 0-3.231-18.423-5.85-41.15-5.85-22.726 0-41.15 2.619-41.15 5.85 0 3.231 18.424 5.85 41.15 5.85z"
      fill="#2D1A87"
    />
    <Path
      d="M43.27 23.966s.44-6.803-.573-11.396c-1.014-4.593 4.846-6.37 8.459-2.513 3.612 3.856 3.789 8.883 1.938 12.87-1.85 3.986-4.406 5.2-4.406 5.2s-4.582-2.34-5.419-4.16z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M37.013 21.974s1.674-2.384 6.212-1.084c4.538 1.3 6.608 5.763 6.96 7.713.353 1.95-.924 3.814-3.876 3.164-2.908-.607-7.886-5.59-7.886-5.59l-1.41-4.203z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M31.197 23.057s2.16-2.21 5.86-1.084c3.657 1.084 4.538 3.944 4.758 5.07.265 1.127 1.586 5.547-1.674 7.063-3.216 1.517-5.595-.91-6.609-2.99-.969-2.123-2.335-8.06-2.335-8.06z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M42.035 68.599s-10.53 1.82-13.834-8.45c-3.304-10.27 0-16.9 0-16.9s-7.225-2.253-7.27-9.533c-.087-10.096 7.579-11.57 11.5-10.356 2.908.866 4.01 5.98 3.172 8.45l-1.101 1.906s2.51-2.557 6.52-1.213c0 0-2.247-5.33 3.172-6.24 6.345-1.04 9.781 6.153 9.693 8.623-.088 2.47-1.013 10.66-8.327 11.656-7.27.997-9.604-.086-9.604-.086s-2.82 7.973 1.453 11.266c4.23 3.293 7.622 1.82 7.622 1.82s1.278 7.67-2.995 9.057z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path d="M27.937 28.126s-.88 3.337 1.366 5.07c2.247 1.733 5.243 2.037 6.3-1.387" fill="#fff" />
    <Path
      d="M27.937 28.126s-.88 3.337 1.366 5.07c2.247 1.733 5.243 2.037 6.3-1.387"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path d="M41.023 32.547s1.41 2.946 4.273 4.203l-4.273-4.203z" fill="#fff" />
    <Path
      d="M41.023 32.547s1.41 2.946 4.273 4.203"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M132.399 57.592c.748 4.724.748 9.36.088 13.823-2.159 14.517-11.499 26.866-24.717 32.11h-.044c-1.762.693-9.032 3.466-10.926 3.986-1.057.26-1.983.52-2.776.694-3.26.823-4.89 1.083-4.89 1.083l-.925-3.51c-17.007-2.47-31.546-16.206-35.07-34.926-3.128-16.553 3.26-32.5 15.112-41.686L67.678 27l8.723-2.687c2.644-1.127 6.565-1.82 9.473-2.34 21.852-3.943 41.943 11.397 46.304 34.536.132.347.176.693.221 1.083z"
      fill="#D4BFFA"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M68.603 105.821l-2.732 7.453s-5.595-12.783-19.473-12.783c-14.099 0-10.574 11.397-5.508 17.767 6.961 8.753 15.64 17.809 22.25 19.066 8.943 1.69 11.41-3.553 10.309-8.667-1.102-5.113-.132-13.519 1.366-15.989 1.498-2.427 2.996-5.72 2.996-5.72s-2.16-4.42-9.208-1.127z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M67.325 109.288c-.044.13.925 1.517 3.084 2.253 2.115.737 5.023 0 5.023 0l1.85-3.683-8.723-2.036-1.234 3.466z"
      fill="#00295B"
    />
    <Path d="M40.89 105.474s6.61-2.73 17.183 7.496c10.574 10.27 11.455 18.33 11.455 18.33" fill="#fff" />
    <Path
      d="M40.89 105.474s6.61-2.73 17.183 7.496c10.574 10.27 11.455 18.33 11.455 18.33"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path d="M63.845 109.895s3.48 4.463 4.494 10.66l-4.494-10.66z" fill="#fff" />
    <Path
      d="M63.845 109.895s3.48 4.463 4.494 10.66"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M119.049 128.614c0 10.963-12.953 11.656-17.403 11.656-4.45 0-15.508.087-15.508-7.626 0-7.757 5.11-8.927 5.11-8.927s-8.15-.65-10.397-2.08c-2.291-1.43-2.82-4.16-2.203-6.196 1.454-4.724 8.37-9.187 8.37-9.187H97.24c-1.277 2.643-5.595 6.283-7.886 8.103-.529.434-.925.737-1.19.91l-.132.087-.132.087c.176 1.82 1.674 2.21 3.04 2.21.97 0 1.939-.217 2.291-.26.881-.087 13.966-1.95 17.182-1.734 3.217.217 8.636 1.994 8.636 12.957z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path d="M91.249 123.717s3.92-1.82 12.864-.953c8.68.866 11.367 5.849 11.367 5.849" fill="#fff" />
    <Path
      d="M91.249 123.717s3.92-1.82 12.864-.953c8.68.866 11.367 5.849 11.367 5.849"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M132.486 71.372c-2.159 14.516-11.499 26.866-24.716 32.109h-.044c-1.762.694-9.032 3.467-10.926 3.987-1.058.26-1.983.52-2.776.693 3.04-2.686 20.839-18.806 27.007-32.586l11.455-4.203z"
      fill="#A07CDE"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M89.134 109.287c-5.331.954-10.618.78-15.597-.39-7.137-1.646-13.702-5.33-19.033-10.443-15.42-15.73-14.054-34.492-12.468-41.772 1.674-7.453 5.199-14.213 10.221-19.543 5.508-5.893 12.777-10.053 21.148-11.57a36.66 36.66 0 0113.041-.087c16.654 2.774 30.885 16.38 34.321 34.796 1.63 8.71.661 17.29-2.379 24.743-5.023 12.35-15.64 21.796-29.254 24.266z"
      fill="#D4BFFA"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M118.388 84.979l-9.252-1.734c3.084-6.326 4.273-13.78 2.819-21.406-2.687-14.256-13.614-24.83-26.478-27.04l.969-9.36c16.698 2.73 30.884 16.38 34.321 34.797 1.674 8.71.661 17.246-2.379 24.743z"
      fill="#B393EB"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M89.838 99.409c-.793.216-1.63.39-2.467.563-16.962 3.077-33.484-9.013-36.832-26.996-3.392-17.983 7.622-35.1 24.628-38.176.661-.13 1.278-.217 1.895-.26 16.3-1.907 31.677 9.967 34.937 27.256 3.217 17.116-6.608 33.41-22.16 37.613z"
      fill="#A07CDE"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M109.18 83.244c-3.833 7.8-10.706 13.867-19.297 16.163-15.95 1.3-30.84-10.443-34.013-27.472-3.172-16.77 6.212-32.803 21.236-37.396 2.864-.347 5.683-.217 8.459.216 12.865 2.21 23.835 12.783 26.479 27.04 1.365 7.67.22 15.123-2.864 21.45z"
      fill="#B393EB"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M39.392 49.185l24.672 1.907c1.146.087 2.071.867 2.336 1.95.308 1.17.925 2.513 2.158 2.903 1.322.39 2.203-1.127 2.776-2.427.44-1.04 1.498-1.733 2.644-1.646l31.324 2.426 8.504.65s2.951 1.517 1.894 4.68c-1.057 3.164-5.992 3.467-5.992 3.467s-2.511 17.42-8.327 17.723c-4.23.216-12.292-.91-16.433-1.17-3.569-.217-6.741-2.383-8.063-5.633 0-.044-.044-.087-.044-.13-1.894-4.81-4.494-11.743-8.194-12.047-3.657-.303-3.966 1.43-4.803 2.904-.837 1.473-2.952 8.406-2.952 8.406s-1.365 3.727-6.256 3.337c-4.89-.39-11.014-.867-11.014-.867s-5.64-1.993-5.64-8.406c0-6.414.089-14.344.089-14.344l-.397-1.69c-.308-1.04.573-2.08 1.718-1.993z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M76.093 56.033c-1.454.39-1.939 2.253-1.146 4.593 1.014 3.033 3.349 8.103 5.155 12.393.925 2.253 3.084 3.77 5.551 3.943l14.099 1.04s2.643.434 4.494-4.983c1.277-3.727 2.423-9.62 2.996-12.956.22-1.344-.793-2.6-2.159-2.687l-27.58-1.69c-.485-.043-.97.087-1.41.347zM42.08 52.912a1.92 1.92 0 00-2.07 1.907c.087 3.9.264 12.176.616 14.17.44 2.6 2.203 3.553 4.494 4.116 2.291.564 9.825.78 9.825.78s2.643-.52 3.612-2.47l.97-1.95 3.128-12.87c.264-1.126-.573-2.253-1.762-2.34L42.08 52.913z"
      fill="#5940D0"
    />
    <Path
      d="M55.077 56.769l1.278 13.433c.044.433-.22.867-.617.997-.353.13-.793.216-1.366.216-.352 0-.617-.043-.837-.087a.977.977 0 01-.881-.866L51.2 56.942a1.008 1.008 0 011.013-1.083l1.895.043c.484 0 .925.39.969.867zM49.922 56.336c.22 2.296 1.101 11.353 1.366 14.083.044.39-.309.736-.705.693-.397-.043-.881-.087-1.234-.13a.65.65 0 01-.573-.563l-1.41-14.04c-.044-.39.309-.737.705-.693l1.278.13c.309-.044.573.216.573.52zM93.186 60.192l1.278 13.433c.044.433-.22.867-.617.997-.352.13-.793.216-1.365.216-.353 0-.617-.043-.838-.086a.977.977 0 01-.88-.867l-1.366-13.52a1.008 1.008 0 011.013-1.083l1.894.043c.44-.043.837.347.881.867zM88.076 59.715c.22 2.297 1.102 11.353 1.366 14.083.044.39-.308.737-.705.693-.396-.043-.881-.086-1.234-.13a.65.65 0 01-.572-.563l-1.41-14.04c-.044-.39.308-.736.705-.693l1.277.13c.309-.043.53.217.573.52z"
      fill="#fff"
    />
    <Path d="M89.354 114.357c-.53.434-.926.737-1.19.91l1.19-.91z" fill="#00295B" />
    <Path
      d="M135.13 104.217s3.128-.823 5.947-.606c2.776.216 3.084 2.123 2.732 3.293l-9.34.303.661-2.99z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M123.191 75.705s14.01-3.033 19.87 8.537c5.859 11.57-8.151 21.406-8.151 21.406s9.825 1.647 11.147 6.153c.793 2.643-3.657 5.72-11.147 6.283-7.49.564-16.918-.65-17.755-10.183-.969-11.136 14.759-10.183 17.094-16.813 2.335-6.63-4.626-9.1-9.384-8.796"
      fill="#fff"
    />
    <Path
      d="M123.191 75.705s14.01-3.033 19.87 8.537c5.859 11.57-8.151 21.406-8.151 21.406s9.825 1.647 11.147 6.153c.793 2.644-3.657 5.72-11.147 6.283-7.49.564-16.918-.65-17.755-10.183-.969-11.136 14.759-10.183 17.094-16.813 2.335-6.63-4.626-9.1-9.384-8.796"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M135.79 106.038s3.128-.824 5.948-.607c2.82.217 3.084 2.123 2.732 3.293l-9.341.304.661-2.99z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M136.495 108.334s3.128-.824 5.948-.607c2.776.217 3.084 2.123 2.732 3.293l-9.341.304.661-2.99z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M135.13 104.174s3.084 3.9 0 8.277c0 0-3.922.606-5.992-1.17-2.071-1.777-2.291-5.46-2.291-5.46"
      fill="#fff"
    />
    <Path d="M135.129 104.174s3.084 3.9 0 8.277v-8.277z" fill="#fff" />
    <Path d="M135.129 104.174s3.084 3.9 0 8.277" stroke="#00295B" strokeWidth={0.453} strokeMiterlimit={10} />
    <Path
      d="M146.1 111.845c-.221-.91-.793-1.647-3.878-1.647-3.084 0-6.388 1.127-6.388 1.127l-.44.823"
      fill="#fff"
    />
    <Path
      d="M146.1 111.845c-.221-.91-.793-1.647-3.878-1.647-3.084 0-6.388 1.127-6.388 1.127l-.44.823"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M122.045 86.105s3.304-.087 5.639-5.157c2.335-5.07.573-7.973.573-7.973l-7.226 2.643"
      fill="#A07CDE"
    />
    <Path
      d="M122.045 86.105s3.304-.087 5.639-5.157c2.335-5.07.573-7.973.573-7.973l-7.226 2.643"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M73.933 92.995c-.793 0-1.63-.044-2.467-.087-16.477-.91-19.253-10.92-19.385-11.353a1.765 1.765 0 011.277-2.123c.926-.217 1.895.303 2.16 1.256.087.347 2.422 8.017 16.168 8.753 13.218.737 15.861-4.896 15.905-4.94.353-.91 1.366-1.3 2.291-.953.925.347 1.366 1.387 1.014 2.253-.177.304-3.084 7.194-16.963 7.194z"
      fill="#fff"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M163.15 49.792c-7.534-.606-14.362-9.533-14.362-9.533s-.573 11.18-6.477 15.816c0 0 8.195 1.257 14.363 9.534.44-10.27 6.476-15.817 6.476-15.817z"
      fill="#E5F68C"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M106.184 5.377C102.748 5.117 99.575 1 99.575 1s-.264 5.113-2.951 7.237c0 0 3.744.563 6.608 4.376.176-4.68 2.952-7.236 2.952-7.236zM19.742 90.655c3.216-1.3 4.935-6.11 4.935-6.11s1.806 4.81 5.066 6.023c0 0-3.392 1.69-4.934 6.11-1.63-4.42-5.067-6.023-5.067-6.023z"
      fill="#FE713C"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
    <Path
      d="M1.15 72.542c7.05-2.73 10.927-13.217 10.927-13.217s3.877 10.53 10.882 13.26c0 0-7.446 3.553-10.926 13.216C8.596 76.095 1.15 72.541 1.15 72.541z"
      fill="#E5F68C"
      stroke="#00295B"
      strokeWidth={0.453}
      strokeMiterlimit={10}
    />
  </Svg>
);

export default memo(GatoBannerOne);
