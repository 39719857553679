import React from 'react';
import { Pressable } from 'react-native';

import { CustomModal } from '@molecules/CustomModal';
import CustomText from '@atoms/CustomText';
import {
  modalSubMenuOpenSelector,
  toggleSubMenuModalHandler,
  useStore,
  subMenuModalInfoSelector,
} from '@store/index';

import { styles } from './styles';

export const SubMenuModal = () => {
  const modalOpen = useStore(modalSubMenuOpenSelector);
  const modalInfo = useStore(subMenuModalInfoSelector);
  const toggleSubMenuModal = useStore(toggleSubMenuModalHandler);

  const handleClose = () => {
    toggleSubMenuModal();
  };

  return (
    <CustomModal visible={modalOpen} onOverlayPress={handleClose} bottomFixed={false}>
      {modalInfo?.menues?.map((submenues) => (
        <Pressable onPress={submenues?.buttonAction} style={styles.submenu} key={submenues.id}>
          <CustomText numberOfLines={2} size="xmedium" color="error" weight="interSemi">
            {submenues?.text}
          </CustomText>
          {submenues?.icon}
        </Pressable>
      ))}
    </CustomModal>
  );
};
