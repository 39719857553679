import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

const CopyLinkIcon = ({ fill, ...props }: SvgProps) => (
  <Svg width={25} height={24} fill="none" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.636 5.864a3.964 3.964 0 00-5.606 0L11.364 7.53a.75.75 0 11-1.061-1.06l1.666-1.667a5.464 5.464 0 017.728 7.728l-1.667 1.666a.75.75 0 11-1.06-1.06l1.666-1.667a3.964 3.964 0 000-5.606zm-3.484 3.484a.75.75 0 010 1.061l-4.243 4.243a.75.75 0 11-1.06-1.06l4.242-4.244a.75.75 0 011.06 0zm-3.182 8.789a3.964 3.964 0 01-5.607-5.607l1.667-1.666a.75.75 0 10-1.06-1.06L5.302 11.47a5.464 5.464 0 107.727 7.727l1.667-1.667a.75.75 0 10-1.06-1.06l-1.668 1.667z"
      fill={fill || '#777E91'}
    />
  </Svg>
);

export default CopyLinkIcon;
