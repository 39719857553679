import useFeatureFlag, { halloweenEventSelector } from '../store/featureFlag';
import logoColors from '../constants/logoColors';

interface EventColor {
  logoColor: string;
}

const useEventColor = (): EventColor => {
  const halloweenEvent = useFeatureFlag(halloweenEventSelector);
  const { defaultColor, halloween } = logoColors;
  const logoColor = halloweenEvent ? halloween : defaultColor;

  return { logoColor };
};

export default useEventColor;
