import * as React from 'react';
import Svg, { SvgProps, Rect, Path } from 'react-native-svg';

export const BackButtonVideoAd = (props: SvgProps) => (
  <Svg width={40} height={40} fill="none" {...props}>
    <Rect width={40} height={40} rx={20} fill="#6F767E" fillOpacity={0.5} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.207 15.793a1 1 0 010 1.414L19.414 20l2.793 2.793a1 1 0 01-1.414 1.414l-3.5-3.5a1 1 0 010-1.414l3.5-3.5a1 1 0 011.414 0z"
      fill="#FCFCFC"
    />
  </Svg>
);
