import AsyncStorage from '@react-native-async-storage/async-storage';
import { useState, useEffect, useRef } from 'react';

interface TimerHook {
    seconds: number;
    startTimer: () => void;
    pauseTimer: () => void;
    resumeTimer: () => void;
    resetTimer: () => void;
}

const useTimer = (): TimerHook => {
  const [seconds, setSeconds] = useState<number>(0);
  const [isRunning, setIsRunning] = useState<boolean>(false);
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (isRunning) {
      intervalRef.current = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isRunning]);

  const startTimer = (): void => {
    setSeconds(0);
    setIsRunning(true);
  };

  const pauseTimer = (): void => {
    setIsRunning(false);
  };

  const resumeTimer = (): void => {
    setIsRunning(true);
  };

  const resetTimer = (): void => {
    setSeconds(0);
    setIsRunning(false);
  };

  AsyncStorage.setItem('sessionSeconds', seconds.toString());
  return {seconds, startTimer, pauseTimer, resumeTimer, resetTimer};
};

export default useTimer;
