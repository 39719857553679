export enum ControlTypeMobileEnum {
  TAP = 'TAP',
  GAMEPAD = 'GAMEPAD',
  SWIPE = 'SWIPE',
}

export enum ControlTypeDesktopEnum {
  KEYBOARD = 'KEYBOARD',
  MOUSE = 'MOUSE',
  BOTH = 'BOTH',
}

export enum ControlTypeEnum {
  TAP = 'TAP',
  GAMEPAD = 'GAMEPAD',
  SWIPE = 'SWIPE',
  KEYBOARD = 'KEYBOARD',
  MOUSE = 'MOUSE',
  BOTH = 'BOTH',
}
